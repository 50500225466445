import store from '../store'
import { Auth, API } from 'aws-amplify';
import * as queries from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { GetUserQuery, ListUsersQuery, PGCService } from "@/API";
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export function getUser(isBypassCache = false) {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return Auth.currentAuthenticatedUser({ bypassCache: isBypassCache })
    .then((data: any) => {
      if (data && data.signInUserSession) {
        store.commit('setUser', data);
        return data;
      }
    }).catch(() => {
      store.commit('setUser', null);
      return null;
    });
}

/**
 * アカウントIDによるドメイン制限の判定を
 * @param user 
 * @param accountId 
 * @returns 
 */
export function verifyAccountId(user: any, accountId: string) {
  const attributes = user.attributes;
  return attributes && attributes["custom:account_id"] ? (attributes["custom:account_id"] === accountId) : false;
}

export function getAccountId(user: any | null) {
  if(user === null) user = store.state.user;
  return user && user.attributes && user.attributes["custom:account_id"] ? user.attributes["custom:account_id"] : null;
}

export function getDepartment(user: any | null) {
  if(user === null) user = store.state.user;
  return user && user.attributes && user.attributes["custom:department"] ? user.attributes["custom:department"] : null;
}

/**
 * アカウント内のユーザー一覧取得
 * @returns 
 */
export async function listUsers(service = PGCService.MANAGEMENT, unselected = "未割り当て") {
  const user = store.getters.user;
  const res = (await API.graphql({
    query: queries.listUsers,
    variables: { 
      input: {
        accountId: getAccountId(user), 
        service: service 
      }
    }
  }) as GraphQLResult<ListUsersQuery>);
  const result = new Array<{ key: string | undefined; val: string | undefined }>();
  
  if (unselected !== "") {
    const data = { key: unselected, val: "" }
    result.push(data)
  }
  
  if (res && res.data && res.data.listUsers && res.data.listUsers.Items) {
    for (const row of res.data.listUsers.Items) {
      if (row) {
        const data = { key: row.name, val: row.id };
        result.push(data);
      }
    }
  }
  return result
}


export async function getUserName(userId: string | undefined) {
  try {
    if(!userId){
      return "";
    }

    const res = (await API.graphql({
      query: queries.getUser,
      variables: { input: { id: userId } }
    })) as GraphQLResult<GetUserQuery>;
    return res?.data?.getUser?.name || "";
  } catch (error) {
    console.error(error)
    return ""
  }
}

export async function isValidSession(session: CognitoUserSession): Promise<boolean> {
  const check = Math.floor(Date.now() / 1000) - 60;
  const idTokenExpiration = session.getIdToken().getExpiration();
  const accessTokenExpiration = session.getAccessToken().getExpiration();
  return idTokenExpiration < check && accessTokenExpiration < check ? true : false;
}

export async function forceRefreshSession(): Promise<CognitoUserSession> {
  const user = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  return user.refreshSession(currentSession.getRefreshToken(), (err, session) => {
    if(err) throw err;
    return session as CognitoUserSession;
  });
}

export async function getSession(): Promise<CognitoUserSession> {
  try
  {
    let session = await Auth.currentSession();
    if(!isValidSession(session))
    {
      session = await forceRefreshSession();
    }
    return session;
  }
  catch(error)
  {
    return await forceRefreshSession();
  }
}




