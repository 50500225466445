import { Translations } from "@aws-amplify/ui-components";

export const vocabularies = {
  ja: {
    //利用しているメッセージのみ日本語化
    [Translations.BACK_TO_SIGN_IN]: 'サインインに戻る',
    [Translations.CHANGE_PASSWORD_ACTION]: '変更',
    [Translations.CHANGE_PASSWORD]: 'パスワード変更',
    [Translations.CODE_LABEL]: '認証コード確認',
    [Translations.CODE_PLACEHOLDER]: '認証コードを入力',
    [Translations.CONFIRM_TOTP_CODE]: '二要素認証',
    [Translations.CONFIRM]: '確認',
    [Translations.EMAIL_LABEL]: 'Emailアドレス *',
    [Translations.EMAIL_PLACEHOLDER]: 'Emailアドレスを入力してください',
    [Translations.FORGOT_PASSWORD_TEXT]: 'パスワードをお忘れですか？',
    [Translations.NEW_PASSWORD_LABEL]: '新しいパスワード',
    [Translations.NEW_PASSWORD_PLACEHOLDER]: 'パスワードを入力してください',
    [Translations.USERNAME_REMOVE_WHITESPACE]: '空白は入力できません',
    [Translations.PASSWORD_REMOVE_WHITESPACE]: '空白は入力できません',
    [Translations.PASSWORD_LABEL]: 'Password *',
    [Translations.PASSWORD_PLACEHOLDER]: 'パスワードを入力してください',
    [Translations.QR_CODE_ALT]: 'qrコード',
    [Translations.RESET_PASSWORD_TEXT]: 'パスワード再設定',
    [Translations.RESET_YOUR_PASSWORD]: 'コードを送信してパスワードを再設定します',
    [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: '確認',
    [Translations.SEND_CODE]: '再設定コードを送信',
    [Translations.SUBMIT]: '送信',
    [Translations.SETUP_TOTP_REQUIRED]: '二要素認証を設定してください',
    [Translations.SIGN_IN_ACTION]: 'ログイン',
    [Translations.SIGN_IN_HEADER_TEXT]: 'ログインしてください',
    [Translations.SIGN_IN_TEXT]: 'ログイン',
    [Translations.SIGN_OUT]: 'ログアウト',
    [Translations.SUCCESS_MFA_TYPE]: '二要素認証の設定が完了しました',
    [Translations.TOTP_HEADER_TEXT]: '二要素認証を設定してください、QRコードをスキャンして発行された認証コードを入力します。',
    [Translations.TOTP_LABEL]: '認証コードを入力',
    [Translations.TOTP_ISSUER]: 'AWSCognito',
    [Translations.TOTP_SETUP_FAILURE]: '二要素認証の設定に失敗しました',
    [Translations.TOTP_SUBMIT_BUTTON_TEXT]: '認証コード確認',
    [Translations.TOTP_SUCCESS_MESSAGE]: '二要素認証の設定が完了しました',
    [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]: 'Failed! Unable to configure MFA at this time',
    [Translations.USERNAME_LABEL]: 'Emailアドレス *',
    [Translations.USERNAME_PLACEHOLDER]: 'Emailアドレスを入力してください',
    [Translations.SIGN_UP_FAILED]: 'ログインできませんでした',
    //定義されないエラーメッセージの変換
    'Username cannot be empty': 'メールアドレスを入力してください',
    'Password cannot be empty': 'パスワードを入力してください',
    'User does not exist.': 'ログインできません、メールアドレスかパスワードが間違っています',
    'Incorrect username or password.': 'ログインできません、メールアドレスかパスワードが間違っています',
    'Confirmation code cannot be empty': '認証コードを入力してください',
    'Invalid code received for user': '認証コードが間違っています',
    'Invalid verification code provided, please try again.': '認証コードが間違っています',
    'Invalid session provided for the user.': 'セッションの有効期限が切れました',
    'Username/client id combination not found.': 'メールを送信できませんでした',
    'Password does not conform to policy: Password not long enough': 'パスワードは12文字以上です',
    'Invalid session for the user, session is expired.': 'セッションの有効期限が切れました',
    'Password does not conform to policy: Password must have symbol characters': 'パスワードに記号を含めてください',
    'Password does not conform to policy: Password must have uppercase characters': 'パスワードに大文字を含めてください',
    'Password does not conform to policy: Password must have lowercase characters': 'パスワードに小文字を含めてください',
    'Password does not conform to policy: Password must have numeric characters': 'パスワードに数字を含めてください',
    'Password does not conform to policy: Password must satisfy regular expression pattern: ^\\S.*\\S$': 'パスワードは12文字以上かつ大文字小文字、記号を含めてください',
    'User is disabled.': 'このユーザーは無効です',
    'User password cannot be reset in the current state.': 'パスワード再設定に失敗しました',
    'Password did not conform with policy: Password not long enough': 'パスワードは12文字以上です',
    'Password did not conform with policy: Password must have symbol characters': 'パスワードに大文字を含めてください',
    'Password must have uppercase characters': 'パスワードに大文字を含めてください',
    'Attempt limit exceeded, please try after some time.': '試行回数の上限に達しました、5分程度時間を空けてお試しください',
    'Password reset required for the user': 'パスワードをリセットする必要があります。',
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$": 'パスワードは12文字以上かつ大文字小文字、記号を含めてください'
  }
}