




import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/Loading.vue";
import UserDetails from "@/components/user/UserDetails.vue";

@Component({
  components: {
    Loading,
    UserDetails
  },
})
export default class UserDetailsView extends Vue {
  name = "UserDetailsView";
}
