
import { Component, Vue } from "vue-property-decorator";
import { uuid } from "vue-uuid";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import { API, graphqlOperation, Storage } from "aws-amplify";
import moment from "moment";
import { UploadFile, ReuseItem, ShareItem, SaleItem, YearItem, MonthItem } from "@/services/files/FileUpload";
import { Coverage, FileGroup, CreateFileGroupInput, CreateFileInput, UpdateFileGroupInput, GetFileGroupQuery, GetCoverageQuery, UpdateFileInput } from '@/API';
import awsconfig from "@/aws-exports";
import { GraphQLResult } from "@aws-amplify/api";
import { FileSearchService, SearchCondition, FileData } from '@/services/files/FileSearch';
import * as _async from 'async';
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";
// import { getSession } from "@/utils/AuthUtils";
import { sleep } from "@/utils/CommonUtils";
import { createMonthItems, createYearItems } from "@/utils/FileUtils";

@Component
export default class FileUploadBaseForm extends Vue {
  name = "file-upload-base-form";
  protected valid = false;
  protected isCoverageRead = false;

  // Uploading flag
  protected uploading = false;
  protected uploadCompleted = false;
  // Progress Unit:percent
  protected progress = 0;
  // Error Alerter
  protected hasError = false;
  protected errorMessage = "";
  // Upload Cancel flag
  protected cancel = false;
  private readonly UPLOAD_PARALLEL = window.navigator.hardwareConcurrency && Math.floor(window.navigator.hardwareConcurrency / 3) > 0 ? Math.floor(window.navigator.hardwareConcurrency / 3) : 1;
  protected readonly UPLOAD_LIMIT_COUNT = 10000;
  protected readonly INSERT_LIMIT_COUNT = FileSearchService.SEARCH_LIMIT;
  private readonly WAIT_TIME_MS = this.UPLOAD_PARALLEL > 1 ? this.UPLOAD_PARALLEL * 100 : 0;
  private readonly ONE_MB = 1024 * 1024 * 1024;
  private readonly RETRY_COUNT = 5;

  private fileSearchService = new FileSearchService();

  protected files: UploadFile[] = [];
  protected fileGroupId = "";
  protected insertFileGroupId = "";
  protected insertFileGroupFileCount = 0;
  protected coverage = {};
  protected coverageId = "";
  protected coverageTitle = "";
  protected title = "";
  protected isCaptionAllAssgined = false;
  protected caption = "";
  protected isAutoDatetime = true;
  protected selectedDatetime = 0;
  protected dateMenu = false;
  protected date = "";
  protected timeMenu = false;
  protected time = "";
  protected selectedByline = 1;
  protected byline = "";
  protected isAutoInputByline = true;
  protected selectedCity = 1;
  protected city = "";
  protected instructions = "";
  protected original = "";
  protected selectedKeywords: string[] = [];
  protected keywordsItems: string[] = [];
  protected protect = true;
  protected caution = false;
  protected cautionInstructions = "";
  protected selectedReuseItem: ReuseItem = { text: "可", value: 1 };
  protected reuseItems: ReuseItem[] = [
    { text: "不可", value: 0 },
    { text: "可", value: 1 },
    { text: "条件付き可", value: 2 }
  ];
  protected reuseCondition = "";
  protected selectedShareItem: ShareItem= { text: "可", value: 1 };
  protected shareItems: ShareItem[] = [
    { text: "不可", value: 0 },
    { text: "可", value: 1 },
    { text: "条件付き可", value: 2 }
  ];
  protected shareCondition = "";
  protected selectedSaleItem: SaleItem = { text: "可", value: 1 };
  protected saleItems: SaleItem[] = [
    { text: "不可", value: 0 },
    { text: "可", value: 1 },
    { text: "条件付き可", value: 2 }
  ];
  protected saleCondition = "";
  protected memo = "";

  protected yearOriginatedItems: YearItem[] = createYearItems();
  protected yearOriginated: YearItem | null = null;
  protected monthOriginatedItems: MonthItem[] = createMonthItems();
  protected monthOriginated: MonthItem | null = null;

  protected yearOriginatedRules = [this.yearOriginatedRuleMethod];
  private yearOriginatedRuleMethod(value){
    if(!this.isAutoDatetime && this.selectedDatetime === 2 && !value){
      return "撮影年は必須項目です";
    }
    return true;
  }

  protected aroundYearOriginated: YearItem | null = null;
  protected aroundYearOriginatedRules = [this.aroundYearOriginatedRuleMethod];
  private aroundYearOriginatedRuleMethod(value){
    if(!this.isAutoDatetime && this.selectedDatetime === 3 && !value){
      return "撮影年頃は必須項目です";
    }
    return true;
  }

  protected ageOriginatedItems: YearItem[] = createYearItems(10);
  protected ageOriginated: YearItem | null = null;
  protected ageOriginatedRules = [this.ageOriginatedRuleMethod];
  private ageOriginatedRuleMethod(value){
    if(!this.isAutoDatetime && this.selectedDatetime === 4 && !value){
      return "撮影年代は必須項目です";
    }
    return true;
  }

  protected publishedStatus = 0;
  protected publishedDateMenu = false;
  protected publishedDate = "";
  protected publishedDateRules = [this.publishedDateRuleMethod];

  private publishedDateRuleMethod(value){
    if(this.publishedStatus === 1 && !value){
      return "初出掲載日は必須項目です";
    }
    return true;
  }

  protected publishedMedia = "";
  protected publishedMediaRules = [
    v => !!v || "初出掲載媒体は必須項目です",
    v => (v && v.length <= 32) || "初出掲載媒体は32文字以下です",
  ];
  protected publishedPage = "";
  protected publishedPageRules = [
    v => (!v || v && String(v).match(/^[1-9][0-9]{0,4}$/) || "掲載頁は1から99999までです")
  ];
  protected isPublishedInfoAllAssgined = false;

  protected originalEdition = { text: 'デジタル', value: 0 };
  protected originalEditionItems = [
    { text: 'デジタル', value: 0 },
    { text: 'カラーネガ', value: 1 },
    { text: 'モノクロネガ', value: 2 },
    { text: 'カラーポジ', value: 3 },
    { text: 'モノクロポジ', value: 4 },
    { text: '紙焼き', value: 5 },
    { text: 'その他', value: 99 },
  ];
  
  protected storageLocation = "";
  private storageLocationRules = [
    v => (!v || v && v.length <= 64) || '保管場所は64文字以下です',
  ];

  protected titleRules = [
    v => !!v || "タイトルは必須項目です",
    v => (v && v.length <= 128) || "タイトルは128文字以下です",
  ];
  protected captionRules = [
    v => !!v || "キャプションは必須項目です",
    v => (v && v.length <= 1024) || "キャプションは1024文字以下です",
  ];
  protected dateRules = [this.dateRuleMethod];

  private dateRuleMethod(value){
    if(!this.isAutoDatetime && this.selectedDatetime === 1 && !value){
      return "撮影日は必須項目です";
    }
    return true;
  }

  protected timeRules = [this.timeRulesMethod];

  private timeRulesMethod(value){
    // if(!this.isAutoDatetime && this.selectedDatetime === 1 && !value){
    //   return "撮影時間は必須項目です";
    // }
    const regex1 = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    if(this.selectedDatetime === 1 && value && !regex1.test(value)){
      const regex2 = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
      if(regex2.test(value)){
        return true;
      }
      return "撮影時間はHH:MM、またはHH:MM:SSで入力してください"
    }
    return true;
  }

  protected bylineRules = [this.bylineRuleMethod];
  private bylineRuleMethod(value) {
    if(this.selectedByline === 1 && !value) {
      return "撮影者は必須項目です";
    }
    if(this.selectedByline === 1 && value && value.length > 32) {
      return "撮影者は32文字以下です";
    }
    return true;
  }

  protected cityRules = [this.cityRuleMethod];
  private cityRuleMethod(value) {
    if(this.selectedCity === 1 && !value) {
      return "撮影場所は必須項目です";
    }
    if(this.selectedCity === 1 && value && value.length > 64) {
      return "撮影場所は64文字以下です";
    }
    return true
  }
  protected instructionsRules = [
    v => (!v || v && v.length <= 1024) || '連絡事項は1024文字以下です',
  ];
  protected originalRules = [
    v => (!v || v && v.length <= 64) || '著作権者は64文字以下です',
  ];

  protected isSelectedKeywordError = false;
  protected selectedKeywordErrorMessages: string | [] = [];
  protected keywordRulesMethod(value){
    if(value !== undefined && value !== null && value.length > 64){
      this.isSelectedKeywordError = true;
      this.selectedKeywordErrorMessages = 'キーワードは64文字以内です';
      return 'キーワードは64文字以内です';
    }
    this.isSelectedKeywordError = false;
    this.selectedKeywordErrorMessages = [];
    return true;
  }

  protected keywordsRulesMethod(value){
    if(value !== undefined && value !== null && value instanceof Array && value.length > 10){
      return 'キーワードは最大10単語までです';
    }
    if(value !== undefined && value !== null && value instanceof Array && value.length > 0){
      for(const i in value){
        if(value[i].length > 64){
          return 'キーワードは1単語につき64文字以内です';
        }
      }
    }
    return true;
  }
  protected cautionInstructionsRules = [
    v => (!v || v && v.length <= 256) || '取り扱い注意事項は256文字以下です',
  ];
  private reuseConditionRules = [
    v => (!v || v && v.length <= 256) || '再使用条件は256文字以下です',
  ];
  private shareConditionRules = [
    v => (!v || v && v.length <= 256) || '共有条件は256文字以下です',
  ];
  private saleConditionRules = [
    v => (!v || v && v.length <= 256) || '外販条件は256文字以下です',
  ];
  private memoRules = [
    v => (!v || v && v.length <= 2048) || '備考は2048文字以下です',
  ];

  protected async setDefault(fileGroupId = "", coverageId = "",) {
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.isCoverageRead = isAllow("coverage", "read", role.coverage?.read) && await isActiveServie("coverage");

    this.insertFileGroupFileCount = 0;
    this.files = [];      
    this.title = "";
    this.caption = "";
    this.isAutoDatetime = true;
    this.selectedDatetime = 1;
    this.date = moment(new Date()).local().format("YYYY-MM-DD");
    this.time = "";
    this.yearOriginated = null;
    this.monthOriginated = null;
    this.aroundYearOriginated = null;
    this.ageOriginated = null;
    this.selectedByline = 1;
    this.byline = "";
    this.selectedCity = 1;
    this.city = "";
    this.instructions = "";
    this.original = "";
    this.selectedKeywords = [];
    this.protect = true;
    this.caution = false;
    this.cautionInstructions = "";
    this.selectedReuseItem = { text: "可", value: 1 };
    this.reuseCondition = "";
    this.selectedShareItem = { text: "可", value: 1 };
    this.shareCondition = "";
    this.selectedSaleItem = { text: "可", value: 1 };
    this.saleCondition = "";
    this.publishedStatus = 0;
    this.publishedDate = "";
    this.publishedMedia = "";
    this.publishedPage = "";
    this.originalEdition = { text: 'デジタル', value: 0 };
    this.storageLocation = "";
    this.memo = "";

    this.fileGroupId = this.insertFileGroupId = fileGroupId;
    this.coverageId = coverageId;

    if(this.insertFileGroupId){
      const condition: SearchCondition = {
        groupIds: [this.insertFileGroupId]
      }
      const { data } = (await API.graphql(graphqlOperation(queries.getFileGroup, {id: this.insertFileGroupId}))) as GraphQLResult<GetFileGroupQuery>;
      const fileGroup = data?.getFileGroup ? data.getFileGroup as FileGroup : null;
      condition.fileId = fileGroup && fileGroup.coverFileId ? fileGroup.coverFileId : undefined;

      const result = await this.fileSearchService.searchFiles(condition, undefined, 0, 1);
      if(result && result.total && result.items && result.items.length > 0){
        this.insertFileGroupFileCount = result.total || 0;
        const file = result.items[0] as FileData;
        this.coverageId = file.coverageId || "";
        this.title = file.title || "";
        this.instructions = file.instructions || "";
        this.memo = file.memo || "";
        this.original = file.original || "";
        this.selectedKeywords = file.keywords as string[] || [];
        this.caution = file.caution === 1 ? true : false;
        this.cautionInstructions = file.cautionInstructions || "";
        this.protect = file.protect === 1 ? true : false;

        if(file.reuse){
          const item = this.reuseItems.find(x => x.value === file.reuse);
          this.selectedReuseItem = item ? item : this.reuseItems[1];
          this.reuseCondition = file.reuseCondition || "";
        }

        if(file.meta){
          const meta = JSON.parse(file.meta);
          this.byline = meta.byline || this.$store.getters.user?.attributes?.name || "";
          this.city = meta.city || "";
        }

        if(file.customItems){
          const custom = JSON.parse(file.customItems);
          if(custom.sale){
            const item = this.saleItems.find(x => x.value === custom.sale);
            this.selectedSaleItem = item ? item : this.saleItems[1];
            this.saleCondition = custom.saleCondition || "";
          }
          if(custom.share){
            const item = this.shareItems.find(x => x.value === custom.sale);
            this.selectedShareItem = item ? item : this.shareItems[1];
            this.shareCondition = custom.shareCondition || "";
          }
        }
      }
    }

    this.coverageTitle = ""; 
    if(this.coverageId && !this.insertFileGroupId){
      const { data } = (await API.graphql(graphqlOperation(queries.getCoverage, {id: this.coverageId}))) as GraphQLResult<GetCoverageQuery>;
      if(data && data.getCoverage){
        const coverage =  (data.getCoverage as Coverage);
        this.coverageTitle = coverage && coverage.title ? coverage.title : "";
      }
    }

    this.cancel = false;
    this.uploading = false;
    this.uploadCompleted = false;
    this.progress = 0;
    this.hasError = false;
    this.errorMessage = "";
  }

  protected customValidate(){
    return this.valid === true && this.isSelectedKeywordError === false ? true : false;
  }

  protected async upload(){
    if(!this.customValidate()){
      return;
    }

    this.uploading = true;
    this.uploadCompleted = false;
    this.hasError = false;
    this.errorMessage = "";
    this.cancel = false;
    this.progress = 0;

    try{
      for(const file of this.files){
        file.promise = null;
        file.perProgress = 0;
        file.error = false;
      }

      await this.createFileGroup();
      await _async.mapValuesLimit(this.files, this.UPLOAD_PARALLEL, _async.asyncify(this.uploadTask));
      await this.uploadPostProcess();

    }catch(error){
      console.error(error);
      await this.abortUpload();
      this.uploading = false;
      this.uploadError(error);
    }
  }

  private async createFileGroup(retryCount = 0){
    try {
      if(!this.insertFileGroupId) {
        const attributes = this.$store.getters.user.attributes;
        this.fileGroupId = uuid.v4();

        // ファイルグループを作成する
        const inputFileGroup: CreateFileGroupInput = {
          id: this.fileGroupId,
          title: (typeof this.title != 'undefined' && this.title) ? this.title : null,
          description: (typeof this.caption != 'undefined' && this.caption) ? this.caption : null,
          memo: (typeof this.memo != 'undefined' && this.memo) ? this.memo : null,
          createdUserId: attributes.sub,
          ownedDepartment: attributes["custom:department"],
          ownedAccountId: attributes["custom:account_id"],
          deleted: 0,
        };

        await API.graphql(graphqlOperation(mutations.createFileGroup, {input: inputFileGroup}));
      }
    } catch(error) {
      retryCount = retryCount + 1;
      if(retryCount > this.RETRY_COUNT){
        throw error;
      }
      await this.createFileGroup(retryCount);
    }
  }

  private async createFile(input: CreateFileInput, retryCount = 0) {
    try {
      await API.graphql(graphqlOperation(mutations.createFile, {input: input}));
    } catch(error) {
      retryCount = retryCount + 1;
      if(retryCount > this.RETRY_COUNT){
        throw error;
      }
      await this.createFile(input, retryCount);
    }
  }

  private async updateFileGroup(input: UpdateFileGroupInput, retryCount = 0) {
    try {
      await API.graphql(graphqlOperation(mutations.updateFileGroup, {input: input}));
    } catch(error) {
      retryCount = retryCount + 1;
      if(retryCount > this.RETRY_COUNT){
        throw error;
      }
      await this.updateFileGroup(input, retryCount);
    }
  }

  private async uploadTask(input: UploadFile, dataId: number){
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try{
        if(this.cancel){
          throw new Error("Upload was cancelled. Upload Abort");
        }

        // 最初にファイルを作成する
        const attributes = this.$store.getters.user.attributes;
        const extension = input.fileObj.name.split('.').pop();
        const fileId = uuid.v4();
        // original/account_id/YYYYMMDD/file_id.拡張子
        // TODO:publicに上がるため、アクセス権は要検討
        const key = `original/${attributes["custom:account_id"]}/${moment(new Date()).format("YYYYMMDD")}/${fileId}.${extension}`;
        const fileUri = `https://${awsconfig.aws_user_files_s3_bucket}.s3-${awsconfig.aws_user_files_s3_bucket_region}.amazonaws.com/public/${key}`;
  
        const inputFile: CreateFileInput = {
          id: fileId,
          groupId: this.fileGroupId,
          title: (typeof this.title != 'undefined' && this.title) ? this.title : null,
          caption: (typeof this.caption != 'undefined' && this.caption && (input.select || this.isCaptionAllAssgined)) ? this.caption : null,
          instructions: (typeof this.instructions != 'undefined' && this.instructions) ? this.instructions : null,
          memo: (typeof this.memo != 'undefined' && this.memo) ? this.memo : null,
          fileName: input.fileObj.name,
          protect: this.protect ? 1 : 0,
          original: (typeof this.original != 'undefined' && this.original) ? this.original : null,
          caution: this.caution ? 1 : 0,
          cautionInstructions: (typeof this.cautionInstructions != 'undefined' && this.cautionInstructions) ? this.cautionInstructions : null,
          reuse: this.selectedReuseItem.value,
          reuseCondition: (typeof this.reuseCondition != 'undefined' && this.reuseCondition) ? this.reuseCondition : null,
          keywords: (typeof this.selectedKeywords != 'undefined' && this.selectedKeywords.length > 0) ? this.selectedKeywords : null,
          extension: extension ? extension.toLowerCase() : null,
          type: input.fileObj.type ? input.fileObj.type : null,
          fileUri: fileUri,
          fileSize: input.fileObj.size ? input.fileObj.size.toString() : '0',
          coverageId: (typeof this.coverageId != 'undefined' && this.coverageId) ? this.coverageId : null,
          createdUserId: attributes.sub,
          ownedDepartment: attributes["custom:department"],
          ownedAccountId: attributes["custom:account_id"],
          deleted: 0,
        };

        // TODO:撮影日時不明の動きを考える
        let dtOrg: string | null = null;
        if(!this.isAutoDatetime){
          if(this.selectedDatetime == 1 && this.date && this.time){
            dtOrg = moment.parseZone(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.SSS');
          } else if(this.selectedDatetime == 1 && this.date){
            dtOrg = moment.parseZone(`${this.date}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
          } else if(this.selectedDatetime == 2 && this.yearOriginated && this.monthOriginated){
            dtOrg = moment.parseZone(`${this.yearOriginated.text}-${this.monthOriginated.text}`, 'YYYY-MM').format('YYYY-MM');
          } else if(this.selectedDatetime == 2 && this.yearOriginated){
            dtOrg = moment.parseZone(`${this.yearOriginated.text}`, 'YYYY').format('YYYY');
          }
        }

        const meta = {
          city: this.selectedCity === 1 && this.city ? this.city : null,
          byline: this.selectedByline === 1 && this.byline ? this.byline : null,
          datetimeOriginated: dtOrg,
          aroundYearOriginated: !this.isAutoDatetime && this.selectedDatetime == 3 && this.aroundYearOriginated ? parseInt(moment.parseZone(`${this.aroundYearOriginated.text}`, 'YYYY').format('YYYY'), 10) : null,
          ageOriginated: !this.isAutoDatetime && this.selectedDatetime == 4 && this.ageOriginated ? parseInt(moment.parseZone(`${this.ageOriginated.text}`, 'YYYY').format('YYYY'), 10) : null,
          originatedStatus: this.isAutoDatetime ? 0 : this.selectedDatetime,
        };

        inputFile.meta = JSON.stringify(meta);

        const customItems = {
          sale: this.selectedSaleItem.value,
          saleCondition: (typeof this.saleCondition != 'undefined' && this.saleCondition) ? this.saleCondition : null,
          share: this.selectedShareItem.value,
          shareCondition: (typeof this.shareCondition != 'undefined' && this.shareCondition) ? this.shareCondition : null,
          publishedStatus: (input.select || this.isPublishedInfoAllAssgined) ? this.publishedStatus : 0,
          publishedDate: this.publishedStatus && this.publishedStatus === 1 && (input.select || this.isPublishedInfoAllAssgined) && moment.parseZone(`${this.publishedDate}`, 'YYYY-MM-DD').format('YYYY-MM-DD') ? this.publishedDate : null,
          publishedMedia: this.publishedStatus && this.publishedStatus === 1 && (input.select || this.isPublishedInfoAllAssgined) && this.publishedMedia ? this.publishedMedia : null,
          publishedPage: this.publishedStatus && this.publishedStatus === 1 && (input.select || this.isPublishedInfoAllAssgined) && this.publishedPage ? parseInt(this.publishedPage, 10) : null,
          originalEdition: this.originalEdition.value,
          storageLocation: this.storageLocation ? this.storageLocation : null
        };

        inputFile.customItems = JSON.stringify(customItems);

        // const session = await getSession();
        // const token = session.getIdToken().getJwtToken();

        // await API.graphql(graphqlOperation(mutations.createFile, {input: inputFile}));
        await this.createFile(inputFile);
        input.fileId = fileId;

        // 表紙ファイルなら、ファイルグループをアップデート
        if(input.select){
          const inputFileGroup: UpdateFileGroupInput = {
            id: this.fileGroupId,
            coverFileId: fileId,
          };
          // await API.graphql(graphqlOperation(mutations.updateFileGroup, {input: inputFileGroup}));
          await this.updateFileGroup(inputFileGroup);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let promise: Promise<any> | null = null;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this;
        promise = Storage.put(
          key,
          input.fileObj,
          {
            contentType: input.fileObj.type,
            contentDisposition: `attachment; filename="${encodeURI(input.fileObj.name)}"`,  // ダウンロードのため
            progressCallback(progress) {
              input.perProgress = Math.floor((progress.loaded / progress.total) * 100);
              _this.updateProgress();
            },
            cacheControl: 'no-store, no-cache',
            expires: new Date('1970-01-01T00:00:00+0000'),
          }
        );

        if(this.WAIT_TIME_MS > 0 && input.fileObj.size < this.ONE_MB)
        {
          await sleep(this.WAIT_TIME_MS);
        }

        input.promise = promise;
        await promise;
        input.complete = true;
        
        resolve({
            dataId,
            input
        });
      }catch(error){
        console.error(error);
        // console.error(error.message);
        input.complete = false;
        input.perProgress = 0;
        input.error = true;
        reject(error);
      }
    });
  }

  private updateProgress(){
    // if(this.cancel){
    //   throw new Error("Upload was cancelled. Upload Abort");
    // }
    const sum = this.files.reduce(function(sum, element){
      return (sum + element.perProgress);
    }, 0);
    if(!this.cancel){
      this.progress = Math.floor(sum / this.files.length);
    }
  }

  private async uploadPostProcess(){
    if(this.cancel){
      // console.log("uploadPostProcess cancel");
      await this.abortUpload();
      return;
    }

    if(this.progress >= 100){
      this.progress = 100;
      for(const file of this.files){
        if(file.imageSrc){
          URL.revokeObjectURL(file.imageSrc);
        }
      }
      this.files = this.files.filter((f) => !f.complete);
      if(this.progress >= 100){
        this.uploadCompleted = true;
      }
    }
  }

  protected async abortUpload(){
    // console.log("abort now")
    try{
      for(const file of this.files){
        try{
          if(file.promise && !file.complete){
            await Storage.cancel(file.promise, "Upload Abort");
          }
          if(file.fileId){
            const input: UpdateFileInput = {
              id: file.fileId,
              protect: 0,
              deleted: 1,
              deletedAt: moment(new Date).utc().toISOString(),
            };
            await API.graphql(graphqlOperation(mutations.updateFile, { input: input }));
          }
        }catch(error){
          console.error(error);
          continue;
        }finally{
          file.perProgress = 0;
          file.promise = null;
          file.complete = false;
          file.error = false;
        }
      }
      if(!this.insertFileGroupId){
        const input: UpdateFileGroupInput = {
          id: this.fileGroupId,
          deleted: 1,
          deletedAt: moment(new Date).utc().toISOString(),
        };  
        await API.graphql(graphqlOperation(mutations.updateFileGroup, { input: input }));
        this.insertFileGroupId = "";
      }
    }catch(error){
      console.error(error);
      this.uploadError(error);
    }finally{
      this.uploading = false;
    }
  }

  protected async getFileCountInGroup(): Promise<number> {
    let count = 0;
    if(this.insertFileGroupId) {
      const condition: SearchCondition = {
        groupIds: [this.insertFileGroupId]
      };

      const result = await this.fileSearchService.searchFiles(condition, undefined, 0, 1);
      if(result && result.total && result.items && result.items.length > 0){
        count = result.total || 0;
      }
    }
    this.insertFileGroupFileCount = count;
    return this.insertFileGroupFileCount;
  }

  private uploadError(error) {
    this.hasError = true;
    this.errorMessage = `アップロードに失敗しました。`;
    if(error.message){
      this.errorMessage = this.errorMessage + `[ ${error.message} ]`
    }
  }

  private beforeDestroy(){
    for(const file of this.files){
      if(file.imageSrc){
        URL.revokeObjectURL(file.imageSrc);
      }
    }
  }
}
