


































































import { Component, Vue, Watch } from "vue-property-decorator";
import FileUploadBaseForm from "@/components/files/create/FileUploadBaseForm.vue";
import FileDragAndDrop from "@/components/files/create/FileDragAndDrop.vue";
import FileDataTable from "@/components/files/create/FileDataTable.vue";
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";


@Component({
  components: {
    FileDragAndDrop,
    FileDataTable,
    Loading,
    Dialog,
  }
})
export default class FileInsert extends FileUploadBaseForm {
  name = "file-insert";
  
  private loading = false;
  private resultDialog = false;
  private resultDialogMode = '';
  private resultDialogTitle = '';
  private resultDialogMessage = '';

  private isNotFile = false;
  private groupId = "";
  private tableLoading = false;
  private isIOSAlert = true;
  private isMaxFileCount = false;

  @Watch('files')
  private onChangeFiles(){
    if(this.isNotFile && this.files.length > 0){
      this.isNotFile = false;
    }
    if(this.isMaxFileCount && (this.files.length + this.insertFileGroupFileCount) <= this.UPLOAD_LIMIT_COUNT){
      this.isMaxFileCount = false;
    }else if(!this.isMaxFileCount && (this.files.length + this.insertFileGroupFileCount) > this.UPLOAD_LIMIT_COUNT){
      this.isMaxFileCount = true;
    }
  }

  private async created(){
    if(window.localStorage){
      const isIOSAlertStr = localStorage.getItem('isIOSAlert');
      this.isIOSAlert = isIOSAlertStr === 'false' ? false : true;
    }

    if(this.$route.params.id){
      this.groupId = this.$route.params.id.toString();
      await this.setDefault(this.groupId);
    }
  }

  private onChangedFileDragAndDrop(value){
    this.files = value;
  }

  private onChangedFileDataTable(value){
    this.files = value;
  }

  private onChangedTableLoading(value){
    this.tableLoading = value;
  }

  private async onClickUpload(){
    if(this.tableLoading){
      return;
    }

    if(this.files.length <= 0){
      this.isNotFile = true;
      return;
    }
    this.insertFileGroupFileCount = await this.getFileCountInGroup();

    if((this.files.length + this.insertFileGroupFileCount) > this.UPLOAD_LIMIT_COUNT){
      this.isMaxFileCount = true;
      return;
    }

    if(!(this.$refs.fileInsertForm as Vue & {validate: () => boolean}).validate()){
      return;
    }

    await this.upload();

    if(this.hasError){
      this.resultDialog = true;
      this.resultDialogMode = 'error';
      this.resultDialogTitle = 'アップロード失敗';
      this.resultDialogMessage = this.errorMessage;
    }
  }

  private onChangeResultDialogClosed(){
      this.resultDialog = false;
      this.resultDialogMode = '';
      this.resultDialogTitle = '';
      this.resultDialogMessage = '';
      this.hasError = false;
      this.errorMessage = '';
  }

  private async onClickUploadCancel(){
    this.cancel = true;
    await this.abortUpload();
  }

  private async onClickUploadcompleted(){
    await this.setDefault(this.groupId);
  }

  private back(){
    this.$router.go(-1);
  }

  private onClosedIOSAlert(){
    if(window.localStorage){
      localStorage.setItem('isIOSAlert', 'false');
    }
  }
}
