




















import { Component, Vue } from "vue-property-decorator";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

@Component
export default class SignIn extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authState: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  }
  beforeDestroy() {
    return onAuthUIStateChange;
  }
}
