/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSharingWithHistory = /* GraphQL */ `
  mutation CreateSharingWithHistory($input: PGCCreateSharingInput!) {
    createSharingWithHistory(input: $input) {
      id
      title
      description
      memo
      limitDate
      limitTime
      sharingUsers
      sharingFiles
      createdUserId
      ownedAccountId
      ownedDepartment
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSharingWithHistory = /* GraphQL */ `
  mutation UpdateSharingWithHistory($input: PGCUpdateSharingInput!) {
    updateSharingWithHistory(input: $input) {
      id
      title
      description
      memo
      limitDate
      limitTime
      sharingUsers
      sharingFiles
      createdUserId
      ownedAccountId
      ownedDepartment
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser($input: CreateCognitoUserInput) {
    createCognitoUser(input: $input) {
      id
    }
  }
`;
export const deleteCognitoUser = /* GraphQL */ `
  mutation DeleteCognitoUser($input: DeleteCognitoUserInput) {
    deleteCognitoUser(input: $input) {
      id
    }
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser($input: UpdateCognitoUserInput) {
    updateCognitoUser(input: $input) {
      id
    }
  }
`;
export const recoveryCognitoUser = /* GraphQL */ `
  mutation RecoveryCognitoUser($input: RecoveryCognitoUserInput) {
    recoveryCognitoUser(input: $input) {
      id
    }
  }
`;
export const disableCognitoUser = /* GraphQL */ `
  mutation DisableCognitoUser($input: DisableCognitoUserInput) {
    disableCognitoUser(input: $input) {
      id
    }
  }
`;
export const resetMFA = /* GraphQL */ `
  mutation ResetMFA($input: ResetMFAInput) {
    resetMFA(input: $input) {
      id
    }
  }
`;
export const resendPasswordEmail = /* GraphQL */ `
  mutation ResendPasswordEmail($input: ResendPasswordEmailInput) {
    resendPasswordEmail(input: $input) {
      id
    }
  }
`;
export const createSelesSender = /* GraphQL */ `
  mutation CreateSelesSender($input: CreateSelesSenderInput) {
    createSelesSender(input: $input) {
      id
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      company
      person
      domain
      logoUri
      config
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      company
      person
      domain
      logoUri
      config
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      company
      person
      domain
      logoUri
      config
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFileGroup = /* GraphQL */ `
  mutation CreateFileGroup(
    $input: CreateFileGroupInput!
    $condition: ModelFileGroupConditionInput
  ) {
    createFileGroup(input: $input, condition: $condition) {
      id
      coverFileId
      title
      description
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      file {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateFileGroup = /* GraphQL */ `
  mutation UpdateFileGroup(
    $input: UpdateFileGroupInput!
    $condition: ModelFileGroupConditionInput
  ) {
    updateFileGroup(input: $input, condition: $condition) {
      id
      coverFileId
      title
      description
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      file {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteFileGroup = /* GraphQL */ `
  mutation DeleteFileGroup(
    $input: DeleteFileGroupInput!
    $condition: ModelFileGroupConditionInput
  ) {
    deleteFileGroup(input: $input, condition: $condition) {
      id
      coverFileId
      title
      description
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      file {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      groupId
      rating
      title
      caption
      instructions
      memo
      fileName
      protect
      original
      caution
      cautionInstructions
      reuse
      reuseCondition
      keywords
      extension
      type
      meta
      tag
      customItems
      fileUri
      fileSize
      coverageId
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      group {
        id
        coverFileId
        title
        description
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        file {
          id
          groupId
          rating
          title
          caption
          instructions
          memo
          fileName
          protect
          original
          caution
          cautionInstructions
          reuse
          reuseCondition
          keywords
          extension
          type
          meta
          tag
          customItems
          fileUri
          fileSize
          coverageId
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          group {
            id
            coverFileId
            title
            description
            memo
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            file {
              id
              groupId
              rating
              title
              caption
              instructions
              memo
              fileName
              protect
              original
              caution
              cautionInstructions
              reuse
              reuseCondition
              keywords
              extension
              type
              meta
              tag
              customItems
              fileUri
              fileSize
              coverageId
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
          coverage {
            id
            title
            location
            startAt
            endAt
            description
            memo
            attachmentFile
            createdUserId
            ownedAccountId
            ownedDepartment
            assignedUserId
            status
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      }
      coverage {
        id
        title
        location
        startAt
        endAt
        description
        memo
        attachmentFile
        createdUserId
        ownedAccountId
        ownedDepartment
        assignedUserId
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      groupId
      rating
      title
      caption
      instructions
      memo
      fileName
      protect
      original
      caution
      cautionInstructions
      reuse
      reuseCondition
      keywords
      extension
      type
      meta
      tag
      customItems
      fileUri
      fileSize
      coverageId
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      group {
        id
        coverFileId
        title
        description
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        file {
          id
          groupId
          rating
          title
          caption
          instructions
          memo
          fileName
          protect
          original
          caution
          cautionInstructions
          reuse
          reuseCondition
          keywords
          extension
          type
          meta
          tag
          customItems
          fileUri
          fileSize
          coverageId
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          group {
            id
            coverFileId
            title
            description
            memo
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            file {
              id
              groupId
              rating
              title
              caption
              instructions
              memo
              fileName
              protect
              original
              caution
              cautionInstructions
              reuse
              reuseCondition
              keywords
              extension
              type
              meta
              tag
              customItems
              fileUri
              fileSize
              coverageId
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
          coverage {
            id
            title
            location
            startAt
            endAt
            description
            memo
            attachmentFile
            createdUserId
            ownedAccountId
            ownedDepartment
            assignedUserId
            status
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      }
      coverage {
        id
        title
        location
        startAt
        endAt
        description
        memo
        attachmentFile
        createdUserId
        ownedAccountId
        ownedDepartment
        assignedUserId
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      groupId
      rating
      title
      caption
      instructions
      memo
      fileName
      protect
      original
      caution
      cautionInstructions
      reuse
      reuseCondition
      keywords
      extension
      type
      meta
      tag
      customItems
      fileUri
      fileSize
      coverageId
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      group {
        id
        coverFileId
        title
        description
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        file {
          id
          groupId
          rating
          title
          caption
          instructions
          memo
          fileName
          protect
          original
          caution
          cautionInstructions
          reuse
          reuseCondition
          keywords
          extension
          type
          meta
          tag
          customItems
          fileUri
          fileSize
          coverageId
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          group {
            id
            coverFileId
            title
            description
            memo
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            file {
              id
              groupId
              rating
              title
              caption
              instructions
              memo
              fileName
              protect
              original
              caution
              cautionInstructions
              reuse
              reuseCondition
              keywords
              extension
              type
              meta
              tag
              customItems
              fileUri
              fileSize
              coverageId
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
          coverage {
            id
            title
            location
            startAt
            endAt
            description
            memo
            attachmentFile
            createdUserId
            ownedAccountId
            ownedDepartment
            assignedUserId
            status
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      }
      coverage {
        id
        title
        location
        startAt
        endAt
        description
        memo
        attachmentFile
        createdUserId
        ownedAccountId
        ownedDepartment
        assignedUserId
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSharing = /* GraphQL */ `
  mutation CreateSharing(
    $input: CreateSharingInput!
    $condition: ModelSharingConditionInput
  ) {
    createSharing(input: $input, condition: $condition) {
      id
      title
      description
      memo
      limitDate
      limitTime
      sharingUsers
      sharingFiles
      createdUserId
      ownedAccountId
      ownedDepartment
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSharing = /* GraphQL */ `
  mutation UpdateSharing(
    $input: UpdateSharingInput!
    $condition: ModelSharingConditionInput
  ) {
    updateSharing(input: $input, condition: $condition) {
      id
      title
      description
      memo
      limitDate
      limitTime
      sharingUsers
      sharingFiles
      createdUserId
      ownedAccountId
      ownedDepartment
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSharing = /* GraphQL */ `
  mutation DeleteSharing(
    $input: DeleteSharingInput!
    $condition: ModelSharingConditionInput
  ) {
    deleteSharing(input: $input, condition: $condition) {
      id
      title
      description
      memo
      limitDate
      limitTime
      sharingUsers
      sharingFiles
      createdUserId
      ownedAccountId
      ownedDepartment
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCoverage = /* GraphQL */ `
  mutation CreateCoverage(
    $input: CreateCoverageInput!
    $condition: ModelCoverageConditionInput
  ) {
    createCoverage(input: $input, condition: $condition) {
      id
      title
      location
      startAt
      endAt
      description
      memo
      attachmentFile
      createdUserId
      ownedAccountId
      ownedDepartment
      assignedUserId
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCoverage = /* GraphQL */ `
  mutation UpdateCoverage(
    $input: UpdateCoverageInput!
    $condition: ModelCoverageConditionInput
  ) {
    updateCoverage(input: $input, condition: $condition) {
      id
      title
      location
      startAt
      endAt
      description
      memo
      attachmentFile
      createdUserId
      ownedAccountId
      ownedDepartment
      assignedUserId
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoverage = /* GraphQL */ `
  mutation DeleteCoverage(
    $input: DeleteCoverageInput!
    $condition: ModelCoverageConditionInput
  ) {
    deleteCoverage(input: $input, condition: $condition) {
      id
      title
      location
      startAt
      endAt
      description
      memo
      attachmentFile
      createdUserId
      ownedAccountId
      ownedDepartment
      assignedUserId
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCoverageInfo = /* GraphQL */ `
  mutation CreateCoverageInfo(
    $input: CreateCoverageInfoInput!
    $condition: ModelCoverageInfoConditionInput
  ) {
    createCoverageInfo(input: $input, condition: $condition) {
      id
      startDate
      endDate
      body
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCoverageInfo = /* GraphQL */ `
  mutation UpdateCoverageInfo(
    $input: UpdateCoverageInfoInput!
    $condition: ModelCoverageInfoConditionInput
  ) {
    updateCoverageInfo(input: $input, condition: $condition) {
      id
      startDate
      endDate
      body
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoverageInfo = /* GraphQL */ `
  mutation DeleteCoverageInfo(
    $input: DeleteCoverageInfoInput!
    $condition: ModelCoverageInfoConditionInput
  ) {
    deleteCoverageInfo(input: $input, condition: $condition) {
      id
      startDate
      endDate
      body
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCoverageDesk = /* GraphQL */ `
  mutation CreateCoverageDesk(
    $input: CreateCoverageDeskInput!
    $condition: ModelCoverageDeskConditionInput
  ) {
    createCoverageDesk(input: $input, condition: $condition) {
      id
      startDate
      endDate
      name
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCoverageDesk = /* GraphQL */ `
  mutation UpdateCoverageDesk(
    $input: UpdateCoverageDeskInput!
    $condition: ModelCoverageDeskConditionInput
  ) {
    updateCoverageDesk(input: $input, condition: $condition) {
      id
      startDate
      endDate
      name
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoverageDesk = /* GraphQL */ `
  mutation DeleteCoverageDesk(
    $input: DeleteCoverageDeskInput!
    $condition: ModelCoverageDeskConditionInput
  ) {
    deleteCoverageDesk(input: $input, condition: $condition) {
      id
      startDate
      endDate
      name
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      data
      body
      operation
      userId
      accountId
      createdAt
      updatedAt
    }
  }
`;
export const updateHistory = /* GraphQL */ `
  mutation UpdateHistory(
    $input: UpdateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    updateHistory(input: $input, condition: $condition) {
      id
      data
      body
      operation
      userId
      accountId
      createdAt
      updatedAt
    }
  }
`;
export const deleteHistory = /* GraphQL */ `
  mutation DeleteHistory(
    $input: DeleteHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    deleteHistory(input: $input, condition: $condition) {
      id
      data
      body
      operation
      userId
      accountId
      createdAt
      updatedAt
    }
  }
`;
export const createCharge = /* GraphQL */ `
  mutation CreateCharge(
    $input: CreateChargeInput!
    $condition: ModelChargeConditionInput
  ) {
    createCharge(input: $input, condition: $condition) {
      id
      accountId
      aggregationDate
      status
      basicCharge
      s3Charge
      excessTransferredCharge
      s3StdUsageSize
      s3IaUsageSize
      s3GlIrUsageSize
      s3GlUsageSize
      transferredSize
      downloadRequestCont
      excludingTax
      includingTax
      taxRate
      exchangeRate
      createdAt
      updatedAt
    }
  }
`;
export const updateCharge = /* GraphQL */ `
  mutation UpdateCharge(
    $input: UpdateChargeInput!
    $condition: ModelChargeConditionInput
  ) {
    updateCharge(input: $input, condition: $condition) {
      id
      accountId
      aggregationDate
      status
      basicCharge
      s3Charge
      excessTransferredCharge
      s3StdUsageSize
      s3IaUsageSize
      s3GlIrUsageSize
      s3GlUsageSize
      transferredSize
      downloadRequestCont
      excludingTax
      includingTax
      taxRate
      exchangeRate
      createdAt
      updatedAt
    }
  }
`;
export const deleteCharge = /* GraphQL */ `
  mutation DeleteCharge(
    $input: DeleteChargeInput!
    $condition: ModelChargeConditionInput
  ) {
    deleteCharge(input: $input, condition: $condition) {
      id
      accountId
      aggregationDate
      status
      basicCharge
      s3Charge
      excessTransferredCharge
      s3StdUsageSize
      s3IaUsageSize
      s3GlIrUsageSize
      s3GlUsageSize
      transferredSize
      downloadRequestCont
      excludingTax
      includingTax
      taxRate
      exchangeRate
      createdAt
      updatedAt
    }
  }
`;
export const createFileDownloadHistory = /* GraphQL */ `
  mutation CreateFileDownloadHistory(
    $input: CreateFileDownloadHistoryInput!
    $condition: ModelFileDownloadHistoryConditionInput
  ) {
    createFileDownloadHistory(input: $input, condition: $condition) {
      id
      accountId
      fileId
      fileSize
      storageClass
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateFileDownloadHistory = /* GraphQL */ `
  mutation UpdateFileDownloadHistory(
    $input: UpdateFileDownloadHistoryInput!
    $condition: ModelFileDownloadHistoryConditionInput
  ) {
    updateFileDownloadHistory(input: $input, condition: $condition) {
      id
      accountId
      fileId
      fileSize
      storageClass
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteFileDownloadHistory = /* GraphQL */ `
  mutation DeleteFileDownloadHistory(
    $input: DeleteFileDownloadHistoryInput!
    $condition: ModelFileDownloadHistoryConditionInput
  ) {
    deleteFileDownloadHistory(input: $input, condition: $condition) {
      id
      accountId
      fileId
      fileSize
      storageClass
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createGlacierDownloadRequest = /* GraphQL */ `
  mutation CreateGlacierDownloadRequest(
    $input: CreateGlacierDownloadRequestInput!
    $condition: ModelGlacierDownloadRequestConditionInput
  ) {
    createGlacierDownloadRequest(input: $input, condition: $condition) {
      id
      accountId
      fileId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateGlacierDownloadRequest = /* GraphQL */ `
  mutation UpdateGlacierDownloadRequest(
    $input: UpdateGlacierDownloadRequestInput!
    $condition: ModelGlacierDownloadRequestConditionInput
  ) {
    updateGlacierDownloadRequest(input: $input, condition: $condition) {
      id
      accountId
      fileId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteGlacierDownloadRequest = /* GraphQL */ `
  mutation DeleteGlacierDownloadRequest(
    $input: DeleteGlacierDownloadRequestInput!
    $condition: ModelGlacierDownloadRequestConditionInput
  ) {
    deleteGlacierDownloadRequest(input: $input, condition: $condition) {
      id
      accountId
      fileId
      userId
      createdAt
      updatedAt
    }
  }
`;
