








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CoverageStatusDialog from '@/components/home/CoverageStatusDialog.vue';
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";

@Component({
  components: {
    CoverageStatusDialog,
  }
})
export default class ScheduleMenu extends Vue {
  private innerSheet = false;
  private attributes;
  private id = '';
  private dialog = false;
  private isOperate = false;
  private isCreate = false;

  @Prop({default:false})
  private sheet;
  @Prop()
  private coverage;

  @Watch('sheet')
  private onChangeSheet(newValue){
    this.innerSheet = newValue;
  }

  @Watch('innerSheet')
  private onChangeInnerSheet(){
    this.$emit('schedule-menu-sheet', this.innerSheet);
  }

  private created(){
    this.setDefalut();
  }

  private mounted(){
    this.setDefalut();
  }

  private async setDefalut(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.isOperate = isAllow("coverage", "operate", role.coverage?.operate) && await isActiveServie("coverage");
    this.isCreate = isAllow("filing", "create") && await isActiveServie("filing");
  }

  private onStatusDialogShow(){
    this.innerSheet = false;
    this.dialog = true;
  }

  private onStatusDialogClosed(){
    this.dialog = false;
  }
}
