import { PGCService, SearchableCoverageFilterInput } from "@/API";
import * as queries from "@/graphql/queries";
import { API } from "aws-amplify";
import * as AuthUtils from "@/utils/AuthUtils";
import { GraphQLResult } from "@aws-amplify/api";
import { SearchCoveragesQuery, SearchCoveragesQueryVariables, SearchableCoverageSortInput, SearchableCoverageSortableFields, SearchableSortDirection } from "@/API";

export class CoverageSerch {
    static readonly SEARCH_LIMIT: number = 10000;

    headers = [
        {
            text: "ステータス",
            align: "center",
            value: "status",
        },
        { text: "タイトル", value: "title" },
        { text: "開始日時", value: "startAt" },
        { text: "終了日時", value: "endAt" },
        { text: "取材者", value: "assignedUserName" },
        { text: "取材場所", value: "location" },
        { text: "アルバム", value: "files", align: "center", sortable: false },
        { text: "", value: "detail", align: "center", sortable: false },
    ];

    async search(searchForm: SearchForm): Promise<CoverageListData[]> {
        const filter: SearchableCoverageFilterInput = {};
        filter.ownedAccountId = { eq: searchForm.accountId };
        filter.deleted = { eq: 0 };
        filter.and = [];
        filter.and?.push({
            or: [
                //対象日に開始か終了がある
                { startAt: { gt: searchForm.dayStart, lt: searchForm.dayEnd } },
                { endAt: { gt: searchForm.dayStart, lt: searchForm.dayEnd } },
                //開始と終了の間に対象日がある
                { and: [{ startAt: { lt: searchForm.dayStart } }, { endAt: { gt: searchForm.dayEnd } }] },
            ],
        });
        
        // フリーワード
        if (searchForm.searchWord) {
            filter.and?.push({
                or: [
                    { title: { matchPhrase: searchForm.searchWord } },
                    { description: { matchPhrase: searchForm.searchWord } },
                    { location: { matchPhrase: searchForm.searchWord } },
                ],
            });
        }

        // 取材者
        if (searchForm.assignedUserId !== null) {
            filter.and?.push({
                or: [
                    { assignedUserId: { eq: searchForm.assignedUserId } },
                ],
            });
        }

        const allUsers: Array<{ key: string | undefined; val: string | undefined }> = await AuthUtils.listUsers(PGCService.COVERAGE);
        let token: (string | null)[] | null = null;
        const sort: SearchableCoverageSortInput = { direction: SearchableSortDirection.asc, field: SearchableCoverageSortableFields.startAt }
        let variables: SearchCoveragesQueryVariables = {
            filter: filter,
            from: 0,
            limit: 1000,
            sort: sort,
        };
        let view: Array<CoverageListData> = [];

        try {
            do
            {
                const CoverageList: Array<CoverageListData> = [];
                const { data } = (await API.graphql({
                    query: queries.searchCoverages,
                    variables: variables,
                })) as GraphQLResult<SearchCoveragesQuery>;
                
                if (data && data.searchCoverages && data.searchCoverages.items) {
                    for (const key in data.searchCoverages.items) {
                        const item = data.searchCoverages.items[key]
                        const user = allUsers.find(({ val }) => val === item?.assignedUserId);
                        const row: CoverageListData = item as CoverageListData;
                        row.assignedUserName = user?.key;
                        CoverageList.push(row);
                    }

                    view = view.concat(CoverageList)

                    if(!data.searchCoverages.nextToken || (data.searchCoverages.nextToken && data.searchCoverages.nextToken.length <= 0 || view.length >= CoverageSerch.SEARCH_LIMIT))
                    {
                        token = null;
                        break;
                    }

                    token = data.searchCoverages.nextToken;

                    variables = {
                        filter: filter,
                        from: -1,
                        limit: 1000,
                        sort: sort,
                        nextToken: token ? token : null,
                    };
                }
            }while(token)
            
        } catch (e) {
            console.log(e);
            throw e;
        }

        return view;
    }
}

export type SearchForm = {
    accountId: string;
    dayStart: string;
    dayEnd: string | null;
    searchWord: string | null;
    assignedUserId: string | null;
}

export type CoverageListData = {
    id?: string;
    title?: string | null;
    location?: string;
    startAt?: string;
    endAt?: string;
    description?: string | null;
    memo?: string | null;
    attachmentFile?: string | null;
    createdUserId?: string;
    ownedAccountId?: string;
    ownedDepartment?: string;
    assignedUserId?: string;
    assignedUserName?: string;
    status?: number;
    deleted?: number;
    deletedAt?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
}
