


































































































import { Component, Vue } from "vue-property-decorator";
import * as service from "@/services/coverage/coverageInfoService";
import CoverageInfoBaseForm from "./CoverageInfoBaseForm.vue";

import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    Dialog,
    Loading,
  },
})
export default class CoverageInfoCreateForm extends CoverageInfoBaseForm {
  name = "coverage-info-create-form";

  async createCoverageInfo() {
    if (!this.validate()) {
      return;
    }

    const attributes = this.$store.state.user.attributes;
    const form: service.InputForm = {
      createdUserId: attributes.sub,
      ownedAccountId: attributes["custom:account_id"],
      ownedDepartment: attributes["custom:department"],
      body: this.body,
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.loading = true;
    await this.CoverageInfoService.createCoverageInfo(form)
      .then(() => {
        this.setDefault();
        (this.$refs.form as Vue & {
          resetValidation: () => void;
        }).resetValidation();
        this.close();
      })
      .catch((e) => {
        console.log(e);
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "連絡事項登録失敗";
        this.resultDialogMessage = `連絡事項の登録に失敗しました。`;
      })
      .finally(() => (this.loading = false));
  }

  private close() {
    this.$emit("createClose");
  }
}
