


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ShareHeader extends Vue {

  @Prop({ default: 1 })
  private page!: number;
  @Prop({ default: 0 })
  private limit!: number;
  @Prop({ default: 0 })
  private length!: number;
  @Prop({ default: 0 })
  private total!: number;

  get searchFrom(): number {
    return ((this.page - 1 ) > 0 ? (this.page - 1 ) : 0) * this.limit;
  }
  
  get searchHitFrom(): number {
    return this.length === 0 ? 0 : this.searchFrom + 1 ;
  }

  get searchHitTo(): number {
    return this.length === 0 ? 0 : this.length + this.searchFrom ;
  }

  back(){
    this.$router.push({name: 'MyPage'});
  }

  private onClickReload(){
    this.$emit("share-reload", true);
  }
}
