

















import { Component, Vue } from "vue-property-decorator";

@Component
export default class AccesslevelSelect extends Vue {
  name = "AccesslevelSelect";
}
