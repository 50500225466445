












































import { Component, Watch, Vue } from "vue-property-decorator";
import * as RoleUtils from "@/utils/RoleUtils";
import * as AuthUtils from "@/utils/AuthUtils";
import * as AccountSettingUtils from "@/utils/AccountSettingUtils";
import { Auth } from "@aws-amplify/auth";
import { decodeRoleJson } from "@/utils/RoleUtils";

@Component
export default class SideMenu extends Vue {
  drawer = false;
  mfaPreference = "";
  menus = [
    { title: "ホーム", icon: "mdi-home", url: "/my_page/", show: true },
    {
      title: "取材管理",
      icon: "mdi-calendar-month-outline",
      url: "/" + AuthUtils.getAccountId(null) + "/coverage",
      show: false,
      class: "d-none d-md-flex",
    },
    {
      title: "ファイルアップロード",
      icon: "mdi-file-upload-outline",
      url: "/" + AuthUtils.getAccountId(null) + "/files/create",
      show: false,
    },
    {
      title: "CSVインポート",
      icon: "mdi-file-import",
      url: "/" + AuthUtils.getAccountId(null) + "/files/csv/import",
      show: false,
    },
    {
      title: "共有管理",
      icon: "mdi-share-variant-outline",
      url: "/" + AuthUtils.getAccountId(null) + "/files/share_list",
      show: false,
    },
    {
      title: "ユーザー情報",
      icon: "mdi-account",
      url: "/" + AuthUtils.getAccountId(null) + "/user/details",
      show: true,
    },
    {
      title: "システム管理",
      icon: "mdi-application-settings",
      url: "/" + AuthUtils.getAccountId(null) + "/admin",
      show: RoleUtils.isAllow("management", "access"),
      class: "d-none d-sm-flex",
    },
  ];

  name = null;
  user = null;
  isAdmin = false;

  async created() {
    await this.setMenus();
    await this.setPreference();
  }

  async mounted() {
    await this.setMenus();
    await this.setPreference();
    this.$store.subscribe(async (mutation) => {
      if (mutation.type === "changeSideDrawer") {
        this.drawer = !this.drawer;
      }
    });
  }

  @Watch("drawer")
  async setPreference() {
    this.user = this.$store.getters.user;
    if(this.drawer){
      this.user = await AuthUtils.getUser(true);
    }
    this.name = this.$store.state.user?.attributes.name;
    this.mfaPreference = this.$store.state.user?.preferredMFA;
    await this.setMenus();
  }

  private async signOut() {
    await Auth.signOut();
    this.$router.push("/" + AuthUtils.getAccountId(null));
    this.$store.commit("setUserList", []);
    this.$store.commit("setAccountData", null);
  }

  private async setMenus(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.menus = [
      { title: "ホーム", icon: "mdi-home", url: "/my_page/", show: true },
      {
        title: "取材管理",
        icon: "mdi-calendar-month-outline",
        url: "/" + AuthUtils.getAccountId(null) + "/coverage",
        show: await AccountSettingUtils.isActiveServie("coverage") && RoleUtils.isAllow("coverage", "manage"),
        class: "d-none d-md-flex",
      },
      {
        title: "ファイルアップロード",
        icon: "mdi-file-upload-outline",
        url: "/" + AuthUtils.getAccountId(null) + "/files/create",
        show: await AccountSettingUtils.isActiveServie("filing") && RoleUtils.isAllow("filing", "create"),
      },
      {
      title: "CSVインポート",
      icon: "mdi-file-import",
      url: "/" + AuthUtils.getAccountId(null) + "/files/csv/import",
      show: await AccountSettingUtils.isActiveServie("filing") && RoleUtils.isAllow("filing", "operate", role.filing?.operate),
      },
      {
        title: "共有管理",
        icon: "mdi-share-variant-outline",
        url: "/" + AuthUtils.getAccountId(null) + "/files/share_list",
        show: await AccountSettingUtils.isActiveServie("sharing") && RoleUtils.isAllow("sharing", "create"),
      },
      {
        title: "ユーザー情報",
        icon: "mdi-account",
        url: "/" + AuthUtils.getAccountId(null) + "/user/details",
        show: true,
      },
      {
        title: "システム管理",
        icon: "mdi-application-settings",
        url: "/" + AuthUtils.getAccountId(null) + "/admin",
        show: RoleUtils.isAllow("management", "access"),
        class: "d-none d-sm-flex",
      },
    ];
  }
}
