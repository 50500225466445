import {
    PGCSearchableFileSortInput,
    PgcSearchFilesQueryVariables,
    PGCSearchableFileSortableFields,
    PGCSearchableSortDirection,
    SearchableFileGroupFilterInput,
    SearchFileGroupsQueryVariables,
    SearchableFileGroupSortInput,
    SearchableFileGroupSortableFields,
    SearchableSortDirection,
    ListSharingWithFilesQueryVariables,
    File, FileGroup, Coverage, Sharing, ListGlacierDownloadRequestsQueryVariables, ModelGlacierDownloadRequestFilterInput, GlacierDownloadRequest, ListGlacierDownloadRequestsQuery, PGCService
} from "@/API";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import { isAllow } from "@/utils/RoleUtils";
import store from "@/store";
import moment from "moment";
import { listUsers } from "@/utils/AuthUtils";
import { GraphQLResult } from "@aws-amplify/api";
import { PgcSearchFilesQuery, SearchFileGroupsQuery, ListSharingWithFilesQuery } from "@/API";
import { AdobeMimeType, AudioMimeType, createYearItems, DocumentMimeType, ImageMimeType, VideoMimeType } from "@/utils/FileUtils";

export interface SearchCondition {
    freeword?: string;
    fileType?: FILE_TYPE;
    title?: string;
    shootingDateFrom?: string;
    shootingDateTo?: string;
    city?: string;
    byline?: string;
    registrationDateFrom?: string;
    registrationDateTo?: string;
    coverageId?: string;
    fileId?: string;
    registrant?: string;
    groupIds?: string[];
    groupTitle?: string;
    groupRegistrationDateFrom?: string;
    groupRegistrationDateTo?: string;
    groupRegistrant?: string;
    downLoadRequest?: boolean;
    publishedStatus?: number;
    publishedDateFrom?: string;
    publishedDateTo?: string;
    publishedMedia?: string;
    publishedPage?: string;
    originalEdition?: number;
    storageLocation?: string;
    isUnknownShootingDate?: boolean;
}

export const SEARCH_MODE = {
    File: 'File',
    FileGroup: 'FileGroup',
} as const;

type SEARCH_MODE = typeof SEARCH_MODE[keyof typeof SEARCH_MODE];

export const FILE_TYPE = {
    All: "all",
    Image: "image",
    Video: "video",
    Audio: "audio",
    Document: "document",
    Adobe: "adobe",
} as const;

type FILE_TYPE = typeof FILE_TYPE[keyof typeof FILE_TYPE];


export interface FileGroupData {
    id?: string;
    coverFileId?: string;
    title?: string;
    description?: string;
    memo?: string;
    createdUserId?: string;
    createdUserName?: string;
    ownedAccountId?: string;
    ownedDepartment?: string;
    deleted?: number;
    deletedAt?: string;
    createdAt?: string;
    updatedAt?: string;
    file?: File;
}

export interface FileData {
    id?: string;
    groupId?: string;
    rating?: number;
    title?: string;
    caption?: string;
    instructions?: string;
    memo?: string;
    fileName?: string;
    protect?: number;
    original?: string;
    caution?: number;
    cautionInstructions?: string;
    reuse?: number;
    reuseCondition?: string;
    keywords?: Array<string | null>;
    extension?: string;
    type?: string;
    meta?: string;
    tag?: Array<string | null>;
    customItems?: string;
    fileUri?: string;
    fileSize?: string;
    coverageId?: string;
    createdUserId?: string;
    createdUserName?: string;
    ownedAccountId?: string;
    ownedDepartment?: string;
    deleted?: number;
    deletedAt?: string;
    createdAt?: string;
    updatedAt?: string;
    group?: FileGroup;
    coverage?: Coverage;
}

export interface FileSearchResult {
    items: FileData[];
    total: number;
    length: number;
    nextToken: string[] | undefined;
}

export interface SharingFileResult {
    sharing?: Sharing;
    items: FileData[];
    total: number;
    length: number;
    nextToken: string[] | undefined;
}

export interface FileGroupSearchResult {
    items: FileGroupData[];
    total: number;
    length: number;
    nextToken: string[] | undefined;
}

export class FileSearchService {
    static readonly SEARCH_LIMIT: number = 30000;
    static readonly SHARE_LIMIT: number = 10000;
    private readonly RETRY_COUNT: number = 3;

    createPGCSearchFilesQueryVariables = async (condition: SearchCondition, nextToken?: string[], from?: number, limit?: number, sort?: PGCSearchableFileSortInput): Promise<PgcSearchFilesQueryVariables> => {
        const variables: PgcSearchFilesQueryVariables = {};

        if (!store.getters.user) {
            return variables;
        }

        // eslint-disable-next-line
        const user: any = store.getters.user;
        let accountId = "";
        let department = "";
        let userId = "";

        if (user) {
            accountId = user.attributes["custom:account_id"];
            department = user.attributes["custom:department"];
            userId = user.attributes.sub;
        }

        // eslint-disable-next-line
        const query: any = {
            bool: {
                must: [
                    {
                        term: {
                            deleted: 0
                        }
                    },
                    {
                        term: {
                            "ownedAccountId.keyword": accountId
                        }
                    }
                ]
            }
        };

        if (!isAllow("filing", "search", "COMPANY")) {
            if (isAllow("filing", "search", "DEPARTMENT")) {
                // eslint-disable-next-line
                const authDepartmentQuery: any = {
                    term: {
                        "ownedDepartment.keyword": department,
                    }
                };
                query.bool.must.push(authDepartmentQuery);
            } else if (isAllow("filing", "search", "SELF")) {
                // eslint-disable-next-line
                const authSelfQuery: any = {
                    term: {
                        "createdUserId.keyword": userId,
                    }
                };
                query.bool.must.push(authSelfQuery);
            } else {
                // eslint-disable-next-line
                const authSelfQuery: any = {
                    term: {
                        // 当たらないランダム文字列を入れる
                        "ownedAccountId.keyword": Math.random().toString(32).substring(2),
                    }
                };
                query.bool.must.push(authSelfQuery);
            }
        }

        if (condition.fileId) {
            // eslint-disable-next-line
            const fileIdQuery: any = {
                term: {
                    // eslint-disable-next-line
                    "id.keyword": condition.fileId
                }
            };
            query.bool.must.push(fileIdQuery);
        }
    
        if (condition.groupIds && condition.groupIds.length > 0) {
            // eslint-disable-next-line
            const groupIdQuery: any = {
                terms: {
                    "groupId.keyword": condition.groupIds
                }
            };
            query.bool.must.push(groupIdQuery);
        } else {
            if (condition.freeword) {
                // eslint-disable-next-line
                const freewordQuery: any = {
                    bool: {
                        should: [
                            // {
                            //     // eslint-disable-next-line
                            //     multi_match: {
                            //         type: "cross_fields",
                            //         query: condition.freeword,
                            //         fields: ["title", "caption", "meta.byline", "meta.city", "instructions", "keywords", "memo", "fileName"],
                            //     }
                            // },
                            // {
                            //     // eslint-disable-next-line
                            //     multi_match: {
                            //         type: "cross_fields",
                            //         query: condition.freeword,
                            //         fields: ["title.kuromoji", "caption.kuromoji", "meta.byline.kuromoji", "meta.city.kuromoji", "instructions.kuromoji", "keywords.kuromoji", "memo.kuromoji", "fileName.kuromoji"],
                            //     }
                            // },
                            {
                                // eslint-disable-next-line
                                multi_match: {
                                    type: "phrase",
                                    query: condition.freeword,
                                    fields: ["title", "caption", "meta.byline", "meta.city", "instructions", "keywords", "memo", "fileName"],
                                    operator: "or",
                                    slop: 0
                                }
                            },
                            {
                                // eslint-disable-next-line
                                multi_match: {
                                    type: "phrase",
                                    query: condition.freeword,
                                    fields: ["title.kuromoji", "caption.kuromoji", "meta.byline.kuromoji", "meta.city.kuromoji", "instructions.kuromoji", "keywords.kuromoji", "memo.kuromoji", "fileName.kuromoji"],
                                    operator: "or",
                                    slop: 9999
                                }
                            },
                        ],
                        // eslint-disable-next-line
                        minimum_should_match: 1
                        // minimum_should_match: 2
                    }                    
                };

                query.bool.must.push(freewordQuery);
            }

            if(condition.isUnknownShootingDate && condition.isUnknownShootingDate === true){
                // eslint-disable-next-line
                const nullShootingDateQuery: any = {
                    bool: {
                        should: [
                            {
                                bool: {
                                    // eslint-disable-next-line
                                    "must_not": [
                                        {
                                            exists: {
                                                field: "meta.datetimeOriginated"
                                            }
                                        },
                                        {
                                            exists: {
                                                field: "meta.aroundYearOriginated"
                                            }
                                        },
                                        {
                                            exists: {
                                                field: "meta.ageOriginated"
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                bool: {
                                    must:[
                                        {
                                            term: {
                                                "meta.originatedStatus": -1
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }    
                };
                query.bool.must.push(nullShootingDateQuery);
            }else{
                const ageItemList = createYearItems(10);
                if (condition.shootingDateFrom && condition.shootingDateTo) {
                    const yearFrom = parseInt(moment.parseZone(condition.shootingDateFrom).startOf('year').format('YYYY'), 10);
                    const yearTo = parseInt(moment.parseZone(condition.shootingDateTo).endOf('year').format('YYYY'), 10);
                    const ageGte = ageItemList.find(x => yearFrom >= x.value && x.value <= yearFrom);
                    const ageLte = ageItemList.find(x => yearTo >= x.value && x.value <= yearTo);

                    // eslint-disable-next-line
                    const shootingDateQuery: any = {
                        bool: {
                            should: [
                                {
                                    range: {
                                        "meta.aroundYearOriginated": {
                                            gte: parseInt(moment.parseZone(condition.shootingDateFrom).startOf('year').subtract(5, 'y').format('YYYY'), 10),
                                            lte: parseInt(moment.parseZone(condition.shootingDateTo).endOf('year').add(5, 'y').format('YYYY'), 10),
                                        }
                                    }
                                },
                                {
                                    range: {
                                        "meta.datetimeOriginated": {
                                            gte: moment.parseZone(condition.shootingDateFrom).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                                            lte: moment.parseZone(condition.shootingDateTo).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                                        }
                                    }
                                }
                            ]
                        }    
                    };

                    if(ageGte && ageLte){
                        shootingDateQuery.bool.should.push(
                            {
                                range: {
                                    "meta.ageOriginated": {
                                        gte: ageGte.value,
                                        lte: ageLte.value,
                                    }
                                }
                            }
                        );
                    }
                    query.bool.must.push(shootingDateQuery);
                } else if (condition.shootingDateFrom) {
                    const yearFrom = parseInt(moment.parseZone(condition.shootingDateFrom).startOf('year').format('YYYY'), 10);
                    const ageGte = ageItemList.find(x => yearFrom >= x.value && x.value <= yearFrom);

                    // eslint-disable-next-line
                    const shootingDateQuery: any = {
                        bool: {
                            should: [
                                {
                                    range: {
                                        "meta.aroundYearOriginated": {
                                            gte: parseInt(moment.parseZone(condition.shootingDateFrom).startOf('year').subtract(5, 'y').format('YYYY'), 10),
                                        }
                                    }
                                },
                                {
                                    range: {
                                        "meta.datetimeOriginated": {
                                            gte: moment.parseZone(condition.shootingDateFrom).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                                        }
                                    }
                                }
                            ]
                        }    
                    };

                    if(ageGte){
                        shootingDateQuery.bool.should.push(
                            {
                                range: {
                                    "meta.ageOriginated": {
                                        gte: ageGte.value,
                                    }
                                }
                            }
                        );
                    }
                    query.bool.must.push(shootingDateQuery);
                } else if (condition.shootingDateTo) {
                    const yearTo = parseInt(moment.parseZone(condition.shootingDateTo).endOf('year').format('YYYY'), 10);
                    const ageLte = ageItemList.find(x => yearTo >= x.value && x.value <= yearTo);

                    // eslint-disable-next-line
                    const shootingDateQuery: any = {
                        bool: {
                            should: [
                                {
                                    range: {
                                        "meta.aroundYearOriginated": {
                                            lte: parseInt(moment.parseZone(condition.shootingDateTo).endOf('year').add(5, 'y').format('YYYY'), 10),
                                        }
                                    }
                                },
                                {
                                    range: {
                                        "meta.datetimeOriginated": {
                                            lte: moment.parseZone(condition.shootingDateTo).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                                        }
                                    }
                                }
                            ]
                        }    
                    };

                    if(ageLte){
                        shootingDateQuery.bool.should.push(
                            {
                                range: {
                                    "meta.ageOriginated": {
                                        lte: ageLte.value,
                                    }
                                }
                            }
                        );
                    }
                    query.bool.must.push(shootingDateQuery);
                }
            }

            if (condition.registrationDateFrom && condition.registrationDateTo) {
                // eslint-disable-next-line
                const rangeRegistrationDateQuery: any = {
                    range: {
                        createdAt: {
                            gte: moment(condition.registrationDateFrom).local().startOf('day').toISOString(),
                            lte: moment(condition.registrationDateTo).local().endOf('day').toISOString(),
                        }
                    }
                };
                query.bool.must.push(rangeRegistrationDateQuery);
            } else if (condition.registrationDateFrom) {
                // eslint-disable-next-line
                const rangeRegistrationDateQuery: any = {
                    range: {
                        createdAt: {
                            gte: moment(condition.registrationDateFrom).local().startOf('day').toISOString(),
                        }
                    }
                };
                query.bool.must.push(rangeRegistrationDateQuery);
            } else if (condition.registrationDateTo) {
                // eslint-disable-next-line
                const rangeRegistrationDateQuery: any = {
                    range: {
                        createdAt: {
                            lte: moment(condition.registrationDateTo).local().endOf('day').toISOString(),
                        }
                    }
                };
                query.bool.must.push(rangeRegistrationDateQuery);
            }

            if (condition.title) {
                // eslint-disable-next-line
                const titleQuery: any = {
                    // eslint-disable-next-line
                    match_phrase: {
                        title: {
                            query: condition.title,
                            slop: 9999
                        }
                    }
                };
                query.bool.must.push(titleQuery);
            }

            if (condition.byline) {
                // eslint-disable-next-line
                const bylineQuery: any = {
                    // eslint-disable-next-line
                    match_phrase: {
                        "meta.byline": {
                            query: condition.byline,
                            slop: 9999
                        }
                    }
                };
                query.bool.must.push(bylineQuery);
            }

            if (condition.city) {
                // eslint-disable-next-line
                const cityQuery: any = {
                    // eslint-disable-next-line
                    match_phrase: {
                        "meta.city": {
                            query: condition.city,
                            slop: 9999
                        }
                    }
                };
                query.bool.must.push(cityQuery);
            }

            if (condition.fileType && condition.fileType !== FILE_TYPE.All) {
                let type: string[] = [];
                switch(condition.fileType){
                    case "image": {
                        type = ImageMimeType.getAllImageMimeTypes().concat(AdobeMimeType.getAllAdobeMimeTypes());
                        break;
                    }
                    case "video": {
                        type = VideoMimeType.getAllVideoMimeTypes();
                        break;
                    }
                    case "audio": {
                        type = AudioMimeType.getAllAudioMimeTypes();
                        break;
                    }
                    case "document": {
                        type = DocumentMimeType.getAllDocumentMimeTypes();
                        break;
                    }
                    case "adobe": {
                        type = AdobeMimeType.getAllAdobeMimeTypes();
                        break;
                    }
                    default: {
                        break;
                    }
                }

                // eslint-disable-next-line
                const typeQuery: any = {
                    terms: {
                        "type.keyword": type
                    }
                };
                query.bool.must.push(typeQuery);
            }

            if (condition.coverageId) {
                // eslint-disable-next-line
                const coverageIdQuery: any = {
                    term: {
                        "coverageId.keyword": condition.coverageId
                    }
                };
                query.bool.must.push(coverageIdQuery);
            }

            if (condition.registrant) {
                // eslint-disable-next-line
                const registrantQuery: any = {
                    term: {
                        // eslint-disable-next-line
                        "createdUserId.keyword": condition.registrant
                    }
                };
                query.bool.must.push(registrantQuery);
            }
            if (condition.downLoadRequest) {
                //ダウンロード7日以内のダウンロードリクエストを取得
                const fileItems = await this.getdownLoadRequest();
                //ダミーでかからないように
                const fileIds: string[] = ["dmmy"]
                for (const key in fileItems) {
                    if (Object.prototype.hasOwnProperty.call(fileItems, key)) {
                        const element = fileItems[key];
                        fileIds.push(String(element.fileId).toString());
                    }
                }
                // eslint-disable-next-line
                const fileIdQuery: any = {
                    terms: {
                        "id.keyword": fileIds
                    }
                };
                query.bool.must.push(fileIdQuery);
            }

            if (condition.publishedStatus != null && (condition.publishedStatus === 0 || condition.publishedStatus === 1)) {
                // eslint-disable-next-line
                const publishedStatusQuery: any = {
                    term: {
                        // eslint-disable-next-line
                        "customItems.publishedStatus": condition.publishedStatus
                    }
                };
                query.bool.must.push(publishedStatusQuery);
            }

            if (condition.publishedStatus === 1 && condition.publishedDateFrom && condition.publishedDateTo) {
                // eslint-disable-next-line
                const publishedDateQuery: any = {
                    range: {
                        // eslint-disable-next-line
                        "customItems.publishedDate": {
                            gte: condition.publishedDateFrom,
                            lte: condition.publishedDateTo,
                        }
                    }
                };
                query.bool.must.push(publishedDateQuery);

            } else if (condition.publishedStatus === 1 && condition.publishedDateFrom) {
                // eslint-disable-next-line
                const publishedDateQuery: any = {
                    range: {
                        // eslint-disable-next-line
                        "customItems.publishedDate": {
                            gte: condition.publishedDateFrom,
                        }
                    }
                };
                query.bool.must.push(publishedDateQuery);
            } else if (condition.publishedStatus === 1 && condition.publishedDateTo) {
                // eslint-disable-next-line
                const publishedDateQuery: any = {
                    range: {
                        // eslint-disable-next-line
                        "customItems.publishedDate": {
                            lte: condition.publishedDateTo,
                        }
                    }
                };
                query.bool.must.push(publishedDateQuery);
            }

            if (condition.publishedStatus === 1 && condition.publishedMedia) {
                // eslint-disable-next-line
                const publishedMediaQuery: any = {
                    // eslint-disable-next-line
                    match_phrase: {
                        "customItems.publishedMedia": {
                            query: condition.publishedMedia,
                            slop: 9999
                        }
                    }
                };
                query.bool.must.push(publishedMediaQuery);
            }

            if (condition.publishedStatus === 1 && condition.publishedPage) {
                // eslint-disable-next-line
                const publishedPageQuery: any = {
                    term: {
                        // eslint-disable-next-line
                        "customItems.publishedPage": condition.publishedPage
                    }
                };
                query.bool.must.push(publishedPageQuery);
            }

            if (condition.originalEdition != null && condition.originalEdition >= 0 && condition.originalEdition <= 99) {
                // eslint-disable-next-line
                const originalEditionQuery: any = {
                    term: {
                        // eslint-disable-next-line
                        "customItems.originalEdition": condition.originalEdition
                    }
                };
                query.bool.must.push(originalEditionQuery);
            }

            if (condition.storageLocation) {
                // eslint-disable-next-line
                const storageLocationQuery: any = {
                    // eslint-disable-next-line
                    match_phrase: {
                        "customItems.storageLocation": {
                            query: condition.storageLocation,
                            slop: 9999
                        }
                    }
                };
                query.bool.must.push(storageLocationQuery);
            }
        }

        variables.filter = JSON.stringify(query);

        const defalutSort: PGCSearchableFileSortInput = {
            field: PGCSearchableFileSortableFields.createdAt,
            direction: PGCSearchableSortDirection.desc,
        };
        variables.sort = sort ? sort : defalutSort;
        variables.limit = limit ? limit : FileSearchService.SEARCH_LIMIT;
        variables.from = from ? from : 0;
        if (nextToken) {
            variables.nextToken = nextToken;
        }
        return variables;
    };


    createSearchFileGroupsQueryVariables = (condition: SearchCondition, nextToken?: string[], from?: number, limit?: number, sort?: SearchableFileGroupSortInput): SearchFileGroupsQueryVariables => {
        const variables: SearchFileGroupsQueryVariables = {};

        if (!store.getters.user) {
            return variables;
        }

        // eslint-disable-next-line
        const user: any = store.getters.user;
        let accountId = "";
        let department = "";
        let userId = "";

        if (user) {
            accountId = user.attributes["custom:account_id"];
            department = user.attributes["custom:department"];
            userId = user.attributes.sub;
        }

        const filter: SearchableFileGroupFilterInput = {
            deleted: {
                eq: 0,
            },
            and: [
                {
                    ownedAccountId: {
                        eq: accountId,
                    }
                }
            ]
        };

        if (!isAllow("filing", "search", "COMPANY")) {
            if (isAllow("filing", "search", "DEPARTMENT")) {
                // eslint-disable-next-line
                const query: any = {
                    ownedDepartment: {
                        eq: department,
                    },
                };
                filter.and?.push(query);
            } else if (isAllow("filing", "search", "SELF")) {
                // eslint-disable-next-line
                const query: any = {
                    createdUserId: {
                        eq: userId,
                    },
                };
                filter.and?.push(query);
            } else {
                // 当たらないランダム文字列を入れる
                // eslint-disable-next-line
                const query: any = {
                    ownedAccountId: {
                        eq: Math.random().toString(32).substring(2)
                    },
                };
                filter.and?.push(query);
            }
        }

        if (condition.freeword) {
            // eslint-disable-next-line
            const query: any = {
                or: [
                    {
                        title: {
                            matchPhrase: condition.freeword,
                        },
                    },
                    {
                        description: {
                            matchPhrase: condition.freeword,
                        },
                    },
                    {
                        memo: {
                            matchPhrase: condition.freeword,
                        },
                    }
                ]
            };
            filter.and?.push(query);
        }

        if (condition.groupTitle) {
            // eslint-disable-next-line
            const query: any = {
                title: {
                    matchPhrase: condition.groupTitle,
                }
            };
            filter.and?.push(query);
        }

        if (condition.groupRegistrationDateFrom && condition.groupRegistrationDateTo) {
            // eslint-disable-next-line
            const query: any = {
                or: [
                    {
                        createdAt: {
                            gte: moment(condition.groupRegistrationDateFrom).local().startOf('day').toISOString(),
                            lte: moment(condition.groupRegistrationDateTo).local().endOf('day').toISOString(),
                        }
                    },
                ]
            };
            filter.and?.push(query);
        } else if (condition.groupRegistrationDateFrom) {
            // eslint-disable-next-line
            const query: any = {
                or: [
                    {
                        createdAt: {
                            gte: moment(condition.groupRegistrationDateFrom).local().startOf('day').toISOString(),
                        }
                    },
                ]
            };
            filter.and?.push(query);
        } else if (condition.groupRegistrationDateTo) {
            // eslint-disable-next-line
            const query: any = {
                or: [
                    {
                        createdAt: {
                            lte: moment(condition.groupRegistrationDateTo).local().endOf('day').toISOString(),
                        }
                    },
                ]
            };
            filter.and?.push(query);
        }

        if (condition.groupRegistrant) {
            // eslint-disable-next-line
            const query: any = {
                createdUserId: {
                    eq: condition.groupRegistrant,
                }
            };
            filter.and?.push(query);
        }

        variables.filter = filter;

        const defalutSort: SearchableFileGroupSortInput = {
            field: SearchableFileGroupSortableFields.createdAt,
            direction: SearchableSortDirection.desc,
        }
        variables.sort = sort ? sort : defalutSort;
        variables.limit = !nextToken && limit ? limit : FileSearchService.SEARCH_LIMIT;
        variables.from = !nextToken && from ? from : 0;
        if (nextToken) {
            variables.nextToken = nextToken;
        }
        return variables;
    };

    searchPGCFiles = async (variables: PgcSearchFilesQueryVariables, retryCount = 0): Promise<GraphQLResult<PgcSearchFilesQuery>> => {
        try{
           const result = (await API.graphql(graphqlOperation(queries.pgcSearchFiles, variables))) as GraphQLResult<PgcSearchFilesQuery>;
           return result;
        }catch(error){
            retryCount = retryCount + 1;
            if(retryCount > this.RETRY_COUNT){
                throw error;
            }
            return await this.searchPGCFiles(variables, retryCount);
        }
    }

    searchFileDataFullList = async (condition: SearchCondition): Promise<FileData[]> => {
        let token: string[] | undefined = undefined;
        let from = 0;
        const limit = 500;

        const sort: PGCSearchableFileSortInput = {
            field: PGCSearchableFileSortableFields.createdAt,
            direction: PGCSearchableSortDirection.desc,
        };

        const allUsers: Array<{ key: string | undefined; val: string | undefined }> = await listUsers(PGCService.FILING);
        let files = new Array<FileData>();

        do{
            let variables = await this.createPGCSearchFilesQueryVariables(condition, token, from, limit, sort);
            const { data } = await this.searchPGCFiles(variables);

            if (data && data.pgcSearchFiles && data.pgcSearchFiles.items) {
                const fileDataList: Array<FileData> = [];

                for (const key in data.pgcSearchFiles.items) {
                    const item = data.pgcSearchFiles.items[key]
                    const user = allUsers.find(({ val }) => val === item?.createdUserId);
                    const fileData: FileData = item as FileData;
                    fileData.createdUserName = user?.key,
                        fileDataList.push(fileData)
                }

                files = files.concat(fileDataList);

                if(!data.pgcSearchFiles.nextToken || (data.pgcSearchFiles.nextToken && data.pgcSearchFiles.nextToken.length <= 0 || files.length >= FileSearchService.SEARCH_LIMIT))
                {
                    token = undefined;
                    break;
                }

                token = data.pgcSearchFiles.nextToken.length > 0 ? data.pgcSearchFiles.nextToken as string[] : undefined;
                from = -1;
                variables = await this.createPGCSearchFilesQueryVariables(condition, token, from, limit, sort);
            }

        }while(token)

        return files;
    }

    searchFiles = async (condition: SearchCondition, nextToken?: string[], from?: number, limit?: number, sort?: PGCSearchableFileSortInput): Promise<FileSearchResult> => {
        let result: FileSearchResult = {
            items: [],
            total: 0,
            length: 0,
            nextToken: undefined,
        };

        if (!store.getters.user) {
            return result;
        }

        const innerFrom = from ? from : nextToken && nextToken.length > 0 ? -1 : 0;
        const innerLimit = limit ? limit : FileSearchService.SEARCH_LIMIT;
        result = await this.searchFile(condition, nextToken, innerFrom, innerLimit, sort);

        return result;
    };

    searchFile = async (condition: SearchCondition, nextToken?: string[], from?: number, limit?: number, sort?: PGCSearchableFileSortInput): Promise<FileSearchResult> => {
        const result: FileSearchResult = {
            items: [],
            total: 0,
            length: 0,
            nextToken: [],
        };

        if (!store.getters.user) {
            return result;
        }

        // TODO: ESは1クエリ10000までしか返ってこないので再帰処理が必要そうだが、スタックオーバーフローとか計算量とか考えると悩む
        const variables = await this.createPGCSearchFilesQueryVariables(condition, nextToken, from, limit, sort as PGCSearchableFileSortInput);
        const { data } = await this.searchPGCFiles(variables);
        if(data && data.pgcSearchFiles){
            const fileDataList: Array<FileData> = [];
            const allUsers: Array<{ key: string | undefined; val: string | undefined }> = await listUsers(PGCService.FILING);
            for (const key in data.pgcSearchFiles.items) {
                const item = data.pgcSearchFiles.items[key]
                const user = allUsers.find(({ val }) => val === item.createdUserId);
                const fileData: FileData = item
                fileData.createdUserName = user?.key,
                    fileDataList.push(fileData)
            }
            result.items = fileDataList || [];
            result.total = data.pgcSearchFiles.total || 0;
            result.length = data.pgcSearchFiles.items?.length || 0;
            result.nextToken = data.pgcSearchFiles.nextToken as string[] || undefined;
        }
        return result;
    };

    searchFileGroups = async (condition: SearchCondition, nextToken?: string[], from?: number, limit?: number, sort?: SearchableFileGroupSortInput): Promise<FileGroupSearchResult> => {
        const result: FileGroupSearchResult = {
            items: [],
            total: 0,
            length: 0,
            nextToken: undefined,
        };

        if (!store.getters.user) {
            return result;
        }

        const innerFrom = from ? from : nextToken && nextToken.length > 0 ? -1 : 0;
        const innerLimit = limit ? limit : FileSearchService.SEARCH_LIMIT;

        // TODO: ESは1クエリ10000までしか返ってこないので再帰処理が必要そうだが、スタックオーバーフローとか計算量とか考えると悩む
        const variables = this.createSearchFileGroupsQueryVariables(condition, nextToken, innerFrom, innerLimit, sort as SearchableFileGroupSortInput);
        const { data } = (await API.graphql(graphqlOperation(queries.searchFileGroups, variables))) as GraphQLResult<SearchFileGroupsQuery>;
        if(data && data.searchFileGroups){
            const fileGroupDataList: Array<FileGroupData> = [];
            const allUsers: Array<{ key: string | undefined; val: string | undefined }> = await listUsers(PGCService.FILING);
            for (const key in data.searchFileGroups.items) {
                const item = data.searchFileGroups.items[key]
                const user = allUsers.find(({ val }) => val === item.createdUserId);
                const fileGroupData: FileGroupData = item
                fileGroupData.createdUserName = user?.key,
                    fileGroupDataList.push(fileGroupData)
            }
            result.items = fileGroupDataList || [];
            result.total = data.searchFileGroups.total || 0;
            result.length = data.searchFileGroups.items?.length || 0;
            result.nextToken = data.searchFileGroups.nextToken as string[] || undefined;
        }
        return result;
    };


    searchShareCandidateFiles = async (fileIds: string[], groupIds: string[]): Promise<FileData[]> => {
        let files = new Array<FileData>();

        if (!store.getters.user) {
            return files;
        }

        // eslint-disable-next-line
        const user: any = store.getters.user;
        let accountId = "";
        let department = "";
        let userId = "";

        if (user) {
            accountId = user.attributes["custom:account_id"];
            department = user.attributes["custom:department"];
            userId = user.attributes.sub;
        }

        const filter = {
            bool: {
                must: [
                    {
                        term: {
                            deleted: 0
                        }
                    },
                    {
                        term: {
                            "ownedAccountId.keyword": accountId
                        }
                    },
                    {
                        terms: {
                            "customItems.share": [1, 2]
                        }
                    }
                ]
            }
        };
        if (fileIds.length > 0) {
            // eslint-disable-next-line
            const query: any = {
                terms: {
                    "id.keyword": fileIds,
                }
            };
            filter.bool.must.push(query);
        }

        if (groupIds.length > 0) {
            // eslint-disable-next-line
            const query: any = {
                terms: {
                    "groupId.keyword": groupIds,
                }
            };
            filter.bool.must.push(query);
        }

        if (!isAllow("filing", "search", "COMPANY")) {
            if (isAllow("filing", "search", "DEPARTMENT")) {
                // eslint-disable-next-line
                const query: any = {
                    term: {
                        "ownedDepartment.keyword": department,
                    }
                };
                filter.bool.must.push(query);
            } else if (isAllow("filing", "search", "SELF")) {
                // eslint-disable-next-line
                const query: any = {
                    term: {
                        "createdUserId.keyword": userId,
                    }
                };
                filter.bool.must.push(query);
            } else {
                // eslint-disable-next-line
                const query: any = {
                    term: {
                        // 当たらないランダム文字列を入れる
                        "ownedAccountId.keyword": Math.random().toString(32).substring(2),
                    }
                };
                filter.bool.must.push(query);
            }
        }

        const sort: PGCSearchableFileSortInput = {
            field: PGCSearchableFileSortableFields.createdAt,
            direction: PGCSearchableSortDirection.desc,
        };

        const allUsers: Array<{ key: string | undefined; val: string | undefined }> = await listUsers(PGCService.FILING);
        let token: (string | null)[] | null = null;

        let variables: PgcSearchFilesQueryVariables = {
            filter: JSON.stringify(filter),
            from: 0,
            limit: 500,
            sort: sort,
        };

        do{
            const { data } = await this.searchPGCFiles(variables);
            if (data && data.pgcSearchFiles && data.pgcSearchFiles.items) {
                const fileDataList: Array<FileData> = [];

                for (const key in data.pgcSearchFiles.items) {
                    const item = data.pgcSearchFiles.items[key]
                    const user = allUsers.find(({ val }) => val === item?.createdUserId);
                    const fileData: FileData = item as FileData;
                    fileData.createdUserName = user?.key,
                        fileDataList.push(fileData)
                }

                files = files.concat(fileDataList);

                if(!data.pgcSearchFiles.nextToken || (data.pgcSearchFiles.nextToken && data.pgcSearchFiles.nextToken.length <= 0 || files.length >= FileSearchService.SEARCH_LIMIT))
                {
                    token = null;
                    break;
                }

                token = data.pgcSearchFiles.nextToken;

                variables = {
                    filter: JSON.stringify(filter),
                    from: -1,
                    limit: 500,
                    sort: sort,
                    nextToken: token ? token : null,
                };
            }
        }while(token)

        return files;
    };


    searchFileIds = async (fileIds: string[]): Promise<FileData[]> => {
        let files = new Array<FileData>();

        if (!store.getters.user) {
            return files;
        }

        // eslint-disable-next-line
        const user: any = store.getters.user;
        let accountId = "";
        let department = "";
        let userId = "";

        if (user) {
            accountId = user.attributes["custom:account_id"];
            department = user.attributes["custom:department"];
            userId = user.attributes.sub;
        }

        const filter = {
            bool: {
                must: [
                    {
                        term: {
                            deleted: 0
                        }
                    },
                    {
                        term: {
                            "ownedAccountId.keyword": accountId
                        }
                    },
                    {
                        terms: {
                            "id.keyword": fileIds
                        }
                    }
                ]
            }
        };

        if (!isAllow("filing", "search", "COMPANY")) {
            if (isAllow("filing", "search", "DEPARTMENT")) {
                // eslint-disable-next-line
                const query: any = {
                    term: {
                        "ownedDepartment.keyword": department,
                    }
                };
                filter.bool.must.push(query);
            } else if (isAllow("filing", "search", "SELF")) {
                // eslint-disable-next-line
                const query: any = {
                    term: {
                        "createdUserId.keyword": userId,
                    }
                };
                filter.bool.must.push(query);
            } else {
                // eslint-disable-next-line
                const query: any = {
                    term: {
                        // 当たらないランダム文字列を入れる
                        "ownedAccountId.keyword": Math.random().toString(32).substring(2),
                    }
                };
                filter.bool.must.push(query);
            }
        }

        const sort: PGCSearchableFileSortInput = {
            field: PGCSearchableFileSortableFields.createdAt,
            direction: PGCSearchableSortDirection.desc,
        };

        // const variables: PgcSearchFilesQueryVariables = {
        //     filter: JSON.stringify(filter),
        //     from: 0,
        //     limit: FileSearchService.SEARCH_LIMIT,
        //     sort: sort,
        // };

        const allUsers: Array<{ key: string | undefined; val: string | undefined }> = await listUsers(PGCService.FILING);
        let token: (string | null)[] | null = null;

        let variables: PgcSearchFilesQueryVariables = {
            filter: JSON.stringify(filter),
            from: 0,
            limit: 500,
            sort: sort,
        };

        do{
            const { data } = await this.searchPGCFiles(variables);
            if (data && data.pgcSearchFiles && data.pgcSearchFiles.items) {
                const fileDataList: Array<FileData> = [];

                for (const key in data.pgcSearchFiles.items) {
                    const item = data.pgcSearchFiles.items[key]
                    const user = allUsers.find(({ val }) => val === item?.createdUserId);
                    const fileData: FileData = item as FileData;
                    fileData.createdUserName = user?.key,
                        fileDataList.push(fileData)
                }

                files = files.concat(fileDataList);

                if(!data.pgcSearchFiles.nextToken || (data.pgcSearchFiles.nextToken && data.pgcSearchFiles.nextToken.length <= 0 || files.length >= FileSearchService.SEARCH_LIMIT))
                {
                    token = null;
                    break;
                }

                token = data.pgcSearchFiles.nextToken;

                variables = {
                    filter: JSON.stringify(filter),
                    from: -1,
                    limit: 500,
                    sort: sort,
                    nextToken: token ? token : null,
                };
            }
        }while(token)
        
        return files;
    };

    searchSharingFiles = async (sharingId: string, from: number, limit: number): Promise<SharingFileResult> => {
        const result: SharingFileResult = {
            sharing: undefined,
            items: [],
            total: 0,
            length: 0,
            nextToken: undefined,
        };

        if (!store.getters.user) {
            return result;
        }

        const variables: ListSharingWithFilesQueryVariables = {
            sharingId: sharingId,
            from: from,
            limit: limit,
        };

        const { data } = (await API.graphql(graphqlOperation(queries.listSharingWithFiles, variables))) as GraphQLResult<ListSharingWithFilesQuery>;

        if (data && data.listSharingWithFiles && data.listSharingWithFiles.sharing && data.listSharingWithFiles.files && data.listSharingWithFiles.files.length > 0) {
            const fileDataList: Array<FileData> = [];
            const allUsers: Array<{ key: string | undefined; val: string | undefined }> = await listUsers(PGCService.FILING);
            for (const key in data.listSharingWithFiles.files) {
                const item = data.listSharingWithFiles.files[key]
                const user = allUsers.find(({ val }) => val === item?.createdUserId);
                const fileData: FileData = item as FileData;
                fileData.createdUserName = user?.key;
                fileDataList.push(fileData);
            }
            result.sharing = data.listSharingWithFiles.sharing || undefined;
            result.items = fileDataList || [];
            result.total = data.listSharingWithFiles.total || 0;
            result.length = data.listSharingWithFiles.files.length || 0;
            result.nextToken = data.listSharingWithFiles.nextToken as string[] || undefined;
        }
        return result;
    };

    getdownLoadRequest = async (): Promise<GlacierDownloadRequest[]> => {
        const user = store.getters.user;
        const accountId = user.attributes["custom:account_id"]
        //7日前
        const limitDateTime = moment().add(-1,'days').toISOString();

        const filter: ModelGlacierDownloadRequestFilterInput = {}
        filter.accountId = { eq: accountId };
        filter.createdAt = { gt: limitDateTime };
        const variables: ListGlacierDownloadRequestsQueryVariables = {
            filter: filter
        };
        const apiResult = await API.graphql(graphqlOperation(queries.listGlacierDownloadRequests, variables)) as GraphQLResult<ListGlacierDownloadRequestsQuery>;
        const result = new Array<GlacierDownloadRequest>()
        if(apiResult.data?.listGlacierDownloadRequests?.items){
            apiResult.data.listGlacierDownloadRequests.items.forEach((item) => {
                if(item){
                    result.push(item)
                }
            });
        }
        return result;
    }
}

