












import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Loading extends Vue {

  @Prop({ default: false })
  private loading!: boolean;

}
