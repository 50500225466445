






























































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import { PGCCreateSharingInput, File, FileGroup, PGCService } from "@/API";
import { FileSearchService, FileData } from "@/services/files/FileSearch";
import Confirm from "@/components/Confirm.vue";
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";
import moment from "moment";
import ShareAddDialog from "@/components/share/ShareAddDialog.vue";
import ShareSearchExUser from "@/components/share/ShareSearchExUser.vue";
import * as AuthUtils from "@/utils/AuthUtils";

@Component({
  components: {
    Confirm,
    Loading,
    Dialog,
    ShareAddDialog,
    ShareSearchExUser
  },
})
export default class ShareCreate extends Vue {
  private valid = false;
  private isNotFile = false;
  private tableLoading = false;

  private sharingFiles = new Array<FileData>();
  private selectedSharingFiles = new Array<FileData>();
  private headers = [
    { text: "ファイル名", value: "fileName" },
    { text: "タイトル", value: "title" },
  ];

  private users = new Array<object>();
  private sharingUsers = new Array<string>();
  private sharingUsersRules = [
    (v) => {
      if (v && v.length <= 0) {
        return "共有するユーザーは必須項目です";
      }
      return true;
    },
    (v) => {
      if (v && v.length > 100) {
        return "共有するユーザー数は100人以下です";
      }
      return true;
    },
  ];
  private limitDateMenu = false;
  private limitDate = "";
  private limitDateRules = [(v) => !!v || "共有期限（年月日）は必須項目です"];
  private limitTimeMenu = false;
  private limitTime = "";
  private limitTimeRules = [(v) => !!v || "共有期限（時間）は必須項目です"];
  private shareTitle = "";
  private shareTitleRules = [
    (v) => !!v || "共有タイトルは必須項目です",
    (v) => (v && v.length <= 64) || "共有タイトルは64文字以下です",
  ];
  private shareComment = "";
  private shareCommentRules = [
    (v) => {
      if (v && v.length > 1024) {
        return "共有コメントは1024文字以下です";
      }
      return true;
    },
  ];

  private attributes;

  private confirmDialog = false;
  private confirmMode = "";
  private confirmTitle = "";
  private confirmMessage = "";

  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private loading = false;

  private shareAddDialog = false;

  private fileSearchService = new FileSearchService();

  private isSharingCountLimit = false;
  private sharingLimitCount = FileSearchService.SHARE_LIMIT;

  @Prop({ default: () => new Array<File>() })
  private files;
  @Prop({ default: () => new Array<FileGroup>() })
  private groups;

  @Watch('sharingFiles')
  private onChangeSharingFiles(){
    if(this.isNotFile && this.sharingFiles.length > 0){
      this.isNotFile = false;
    }

    if(this.isSharingCountLimit && this.sharingFiles.length <= this.sharingLimitCount){
      this.isSharingCountLimit = false;
    }
  }

  private async created() {
    try
    {
      this.tableLoading = true;
      if (this.$store.getters.user && this.$store.getters.user.attributes) {
        this.attributes = this.$store.getters.user.attributes;

        this.sharingFiles = new Array<FileData>();
        this.selectedSharingFiles = new Array<FileData>();

        const fileIds = new Array<string>();
        const groupIds = new Array<string>();

        for (const file of this.files) {
          if (file && file.id) {
            fileIds.push(file.id);
          }
        }

        for (const group of this.groups) {
          if (group && group.id) {
            groupIds.push(group.id);
          }
        }

        const result = fileIds.length > 0 || groupIds.length > 0 ? await this.fileSearchService.searchShareCandidateFiles(fileIds, groupIds) : new Array<FileData>();

        for (const file of result) {
          this.sharingFiles.push(file);
        }

        this.users = await AuthUtils.listUsers(PGCService.SHARING, "");
      }
    }
    catch(error)
    {
      console.error(error);
    }
    finally
    {
      this.tableLoading = false;
    }
  }

  private onClickAdd() {
    this.shareAddDialog = true;
  }

  private onChangeShareAddDialogSelected(files: FileData[]) {
    const filteredFiles = files.filter((file) => {
      if (file.customItems) {
        const customItems = JSON.parse(file.customItems);
        if (
          customItems &&
          (customItems.share === 1 || customItems.share === 2) &&
          !this.sharingFiles.find((sharingFile) => sharingFile.id === file.id)
        ) {
          return true;
        }
      }
    });

    for (const file of filteredFiles) {
      if (file) {
        this.sharingFiles.push(file);
      }
    }
    this.shareAddDialog = false;
  }

  private onClickDelete() {
    if (this.selectedSharingFiles.length > 0) {
      this.confirmMode = "warning";
      this.confirmTitle = "削除確認";
      this.confirmMessage = "選択したファイルを削除してもよろしいでしょうか？";
      this.confirmDialog = true;
    }
  }

  private confirmed(value) {
    this.confirmDialog = false;
    if(value){
      this.sharingFiles = this.sharingFiles.filter(
        (x) => this.selectedSharingFiles.indexOf(x) === -1
      );
      this.selectedSharingFiles.splice(0);
    }
  }

  private async createShare() {
    if(this.tableLoading)
    {
      return;
    }

    if(this.sharingFiles.length <= 0){
      this.isNotFile = true;
      return;
    }

    if(this.sharingFiles.length > this.sharingLimitCount){
      this.isSharingCountLimit = true;
      return;
    }

    if((this.$refs.shareCreateForm as Vue & {
      validate: () => boolean;
    }).validate()){
      this.loading = true;

      const dateTimeUtc = moment(
        `${this.limitDate} ${this.limitTime}`,
        "YYYY-MM-DD HH:mm"
      ).utc();
      const limitDateUtc = dateTimeUtc.format("YYYY-MM-DD");
      const limitTimeUtc = dateTimeUtc.format("HH:mm:ssZ");

      const sharingFileIds = new Array<string>();
      for (const file of this.sharingFiles) {
        if (file.id) {
          sharingFileIds.push(file.id);
        }
      }

      const input: PGCCreateSharingInput = {
        title: this.shareTitle,
        description: this.shareComment,
        limitDate: limitDateUtc,
        limitTime: limitTimeUtc,
        sharingUsers: this.sharingUsers,
        sharingFiles: sharingFileIds,
        createdUserId: this.attributes.sub,
        ownedDepartment: this.attributes["custom:department"],
        ownedAccountId: this.attributes["custom:account_id"],
        status: 10,
        deleted: 0,
      };

      try {
        await API.graphql(
          graphqlOperation(mutations.createSharingWithHistory, { input: input })
        );

        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "info";
        this.resultDialogTitle = "作成成功";
        this.resultDialogMessage = `共有の作成に成功しました。\n元の画面に戻ります。`;
      } catch {
        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "作成失敗";
        this.resultDialogMessage = `共有の作成に失敗しました。\n元の画面に戻ります。`;
      }
    }
  }

  //外部ユーザーの検索
  private addExUser(exUserStr: string, exUserId: string){
    this.users.push({key: exUserStr, val: exUserId})
    this.users = Array.from(new Set(this.users))
    this.sharingUsers.push(exUserId);
    this.sharingUsers = Array.from(new Set(this.sharingUsers))
  }

  private onChangeDialogClosed(value) {
    if (value) {
      this.resultDialog = false;
      this.back();
    }
  }

  private back() {
    this.$router.go(-1);
  }
}
