




























































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { SearchCondition } from "@/services/files/FileSearch";
import * as AuthUtils from "@/utils/AuthUtils";
import { PGCService } from "@/API";

@Component
export default class FileGroupSearchForm extends Vue {
  private title = "";
  private dateFrom = ""
  private dateFromMenu = false;
  private dateTo = "";
  private dateToMenu = false;
  private registrant = "";
  private users = new Array<object>();
  private innerCondition: SearchCondition = {};

  @Prop({default:()=>({})})
  private condition;

  @Watch("condition")
  private onChangeCondition(){
    this.getCondition();
  }

  @Watch("title")
  @Watch("dateFrom")
  @Watch("dateTo")
  @Watch("registrant")
  private onChangeSearchWords(){
    this.setCondition();
  }

  private async created(){
    if(this.condition){
      await this.getCondition();
      this.innerCondition = this.condition;
    }else{
      this.innerCondition = {}
    }
  }

  private clear(){
    this.title = "";
    this.dateFrom = "";
    this.dateTo = "";
    this.registrant = "";
    this.$emit("file-group-search-clear", true);
    this.setCondition();
  }

  private searchEnter(event){
    if(event.keyCode !== 13) return;
    this.search();
  }

  private search(){
    this.$emit("file-group-search", this.innerCondition);
  }

  private async getCondition(){
    this.title = this.condition.groupTitle || "";
    this.dateFrom = this.condition.groupRegistrationDateFrom || "";
    this.dateTo = this.condition.groupRegistrationDateTo || "";
    this.registrant = this.condition.groupRegistrant || "";
    this.users = await AuthUtils.listUsers(PGCService.FILING, "未選択");
  }

  private setCondition(){
    this.innerCondition.groupTitle = this.title;
    this.innerCondition.groupRegistrationDateFrom = this.dateFrom;
    this.innerCondition.groupRegistrationDateTo = this.dateTo;
    this.innerCondition.groupRegistrant = this.registrant;
    this.$emit("file-group-search-condition", this.innerCondition);
  }
}
