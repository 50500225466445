import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import { ExportFilesCSVInput, ExportFilesCSVQuery, FileGroup, UpdateFileGroupInput, UpdateFileInput } from "@/API";
import { SearchCondition, FileSearchService, FileData } from "@/services/files/FileSearch";
import { GraphQLResult } from "@aws-amplify/api";
import { GetFileGroupQuery } from "@/API";

export class FileEditService {
    private readonly RETRY_COUNT = 3;
    private fileSearchService = new FileSearchService();

    async changeCoverFile(groupId: string, coverFileId?: string, retryCount = 0){
        try{
            const input: UpdateFileGroupInput = {
                id: groupId,
                coverFileId: coverFileId ? coverFileId : null,
            };
            await API.graphql(graphqlOperation(mutations.updateFileGroup, {input: input}));
        }catch(error){
            retryCount = retryCount + 1;
            if(retryCount > this.RETRY_COUNT)
            {
                throw error;
            }
            await this.changeCoverFile(groupId, coverFileId, retryCount);
        }
    }

    async moveFiles(destGroupId: string, moveFiles: FileData[]){
        for(const file of moveFiles){
            if(file){
                this.moveFile(destGroupId, file);
            }
        }
    }

    async moveFile(destGroupId: string, moveFile: FileData, retryCount = 0){
        try{
            // 表紙ファイルの場合は、表紙ファイルから外す
            if(moveFile.group && moveFile.group.id && moveFile.group.coverFileId === moveFile.id){
                const { data } = await this.getFileGroup(moveFile.group.id);
                if(data && data.getFileGroup && data.getFileGroup.coverFileId === moveFile.group.coverFileId){
                    await this.changeCoverFile(moveFile.group.id);
                }
            }
            if(moveFile.id){
                const input: UpdateFileInput = {
                    id: moveFile.id,
                    groupId: destGroupId,
                };
                await API.graphql(graphqlOperation(mutations.updateFile, {input: input}));
            }
        }catch(error){
            retryCount = retryCount + 1;
            if(retryCount > this.RETRY_COUNT)
            {
                throw error;
            }
            await this.moveFile(destGroupId, moveFile, retryCount);
        }
    }

    async moveFilesFromGroups(destGroupId: string, sourceGroups: FileGroup[], moveFiles: FileData[]){
        await this.moveFiles(destGroupId, moveFiles);
        const hasCoverFileGroups = sourceGroups.filter(group => moveFiles.find(file => group && group.coverFileId && file && file.id === file.id));
        for(const group of hasCoverFileGroups){
            if(group && group.id && group.coverFileId){
               const { data } = await this.getFileGroup(group.id);
               if(data && data.getFileGroup && data.getFileGroup.coverFileId === group.coverFileId){
                   await this.changeCoverFile(group.id);
               }
            }
        }
    }

    async getFileGroup(groupId: string, retryCount = 0): Promise<GraphQLResult<GetFileGroupQuery>>{
        try{
            return (await API.graphql(graphqlOperation(queries.getFileGroup, {id: groupId}))) as GraphQLResult<GetFileGroupQuery>;
        }catch(error){
            retryCount = retryCount + 1;
            if(retryCount > this.RETRY_COUNT){
                throw error;
            }
            return await this.getFileGroup(groupId, retryCount);
        }
    }

    async changeCoordinatedCoverage(fileId: string, coverageId: string, retryCount = 0){
        try{
            const input: UpdateFileInput = {
                id: fileId,
                coverageId: coverageId,
            };
            await API.graphql(graphqlOperation(mutations.updateFile, {input: input}));
        }catch(error){
            retryCount = retryCount + 1;
            if(retryCount > this.RETRY_COUNT){
                throw error;
            }
            await this.changeCoordinatedCoverage(fileId, coverageId, retryCount);
        }
    }

    async changeCoordinatedCoverageFromGroup(groupId: string, coverageId: string){
        const condition: SearchCondition = {
            groupIds: [groupId],
        }
        const files = await this.fileSearchService.searchFileDataFullList(condition);
        for(const file of files){
            if(file && file.id){
                await this.changeCoordinatedCoverage(file.id, coverageId);
            }
        }
    }

    async exportFilesCSVFromFileGroups(accountId: string, groupIds: string[]){
        const input: ExportFilesCSVInput = {
            accountId: accountId,
            fileGroupIds: groupIds
        };
        const {data} = (await API.graphql(graphqlOperation(queries.exportFilesCSV, {input: input}))) as GraphQLResult<ExportFilesCSVQuery>;

        if(data && data.exportFilesCSV && data.exportFilesCSV.signedUrl)
        {
            return data.exportFilesCSV.signedUrl;
        }
        throw new Error('SignedURL Not Found.');
    }

    async exportFilesCSVFromFiles(accountId: string, fileIds: string[]){
        const input: ExportFilesCSVInput = {
            accountId: accountId,
            fileIds: fileIds
        };
        const {data} = (await API.graphql(graphqlOperation(queries.exportFilesCSV, {input: input}))) as GraphQLResult<ExportFilesCSVQuery>;

        if(data && data.exportFilesCSV && data.exportFilesCSV.signedUrl)
        {
            return data.exportFilesCSV.signedUrl;
        }
        throw new Error('SignedURL Not Found.');
    }
}