import { MonthItem, YearItem } from "@/services/files/FileUpload";

export class FileExtension {
  static toString(extensions: string[]){
    return extensions.join(",");
  }

  static analyzeExtension(extensions: string[], targetExtension: string){
    const found = extensions.find(extension => extension === `.${targetExtension.toLowerCase()}`);
    return found ? true : false;
  }
}

export class FileMimeType {
  static toString(mimeTypes: string[]){
    return mimeTypes.join(",");
  }

  static analyzeMimeType(mimeTypes: string[], targetContentType: string){
    const found = mimeTypes.find(mimeType => mimeType === targetContentType);
    return found ? true : false;
  }
}

export class ImageExtension extends FileExtension {
  static readonly jpeg: string[] = [".jpg",".jpeg",".jfif",".pjp",".pjpeg"];
  static readonly png: string[] = [".png"];
  static readonly gif: string[] = [".gif"];
  static readonly bmp: string[] = [".bmp"];
  static readonly svg: string[] = [".svg"];
  static readonly tiff: string[] = [".tif",".tiff",".nsk"];
  static readonly webp: string[] = [".webp"];
  static readonly avif: string[] = [".avif"];
  static readonly heif: string[] = [".hif",".heif",".heic"];
  static readonly eps: string[] = [".eps"];
  // N,C,S,F,O,P,P,A
  static readonly raw: string[] = [".nef",".nrw",".crw",".cr2",".cr3",".arw",".raf",".orf",".raw",".rw2",".pef",".dng"];

  static getAllImageExtensions(){
    return this.jpeg.concat(
      this.png,
      this.gif,
      this.bmp,
      this.svg,
      this.tiff,
      this.webp,
      this.avif,
      this.heif,
      this.eps,
      this.raw
    );
  }

  static getAllImageExtensionsToString(){
    return this.toString(this.getAllImageExtensions());
  }

  static analyzeImageExtension(extension: string){
    return this.analyzeExtension(this.getAllImageExtensions(), extension);
  }
}

export class ImageMimeType extends FileMimeType {
  static readonly jpeg: string[] = ["image/jpeg", "application/pjpeg"];
  static readonly png: string[] = ["image/png"];
  static readonly gif: string[] = ["image/gif"];
  static readonly bmp: string[] = ["image/bmp"];
  static readonly svg: string[] = ["image/svg+xml"];
  static readonly tiff: string[] = ["image/tiff"];
  static readonly webp: string[] = ["image/webp"];
  static readonly avif: string[] = ["image/avif"];
  static readonly heif: string[] = ["image/heif","image/heic","image/heif-sequence","image/heic-sequence"];
  static readonly eps: string[] = ["application/postscript","image/x-eps"];
  // N,C,S,F,O,P,P,L,A
  static readonly raw: string[] = [
    "image/x-nikon-nef","image/NEF","image/x-nikon-nrw","image/NRW",
    "image/x-canon-crw","image/CRW","image/x-canon-cr2","image/CR2","image/x-canon-cr3","image/CR3",
    "image/x-sony-arw","image/ARW",
    "image/x-fuji-raf","image/RAF",
    "image/x-olympus-orf","image/ORF",
    "image/x-panasonic-raw","image/RAW","image/x-panasonic-rw2","image/RW2",
    "image/x-pentax-pef","image/PEF",
    "image/x-adobe-dng","image/DNG"
  ];

  static getAllImageMimeTypes(){
    return this.jpeg.concat(
      this.png,
      this.gif,
      this.bmp,
      this.svg,
      this.tiff,
      this.webp,
      this.avif,
      this.heif,
      this.eps,
      this.raw
    );
  }

  static getAllImageMimeTypeToString(){
    return this.toString(this.getAllImageMimeTypes());
  }

  static analyzeImageMimeType(contentType: string){
    return this.analyzeMimeType(this.getAllImageMimeTypes(), contentType);
  }
}

export class VideoExtension extends FileExtension {
  static readonly mp4: string[] = [".mp4",".m4v"];
  static readonly mov: string[] = [".mov"];
  static readonly webm: string[] = [".webm"];
  static readonly m2ts: string[] = [".mts",".m2ts"];
  static readonly avi: string[] = [".avi"];
  static readonly mpeg: string[] = [".mpg",".mpeg"];
  static readonly mxf: string[] = [".mxf"];
  static readonly asf: string[] = [".asf",".wmv", "wma"];
  static readonly flv: string[] = [".flv",".f4v"];

  static getAllVideoExtensions(){
    return this.mp4.concat(
      this.mov,
      this.webm,
      this.m2ts,
      this.avi,
      this.mpeg,
      this.mxf,
      this.asf,
      this.flv
    );
  }

  static getAllVideoExtensionsToString(){
    return this.toString(this.getAllVideoExtensions());
  }

  static analyzeVideoExtension(extension: string){
    return this.analyzeExtension(this.getAllVideoExtensions(), extension);
  }
}

export class VideoMimeType extends FileMimeType {
  static readonly mp4: string[] = ["video/mp4","video/x-m4v"];
  static readonly mov: string[] = ["video/quicktime"];
  static readonly webm: string[] = ["video/webm"];
  static readonly m2ts: string[] = ["video/vnd.dlna.mpeg-tts"];
  static readonly avi: string[] = ["video/avi","video/msvideo","video/x-msvideo"];
  static readonly mpeg: string[] = ["video/mpeg"];
  static readonly mxf: string[] = ["application/mxf"];
  static readonly asf: string[] = ["video/x-ms-asf", "video/x-ms-wmv", "video/x-ms-wma"];
  static readonly flv: string[] = ["video/x-flv"];

  static getAllVideoMimeTypes(){
    return this.mp4.concat(
      this.mov,
      this.webm,
      this.m2ts,
      this.avi,
      this.mpeg,
      this.mxf,
      this.asf,
      this.flv
    );
  }

  static getAllVideoMimeTypeToString(){
    return this.toString(this.getAllVideoMimeTypes());
  }

  static analyzeVideoMimeType(contentType: string){
    return this.analyzeMimeType(this.getAllVideoMimeTypes(), contentType);
  }
}

export class AudioExtension extends FileExtension {
  static readonly mp3: string[] = [".mp3"];
  static readonly mp4: string[] = [".mp4"];
  static readonly wav: string[] = [".wav"];
  static readonly wma: string[] = [".wma"];
  static readonly aac: string[] = [".m4a"];
  static readonly flac: string[] = [".flac"];

  static getAllAudioExtensions(){
    return this.mp3.concat(
      this.mp4,
      this.wav,
      this.wma,
      this.aac,
      this.flac
    );
  }

  static getAllAudioExtensionsToString(){
    return this.toString(this.getAllAudioExtensions());
  }

  static analyzeAudioExtension(extension: string){
    return this.analyzeExtension(this.getAllAudioExtensions(), extension);
  }
}

export class AudioMimeType extends FileMimeType {
  static readonly mp3: string[] = ["audio/mpeg"];
  static readonly mp4: string[] = ["audio/mp4"];
  static readonly wav: string[] = ["audio/wav","audio/x-wav"];
  static readonly wma: string[] = ["audio/x-ms-wma"];
  static readonly acc: string[] = ["audio/x-m4a"];
  static readonly flac: string[] = ["audio/flac"];

  static getAllAudioMimeTypes(){
    return this.mp3.concat(
      this.mp4,
      this.wav,
      this.wma,
      this.acc,
      this.flac
    );
  }

  static getAllAudioMimeTypeToString(){
    return this.toString(this.getAllAudioMimeTypes());
  }

  static analyzeAudioMimeType(contentType: string){
    return this.analyzeMimeType(this.getAllAudioMimeTypes(), contentType);
  }
}

export class DocumentExtension extends FileExtension {
  static readonly text: string[] = [".txt"];
  static readonly pdf: string[] = [".pdf"];
  static readonly docx: string[] = [".doc",".docx"];
  static readonly xlsx: string[] = [".xls",".xlsx"];
  static readonly pptx: string[] = [".ppt",".pptx"];

  static getAllDocumentExtensions(){
    return this.text.concat(
      this.pdf,
      this.docx,
      this.xlsx,
      this.pptx
    );
  }

  static getAllDocumentExtensionsToString(){
    return this.toString(this.getAllDocumentExtensions());
  }

  static analyzeDocumentExtension(extension: string){
    return this.analyzeExtension(this.getAllDocumentExtensions(), extension);
  }
}

export class DocumentMimeType extends FileMimeType {
  static readonly text: string[] = ["text/plain"];
  static readonly pdf: string[] = ["application/pdf"];
  static readonly docx: string[] = ["application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
  static readonly xlsx: string[] = ["application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  static readonly pptx: string[] = ["application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.presentationml.presentation"];

  static getAllDocumentMimeTypes(){
    return this.text.concat(
      this.pdf,
      this.docx,
      this.xlsx,
      this.pptx
    );
  }

  static getAllDocumentMimeTypeToString(){
    return this.toString(this.getAllDocumentMimeTypes());
  }

  static analyzeDocumentMimeType(contentType: string){
    return this.analyzeMimeType(this.getAllDocumentMimeTypes(), contentType);
  }
}

export class AdobeExtension extends FileExtension {
  static readonly ai: string[] = [".ai"];
  static readonly psd: string[] = [".psd"];

  static getAllAdobeExtensions(){
    return this.ai.concat(
      this.psd
    );
  }

  static getAllAdobeExtensionsToString(){
    return this.toString(this.getAllAdobeExtensions());
  }

  static analyzeAdobeExtension(extension: string){
    return this.analyzeExtension(this.getAllAdobeExtensions(), extension);
  }
}

export class AdobeMimeType extends FileMimeType {
  static readonly ai: string[] = ["application/postscript"];
  static readonly psd: string[] = ["image/vnd.adobe.photoshop","application/x-photoshop","application/photoshop","application/psd","image/psd","image/photoshop","image/x-photoshop"];

  static getAllAdobeMimeTypes(){
    return this.ai.concat(
      this.psd
    );
  }

  static getAllAdobeMimeTypeToString(){
    return this.toString(this.getAllAdobeMimeTypes());
  }

  static analyzeAdobeMimeType(contentType: string){
    return this.analyzeMimeType(this.getAllAdobeMimeTypes(), contentType);
  }
}

export class AllExtension {
  static getAcceptExtension(){
    return `${ImageExtension.getAllImageExtensionsToString()},
    ${VideoExtension.getAllVideoExtensionsToString()},
    ${AudioExtension.getAllAudioExtensionsToString()},
    ${DocumentExtension.getAllDocumentExtensionsToString()},
    ${AdobeExtension.getAllAdobeExtensionsToString()}`;
  }

  static analyzeExtension(extension: string){
    let flag = false;
    if(ImageExtension.analyzeImageExtension(extension)){
      flag = true;
    }
    if(VideoExtension.analyzeVideoExtension(extension)){
      flag = true;
    }
    if(AudioExtension.analyzeAudioExtension(extension)){
      flag = true;
    }
    if(DocumentExtension.analyzeDocumentExtension(extension)){
      flag = true;
    }
    if(AdobeExtension.analyzeAdobeExtension(extension)){
      flag = true;
    }
    return flag;
  }
}

export class AllMimeType {
  static getAcceptMimeType(){
    return `${ImageMimeType.getAllImageMimeTypeToString()},
    ${VideoMimeType.getAllVideoMimeTypeToString()},
    ${AudioMimeType.getAllAudioMimeTypeToString()},
    ${DocumentMimeType.getAllDocumentMimeTypeToString()},
    ${AdobeMimeType.getAllAdobeMimeTypeToString()}`;
  }

  static analyzeMimeType(mimeType: string){
    let flag = false;
    if(ImageMimeType.analyzeImageMimeType(mimeType)){
      flag = true;
    }
    if(VideoMimeType.analyzeVideoMimeType(mimeType)){
      flag = true;
    }
    if(AudioMimeType.analyzeAudioMimeType(mimeType)){
      flag = true;
    }
    if(DocumentMimeType.analyzeDocumentMimeType(mimeType)){
      flag = true;
    }
    if(AdobeMimeType.analyzeAdobeMimeType(mimeType)){
      flag = true;
    }
    return flag;
  }
}

export const FILE_POST_PROCESS = {
  Edit: 'FileEdit',
  Send: 'FileSend',
  Share: 'FileShare',
  Download: 'FileDownload',
  Move: 'FileMove',
  Delete: 'FileDelete',
  Sale: 'FileSale',
} as const;

type FILE_POST_PROCESS = typeof FILE_POST_PROCESS[keyof typeof FILE_POST_PROCESS];

export const FILE_GROUP_POST_PROCESS = {
  Edit: 'FileGroupEdit',
  Send: 'FileGroupSend',
  Share: 'FileGroupShare',
  Download: 'FileGroupDownload',
  Move: 'FileGroupMove',
  Delete: 'FileGroupDelete',
  Sale: 'FileGroupSale'
} as const;

type FILE_GROUP_POST_PROCESS = typeof FILE_GROUP_POST_PROCESS[keyof typeof FILE_GROUP_POST_PROCESS];

export const timer = (ms: number) => {
  return new Promise( ( resolve ) => setTimeout(resolve, ms));
};

export const getFileType = (extension: string, contentType: string) => {
  const innerExtension = `.${extension.toLowerCase()}`;
  let fileType = "unknown";
  const contentTypes = contentType.split("/");
  if(contentTypes.length <= 0){
    return fileType;
  }
  const prefix = contentTypes[0];

  switch(prefix){
    case "image": {
      fileType = "image";
      break;
    }
    case "video": {
      fileType = "video";
      break;
    }
    case "audio": {
      fileType = "audio";
      break;
    }
  }
  if(VideoMimeType.mxf.find(x => x === contentType) && VideoExtension.mxf.find(x => x === innerExtension)){
    fileType = "video";
  }
  if(ImageMimeType.heif.find(x => x === contentType) && ImageExtension.heif.find(x => x === innerExtension)){
    fileType = "heif";
  }
  if(ImageMimeType.raw.find(x => x === contentType) && ImageExtension.raw.find(x => x === innerExtension)){
    fileType = "raw";
  }
  if(ImageMimeType.eps.find(x => x === contentType) && ImageExtension.eps.find(x => x === innerExtension)){
    fileType = "eps";
  }
  if(AdobeMimeType.ai.find(x => x === contentType) && AdobeExtension.ai.find(x => x === innerExtension)){
    fileType = "ai";
  }
  if(AdobeMimeType.psd.find(x => x === contentType) && AdobeExtension.psd.find(x => x === innerExtension)){
    fileType = "psd";
  }
  if(DocumentMimeType.text.find(x => x === contentType)){
    fileType = "text";
  }
  if(DocumentMimeType.pdf.find(x => x === contentType)){
    fileType = "pdf";
  }
  if(DocumentMimeType.docx.find(x => x === contentType)){
    fileType = "docx";
  }
  if(DocumentMimeType.xlsx.find(x => x === contentType)){
    fileType = "xlsx";
  }
  if(DocumentMimeType.pptx.find(x => x === contentType)){
    fileType = "pptx";
  }
  return fileType;
};

export const getIcon = (fileType: string) => {
  let icon = "";
  switch(fileType){
    case "audio": {
      icon = "/img/audio.png";
      break;
    }
    case "heif": {
      icon = "/img/heif.png";
      break;
    }
    case "eps": {
      icon = "/img/eps.png";
      break;
    }
    case "raw": {
      icon = "/img/raw.png";
      break;
    }
    case "ai": {
      icon = "/img/ai.png";
      break;
    }
    case "psd": {
      icon = "/img/psd.png";
      break;
    }
    case "text": {
      icon = "/img/text.png";
      break;
    }
    case "pdf": {
      icon = "/img/pdf.png";
      break;
    }
    case "docx": {
      icon = "/img/docx.png";
      break;
    }
    case "xlsx": {
      icon = "/img/xlsx.png";
      break;
    }
    case "pptx": {
      icon = "/img/pptx.png";
      break;
    }
  }

  return icon;
}

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

export const START_YEAR = 1900;
export const createYearItems = (step = 1) => {
  const year = new Date().getFullYear();
  const back = year - START_YEAR + 1;
  const years: YearItem[] = [];
  for(let i = 0; i < back; i = i + step){
    years.push({ text: (year - back + i + 1).toString(), value : year - back + i + 1});
  }
  return years.sort((a,b) => {
    return b.value - a.value;
  });
};

export const createMonthItems = () => {
  const months: MonthItem[] = [];
  for(let i = 1; i <= 12; i++){
    months.push({ text: String(i).padStart(2, '0'), value : i});
  }
  return months;
};