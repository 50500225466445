















import { Component, Vue, Prop } from "vue-property-decorator";
import InfoMenu from "@/components/home/InfoMenu.vue";

@Component({
  components:{
    InfoMenu,
  }
})
export default class InfoItem extends Vue {
  private sheet = false;

  @Prop()
  private index;
  @Prop()
  private coverageInfo;

  private onChangeInfoMenuSheet(value){
    this.sheet = value;
  }

  private onChangeInfoMenuResult(value){
    this.$emit("info-item-result", value);
  }
}
