import Vue from 'vue'
import Vuex from 'vuex'
import { SearchCondition } from '@/services/files/FileSearch'

Vue.use(Vuex)

export default new Vuex.Store({
  // TODO:プロダクションの場合は、falseにすること
  devtools : true,
  state: {
    user: null,
    accountData: null,
    sideDrawer: false,
    showCoverageForm: false,
    showCoverageEditForm: false,
    selectCoverageId: "",
    selectCoverageDeskId: "",
    selectCoverageDate: "",
    selectCoverageDateTo: "",
    selectCoverageInfoId: "",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customAmplifyTorken: {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchCondition: {},
    searchMode: "",
    searchResultPage: 1,
    groupDetailPage: 1,
    fileDetailPosition: 0,
    shareContentPage: 1,
    selectManagementUser: "",
    redirectPath: "",
    from: "",
    to: "",
    userList: []
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setAccountData(state, accountData) {
      state.accountData = accountData
    },
    changeSideDrawer(state) {
      state.sideDrawer = !state.sideDrawer
    },
    showCoverageForm(state) {
      state.showCoverageForm = !state.showCoverageForm
    },
    showCoverageEditForm(state) {
      state.showCoverageEditForm = !state.showCoverageEditForm
    },
    selectCoverage(state, selectCoverageId) {
      state.selectCoverageId = selectCoverageId
    },
    selectCoverageDesk(state, selectCoverageDeskId) {
      state.selectCoverageDeskId = selectCoverageDeskId
    },
    selectCoverageInfo(state, selectCoverageInfoId) {
      state.selectCoverageInfoId = selectCoverageInfoId
    },
    selectCoverageDate(state, selectCoverageDate) {
      state.selectCoverageDate = selectCoverageDate
    },
    selectCoverageDateTo(state, selectCoverageDateTo) {
      state.selectCoverageDateTo = selectCoverageDateTo
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCustomAmplifyTorken(state, data: { key: any; value: any }) {
      state.customAmplifyTorken[data.key] = data.value;
    },
    deleteCustomAmplifyTorkenKey(state, key){
      return delete state.customAmplifyTorken[key];
    },
    clearCustomAmplifyTorkenKey(state){
      return state.customAmplifyTorken = {};
    },
    setSearchCondition(state, condition: SearchCondition){
      state.searchCondition = condition;
    },
    setSearchMode(state, searchMode: string){
      state.searchMode = searchMode;
    },
    setSearchResultPage(state, page: number){
      state.searchResultPage = page;
    },
    setGroupDetailPage(state, page: number){
      state.groupDetailPage = page;
    },
    setFileDetailPosition(state, position: number){
      state.fileDetailPosition = position;
    },
    setShareContentPage(state, page: number){
      state.shareContentPage = page;
    },
    selectManagementUser(state, userId){
      state.selectManagementUser = userId;
    },
    setRedirectPath(state, redirectPath){
      state.redirectPath = redirectPath;
    },
    setFrom(state, from){
      state.from = from;
    },
    setTo(state, to){
      state.to = to;
    },
    setUserList(state, userList){
      state.userList = userList;
    }
  },
  getters: {
    user(state){
      return state.user;
    },
    accountData(state){
      return state.accountData;
    },
    selectCoverageDate(state){
      return state.selectCoverageDate;
    },
    selectCoverageDateTo(state){
      return state.selectCoverageDateTo;
    },
    searchCondition(state){
      return state.searchCondition;
    },
    searchMode(state){
      return state.searchMode;
    },
    searchResultPage(state){
      return state.searchResultPage;
    },
    groupDetailPage(state){
      return state.groupDetailPage;
    },
    fileDetailPosition(state){
      return state.fileDetailPosition;
    },
    shareContentPage(state){
      return state.shareContentPage;
    },
    getRedirectPath(state){
      return state.redirectPath;
    },
    from(state){
      return state.from;
    },
    to(state){
      return state.to;
    },
    userList(state){
      return state.userList;
    }
  },
  actions: {
  },
  modules: {
  }
})
