





























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { API, graphqlOperation } from "aws-amplify";
import { UpdateCoverageInput } from "@/API";
import * as mutations from "@/graphql/mutations";

@Component
export default class CoverageStatusDialog extends Vue {
  private innerDialog = false;
  private statusItems = [
    { value: 0, text: '未対応' },
    { value: 10, text: '取材中' },
    { value: 90, text: '完了' },
  ];
  private selectedStatus = { value: 0, text: '未対応' };

  @Prop({ default: false })
  private dialog!: boolean;
  @Prop()
  private coverage;

  @Watch('dialog')
  onChangeDialog(newValue){
    this.innerDialog = newValue;
  }
  
  @Watch('coverage')
  onChangeCoverage(newValue){
    if(newValue.status === 10){
      this.selectedStatus = { value: 10, text: '取材中' };
    }else if(newValue.status === 90){
      this.selectedStatus = { value: 90, text: '完了' };
    }else{
      this.selectedStatus = { value: 0, text: '未対応' };  
    }
  }

  onClickCancel(){
    this.closed();
  }

  async onClickOK(){
    const input: UpdateCoverageInput = {
      id: this.coverage.id,
      status: this.selectedStatus.value,
    };

    await API.graphql(graphqlOperation(mutations.updateCoverage, {input: input}));
    this.closed();
  }

  closed(){
    this.innerDialog = false;
    this.$emit('status-dialog-closed', true);
  }
}
