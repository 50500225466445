
import { Component, Vue } from "vue-property-decorator";
import { Storage } from "aws-amplify";
import moment from "moment";
import { uuid } from "vue-uuid";
import Papa from "papaparse";


@Component
export default class FileCSVImportBaseForm extends Vue {
  name = "file-csv-import-base-form";

  protected static MAX_IMPORT_NUM = 10000

  protected valid = false;
  protected csvFile: File | null = null;
  protected errorMessages: string[] = [];

  protected csvFileRules = [
    v => !!v || "CSVファイルは必須項目です",
    v => (v && v.size > 0) || "CSVファイルがOバイトです",
  ];

  protected setDefault(){
    this.csvFile = null;
    this.errorMessages = [];
    this.valid = false;
  }

  protected async importCSV(){
    const attributes = this.$store.getters.user.attributes;
    if(attributes === null || attributes === undefined || this.csvFile === null || this.csvFile === undefined)
    {
      return;
    }
    const accountId = attributes["custom:account_id"];
    if(accountId === null || accountId === undefined){
      return;
    }
    
    const key = `csv/import/files/${accountId}/${moment(new Date()).format("YYYYMMDD")}/${uuid.v4()}.csv`;
    await Storage.put(
          key,
          this.csvFile,
          {
            contentType: this.csvFile.type,
            contentDisposition: `attachment; filename="${encodeURI(this.csvFile.name)}"`,
          }
        );
  }

  protected async checkExtension(){
    if(!this.csvFile || (this.csvFile && (this.csvFile.size <= 0 || !this.csvFile.name))){
      return false;
    }

    const extension = this.csvFile.name.toLowerCase().split('.').pop();

    if(extension !== "csv" || this.csvFile.type !== "text/csv"){
      this.valid = false;
      this.errorMessages.push("CSVファイルではありません");
      return false;

    }

    return true;
  }

  protected async checkBOM(){
    if(!this.csvFile || (this.csvFile && this.csvFile.size <= 0)){
      return false;
    }

    const buffer = await this.csvFile.arrayBuffer();
    const uint8Array = new Uint8Array(buffer);
    const actual = uint8Array.slice(0, 3);
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

    const compareArrays = (a, b): boolean => a.length === b.length && a.every((element, index) => element === b[index]);
    if(!compareArrays(actual, bom)){
      this.valid = false;
      this.errorMessages.push("BOM付きUTF-8のCSVファイルではありません");
      return false;
    }

    return true;
  }

  protected async checkMaxColumn(){
    if(!this.csvFile || (this.csvFile && this.csvFile.size <= 0)){
      return false;
    }

    const buffer = await this.csvFile.arrayBuffer();
    const uint8Array = new Uint8Array(buffer);
    const textDecoder = new TextDecoder("utf-8");
    const csvStr = textDecoder.decode(uint8Array);

    const config = { header: true, skipEmptyLines: true };
    const { data } = Papa.parse(csvStr, config);

    if(data && data.length > FileCSVImportBaseForm.MAX_IMPORT_NUM){
      this.valid = false;
      this.errorMessages.push(`1ファイルにつき最大${FileCSVImportBaseForm.MAX_IMPORT_NUM}件までです`);
      return false;
    }

    return true;
  }
}
