












































import { Component, Vue } from 'vue-property-decorator';
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";
import FileCSVImportBaseForm from '@/components/files/edit/FileCSVImportBaseForm.vue';

@Component({
  components:{
    Loading,
    Dialog,
  }
})
export default class FileCSVImport extends FileCSVImportBaseForm {
  name = "file-csv-import";

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = '';
  private resultDialogTitle = '';
  private resultDialogMessage = '';
  private fileInputLoading = false;

  private created() {
    this.setDefault();
  }

  private back(){
    this.$router.push({name: 'MyPage'});
  }

  private onChangeResultDialogClosed(){
    this.resultDialog = false;
    this.setDefault();
    (this.$refs.fileCSVImportForm as Vue & { 
      resetValidation: () => boolean; 
    }).resetValidation();
  }

  private async onChangeFileInput(){
    this.fileInputLoading = true;
    this.valid = true;
    this.errorMessages = [];
    await this.checkExtension();
    await this.checkBOM();
    await this.checkMaxColumn();
    this.fileInputLoading = false;
  }

  private async onClickImport(){
    if(!(this.$refs.fileCSVImportForm as Vue & {validate: () => boolean}).validate() || !this.valid){
      return;
    }

    this.loading = true;
    try{

      await this.importCSV();

      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "インポート完了";
      this.resultDialogMessage = "CSVファイルをインポートしました。\n反映されるまでしばらくお待ちください。";
    }catch(error){
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "インポート失敗";
      this.resultDialogMessage = "CSVファイルのインポートに失敗しました。";
    }finally{
      this.loading = false;
    }
  }
}
