




























































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import moment from "moment";
import CoverageCreateForm from "./CoverageCreateForm.vue";
import CoverageEditForm from "./CoverageEditForm.vue";
import CoverageView from "./CoverageView.vue";
import CoverageFileLink from "./CoverageFileLink.vue";
import CoverageStatus from "./CoverageStatus.vue";
import * as CoverageSerch from "@/services/coverage/coverageSerch";
import {
  SearchForm,
  CoverageListData,
} from "@/services/coverage/coverageSerch";
import * as AuthUtils from "@/utils/AuthUtils";
import { PGCService } from "@/API";
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";

@Component({
  components: {
    CoverageCreateForm,
    CoverageEditForm,
    CoverageView,
    CoverageFileLink,
    CoverageStatus,
  },
})
export default class CoverageListBase extends Vue {
  dateFrom = "";
  dateTo = "";
  searchDateFromMenu = false;
  searchDateToMenu = false;
  assignedUserId = null;
  selectUsers = new Array<object>();
  freeWord = "";
  items: Array<CoverageListData> = [];
  createDialog = false;
  viewDialog = false;
  editDialog = false;
  editId = "";
  coverageSerch = new CoverageSerch.CoverageSerch();
  subscription;
  subscriptionUpdate;
  test = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gTimer: any;
  
  private isManage = true;
  private isOperate = true;
  private isFileSearch = false;

  async created() {
    try{
      const user = this.$store.getters.user;
      const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
      this.dateFrom = new Date().toISOString().substr(0, 10);
      this.isManage = isAllow("coverage", "manage") && await isActiveServie("coverage");
      this.isOperate = isAllow("coverage", "operate", role.coverage?.operate) && await isActiveServie("coverage");
      this.isFileSearch = isAllow("filing", "search", role.filing?.search) && await isActiveServie("filing");
      this.$store.getters.selectCoverageDate
        ? (this.dateFrom = this.$store.getters.selectCoverageDate)
        : null;
      this.selectUsers = await AuthUtils.listUsers(PGCService.COVERAGE);
      this.selectUsers.unshift({key: "未選択", val: null })
      await this.search();
    }catch(e){
      console.error(e);
    }finally{
      this.$emit("loadEnd");
    }
  }

  @Watch("dateFrom")
  @Watch("dateTo")
  protected async reSearch() {
    try{
      this.$emit("loadStart");
      this.$store.commit("selectCoverageDate", this.dateFrom ? this.dateFrom : "");
      this.$store.commit("selectCoverageDateTo", this.dateTo ? this.dateTo : "");
      await this.search();
    }catch(e){
      console.error(e);
    }finally{
      this.$emit("loadEnd");
    }
  }

  @Watch("freeWord")
  @Watch("assignedUserId")
  protected wordSearch() {
    if (this.gTimer) {
      clearTimeout(this.gTimer);
    }
    this.gTimer = setTimeout(async () => {
      await this.search();
    }, 1000);
  }

  async search() {
    const user = this.$store.state.user;
    this.$store.state.showCoverageForm = false;
    const dayStart = this.getStartDate();
    const dayEnd = this.getEndDate();
    const searchAssignedUserId = this.assignedUserId === null ? null : this.assignedUserId;
    const searchWord = this.freeWord === null ? "" : this.freeWord;
    const searchForm: SearchForm = {
      accountId: user?.attributes["custom:account_id"],
      dayStart: dayStart,
      dayEnd: dayEnd,
      searchWord: searchWord,
      assignedUserId: searchAssignedUserId,
    };
    this.items = await this.coverageSerch.search(searchForm);
  }

  private getStartDate(){
    if(!this.dateFrom || this.dateFrom.length <= 0){
      return moment("1970-01-01").startOf("day").toISOString();
    }
    return moment(this.dateFrom).startOf("day").toISOString();
  }

  private getEndDate() {
    if((!this.dateFrom || this.dateFrom.length <= 0) && (!this.dateTo || this.dateTo.length <= 0)){
      return moment("2037-12-31").endOf("day").toISOString();
    }
    if(this.dateFrom && (!this.dateTo || this.dateTo.length <= 0)){
      return moment(this.dateFrom).endOf("day").toISOString();
    }
    return moment(this.dateTo).endOf("day").toISOString();
  }

  private dateformat(date: Date) {
    return moment(date).format("YYYY-MM-DD HH:mm");
  }

  private substr(str: string, max: number) {
    return str?.length > max ? str.substr(0, max) + "..." : str;
  }

  private viewItem(item) {
    this.$store.commit("selectCoverage", item.id);
    this.viewDialog = true;
  }

  closeView() {
    this.viewDialog = false;
  }

  private async createFormClosed(value){
    this.createDialog = false;
    if(value){
      await this.formClosed();
    }
  }

  private async editFormClosed(value){
    this.editDialog = false;
    if(value){
      await this.formClosed();
    }
  }

  private async formClosed(){
    if (this.gTimer) {
      clearTimeout(this.gTimer);
    }
    this.gTimer = setTimeout(async () => {
      try{
        this.$emit("loadStart");
        await this.search();
      }catch(e){
        console.error(e);
      }finally{
        this.$emit("loadEnd");
      }
    }, 3000);
  }

  private editItem(item) {
    this.$store.commit("selectCoverage", item.id);
    this.editDialog = true;
  }
}
