














import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { UploadFile } from "@/services/files/FileUpload";
import { AdobeExtension, AllExtension, AllMimeType, ImageExtension, ImageMimeType, VideoExtension } from "@/utils/FileUtils";
import imageCompression from 'browser-image-compression';
import exifr from 'exifr'

@Component
export default class FileDragAndDrop extends Vue {
  private innerFiles: Array<UploadFile> = [];
  private rules = [
    v => !!v || 'アップロードファイルは必須項目です',
  ];
  private accept = `${AllExtension.getAcceptExtension()},${AllMimeType.getAcceptMimeType()}`;
  private innerLoading = false;

  @Prop({ default: () => { new Array<UploadFile>()} })
  private files;

  @Prop({default: false})
  private isCoverFileSelected;

  @Watch('files')
  private onChangeFiles(newValue){
    this.innerFiles = newValue;
  }

  @Watch('innerFiles')
  private onChangeInnerFiles(newValue){
    this.$emit('changed-file-drag-and-drop', newValue);
  }

  @Watch('innerLoading')
  private onChangeInnerLoading(newValue){
    this.$emit('changed-table-loading', newValue);
  }

  private async onDrop(event) {
    try{
      this.innerLoading = true;
      const fileList = event.target.files ? event.target.files : event.dataTransfer.files;
      this.innerFiles = this.innerFiles.filter((f) => f.complete === false);

      for(const file of fileList) {
        let analyzed = false;
        if(file && file.size > 0 && file.type && file.name){
          analyzed = AllMimeType.analyzeMimeType(file.type);
          const fileName = file.name.split(".").pop();
          if(analyzed && fileName){
            analyzed = AllExtension.analyzeExtension(fileName);
          }
        }else if(file && file.size > 0 && file.name){
          const fileName = file.name.split(".").pop();
          if(fileName){
            if(ImageExtension.analyzeExtension(ImageExtension.tiff, fileName) 
            || ImageExtension.analyzeExtension(ImageExtension.heif, fileName)
            || ImageExtension.analyzeExtension(ImageExtension.raw, fileName) 
            || AdobeExtension.analyzeExtension(AdobeExtension.psd, fileName)
            || VideoExtension.analyzeExtension(VideoExtension.mxf, fileName)
            || VideoExtension.analyzeExtension(VideoExtension.flv, fileName)){
              analyzed = true;
            }
          }
        }

        const duplicated = this.innerFiles.find(v => (v.fileObj.name === file.name && v.fileObj.lastModified === file.lastModified && v.fileObj.size === file.size && v.fileObj.type === file.type));
        if(duplicated === undefined && analyzed){
          const acceptImageType = ImageMimeType.jpeg.concat(
            ImageExtension.png,
            ImageExtension.gif,
            ImageExtension.bmp
          );
          let imageSrc = "";
          let isImage = false;

          if(this.isCoverFileSelected && acceptImageType.find(x => x === file.type)){
            isImage = true;
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 120,
              useWebWorker: true
            };

            if(ImageMimeType.jpeg.find(x => x === file.type)){
              try{
                imageSrc = await exifr.thumbnailUrl(file) || "";
              }catch(error){
                console.error(error);
              }
            }

            if(!imageSrc){ 
              try{
                const compressedFile = await imageCompression(file, options);
                imageSrc = URL.createObjectURL(compressedFile);
              }catch(error){
                console.error(error);
              }
            }
          }

          if(this.isCoverFileSelected && this.innerFiles.length <= 0){
            this.innerFiles.push({ select: true, fileObj: file, image: isImage, imageSrc: imageSrc, complete: false, error: false, perProgress: 0, promise: null, fileId: "" });
          }else{
            this.innerFiles.push({ select: false, fileObj: file, image: isImage, imageSrc: imageSrc, complete: false, error: false, perProgress: 0, promise: null, fileId: "" });
          }
        }
      }
      // これをしないとファイルを削除した後に、再度同じファイルを選択できない
      event.target.value = null;
    }catch(error){
      console.error(error);
    }finally{
      this.innerLoading = false;
    }
  }
}
