




















import { Component, Vue } from "vue-property-decorator";
import * as PageAction from "@/utils/PageActionUtils";
import * as AccountSettingUtils from "@/utils/AccountSettingUtils";
import SearchBar from "@/components/files/SearchBar.vue";
import { SEARCH_MODE, SearchCondition } from "@/services/files/FileSearch";
import { uuid } from "vue-uuid";
import { isAllow } from "@/utils/RoleUtils";
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { GetAccountQuery } from "@/API";

@Component({
  components: {
    SearchBar,
  },
})
export default class Header extends Vue {
  private isSearch = false;
  company = "";
  logoUri = "";
  mfaPreference = "";

  async created() {
    await this.setPreference();
    const attributes = this.$store.state.user.attributes;
    if (!this.$store.state.accountData) {
      ((API.graphql({
        query: queries.getAccount,
        variables: { id: attributes["custom:account_id"] },
      })) as Promise<GraphQLResult<GetAccountQuery>>).then((account) => {
        this.$store.commit("setAccountData", account.data?.getAccount);
        this.company = account.data?.getAccount?.company || "";
        this.logoUri = account.data?.getAccount?.logoUri || "";
      });
    }else{
      this.company = this.$store.state.accountData.company;
      this.logoUri = this.$store.state.accountData.logoUri;
    }
  }

  async mounted() {
    await this.setPreference();
    this.$store.subscribe(async (mutation) => {
      if (mutation.type === "setUser") {
        await this.setPreference();
      }
    });
  }

  private get searchMode() {
    return JSON.parse(JSON.stringify(this.$store.getters.searchMode));
  }

  private changeSideDrawer() {
    PageAction.changeSideDrawer();
  }

  private onSearch(condition: SearchCondition) {
    this.$store.commit("setSearchCondition", condition);
    this.$store.commit("setSearchResultPage", 1);
    const mode = this.searchMode;

    if (mode === SEARCH_MODE.FileGroup) {
      this.$router.push({
        name: "FileGroupSearchResult",
        query: { q: uuid.v4() },
      });
    } else {
      this.$router.push({ name: "FileSearchResult", query: { q: uuid.v4() } });
    }
  }

  private async setPreference() {
    this.mfaPreference = this.$store.state.user?.preferredMFA;
    this.isSearch = isAllow("filing", "search", "SELF") && await AccountSettingUtils.isActiveServie("filing");
  }
}
