





















































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { FileSearchService, SEARCH_MODE, SearchCondition, FileData } from '@/services/files/FileSearch';
import { Coverage } from "@/API";
import MetaInfoTab from '@/components/files/details/MetaInfoTab.vue';
import FileDetailHeader from '@/components/files/details/FileDetailHeader.vue';
import FileMenu from '@/components/files/FileMenu.vue';
import Preview from '@/components/files/details/Preview.vue';
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";

@Component({
  components:{
    FileDetailHeader,
    FileMenu,
    MetaInfoTab,
    Preview,
    Loading,
    Dialog,
  }
})
export default class FileDetail extends Vue {
  private sheet = false;
  
  private file: FileData = {};
  // eslint-disable-next-line
  private coverage: any = {};

  private innerPosition = 0;
  private limit = 1;
  private total = 0;
  private first = 0;
  private backRoute = "";

  private fileSearchService = new FileSearchService();
  private condition: SearchCondition = {};
  private nextToken: string[] | undefined = undefined;

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = '';
  private resultDialogTitle = '';
  private resultDialogMessage = '';

  private get from(){
    const from = 0;
    if(this.total <= 0){
      return from;
    }
    if(this.total <= this.innerPosition){
      return this.total;
    }
    return this.innerPosition + 1;
  }

  private get to(): number {
    if(this.total > FileSearchService.SEARCH_LIMIT){
      return FileSearchService.SEARCH_LIMIT - 1;
    }
    return this.total - 1;
  }

  @Prop({default: null})
  private position;

  @Watch('innerPosition')
  private async onChangeInnerPosition(newPosition) {
    this.$store.commit("setFileDetailPosition", newPosition);
    this.loading = true;
    await this.search();
  }

  private async created(){
    this.innerPosition = this.position !== null ? this.position : this.$store.getters.fileDetailPosition ? this.$store.getters.fileDetailPosition : 0;
    if(this.innerPosition === 0){
      this.$store.commit("setFileDetailPosition", this.innerPosition);
      this.loading = true;

      if(this.$store.getters.from?.name === "FileEdit"){
        this.$store.commit("setFrom", "");
        this.$store.commit("setTo", "");
        setTimeout(() => {
          this.search();
        }, 3000);
      }else{
        await this.search();
      }
    }
  }

  private async search(){
    this.condition = this.$store.getters.searchCondition ? JSON.parse(JSON.stringify(this.$store.getters.searchCondition)): { mode: SEARCH_MODE.File };
    this.innerPosition = this.innerPosition >= FileSearchService.SEARCH_LIMIT ? FileSearchService.SEARCH_LIMIT : this.innerPosition; 
    this.total = 0;
    this.file = {};
    this.coverage = {};
    
    try{
      const result = await this.fileSearchService.searchFiles(this.condition, undefined, this.innerPosition, this.limit);
      if(result && result.items && result.items.length > 0){
        this.file = result.items[0];
        this.total = result.total || 0;
        this.nextToken = result.nextToken || undefined;
        if(this.file.coverageId && this.file.coverage){
          this.coverage = (this.file.coverage as Coverage);
        }
        this.loading = false;
      }else{
        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "warning";
        this.resultDialogTitle = "取得失敗";
        this.resultDialogMessage = `このファイルは削除されているか、アクセス権がありません。`;
      }
    }catch(error){
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "取得失敗";
      this.resultDialogMessage = `データの取得に失敗しました。\n再度、お試しください。`;
    }finally{
      this.loading = false;
    }
  }
  
  private back(){
    if(this.condition.groupIds && this.condition.groupIds.length > 0 && this.file.groupId){
      this.$router.push({name: 'FileGroupDetail', params: {id: this.file.groupId}})
      return;
    }

    const mode = this.$store.getters.searchMode;
    switch(mode){
      case SEARCH_MODE.File: {
        this.$router.push({name: 'FileSearchResult'});
        break;
      }
      case SEARCH_MODE.FileGroup: {
        if(this.file.groupId){
          this.$router.push({name: 'FileGroupDetail', params: {id: this.file.groupId}});
        }else{
          this.$router.push({name: 'FileGroupSearchResult'});
        }
        break;
      }
      default:{
        this.$router.push({name: 'Home'});
        break;
      }
    }
  }

  private async onClickReload(){
    this.loading = true;
    await this.search();
  }

  private onChangeResultDialogClosed(){
    this.resultDialog = false;
  }

  private onChangeFileMenuSheet(value){
    this.sheet = value;
  }

  private async onChangeFileMenuResult(value){
    if(value){
      if(this.innerPosition > 0){
        this.innerPosition = this.innerPosition - 1;
      }
      this.loading = true;
      setTimeout(() => {
        this.search();
      }, 3000);
    }
  }

  private async onClickFirst(){
    this.innerPosition = 0;
  }

  private async onClickPrevious(){
    this.innerPosition = this.innerPosition - 1;
  }

  private async onClickNext(){
    this.innerPosition = this.innerPosition + 1;
  }

  private async onClickLast(){
    this.innerPosition = this.to;
  }

  private async onChangeReload(){
    this.sheet = false;
    await this.onClickReload();
  }
}
