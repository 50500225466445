
import { Component, Vue } from "vue-property-decorator";
import * as queries from "@/graphql/queries";
import * as service from "@/services/coverage/coverageDeskService";
import { API } from "aws-amplify";
import moment from "moment";
import { GraphQLResult } from "@aws-amplify/api";
import { GetCoverageDeskQuery } from "@/API";

@Component
export default class CoverageDeskBaseForm extends Vue {
  name = "coverage-desk-base-form";
  valid = false;
  startDateMenu = false;
  endDateMenu = false;
  selectItem;
  deskName = "";
  startDate = new Date().toISOString().substr(0, 10);
  endDate = new Date().toISOString().substr(0, 10);
  memo = "";
  CoverageDeskService = new service.CoverageDeskService();

  // 入力ルール
  deskCount = 32;
  deskNameRules = [(v) => !!v || '担当者は必須項目です', (v) => v && v.length <= this.deskCount || `担当者は${this.deskCount}文字以下です`];
  dateCount = 10;
  startDateRules = [(v) => !!v || '開始日は必須項目です', (v) => v && v.length === this.dateCount, this.dateCompare];
  endDateRules = [(v) => !!v || '終了日は必須項目です', (v) => v && v.length === this.dateCount, this.dateCompare];
  memoCount = 1000;
  memoRules = [(v) => v !== undefined && v.length <= this.memoCount || `メモは${this.memoCount}文字以下です`];

  private dateCompare(){
    if(moment(this.startDate).isSameOrBefore(moment(this.endDate))){
      return true;
    }
    return '開始日 <= 終了日です';
  }

  //ダイアログコントロール
  loading = false;
  protected resultDialog = false;
  protected resultDialogMode = "";
  protected resultDialogTitle = "";
  protected resultDialogMessage = "";

  protected confirmDialog = false;
  protected confirmMode = "";
  protected confirmTitle = "";
  protected confirmMessage = "";

  protected validate() {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  protected setDefault() {
    this.selectItem = "";
    this.deskName = "";
    this.startDate = new Date().toISOString().substr(0, 10);
    this.endDate = new Date().toISOString().substr(0, 10);
    this.memo = "";
  }

  protected async setCoverageDesk(id: string) {
    const { data } = (await API.graphql({
      query: queries.getCoverageDesk,
      variables: { id: id },
    })) as GraphQLResult<GetCoverageDeskQuery>;
    this.deskName = data?.getCoverageDesk?.name || "";
    this.memo = data?.getCoverageDesk?.memo || "";
    this.startDate = data?.getCoverageDesk?.startDate || new Date().toISOString().substr(0, 10);
    this.endDate = data?.getCoverageDesk?.endDate || new Date().toISOString().substr(0, 10);
  }

  protected onChangeResultDialogClosed(){
    this.resultDialog = false;
  }
}
