






















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Confirm from '@/components/Confirm.vue';
import { FileDeleteService } from '@/services/files/FileDelete';
import { FILE_GROUP_POST_PROCESS } from '@/utils/FileUtils';
import CoverFileChangeDialog from '@/components/files/CoverFileChangeDialog.vue';
import FileMoveDialog from "@/components/files/FileMoveDialog.vue";
import CoverageSearchDialog from "@/components/files/CoverageSearchDialog.vue";
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";
import Loading from "@/components/Loading.vue";
import { FileEditService } from "@/services/files/FileEdit";
import { Coverage } from '@/API';
import Dialog from "@/components/Dialog.vue";
import { getAccountId } from '@/utils/AuthUtils';
import { downloadUri } from '@/services/files/FileDownload';

@Component({
    components:{
        Confirm,
        CoverFileChangeDialog,
        FileMoveDialog,
        CoverageSearchDialog,
        Loading,
        Dialog,
    }
})
export default class FileGroupMenu extends Vue {
  private innerSheet = false;
  private dialog = false;
  private coverFileChangeDialog = false;
  private confirmMode = "";
  private confirmTitle = "";
  private confirmMessage = "";
  private postProcess = "";
  private attributes;
  private fileMoveDialog = false;
  private groupIds: string[] = [];
  private coverageSearchDialog = false;
  private loading = false;
  private fileEditService = new FileEditService();
  private fileDeleteService = new FileDeleteService();

  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private isCreate = false;
  private isOperate = false;
  private isCoverageOperate = false;
  private isDownload = false;
  private isShareCreate = false;

  @Prop({default:false})
  private sheet;
  @Prop()
  private group;

  @Watch('sheet')
  private onChangeSheet(newValue){
    this.innerSheet = newValue;
  }

  @Watch('innerSheet')
  private onChangeInnerSheet(){
    this.$emit('group-menu-sheet', this.innerSheet);
  }

  @Watch('group')
  private onChangeGroup(newGroup){
    if(newGroup){
      this.groupIds.push(newGroup.id);
    }
  }

  private async created(){
    await this.setDefalut();
  }

  private async mounted(){
    await this.setDefalut();
  }

  private async setDefalut(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.isCreate = isAllow("filing","create") && await isActiveServie("filing");
    this.isOperate = isAllow("filing", "operate", role.filing?.operate) && await isActiveServie("filing");
    this.isCoverageOperate = isAllow("coverage", "read", role.coverage?.read) && await isActiveServie("coverage");
    this.isDownload = isAllow("filing", "download") && await isActiveServie("filing");
    this.isShareCreate = isAllow("sharing", "create") && await isActiveServie("sharing");
  }

 private onClickCoverFileChange(){
    this.innerSheet = false;
    this.coverFileChangeDialog = true;
  }

  private onChangeCoverFileChangeDialog(value){
    this.coverFileChangeDialog = false;
    this.$emit('group-menu-result', value);
  }

  private onCoverFileChangeDialog(value){
    this.coverFileChangeDialog = value;
  }

  private onClickCoverageChange(){
    this.innerSheet = false;
    this.coverageSearchDialog = true;
  }

  private onCoverageSearchDialog(value){
    this.coverageSearchDialog = value;
  }

  private async onChangeCoverageSearchSelected(value){
    if(value && this.group && this.group.id){
      try{
        this.loading = true;
        const coverage = value as Coverage;
        if(coverage && coverage.id){
          await this.fileEditService.changeCoordinatedCoverageFromGroup(this.group.id, coverage.id);
          this.loading = false;
          this.resultDialog = true;
          this.resultDialogMode = "info";
          this.resultDialogTitle = "更新成功";
          this.resultDialogMessage = "連携取材の更新に成功しました。"
        }
      }catch(error){
        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "更新失敗";
        this.resultDialogMessage = `連携取材の更新に失敗しました。`;
      }finally{
        this.loading = false;
      }
    }else{
      this.coverageSearchDialog = false;
    }
  }

  private onClickFileMove(){
    this.innerSheet = false;
    this.fileMoveDialog = true;
  }

  private onChangeFileMoveDialog(value){
    this.fileMoveDialog = false;
    this.$emit('group-menu-result', value);
  }

  private onFileMoveDialog(value){
    this.fileMoveDialog = value;
  }

  private async onClickDelete(){
    this.confirmTitle = '削除確認';
    this.confirmMessage = 'このアルバムを削除してもよろしいでしょうか？\n※プロテクトされている場合は削除されません。'
    this.confirmMode = 'warning';
    this.postProcess = FILE_GROUP_POST_PROCESS.Delete;
    this.dialog = true;
  }

  private async confirmed(value){
    this.dialog = false;

    if(value){
      switch(this.postProcess){
        case FILE_GROUP_POST_PROCESS.Delete: {
          if(this.group){
            try{
              this.loading = true;
              await this.fileDeleteService.deleteFileGroup(this.group);
              this.loading = false;
              this.resultDialog = true;
              this.resultDialogMode = "info";
              this.resultDialogTitle = "削除成功";
              this.resultDialogMessage = `アルバムの削除に成功しました。`;
            }catch(error){
              this.loading = false;
              this.resultDialog = true;
              this.resultDialogMode = "error";
              this.resultDialogTitle = "削除失敗";
              this.resultDialogMessage = `アルバムの削除に失敗しました。`;
            }finally{
              this.loading = false;
              this.innerSheet = false;
            }
          }
          break;
        }
      }
    }
  }

  private onChangeResultDialogClosed(){
    this.coverageSearchDialog = false;
    this.resultDialog = false;
    this.$emit('group-menu-result', true);
  }

  private async onClickFilesCSVExport(){
    const user = this.$store.getters.user;
    const accountId = getAccountId(user);
    const fileGroupIds = [this.group.id];
    try{
      this.innerSheet = false;
      this.loading = true;
      const signedUrl = await this.fileEditService.exportFilesCSVFromFileGroups(accountId, fileGroupIds);
      downloadUri(signedUrl);
    }catch(error){
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "エクスポート失敗";
      this.resultDialogMessage = `CSVファイルのエクスポートに失敗しました。`;
    }finally{
      this.innerSheet = false;
      this.loading = false;
    }
  }
}
