






























































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { UploadFile } from "@/services/files/FileUpload";
import Confirm from "@/components/Confirm.vue";
import CoverFileSelectDialog from "@/components/files/create/CoverFileSelectDialog.vue";

@Component({
  components:{
    Confirm,
    CoverFileSelectDialog,
  }
})
export default class FileDataTable extends Vue {

  private innerFiles: Array<UploadFile> = [];
  private selectCoverDialog = false;
  private selectedCoverFile = {};
  private fileNameSearch = '';
  private tableLoading = false;
  private selectedCoverFileArray: Array<UploadFile> = [];
  private headers = [
    { text: 'ファイル名', value: 'fileObj.name' },
    { text: 'アクション', value: 'complete', sortable: false },
  ];

  private confirmDialog = false;
  private confirmMode = "";
  private confirmTitle = "";
  private confirmMessage = "";

  @Prop({ default: [] })
  private files;

  @Prop({ default: false })
  private isCoverFileSelected;

  @Prop({ default: false })
  private isTableLoading;

  @Watch('isTableLoading')
  private onChangeTableLoading(newValue){
    this.tableLoading = newValue;
  }

  @Watch('files')
  private onChangeFiles(newValue){
    this.innerFiles = newValue;
  }

  @Watch('innerFiles')
  private onChangeInnerFiles(newValue){
    if(newValue.length <= 0){
      this.selectedCoverFileArray.splice(0);
      this.selectedCoverFile = {};
    }
    if(this.selectedCoverFileArray.length <= 0 && newValue.length > 0 && newValue[0].select){
      this.selectedCoverFileArray.push(newValue[0]);
      this.selectedCoverFile = newValue[0];
    }
    this.$emit('changed-file-data-table', newValue);
  }

  private onClickShowSelectCoverFileDialog(){
    if(this.tableLoading){
      return;
    }
    this.selectedCoverFile = this.selectedCoverFileArray[0];
    this.selectCoverDialog = !this.selectCoverDialog;
  }

  private deleteMode = "";
  private deleteItem: UploadFile | undefined = undefined;
  private onDelete(item: UploadFile) {
    this.deleteItem = item;
    this.confirmMode = "warning";
    this.confirmTitle = "削除確認";
    this.confirmMessage = (item.fileObj.name || "選択したファイル") + "を削除してもよろしいでしょうか？";
    this.confirmDialog = true;
    this.deleteMode = "single";
  }

  private onDeleteAll() {
    if(this.tableLoading){
      return;
    }
    this.confirmMode = "warning";
    this.confirmTitle = "削除確認";
    this.confirmMessage = "ファイルを全て削除してもよろしいでしょうか？";
    this.confirmDialog = true;
    this.deleteMode = "multiple";
  }

  private confirmed(isConfirmed) {
    if(isConfirmed){
      switch(this.deleteMode){
        case "single":{
          if(this.deleteItem){
            if(this.deleteItem.imageSrc){
              URL.revokeObjectURL(this.deleteItem.imageSrc);
            }
            const index = this.innerFiles.indexOf(this.deleteItem);
            this.innerFiles.splice(index, 1);
            // 削除したものが表紙だったらv-modelから削除する
            if(this.selectedCoverFileArray.length > 0 && this.selectedCoverFileArray[0] === this.deleteItem){
              this.selectedCoverFileArray.splice(0, 1);
            }
            if(this.selectedCoverFile === this.deleteItem){
              this.selectedCoverFile = {};
            }
          }
          break;
        }
        case "multiple":{
          for(const file of this.innerFiles){
            if(file.imageSrc){
              URL.revokeObjectURL(file.imageSrc);
            }
          }
          this.innerFiles.splice(0);
          this.selectedCoverFileArray.splice(0, 1);
          this.selectedCoverFile = {};
          break;
        }
      }
    }
    
    this.deleteMode = "";
    this.deleteItem = undefined;
    this.confirmDialog = false;
  }

  private onChangeItemSelected(event){
    if(event.value){
      this.$set(event.item, 'select', true);
      this.selectedCoverFile = event.item;
    }else{
      this.$set(event.item, 'select', false);
      this.selectedCoverFile = {};
    }
  }

  private onChangeCoverFileSelectDialog(value){
    this.selectCoverDialog = value;
  }

  private onChangeSelectedCoverFile(value){
    this.selectedCoverFile = value ? value : {};

    this.selectedCoverFileArray.splice(0);
    for(const file of this.innerFiles){
      this.$set(file, 'select', false);
      if(file === this.selectedCoverFile){
        this.$set(file, 'select', true);
        this.selectedCoverFileArray.splice(0, 1, file);
      }
    }
  }
}
