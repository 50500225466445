























































import { Component, Vue } from "vue-property-decorator";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { GetUserQuery } from "@/API";

@Component
export default class ShareSearchExUser extends Vue {
  //外部ユーザー
  private exUser = "";
  private exUserStr = "";
  private exUserId = "";
  private exUserDialog = false;

  //外部ユーザーの検索
  private onClickExUserAdd() {
    this.exUserDialog = true;
  }

  private async exUserSearch() {
    const input = { email: this.exUser };
    ((API.graphql(graphqlOperation(queries.getUser, { input: input }))) as Promise<GraphQLResult<GetUserQuery>>).then(
      (result) => {
        const attributes = this.$store.getters.user.attributes;
        if (result.data?.getUser?.accountId !== attributes["custom:account_id"]) {
          this.exUserStr =
            result.data?.getUser?.accountId + ":" + result.data?.getUser?.name;
          this.exUserId = result.data?.getUser?.id || "";
        }
      }
    ).catch((e)=>{
      console.log(e)
    });
    null;
  }

  private addExUser() {
    this.$emit("addExUser",this.exUserStr, this.exUserId);
  }

  private back() {
    this.$router.go(-1);
  }

  private closed(){
    this.exUserDialog = false;
  }
}
