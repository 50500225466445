import { RouteConfig } from 'vue-router'
import { mainLayout } from '../components/layout/MainLayout'
import MyPage from '../views/MyPage.vue'


const routes: Array<RouteConfig> = [
  // ログイン必須
  {
    // ログイン後初期表表示
    path: '/:accountId/my_page/',
    name: 'MyPage',
    meta: { requiresAuth: true },
    component: mainLayout(MyPage),
  }
]

export default routes
