























































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from "moment";
import Fraction from "fraction.js";

@Component
export default class ImageInfo extends Vue {
  @Prop()
  private file;

  private camera = '';
  private lens = '';
  private focalLength = '';
  private fNumber = '';
  private exposureTime = '';
  private iso = '';
  private exposureProgram = '';
  private meteringMode = '';
  private exposureBiasValue = '';
  private whiteBalance = '';
  private sceneCaptureType = '';
  private colorSpace = '';
  private imageSize = '';
  private datetimeOriginal = '';
  private modifiedDatetime = '';
  private software = '';

  private ExposurePrograms = [
    {name: '未定義', value: 0},
    {name: 'マニュアル', value: 1},
    {name: 'ノーマルプログラム', value: 2},
    {name: '絞り優先', value: 3},
    {name: 'シャッター優先', value: 4},
    {name: 'creativeプログラム', value: 5},
    {name: 'actionプログラム', value: 6},
    {name: 'ポートレイトモード', value: 7},
    {name: 'ランドスケープモード', value: 8},
  ];

  private MeteringModes = [
    {name: '不明', value: 0},
    {name: '平均', value: 1},
    {name: '中央重点', value: 2},
    {name: 'スポット', value: 3},
    {name: 'マルチスポット', value: 4},
    {name: '分割測光', value: 5},
    {name: '部分測光', value: 6},
    {name: 'その他', value: 255},
  ];

  private WhiteBalances = [
    {name: 'オート', value: 0},
    {name: 'マニュアル', value: 1},
  ];

  private SceneCaptureTypes = [
    {name: '標準', value: 0},
    {name: '風景', value: 1},
    {name: '人物', value: 2},
    {name: '夜景', value: 3},
  ];

  private created(){
    this.setFile();
  }

  @Watch('file')
  private onFileChanged(){
    this.setFile();
  }

  private setFile(){
    if(this.file){
      const meta = this.file.meta ? JSON.parse(this.file.meta) : undefined;
      this.imageSize = meta && meta.imageWidth && meta.imageHeight ? `${meta.imageWidth} × ${meta.imageHeight}` : '';
      this.camera = meta && meta.model ? meta.model : '';
      this.lens = meta && meta.lensModel ? meta.lensModel : '';
      this.focalLength = meta && meta.focalLength ? `${meta.focalLength}mm` : '';
      this.fNumber = meta && meta.fNumber ? `f/${meta.fNumber}` : '';
      this.exposureTime = meta && meta.exposureTime ? parseFloat(meta.exposureTime) < 1 ? `${new Fraction(meta.exposureTime).toFraction(true)}秒` : `${meta.exposureTime}秒` : '';
      this.iso = meta && meta.isoSpeedRatings ? parseInt(meta.isoSpeedRatings, 10).toString() : '';

      if(meta && meta.exposureProgram){
        const foundExposureProgram = this.ExposurePrograms.find(v => v.value === meta.exposureProgram);
        this.exposureProgram = foundExposureProgram ? foundExposureProgram.name : '';
      }

      if(meta && meta.meteringMode){
        const foundMeteringMode = this.MeteringModes.find(v => v.value === meta.meteringMode);
        this.meteringMode = foundMeteringMode ? foundMeteringMode.name : '';
      }
      
      this.exposureBiasValue = meta && meta.exposureBiasValue !== undefined ? `${Math.round(meta.exposureBiasValue * 10)/10}EV` : '';

      if(meta && meta.whiteBalance !== 'undefined'){
        const foundWhiteBalance = this.WhiteBalances.find(v => v.value === meta.whiteBalance);
        this.whiteBalance = foundWhiteBalance ? foundWhiteBalance.name : '';
      }
      
      if(meta && meta.sceneCaptureType){
        const foundScenceCaptureType = this.SceneCaptureTypes.find(v => v.value === meta.sceneCaptureType);
        this.sceneCaptureType = foundScenceCaptureType ? foundScenceCaptureType.name : '';
      }

      this.colorSpace = meta && meta.colorSpace ? meta.colorSpace : '';
      this.datetimeOriginal = '';
      if(meta && meta.datetimeOriginal){
        if(moment.parseZone(meta.datetimeOriginal, 'YYYY-MM-DD', true).isValid()){
          this.datetimeOriginal = moment.parseZone(meta.datetimeOriginal, 'YYYY-MM-DD', true).format('YYYY-MM-DD');
        }else if(moment.parseZone(meta.datetimeOriginal, 'YYYY-MM-DDTHH:mm:ss.SSS', true).isValid()){
          this.datetimeOriginal = moment.parseZone(meta.datetimeOriginal, 'YYYY-MM-DDTHH:mm:ss.SSS', true).format('YYYY-MM-DD HH:mm:ss.SSS');
        }
      }
      this.modifiedDatetime = '';
      if(meta && meta.modifiedDatetime){
        const modifiedDatetime = moment.parseZone(meta.modifiedDatetime, 'YYYY-MM-DDTHH:mm:ss.SSS', true);
        if(modifiedDatetime.isValid()){
          this.modifiedDatetime = modifiedDatetime.format('YYYY-MM-DD HH:mm:ss.SSS');
        }
      }

      this.software = meta && meta.software ? meta.software : '';
    }
  }
}

