
















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Storage } from "aws-amplify";
import moment from "moment";
import { getFileType, getIcon } from "@/utils/FileUtils";

@Component
export default class FileMoveListItem extends Vue {
  @Prop()
  private group;
  
  private title = "";
  private createdAt = "";
  private src = "";
  private fileType = "";

  private async created(){
    await this.setGroup(); 
  }
  
  @Watch('group')
  private async onChangeGroup(){
    await this.setGroup();
  }
  
  private async setGroup(){
    this.title = this.group.title ? this.group.title : "";
    this.createdAt = this.group.createdAt ? moment(this.group.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSZ').local().format('YYYY-MM-DD HH:mm:ss.SSSZ') : '';

    this.src = "";
    if(this.group.coverFileId && this.group.file && this.group.file.deleted === 0){
      this.fileType = this.group.file.type && this.group.file.extension ? getFileType(this.group.file.extension, this.group.file.type) : '';
      const meta = this.group.file && this.group.file.meta ? JSON.parse(this.group.file.meta) : undefined;
      const thumbnailUri = meta && meta.thumbnailUri ? meta.thumbnailUri.split('/') : [];

      if(thumbnailUri.length > 0){
        this.src = (await Storage.get(`${thumbnailUri[4]}/${thumbnailUri[5]}/${thumbnailUri[6]}/${thumbnailUri[7]}`, {download: false, expires: 300, contentType: 'image/jpeg'})).toString();
      }else{
        this.src = getIcon(this.fileType);
      }
    }
  }

  private onClickListItem(group){
    this.$emit('file-move-list-item-clicked', group);
  }
}
