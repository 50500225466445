



































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { CoverageInfo } from "@/API";
import InfoItem from "@/components/home/InfoItem.vue";
import { SearchForm, CoverageInfoService } from "@/services/coverage/coverageInfoService";
import CoverageInfoCreateForm from "@/components/coverage/info/CoverageInfoCreateForm.vue";
import Loading from "@/components/Loading.vue";
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";

@Component({
  components:{
    InfoItem,
    CoverageInfoCreateForm,
    Loading,
  }
})
export default class Info extends Vue {
  @Prop({ default: moment(new Date()).local().format('YYYY-MM-DD') })
  private date;

  private user;
  private infos: CoverageInfo[] = [];
  private sheet = false;

  private createDialog = false;
  private infoService = new CoverageInfoService();
  private searchForm: SearchForm = {
    dayStart: "",
    dayEnd: "",
  }

  private loading = false;
  private isRead = false;
  private isOperate = false;

  @Watch('date')
  private async onChangeDate(){
    await this.search();
  }

  private async created(){
    await this.setDefalut();
    await this.search();
    
    if(this.isRead){
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filter: any = {};
      if(isAllow("coverageInfo", "read", "COMPANY") && await isActiveServie("coverage")){
        filter.ownedAccountId = this.user?.attributes["custom:account_id"];
      }else if(isAllow("coverageInfo", "read", "DEPARTMENT") && await isActiveServie("coverage")){
        filter.ownedDepartment = this.user?.attributes["custom:department"];
      }else if(isAllow("coverageInfo", "read", "SELF") && await isActiveServie("coverage")){
        filter.createdUserId = this.user?.attributes.sub;
      }
    }
  }

  private async setDefalut(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    const service = await isActiveServie("coverage");
    this.isRead = isAllow("coverageInfo", "read", role.coverageInfo?.read) && service ? true : false;
    this.isOperate = isAllow("coverageInfo", "operate", role.coverageInfo?.operate) && service ? true : false;
  }

  private async search(){
    try{
      if(!this.isRead){
        return;
      }
      this.loading = true;
      this.user = this.$store.getters.user;
      this.searchForm.dayStart = moment(this.date).startOf("day").toISOString();
      this.searchForm.dayEnd = moment(this.date).endOf("day").toISOString();
      this.infos = await this.infoService.search(this.searchForm);
    }catch(error){
      console.error(error);
      this.infos = [];
    }finally{
      this.loading = false;
    }
  }

  private onChangeInfoMenuSheet(value){
    this.sheet = value;
  }

  private async createClose() {
    this.createDialog = false;
    setTimeout(() => {
      this.search();
    }, 1000);
  }

  private async onChangeInfoItemResult(){
    setTimeout(() => {
      this.search();
    }, 1000);
  }
}
