export const SHARE_POST_PROCESS = {
    Edit: 'EditShare',
    ChangeStatus: 'ChangeShareStatus',
    Delete: 'EditShareEdit',
} as const;

type SHARE_POST_PROCESS = typeof SHARE_POST_PROCESS[keyof typeof SHARE_POST_PROCESS];

export const SHARE_STATUS = {
    pending: 0,
    sharing: 10,
    shared: 99,
} as const;

type SHARE_STATUS = typeof SHARE_STATUS[keyof typeof SHARE_STATUS];