import { ModelCoverageDeskConditionInput,CoverageDesk, CreateCoverageDeskInput, UpdateCoverageDeskInput, CoverageDesksByOwnedAccountIdQuery, ModelSortDirection, CoverageDesksByOwnedAccountIdQueryVariables } from "@/API";
import * as queries from "@/graphql/queries";
import { createCoverageDesk, updateCoverageDesk } from "@/graphql/mutations";
import { API } from "aws-amplify";
import moment from "moment";
import { uuid } from "vue-uuid";
import { GraphQLResult } from "@aws-amplify/api";
import store from "@/store";

export class CoverageDeskService {

    headers = [
        { text: "開始日時", value: "startDate" },
        { text: "終了日時", value: "endDate" },
        { text: "担当者", value: "name" },
        { text: "", value: "detail", align: "center", sortable: false },
    ];

    async search(searchForm: SearchForm): Promise<CoverageDesk[]> {
        let coverageDesks: Array<CoverageDesk> = [];
        const attributes = store.getters.user?.attributes;
        const filter: ModelCoverageDeskConditionInput = { deleted: { eq: 0 } };
        filter.and = [];
        filter.and?.push({
            or: [
                //対象日に開始か終了がある
                { startDate: { gt: searchForm.dayStart, lt: searchForm.dayEnd } },
                { endDate: { gt: searchForm.dayStart, lt: searchForm.dayEnd } },
                //開始と終了の間に対象日がある
                { and: [{ startDate: { lt: searchForm.dayStart } }, { endDate: { gt: searchForm.dayEnd } }] },
            ],
        });
        const variables: CoverageDesksByOwnedAccountIdQueryVariables = {
            ownedAccountId: attributes["custom:account_id"],
            startDateEndDate: { 
                between: [
                    { startDate: moment(searchForm.dayStart).subtract(1, 'years').format('YYYY-MM-DD'), endDate: moment(searchForm.dayStart).format('YYYY-MM-DD') },
                    { startDate: moment(searchForm.dayEnd).format('YYYY-MM-DD'), endDate: moment(searchForm.dayEnd).add(1, 'years').format('YYYY-MM-DD') },
                ]
            },
            filter: filter,
            sortDirection: ModelSortDirection.ASC,
            limit: 1000
        };

        try {
            // TODO:nextTokenによる再帰処理が必要
            const { data } = (await API.graphql({
                query: queries.coverageDesksByOwnedAccountId,
                variables: variables,
            })) as GraphQLResult<CoverageDesksByOwnedAccountIdQuery>;
            coverageDesks = data?.coverageDesksByOwnedAccountId?.items as CoverageDesk[];
        } catch (e) {
            console.log(e);
            throw e;
        }
        return coverageDesks;
    }


    async createCoverageDesk(form: InputForm) {
        const id = uuid.v4();
        const createCoverageDeskInput: CreateCoverageDeskInput = {
            id: id,
            name: form.name,
            startDate: form.startDate,
            endDate: form.endDate,
            memo: form.memo,
            createdUserId: form.createdUserId,
            ownedAccountId: form.ownedAccountId,
            ownedDepartment: form.ownedDepartment,
            deleted: 0,
        };
        try {
            await API.graphql({
                query: createCoverageDesk,
                variables: { input: createCoverageDeskInput },
            });
        } catch (e) {
            console.log(e)
            throw e;
        }
    }

    async updateCoverageDesk(form: EditForm) {
        const updateCoverageDeskInput: UpdateCoverageDeskInput = {
            id: form.id,
            name: form.name,
            startDate: form.startDate,
            endDate: form.endDate,
            memo: form.memo,
            createdUserId: form.createdUserId,
        };
        try {
            await API.graphql({
                query: updateCoverageDesk,
                variables: { input: updateCoverageDeskInput },
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async deleteCoverageDesk(id: string) {
        const updateCoverageDeskInput: UpdateCoverageDeskInput = {
            id: id,
            deleted: 1,
            deletedAt: moment().toISOString(),
        };
        //削除フラグを立てる
        try {
            await API.graphql({
                query: updateCoverageDesk,
                variables: { input: updateCoverageDeskInput },
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    }
}

export type SearchForm = {
    dayStart: string;
    dayEnd: string | null;
}

export type InputForm = {
    startDate: string;
    endDate: string;
    name: string;
    memo: string;
    createdUserId: string;
    ownedAccountId: string;
    ownedDepartment: string;
}


export type EditForm = {
    id: string;
    startDate: string;
    endDate: string;
    name: string;
    memo: string;
    createdUserId: string;
}