

















import { Component, Vue } from "vue-property-decorator";
import CoverageDesk from "@/components/coverage/desk/CoverageDesk.vue";
import CoverageInfo from "@/components/coverage/info/CoverageInfo.vue";
import CoverageList from "@/components/coverage/CoverageList.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    CoverageDesk,
    CoverageInfo,
    CoverageList,
    Loading,
  },
})
export default class CoverageMain extends Vue {
  name = "CoverageMain";
  loading = true;

  loadStart() {
    this.loading = true;
  }

  loadEnd() {
    this.loading = false;
  }
}
