











































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Confirm from "@/components/Confirm.vue";
import {
  downloadFile,
  downLoadRequest,
  getStorageInfo,
} from "@/services/files/FileDownload";
import { FileDeleteService } from "@/services/files/FileDelete";
import { isAllowSendFile, isAllowSendFileOperate, selesSendFile } from "@/services/files/FileSale";
import { FILE_POST_PROCESS } from "@/utils/FileUtils";
import CoverFileChangeDialog from "@/components/files/CoverFileChangeDialog.vue";
import FileMoveDialog from "@/components/files/FileMoveDialog.vue";
import { File } from "@/API";
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    Confirm,
    CoverFileChangeDialog,
    FileMoveDialog,
    Dialog,
    Loading,
  },
})
export default class FileMenu extends Vue {
  private fileDeleteService = new FileDeleteService();
  private innerSheet = false;
  private dialog = false;
  private coverFileChangeDialog = false;
  private confirmMode = "";
  private confirmTitle = "";
  private confirmMessage = "";
  private postProcess = "";
  private fileMoveDialog = false;
  private moveFiles: File[] = [];
  private attributes;

  private isCreate = false;
  private isOperate = false;
  private isDownload = false;
  private isShareCreate = false;
  private isSale = false;

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private glacierConfirm = false;
  private glacierConfirmMode = "";
  private glacierConfirmTitle = "";
  private glacierConfirmMessage = "";

  private glacierDialog = false;
  private glacierDialogMode = "";
  private glacierDialogTitle = "";
  private glacierDialogMessage = "";

  private innerIsShowReload = false;

  @Prop({ default: false })
  private sheet;
  @Prop()
  private file;
  @Prop()
  private mode;
  @Prop({ default: false })
  private isShowReload;

  @Watch("sheet")
  private onChangeSheet(newValue) {
    this.innerSheet = newValue;
  }

  @Watch("innerSheet")
  private onChangeInnerSheet() {
    this.$emit("file-menu-sheet", this.innerSheet);
  }

  @Watch("isShowReload")
  private onChangeIsShowReload(newValue) {
    this.innerIsShowReload = newValue;
  }

  @Watch("file")
  private async onChangeFile() {
    await this.setDefalut();
  }

  private async created() {
    await this.setDefalut();
  }

  private async mounted() {
    await this.setDefalut();
  }

  private async setDefalut(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.isCreate = isAllow("filing","create") && await isActiveServie("filing");
    this.isOperate = isAllow("filing", "operate", role.filing?.operate) && await isActiveServie("filing");
    this.isDownload = isAllow("filing", "download") && await isActiveServie("filing");
    this.isShareCreate = isAllow("sharing", "create") && await isActiveServie("sharing");
    this.isSale = isAllow("sale", "operate", role.sale?.operate) && await isAllowSendFileOperate(await this.file) && await isActiveServie("sale") && await isAllowSendFile(await this.file);
  }

  private async onClickDownload() {
    try {
      this.innerSheet = false;
      this.loading = true;

      const storageInfo = await getStorageInfo(this.file);
      if (this.file && storageInfo && storageInfo.glacierStatus === "GLACIER") {
        //ダイアログを開いて確認
        this.glacierConfirmMode = "warning";
        this.glacierConfirmTitle = "アーカイブ済みのファイル";
        this.glacierConfirmMessage = "アーカイブ済みのファイルです。\nダウンロードするには、リクエストを送信する必要があります。\nリクエストを送信しますか？";
        this.glacierConfirm = true;
      } else if (this.file && storageInfo && storageInfo.glacierStatus === "PROCESSING") {
          this.resultDialog = true;
          this.resultDialogMode = "warning";
          this.resultDialogTitle = "ダウンロードリクエスト待ち";
          this.resultDialogMessage = "ファイルのダウンロードできるまで暫くお待ちください。\n時間をおいて再操作をお願いします。";
      } else if (this.file && storageInfo && (storageInfo.glacierStatus === "RESTORETED" || storageInfo.glacierStatus === "NOT_GLACIER" )) {
        await downloadFile(this.file, storageInfo.storageClass);
      }
    } catch (error) {
      console.error(error)
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "ダウンロード失敗";
      this.resultDialogMessage = "ファイルのダウンロードに失敗しました。";
    } finally {
      this.loading = false;
    }
  }

  private onClickCoverFileChange() {
    this.innerSheet = false;
    this.coverFileChangeDialog = true;
  }

  private onChangeCoverFileChangeDialog(value) {
    this.coverFileChangeDialog = false;
    this.$emit("file-menu-result", value);
  }

  private onCoverFileChangeDialog(value){
    this.coverFileChangeDialog = value;
  }

  private onClickFileMove() {
    this.moveFiles.push(this.file);
    this.innerSheet = false;
    this.fileMoveDialog = true;
  }

  private onChangeFileMoveDialog(value) {
    this.fileMoveDialog = false;
    this.moveFiles = [];
    this.$emit("file-menu-result", value);
  }

  private onFileMoveDialog(value){
    this.fileMoveDialog = value;
  }

  private async onClickDelete() {
    this.confirmTitle = "削除確認";
    this.confirmMessage =
      "このファイルを削除してもよろしいでしょうか？\n※プロテクトされている場合は削除されません";
    this.confirmMode = "warning";
    this.postProcess = FILE_POST_PROCESS.Delete;
    this.dialog = true;
  }

  private async onClickSale() {
    this.confirmTitle = "外販送信確認";
    this.confirmMessage =
      "このファイルを外販送信してもよろしいでしょうか？";
    this.confirmMode = "warning";
    this.postProcess = FILE_POST_PROCESS.Sale;
    this.dialog = true;
  }

  private async confirmed(value) {
    this.dialog = false;
    if (value) {
      switch (this.postProcess) {
        case FILE_POST_PROCESS.Delete: {
          if (this.file) {
            try {
              this.loading = true;
              await this.fileDeleteService.deleteFile(this.file);
              this.loading = false;
              this.resultDialog = true;
              this.resultDialogMode = "info";
              this.resultDialogTitle = "削除成功";
              this.resultDialogMessage = "ファイルの削除に成功しました。";
            } catch (error) {
              this.loading = false;
              this.resultDialog = true;
              this.resultDialogMode = "error";
              this.resultDialogTitle = "削除失敗";
              this.resultDialogMessage = "ファイルの削除に失敗しました。";
            } finally {
              this.loading = false;
              this.innerSheet = false;
            }
          }
          break;
        }
        case FILE_POST_PROCESS.Sale: {
          if (this.file) {
            try {
              this.loading = true;
              await selesSendFile(this.file);
              this.loading = false;
              this.resultDialog = true;
              this.resultDialogMode = "info";
              this.resultDialogTitle = "外販送信成功";
              this.resultDialogMessage = "ファイルの外販送信に成功しました。";
            } catch (error) {
              this.loading = false;
              this.resultDialog = true;
              this.resultDialogMode = "error";
              this.resultDialogTitle = "外販送信失敗";
              this.resultDialogMessage = "ファイルの外販送信に失敗しました。";
            } finally {
              this.loading = false;
              this.innerSheet = false;
            }
          }
          break;
        }
      }
    }
  }

  private onChangeResultDialogClosed(){
    let next = false; 
    if(this.postProcess === FILE_POST_PROCESS.Delete){
      next= true;
    }
    this.resultDialog = false;
    this.$emit('file-menu-result', next);
  }

  private async glacierConfirmed(value) {
    this.glacierConfirm = false;
    if (!value) {
      return;
    }
    this.loading = true;
    try {
      await downLoadRequest(this.file);
      this.glacierDialogMode = "info";
      this.glacierDialogTitle = "リクエスト完了";
      this.glacierDialogMessage = "ダウンロードリクエストを送信しました。\n10分程度でダウンロード可能となります。\nファイル検索からリクエストの一覧が確認出来ます。";
      this.glacierDialog = true;
    } catch (error) {
      console.error(error);
      this.glacierDialogMode = "error";
      this.glacierDialogTitle = "ダウンロードリクエスト失敗";
      this.glacierDialogMessage =
        "ファイルのダウンロードリクエストに失敗しました。";
      this.glacierDialog = true;
    } finally {
      this.loading = false;
    }
  }

  private onGlacierResultDialogClosed() {
    this.glacierDialog = false;
  }

  private onClickReload(){
    this.$emit("file-menu-reload", true);
  }
}
