/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listSharingWithFiles = /* GraphQL */ `
  query ListSharingWithFiles(
    $sharingId: ID!
    $sort: PGCSearchableFileSortInput
    $limit: Int
    $nextToken: [String]
    $from: Int
  ) {
    listSharingWithFiles(
      sharingId: $sharingId
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      sharing {
        id
        title
        description
        memo
        limitDate
        limitTime
        sharingUsers
        sharingFiles
        createdUserId
        ownedAccountId
        ownedDepartment
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
      files {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const listSharingFiles = /* GraphQL */ `
  query ListSharingFiles($sharingId: ID!, $fileId: ID!) {
    listSharingFiles(sharingId: $sharingId, fileId: $fileId) {
      id
      groupId
      rating
      title
      caption
      instructions
      memo
      fileName
      protect
      original
      caution
      cautionInstructions
      reuse
      reuseCondition
      keywords
      extension
      type
      meta
      tag
      customItems
      fileUri
      fileSize
      coverageId
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      group {
        id
        coverFileId
        title
        description
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        file {
          id
          groupId
          rating
          title
          caption
          instructions
          memo
          fileName
          protect
          original
          caution
          cautionInstructions
          reuse
          reuseCondition
          keywords
          extension
          type
          meta
          tag
          customItems
          fileUri
          fileSize
          coverageId
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          group {
            id
            coverFileId
            title
            description
            memo
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            file {
              id
              groupId
              rating
              title
              caption
              instructions
              memo
              fileName
              protect
              original
              caution
              cautionInstructions
              reuse
              reuseCondition
              keywords
              extension
              type
              meta
              tag
              customItems
              fileUri
              fileSize
              coverageId
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
          coverage {
            id
            title
            location
            startAt
            endAt
            description
            memo
            attachmentFile
            createdUserId
            ownedAccountId
            ownedDepartment
            assignedUserId
            status
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      }
      coverage {
        id
        title
        location
        startAt
        endAt
        description
        memo
        attachmentFile
        createdUserId
        ownedAccountId
        ownedDepartment
        assignedUserId
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const pgcSearchFiles = /* GraphQL */ `
  query PgcSearchFiles(
    $filter: AWSJSON
    $sort: PGCSearchableFileSortInput
    $limit: Int
    $nextToken: [String]
    $from: Int
  ) {
    pgcSearchFiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const searchFiles = /* GraphQL */ `
  query SearchFiles(
    $filter: SearchableFileFilterInput
    $sort: SearchableFileSortInput
    $limit: Int
    $nextToken: [String]
    $from: Int
  ) {
    searchFiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const searchFileGroups = /* GraphQL */ `
  query SearchFileGroups(
    $filter: SearchableFileGroupFilterInput
    $sort: SearchableFileGroupSortInput
    $limit: Int
    $nextToken: [String]
    $from: Int
  ) {
    searchFileGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        coverFileId
        title
        description
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        file {
          id
          groupId
          rating
          title
          caption
          instructions
          memo
          fileName
          protect
          original
          caution
          cautionInstructions
          reuse
          reuseCondition
          keywords
          extension
          type
          meta
          tag
          customItems
          fileUri
          fileSize
          coverageId
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          group {
            id
            coverFileId
            title
            description
            memo
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            file {
              id
              groupId
              rating
              title
              caption
              instructions
              memo
              fileName
              protect
              original
              caution
              cautionInstructions
              reuse
              reuseCondition
              keywords
              extension
              type
              meta
              tag
              customItems
              fileUri
              fileSize
              coverageId
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
          coverage {
            id
            title
            location
            startAt
            endAt
            description
            memo
            attachmentFile
            createdUserId
            ownedAccountId
            ownedDepartment
            assignedUserId
            status
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      }
      nextToken
      total
    }
  }
`;
export const searchCoverages = /* GraphQL */ `
  query SearchCoverages(
    $filter: SearchableCoverageFilterInput
    $sort: SearchableCoverageSortInput
    $limit: Int
    $nextToken: [String]
    $from: Int
  ) {
    searchCoverages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        location
        startAt
        endAt
        description
        memo
        attachmentFile
        createdUserId
        ownedAccountId
        ownedDepartment
        assignedUserId
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchSharings = /* GraphQL */ `
  query SearchSharings(
    $filter: SearchableSharingFilterInput
    $sort: SearchableSharingSortInput
    $limit: Int
    $nextToken: [String]
    $from: Int
  ) {
    searchSharings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        description
        memo
        limitDate
        limitTime
        sharingUsers
        sharingFiles
        createdUserId
        ownedAccountId
        ownedDepartment
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchHistorys = /* GraphQL */ `
  query SearchHistorys(
    $filter: SearchableHistoryFilterInput
    $sort: SearchableHistorySortInput
    $limit: Int
    $nextToken: [String]
    $from: Int
  ) {
    searchHistorys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        data
        body
        operation
        userId
        accountId
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($input: ListUserInput) {
    listUsers(input: $input) {
      Items {
        id
        name
        enabled
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($input: GetUserInput) {
    getUser(input: $input) {
      id
      name
      email
      accountId
      department
    }
  }
`;
export const getCognitoUser = /* GraphQL */ `
  query GetCognitoUser($input: GetCognitoUserInput) {
    getCognitoUser(input: $input) {
      id
      name
      email
      accountId
      department
      role
      deleted
      status
      enabled
    }
  }
`;
export const exportFilesCSV = /* GraphQL */ `
  query ExportFilesCSV($input: ExportFilesCSVInput) {
    exportFilesCSV(input: $input) {
      signedUrl
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      company
      person
      domain
      logoUri
      config
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $id: ID
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccounts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        company
        person
        domain
        logoUri
        config
        deleted
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFileGroup = /* GraphQL */ `
  query GetFileGroup($id: ID!) {
    getFileGroup(id: $id) {
      id
      coverFileId
      title
      description
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      file {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listFileGroups = /* GraphQL */ `
  query ListFileGroups(
    $id: ID
    $filter: ModelFileGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFileGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        coverFileId
        title
        description
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        file {
          id
          groupId
          rating
          title
          caption
          instructions
          memo
          fileName
          protect
          original
          caution
          cautionInstructions
          reuse
          reuseCondition
          keywords
          extension
          type
          meta
          tag
          customItems
          fileUri
          fileSize
          coverageId
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          group {
            id
            coverFileId
            title
            description
            memo
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            file {
              id
              groupId
              rating
              title
              caption
              instructions
              memo
              fileName
              protect
              original
              caution
              cautionInstructions
              reuse
              reuseCondition
              keywords
              extension
              type
              meta
              tag
              customItems
              fileUri
              fileSize
              coverageId
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
          coverage {
            id
            title
            location
            startAt
            endAt
            description
            memo
            attachmentFile
            createdUserId
            ownedAccountId
            ownedDepartment
            assignedUserId
            status
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      groupId
      rating
      title
      caption
      instructions
      memo
      fileName
      protect
      original
      caution
      cautionInstructions
      reuse
      reuseCondition
      keywords
      extension
      type
      meta
      tag
      customItems
      fileUri
      fileSize
      coverageId
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      group {
        id
        coverFileId
        title
        description
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        file {
          id
          groupId
          rating
          title
          caption
          instructions
          memo
          fileName
          protect
          original
          caution
          cautionInstructions
          reuse
          reuseCondition
          keywords
          extension
          type
          meta
          tag
          customItems
          fileUri
          fileSize
          coverageId
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          group {
            id
            coverFileId
            title
            description
            memo
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            file {
              id
              groupId
              rating
              title
              caption
              instructions
              memo
              fileName
              protect
              original
              caution
              cautionInstructions
              reuse
              reuseCondition
              keywords
              extension
              type
              meta
              tag
              customItems
              fileUri
              fileSize
              coverageId
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
          coverage {
            id
            title
            location
            startAt
            endAt
            description
            memo
            attachmentFile
            createdUserId
            ownedAccountId
            ownedDepartment
            assignedUserId
            status
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      }
      coverage {
        id
        title
        location
        startAt
        endAt
        description
        memo
        attachmentFile
        createdUserId
        ownedAccountId
        ownedDepartment
        assignedUserId
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $id: ID
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSharing = /* GraphQL */ `
  query GetSharing($id: ID!) {
    getSharing(id: $id) {
      id
      title
      description
      memo
      limitDate
      limitTime
      sharingUsers
      sharingFiles
      createdUserId
      ownedAccountId
      ownedDepartment
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSharings = /* GraphQL */ `
  query ListSharings(
    $id: ID
    $filter: ModelSharingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSharings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        memo
        limitDate
        limitTime
        sharingUsers
        sharingFiles
        createdUserId
        ownedAccountId
        ownedDepartment
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoverage = /* GraphQL */ `
  query GetCoverage($id: ID!) {
    getCoverage(id: $id) {
      id
      title
      location
      startAt
      endAt
      description
      memo
      attachmentFile
      createdUserId
      ownedAccountId
      ownedDepartment
      assignedUserId
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCoverages = /* GraphQL */ `
  query ListCoverages(
    $id: ID
    $filter: ModelCoverageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCoverages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        location
        startAt
        endAt
        description
        memo
        attachmentFile
        createdUserId
        ownedAccountId
        ownedDepartment
        assignedUserId
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoverageInfo = /* GraphQL */ `
  query GetCoverageInfo($id: ID!) {
    getCoverageInfo(id: $id) {
      id
      startDate
      endDate
      body
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCoverageInfos = /* GraphQL */ `
  query ListCoverageInfos(
    $id: ID
    $filter: ModelCoverageInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCoverageInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        startDate
        endDate
        body
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const coverageInfosByOwnedAccountId = /* GraphQL */ `
  query CoverageInfosByOwnedAccountId(
    $ownedAccountId: ID
    $startDateEndDate: ModelCoverageInfoByOwnedAccountIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoverageInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coverageInfosByOwnedAccountId(
      ownedAccountId: $ownedAccountId
      startDateEndDate: $startDateEndDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        body
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoverageDesk = /* GraphQL */ `
  query GetCoverageDesk($id: ID!) {
    getCoverageDesk(id: $id) {
      id
      startDate
      endDate
      name
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCoverageDesks = /* GraphQL */ `
  query ListCoverageDesks(
    $id: ID
    $filter: ModelCoverageDeskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCoverageDesks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        startDate
        endDate
        name
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const coverageDesksByOwnedAccountId = /* GraphQL */ `
  query CoverageDesksByOwnedAccountId(
    $ownedAccountId: ID
    $startDateEndDate: ModelCoverageDeskByOwnedAccountIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoverageDeskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coverageDesksByOwnedAccountId(
      ownedAccountId: $ownedAccountId
      startDateEndDate: $startDateEndDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        name
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      data
      body
      operation
      userId
      accountId
      createdAt
      updatedAt
    }
  }
`;
export const listHistorys = /* GraphQL */ `
  query ListHistorys(
    $id: ID
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHistorys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        data
        body
        operation
        userId
        accountId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCharge = /* GraphQL */ `
  query GetCharge($id: ID!, $accountId: ID!) {
    getCharge(id: $id, accountId: $accountId) {
      id
      accountId
      aggregationDate
      status
      basicCharge
      s3Charge
      excessTransferredCharge
      s3StdUsageSize
      s3IaUsageSize
      s3GlIrUsageSize
      s3GlUsageSize
      transferredSize
      downloadRequestCont
      excludingTax
      includingTax
      taxRate
      exchangeRate
      createdAt
      updatedAt
    }
  }
`;
export const listCharges = /* GraphQL */ `
  query ListCharges(
    $id: ID
    $accountId: ModelIDKeyConditionInput
    $filter: ModelChargeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCharges(
      id: $id
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        aggregationDate
        status
        basicCharge
        s3Charge
        excessTransferredCharge
        s3StdUsageSize
        s3IaUsageSize
        s3GlIrUsageSize
        s3GlUsageSize
        transferredSize
        downloadRequestCont
        excludingTax
        includingTax
        taxRate
        exchangeRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chargeByAggregationDate = /* GraphQL */ `
  query ChargeByAggregationDate(
    $aggregationDate: AWSDate
    $status: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chargeByAggregationDate(
      aggregationDate: $aggregationDate
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        aggregationDate
        status
        basicCharge
        s3Charge
        excessTransferredCharge
        s3StdUsageSize
        s3IaUsageSize
        s3GlIrUsageSize
        s3GlUsageSize
        transferredSize
        downloadRequestCont
        excludingTax
        includingTax
        taxRate
        exchangeRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chargeByAccountId = /* GraphQL */ `
  query ChargeByAccountId(
    $accountId: ID
    $aggregationDateStatus: ModelChargeByAccountIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chargeByAccountId(
      accountId: $accountId
      aggregationDateStatus: $aggregationDateStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        aggregationDate
        status
        basicCharge
        s3Charge
        excessTransferredCharge
        s3StdUsageSize
        s3IaUsageSize
        s3GlIrUsageSize
        s3GlUsageSize
        transferredSize
        downloadRequestCont
        excludingTax
        includingTax
        taxRate
        exchangeRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFileDownloadHistory = /* GraphQL */ `
  query GetFileDownloadHistory($id: ID!, $accountId: ID!) {
    getFileDownloadHistory(id: $id, accountId: $accountId) {
      id
      accountId
      fileId
      fileSize
      storageClass
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listFileDownloadHistorys = /* GraphQL */ `
  query ListFileDownloadHistorys(
    $id: ID
    $accountId: ModelIDKeyConditionInput
    $filter: ModelFileDownloadHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFileDownloadHistorys(
      id: $id
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        fileId
        fileSize
        storageClass
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const fileDownloadHistoryByCreatedAt = /* GraphQL */ `
  query FileDownloadHistoryByCreatedAt(
    $createdAt: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelFileDownloadHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileDownloadHistoryByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        fileId
        fileSize
        storageClass
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlacierDownloadRequest = /* GraphQL */ `
  query GetGlacierDownloadRequest($id: ID!, $accountId: ID!) {
    getGlacierDownloadRequest(id: $id, accountId: $accountId) {
      id
      accountId
      fileId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listGlacierDownloadRequests = /* GraphQL */ `
  query ListGlacierDownloadRequests(
    $id: ID
    $accountId: ModelIDKeyConditionInput
    $filter: ModelGlacierDownloadRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGlacierDownloadRequests(
      id: $id
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        fileId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const glacierDownloadRequestByCreatedAt = /* GraphQL */ `
  query GlacierDownloadRequestByCreatedAt(
    $createdAt: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelGlacierDownloadRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    glacierDownloadRequestByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        fileId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
