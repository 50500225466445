






import { Component, Vue } from "vue-property-decorator";
import MyPage from "@/components/MyPage.vue"; // @ is an alias to /src

@Component({
  name: "my_page",
  components: {
    MyPage,
  },
})
export default class Mypage extends Vue {}
