






























import { Component, Vue } from 'vue-property-decorator';
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import * as queries from "@/graphql/queries";
import { SearchSharingsQueryVariables, SearchableSharingFilterInput, SearchableSortDirection, SearchableSharingSortInput, SearchableSharingSortableFields } from "@/API";
import ShareCard from "@/components/share/ShareCard.vue";
import ShareHeader from "@/components/share/ShareHeader.vue";
import FileSearchPagination from "@/components/files/searches/FileSearchPagination.vue";
import { Sharing, SearchSharingsQuery } from "@/API";
import { isAllow } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";
import { scrollTop } from "@/utils/FileUtils";
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";
import { FileSearchService } from '@/services/files/FileSearch';

@Component({
  components: {
    ShareHeader,
    ShareCard,
    FileSearchPagination,
    Loading,
    Dialog,
  }
})
export default class ShareList extends Vue {
  private mode = 'share';
  private select = false;
  private selectedSharings: Sharing[] = [];

  private total = 0;
  private shraings: (Sharing | null)[] = [];
  private limit = 36;
  private attributes;
  
  private page = 1;
  private length = 0;
  private routerName = "ShareList";

  private allowedRoles = ["SELF", "DEPARTMENT", "COMPANY"];

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = '';
  private resultDialogTitle = '';
  private resultDialogMessage = '';

  private get from(): number {
    return ((this.page - 1 ) > 0 ? (this.page - 1 ) : 0) * this.limit;
  }

  private get to(): number {
    if(this.from + this.limit >= FileSearchService.SEARCH_LIMIT) {
      return FileSearchService.SEARCH_LIMIT - this.from;
    }
    return this.limit;
  }

  private async created(){
    const user = this.$store.getters.user;
    this.attributes = user?.attributes;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter: any = {};
    if(isAllow("sharing", "read", "COMPANY") && await isActiveServie("sharing")){
      filter.ownedAccountId = user?.attributes["custom:account_id"];
    }else if(isAllow("sharing", "read", "DEPARTMENT") && await isActiveServie("sharing")){
      filter.ownedDepartment = user?.attributes["custom:department"];
    }else if(isAllow("sharing", "read", "SELF") && await isActiveServie("sharing")){
      filter.createdUserId = user?.attributes.sub;
    }

    await this.search();  
  }

  private async search(){
    if(!isAllow("sharing", "manage") || isAllow("sharing", "manage", "DENY") || !isActiveServie("sharing")){
      this.$router.push({name:'Home'});
    }
    if(this.attributes){
      
      const filter: SearchableSharingFilterInput = {
        deleted: {
          eq: 0 
        },
        ownedAccountId: {
          eq: this.attributes["custom:account_id"]
        },
      };

      if(isAllow("sharing", "read", "COMPANY")){
        filter.ownedAccountId = { eq: this.attributes["custom:account_id"] };
      }else if(isAllow("sharing", "read", "DEPARTMENT")){
        filter.ownedDepartment = { eq: this.attributes["custom:department"] };
      }else if(isAllow("sharing", "read", "SELF")){
        filter.createdUserId = { eq: this.attributes.sub };
      }

      const sort: SearchableSharingSortInput = {
        field: SearchableSharingSortableFields.createdAt,
        direction: SearchableSortDirection.desc,
      }
      
      const variables: SearchSharingsQueryVariables = {
        filter: filter,
        sort: sort,
        from: this.from,
        limit: this.to,
      };

      try{
        const { data } = (await API.graphql(graphqlOperation(queries.searchSharings, variables))) as GraphQLResult<SearchSharingsQuery>;
        this.shraings = data?.searchSharings?.items || [];
        this.total = data?.searchSharings?.total || 0;
        this.length = data?.searchSharings?.items?.length || 0;
      }catch(error){
        this.shraings = [];
        this.total = 0;
        this.length = 0;
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "取得失敗";
        this.resultDialogMessage = `データの取得に失敗しました。\n再度、お試しください。`;
      }finally{
        this.loading = false;
      }
    }
  }
  private async onChangePage(value){
    this.page = value;
    this.loading = true;
    scrollTop();
    await this.search();
  }
  
  private onChangeResultDialogClosed(){
    this.resultDialog = false;
  }

  private async onChangeShareCardResult(value){
    if(value){
      this.loading = true;
      setTimeout(() => {
        this.search();
      }, 3000);
    }
  }

  private async onReload(){
    this.loading = true;
    await this.search();
  }
}
