




import { Component, Vue } from 'vue-property-decorator';
import SignIn from '@/components/SignIn.vue'; // @ is an alias to /src

@Component({
  name: "home",
  components: {
     SignIn,
   },
})
export default class Home extends Vue {}
