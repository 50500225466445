import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components'
import store from '../store'
import * as AuthUtils from '../utils/AuthUtils'
import * as AccountSettingUtils from '../utils/AccountSettingUtils'
import Home from './home'
import MyPage from './myPage'
import Coverage from './coverage'
import Files from './files'
import User from './user'
import Admin from './admin'
import * as RoleUtils from "@/utils/RoleUtils";
import Auth from '@aws-amplify/auth'


Vue.use(VueRouter)

/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
let user: any;

const routes: Array<RouteConfig> = [
  ...Home,
  ...MyPage,
  ...Coverage,
  ...Files,
  ...User,
  ...Admin
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// ログイン状態管理
onAuthUIStateChange(async (authState, authData) => {
  if (authState === AuthState.SignedOut) {
    store.commit('setUser', null);
    router.push({ path: '/' });
  } else if (authState === AuthState.SignedIn) {
    user = authData ? authData : await AuthUtils.getUser();
    const userAccountId = AuthUtils.getAccountId(user);
    await AccountSettingUtils.getSetting();
    if (!user || !userAccountId) {
      store.commit('setUser', null);
      router.push({ path: '/' });
    }
    //イベントが2重に走る際の対策IF文
    if (!router.currentRoute.matched.some(record => record.meta.requiresAuth)) {
      if (store.state.redirectPath.length > 1) {
        const path: string = store.state.redirectPath;
        store.commit('setRedirectPath', "");
        router.push({ path: path }).catch(() => { null })
      } else {
        router.push({ path: '/' + userAccountId + '/my_page/' }).catch(() => { null })
      }
    }
  }
});

router.beforeResolve(async (to, from, next) => {
  store.commit("setFrom", from);
  store.commit("setTo", to);

  const urlAccountId = typeof to.params?.accountId === typeof undefined ? "" : to.params?.accountId;
  //認証必須ページ
  if (to.matched.some(record => record.meta.requiresAuth)) {
    user = await AuthUtils.getUser();
    if (!user) {
      //ログインしなおした場合に備えてURLを保存しておく
      store.commit('setRedirectPath', to.fullPath);
      // localstorageにjwtが残っているため強制削除
      await Auth.signOut();
      return next({
        path: "/" + urlAccountId,
        query: { redirect: to.fullPath }
      });
    }

    // currentUserは、localstorageを見ているため、セッションも有効か確認する
    try {
      await Auth.currentSession();
    } catch (error) {
      console.error(error);
      // localstorageにjwtが残っているため強制削除
      await Auth.signOut();
      //ログインしなおした場合に備えてURLを保存しておく
      store.commit('setRedirectPath', to.fullPath);
      return next({
        path: "/" + urlAccountId,
        query: { redirect: to.fullPath }
      });
    }
  
    const userAccountId = AuthUtils.getAccountId(user);
    if (!urlAccountId || !AuthUtils.verifyAccountId(user, urlAccountId)) {
      return next({
        path: '/' + userAccountId + to.fullPath.replace("/" + urlAccountId + "/", "/"),
        query: to.query,
        params: to.params
      });
    }

    //契約内容でアクセスできる範囲が変わるため、ルーティングを制御する
    const isFiling = to.matched.some(record => record.meta.requiresFiling);
    const isCoverage = to.matched.some(record => record.meta.requiresCoverage);
    const isSharing = to.matched.some(record => record.meta.requiresSharing);
    if((isFiling && !await AccountSettingUtils.isActiveServie("filing")) 
    || (isCoverage && !await AccountSettingUtils.isActiveServie("coverage")) 
    || (isSharing && !await AccountSettingUtils.isActiveServie("sharing"))){
      return next({
        path: '/' + userAccountId + "/my_page",
        query: to.query,
        params: to.params
      });
    }

    // 権限によってルーティングを制御する
    const role = RoleUtils.decodeRoleJson(user?.attributes["custom:role"] || undefined);
    if(!role){
      return next({
        path: '/' + userAccountId + "/my_page",
        query: to.query,
        params: to.params
      });
    }

    const isFilingCreate = to.matched.some(record => record.meta.requiresFilingCreate);
    const isFilingOperate = to.matched.some(record => record.meta.requiresFilingOperate);
    const isFilingSearch = to.matched.some(record => record.meta.requiresFilingSearch);
    const isCoverageManage = to.matched.some(record => record.meta.requiresCoverageManage);
    const isCoverageRead = to.matched.some(record => record.meta.requiresCoverageRead);
    const isSharingCreate = to.matched.some(record => record.meta.requiresSharingCreate);
    const isSharingManage = to.matched.some(record => record.meta.requiresSharingManage);
    const isManagementAccess = to.matched.some(record => record.meta.requiresManagementAccess);
    
    if((isFilingCreate && !RoleUtils.isAllow("filing","create")) 
    || (isFilingOperate && !RoleUtils.isAllow("filing", "operate", role.filing?.operate)) 
    || (isFilingSearch && !RoleUtils.isAllow("filing", "search", role.filing?.search)) 
    || (isCoverageManage && !RoleUtils.isAllow("coverage", "manage")) 
    || (isCoverageRead && !RoleUtils.isAllow("coverage", "read", role.coverage?.read)) 
    || (isSharingCreate && !RoleUtils.isAllow("sharing", "create")) 
    || (isSharingManage && !RoleUtils.isAllow("sharing", "manage", role.sharing?.manage))
    || (isManagementAccess && !RoleUtils.isAllow("management", "access"))){
      return next({
        path: '/' + userAccountId + "/my_page",
        query: to.query,
        params: to.params
      });
    }

    return next();
  }
  
  //Homeでログインしている場合
  if (to.name === 'Home' || to.name === 'RootHome') {
    user = await AuthUtils.getUser();
    if (user) {
      const userAccountId = AuthUtils.getAccountId(user);
      return next({
        path: '/' + userAccountId + "/my_page",
        query: to.query,
        params: to.params
      });
    }
    return next();
  }
  return next();
});


export default router
