
















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ShareMenu from '@/components/share/ShareMenu.vue';
import moment from "moment";
import { SHARE_STATUS } from '@/utils/ShareUtils';

@Component({
  components:{
    ShareMenu,
  }
})
export default class FileGroupCard extends Vue {

  @Prop()
  private sharing;
  @Prop()
  private select;

  private attributes;

  private sheet = false;
  private show = false;
  private lines = 2;

  private id = '';
  private title = ''
  private description = ''
  private limitDateTime = '';
  private sharingUsersCount = 0;
  private status = -1;
  private statusMessage = '共有中';
  private statusColor = "success";

  private async created(){
    await this.setSharing();
  }

  @Watch('sharing')
  private async onSharingChanged(){
    await this.setSharing();
  }

  private async setSharing(){

    if(this.$store.getters.user && this.$store.getters.user.attributes){
      this.attributes = this.$store.getters.user.attributes;
    }

    this.id = this.sharing.id;
    this.title = this.sharing.title ? this.sharing.title : '';
    this.description = this.sharing.description ? this.sharing.description : '';
    this.limitDateTime = this.sharing.limitDate && this.sharing.limitTime ? moment(`${this.sharing.limitDate} ${this.sharing.limitTime}`, 'YYYY-MM-DD HH:mm:ssZ').local().format('YYYY-MM-DD HH:mm:ss') : '不明';
    this.sharingUsersCount = this.sharing.sharingUsers ? this.sharing.sharingUsers.length : 0;
    this.status = this.sharing.status;

    if(moment(this.limitDateTime).local().valueOf() < moment().local().valueOf()){
      this.status = SHARE_STATUS.shared;
      this.statusMessage = '期限切れ';
      this.statusColor = 'default';
    }else if(this.status === SHARE_STATUS.pending){
      this.statusMessage = '停止中';
      this.statusColor = 'warning';
    }else if(this.status === SHARE_STATUS.sharing){
      this.statusMessage = '共有中';
      this.statusColor = 'success';
    }else{
      this.statusMessage = '不明';
      this.statusColor = 'error';
    }
  }
  
  private onChangeShareMenuSheet(value){
    this.sheet = value;
  }

  private onChangeShareMenuResult(value){
    if(value){
      this.$emit('share-card-result', value);
    }
  }
}
