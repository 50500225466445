
import { Component, Vue, Watch } from "vue-property-decorator";
import * as PageAction from "@/utils/PageActionUtils";
import * as queries from "@/graphql/queries";
import * as service from "@/services/coverage/coverageServise";
import { Storage } from "aws-amplify";
import { API } from "aws-amplify";
import moment from "moment";
import { Coverage, GetCoverageQuery } from "@/API";
import {
  DocumentExtension,
  ImageExtension,
} from "@/utils/FileUtils";
import { GraphQLResult } from "@aws-amplify/api";

@Component
export default class CoverageBaseForm extends Vue {
  name = "coverage-base-form";
  loading = false;
  valid = false;
  PageAction = PageAction;
  CoverageServise = new service.CoverageServise();

  id = "";
  title = "";
  description = "";
  memo = "";
  assignedUserId = "";
  startDate = new Date().toISOString().substr(0, 10);
  startDateMenu = false;
  startTime = "";
  endDate = new Date().toISOString().substr(0, 10);
  endDateMenu = false;
  endTime = "";
  location = "";
  // eslint-disable-next-line
  files: any = new Array<object>();
  selectUsers = new Array<object>();
  status = 0;
  deleted = 0;
  // eslint-disable-next-line
  coverage: any = {};

  // 入力ルール
  titleCount = 64
  titleRules = [(v) => !!v || 'タイトルは必須項目です', (v) => v && v.length <= this.titleCount || `タイトルは${this.titleCount}文字以下です`];
  dateCount = 10
  startDateRules = [(v) => !!v || '開始日は必須項目です', (v) => v && v.length === this.dateCount];
  endDateRules = [(v) => !!v || '終了日は必須項目です', (v) => v && v.length === this.dateCount];
  timeCount = 5
  startTimeRules = [(v) => !!v || '開始時間は必須項目です', (v) => v && v.length === this.timeCount];
  endTimeRules = [(v) => !!v || '終了時間は必須項目です', (v) => v && v.length === this.timeCount];
  descriptionCount = 1000
  descriptionRules = [(v) => v !== undefined && v.length <= this.descriptionCount || `説明は${this.descriptionCount}文字以下です`];
  locationCount = 64
  locationRules = [(v) => v !== undefined && v.length <= this.locationCount || `取材場所は${this.locationCount}文字以下です`];
  idRules = [];
  fileRules = [this.fileRulesMethod];
  isExtraInvalid = false;
  coverageDatetimeErrorMessages: string[] = [];

  private fileRulesMethod(files){
    for(const file of files){
      if(file && file.size <= 0){
        return "0バイトのファイルが含まれています"
      }
      if(file && file.name && !this.accept.find(x => x === `.${file.name.split(".").pop().toLowerCase()}`)){
        return "アップロード対象外のファイルが含まれています"
      }
    }
    return true;
  }

  protected dateTimeCompare(){
    if(moment(`${this.startDate} ${this.startTime}`, 'YYYY-MM-DD HH:mm').isSameOrBefore(moment(`${this.endDate} ${this.endTime}`, 'YYYY-MM-DD HH:mm'))){
      return true;
    }
    return '開始日時 <= 終了日時です';
  }

  @Watch("startDate")
  @Watch("startTime")
  @Watch("endDate")
  @Watch("endTime")
  private validateCoverageDatetime(){
    if(moment(`${this.startDate} ${this.startTime}`, 'YYYY-MM-DD HH:mm').isSameOrBefore(moment(`${this.endDate} ${this.endTime}`, 'YYYY-MM-DD HH:mm'))){
      this.isExtraInvalid = false;
      this.coverageDatetimeErrorMessages = [];
      return;
    }
    this.isExtraInvalid = true;
    this.coverageDatetimeErrorMessages.push('開始日時 <= 終了日時です');
  }

  protected accept = DocumentExtension.getAllDocumentExtensions().concat(
    ImageExtension.jpeg,
    ImageExtension.png,
    ImageExtension.gif,
    ImageExtension.bmp
  );

  //ダイアログコントロール
  protected resultDialog = false;
  protected resultDialogMode = "";
  protected resultDialogTitle = "";
  protected resultDialogMessage = "";

  protected confirmDialog = false;
  protected confirmMode = "";
  protected confirmTitle = "";
  protected confirmMessage = "";

  protected setDefault() {
    this.title = "";
    this.description = "";
    this.memo = "";
    this.assignedUserId = "";
    this.startDate = new Date().toISOString().substr(0, 10);
    this.startDateMenu = false;
    this.startTime = "";
    this.endDate = new Date().toISOString().substr(0, 10);
    this.endDateMenu = false;
    this.endTime = "";
    this.location = "";
    this.files = new Array<File>();
    this.status = 0;
    this.deleted = 0;
  }

  protected async setCoverage() {
    const { data } = (await API.graphql({
      query: queries.getCoverage,
      variables: { id: await this.id },
    })) as GraphQLResult<GetCoverageQuery>;
    this.title = data?.getCoverage?.title || "";
    this.description = data?.getCoverage?.description || "";
    this.memo = data?.getCoverage?.memo || "";
    this.assignedUserId = data?.getCoverage?.assignedUserId || "";
    this.startDate = data?.getCoverage?.startAt ? this.getDate(data.getCoverage.startAt) : new Date().toISOString().substr(0, 10);
    this.startTime = data?.getCoverage?.startAt ? this.getTime(data.getCoverage.startAt) : "";
    this.endDate = data?.getCoverage?.endAt ? this.getDate(data.getCoverage.endAt) : new Date().toISOString().substr(0, 10);
    this.endTime = data?.getCoverage?.endAt ? this.getTime(data.getCoverage.endAt) : "";
    this.location = data?.getCoverage?.location || "";
    this.files = data?.getCoverage?.attachmentFile ? JSON.parse(data.getCoverage.attachmentFile) : [];
    this.status = data?.getCoverage?.status || 0;
    this.deleted = data?.getCoverage?.deleted || 0;
    this.coverage = data?.getCoverage as Coverage || {};
  }

  protected getDate(at: string) {
    return moment(at).format("YYYY-MM-DD");
  }

  protected getTime(at: string) {
    return moment(at).format("HH:mm");
  }

  protected dateformat(date: string, time: string) {
    return moment(date + " " + time).toISOString();
  }

  protected getFile(path: string, filename: string) {
    Storage.get(path, { download: true })
      .then((result) => {
        this.doDouwnlod(result, filename);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  protected doDouwnlod(s3Result, filename) {
    const url = URL.createObjectURL(this.getBody(s3Result));
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    a.remove();
  }

  private getBody(s3Result) {
    return s3Result.Body;
  }

  protected onChangeResultDialogClosed() {
    this.resultDialog = false;
  }
}
