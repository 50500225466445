
























































































import { Component, Vue, Prop } from "vue-property-decorator";
import FileConfirm from "@/components/Confirm.vue";
import FileMoveDialog from "@/components/files/FileMoveDialog.vue";
import { FileDeleteService } from "@/services/files/FileDelete";
import {FILE_POST_PROCESS, FILE_GROUP_POST_PROCESS} from "@/utils/FileUtils";
import FileMultipleEditDialog from "@/components/files/edit/FileMultipleEditDialog.vue";
import FileGroupMultipleEditDialog from "@/components/files/edit/FileGroupMultipleEditDialog.vue";
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import { selesSendFiles } from "@/services/files/FileSale";
import { getAccountId } from "@/utils/AuthUtils";
import { downloadUri } from "@/services/files/FileDownload";
import { FileEditService } from "@/services/files/FileEdit";
import { FileData } from "@/services/files/FileSearch";

@Component({
  components:{
    FileConfirm,
    FileMoveDialog,
    FileMultipleEditDialog,
    FileGroupMultipleEditDialog,
    Dialog,
    Loading,
  }
})
export default class FileSearchHeader extends Vue {
  private fileEditService = new FileEditService();
  private fileDeleteService = new FileDeleteService();
  private select = false;

  @Prop({ default: [] })
  private selectedItems;
  @Prop({ default: 1 })
  private page!: number;
  @Prop({ default: 0 })
  private limit!: number;
  @Prop({ default: 0 })
  private length!: number;
  @Prop({ default: 0 })
  private total!: number;
  @Prop()
  private backRoute;
  @Prop({ default: 'file' })
  private mode!: string;
  @Prop({default: 'ファイル検索結果'})
  private title!: string;

  private fileConfirmDialog = false;
  private confirmMode = '';
  private confirmTitle = '';
  private confirmMessage = '';
  private postProcess = '';

  private fileMoveDialog = false;
  private fileEditDialog = false;
  private fileGroupEditDialog = false;

  private isOperate = false;
  private isDownload = false;
  private isShareManage = false;
  private isShareCreate = false;

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private created(){
    this.setDefalut();
  }

  private mounted(){
    this.setDefalut();
  }

  private async setDefalut(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.isOperate = isAllow("filing", "operate", role.filing?.operate) && await isActiveServie("filing");
    this.isDownload = isAllow("filing", "download") && await isActiveServie("filing");
    this.isShareManage = isAllow("sharing", "manage", role.sharing?.manage) && await isActiveServie("sharing");
    this.isShareCreate = isAllow("sharing", "create") && await isActiveServie("sharing");
  }

  private get searchFrom(): number {
    return ((this.page - 1 ) > 0 ? (this.page - 1 ) : 0) * this.limit;
  }
  
  private get searchHitFrom(): number {
    return this.length === 0 ? 0 : this.searchFrom + 1;
  }

  private get searchHitTo(): number {
    return this.length === 0 ? 0 : this.length + this.searchFrom ;
  }

  private onClickReload(){
    this.$emit("reload", true);
  }

  private onClickSelectMode(){
    this.select = !this.select;
    this.$emit("select-mode", this.select);
  }

  private onClickShowShareInfoDialog(){
    this.$emit("show-share-info-dialog", true);
  }

  private onClickEdit(){
    if(this.selectedItems.length <= 0){
      return;
    }
    if(this.mode === 'file'){
      this.fileEditDialog = true;
    }else if(this.mode === 'group'){
      this.fileGroupEditDialog = true;
    }
  }

  private onChangeFileEditDialog(value){
    this.onClickSelectMode();
    this.fileEditDialog = false;
    this.$emit('file-search-header-result', value);
  }

  private onFileMultipleEditDialog(value){
    this.fileEditDialog = value;
  }

  private onChangeFileGroupEditDialog(value){
    this.onClickSelectMode();
    this.fileGroupEditDialog = false;
    this.$emit('file-search-header-result', value);
  }

  private onFileGroupMultipleEditDialog(value){
    this.fileGroupEditDialog = value;
  }

  private onClickShare(){
    if(this.selectedItems.length <= 0){
      return;
    }
    if(this.mode === "file"){
      this.$router.push({name:'ShareCreate', params:{ files: this.selectedItems }});
    }else if(this.mode === "group"){
      this.$router.push({name:'ShareCreate', params:{ groups: this.selectedItems }});
    }
  }

  private onClickMove(){
    if(this.selectedItems.length <= 0){
      return;
    }
    this.fileMoveDialog = true;
  }

  private onChangeFileMoveDialog(value){
    this.onClickSelectMode();
    this.fileMoveDialog = false;
    this.$emit('file-search-header-result', value);
  }

  private onFileMoveDialog(value){
    this.fileMoveDialog = value;
  }

  private onClickDelete(){
    if(this.selectedItems.length <= 0){
      return;
    }

    if(this.mode === 'file'){
      this.postProcess = FILE_POST_PROCESS.Delete;
      this.confirmMessage = '選択されたファイルを全て削除してもよろしいでしょうか？\n※プロテクトされている場合は削除されません。'
    }else if(this.mode === 'group'){
      this.postProcess = FILE_GROUP_POST_PROCESS.Delete;    
      this.confirmMessage = '選択されたアルバムを全て削除してもよろしいでしょうか？\n※プロテクトされている場合は削除されません。'
    }

    this.confirmTitle = '削除確認';
    this.confirmMode = 'warning';
    this.fileConfirmDialog = true;
  }

  
  private onClickSale(){
    if(this.selectedItems.length <= 0){
      return;
    }

    if(this.mode === 'file'){
      this.postProcess = FILE_POST_PROCESS.Sale;
      this.confirmMessage = '選択されたファイルを全て外販送信してもよろしいでしょうか？\n※販売を不可に設定している場合は送信されません。'
    }else if(this.mode === 'group'){
      this.postProcess = FILE_GROUP_POST_PROCESS.Sale;    
      this.confirmMessage = '選択されたアルバムを全て削除してもよろしいでしょうか？\n※販売を不可に設定している場合は送信されません。'
    }

    this.confirmTitle = '外販送信確認';
    this.confirmMode = 'warning';
    this.fileConfirmDialog = true;
  }

  private back(){
    if(this.backRoute){ 
      if(this.mode === "share" && !this.isShareManage ){
        this.$router.push({name: "Home"});
      }else{
        this.$router.push({name: this.backRoute});
      }
    }else{
      this.$router.go(-1);
    }
  }

  private async confirmed(value){
    this.fileConfirmDialog = false;
    if(value){
      this.onClickSelectMode();
      switch(this.postProcess){
        case FILE_POST_PROCESS.Delete: {
          try{
            this.loading = true;
            await this.fileDeleteService.deleteFiles(this.selectedItems);
            this.loading = false;
            this.resultDialog = true;
            this.resultDialogMode = "info";
            this.resultDialogTitle = "削除成功";
            this.resultDialogMessage = "ファイルの削除に成功しました。";
          }catch(error){
            this.loading = false;
            this.resultDialog = true;
            this.resultDialogMode = "info";
            this.resultDialogTitle = "削除失敗";
            this.resultDialogMessage = "ファイルの削除に失敗しました。";
          }finally{
            this.loading = false;
          }
          break;
        }
        case FILE_GROUP_POST_PROCESS.Delete: {
          try{
            this.loading = true;
            await this.fileDeleteService.deleteFileGroups(this.selectedItems);
            this.loading = false;
            this.resultDialog = true;
            this.resultDialogMode = "info";
            this.resultDialogTitle = "削除成功";
            this.resultDialogMessage = "アルバムの削除に成功しました。";
          }catch(error){
            this.loading = false;
            this.resultDialog = true;
            this.resultDialogMode = "info";
            this.resultDialogTitle = "削除失敗";
            this.resultDialogMessage = "アルバムの削除に失敗しました。"
          }finally{
            this.loading = false;
          }
          break;
        }
        case FILE_POST_PROCESS.Sale: {
          try{
            this.loading = true;
            await selesSendFiles(this.selectedItems);
            this.loading = false;
            this.resultDialog = true;
            this.resultDialogMode = "info";
            this.resultDialogTitle = "送信成功";
            this.resultDialogMessage = "ファイルの外販送信に成功しました。";
          }catch(error){
            this.loading = false;
            this.resultDialog = true;
            this.resultDialogMode = "info";
            this.resultDialogTitle = "送信失敗";
            this.resultDialogMessage = "ファイルの外販送信に失敗しました。";
          }finally{
            this.loading = false;
          }
          break;
        }
        case FILE_GROUP_POST_PROCESS.Sale: {
          try{
            this.loading = true;
            await selesSendFiles(this.selectedItems);
            this.loading = false;
            this.resultDialog = true;
            this.resultDialogMode = "info";
            this.resultDialogTitle = "削除成功";
            this.resultDialogMessage = "ファイルの外販送信に成功しました。";
          }catch(error){
            this.loading = false;
            this.resultDialog = true;
            this.resultDialogMode = "info";
            this.resultDialogTitle = "削除失敗";
            this.resultDialogMessage = "ファイルの外販送信に失敗しました。"
          }finally{
            this.loading = false;
          }
          break;
        }
      }
    }
  }

  private onChangeResultDialogClosed(){
    this.resultDialog = false;
    this.$emit('file-search-header-result', true);
  }

  private async onClickFilesCSVExport(){
    if(this.mode !== 'file' || this.selectedItems.length <= 0){
      return;
    }

    const user = this.$store.getters.user;
    const accountId = getAccountId(user);
    const files = this.selectedItems as Array<FileData>;
    const fileIds: string[] = [];
    for(const file of files){
      if(file && file.id){
        fileIds.push(file.id);
      }
    }

    try{
      this.onClickSelectMode();
      this.loading = true;
      const signedUrl = await this.fileEditService.exportFilesCSVFromFiles(accountId, fileIds);
      downloadUri(signedUrl);
    }catch(error){
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "エクスポート失敗";
      this.resultDialogMessage = `CSVファイルのエクスポートに失敗しました。`;
    }finally{
      this.loading = false;
    }
  }
}
