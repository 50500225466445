
























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import { ChargeByAccountIdQuery, ChargeByAccountIdQueryVariables } from "@/API";
import moment from "moment";
import { GraphQLResult } from "@aws-amplify/api";

@Component
export default class PriceMain extends Vue {
    private user;
    private accountId = "";

    private headers = [
        { text: "項目", value: "name", align: "left", sortable: false},
        { text: "利用データ量", value: "size", align: "right"},
        { text: "金額", value: "charge", align: "right"},
    ];

    private tableLoading = false;
    private items: ChargeShow[] = [];
    private month = moment(new Date()).local().format('YYYY-MM');
    private tax = 0;
    private rate = 0;
    private totalCharge = 0;
    private lastUpdatedAt = '';

    @Prop({default:false})
    private show;

    @Watch("show")
    private async onChangeShow(newValue){
        if(newValue){
            this.month = moment(new Date()).local().format('YYYY-MM');
            await this.search();
        }
    }

    private async created(){
        this.user = this.$store.getters.user;
        this.accountId = this.user && this.user.attributes ? this.user.attributes["custom:account_id"] : "";
    }

    private async search() {
      this.totalCharge = 0;
      this.tax = 0;
      this.rate = 0;
      this.tableLoading = true;
      this.items = [];
      if (!this.accountId) {
        return;
      }

      const variables: ChargeByAccountIdQueryVariables = {
        accountId: this.accountId,
        aggregationDateStatus: {
          between: [
            {
              aggregationDate: moment(this.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
              status: 1
            },
            {
              aggregationDate: moment(this.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD'),
              status: 1
            },
          ]
        }
      }

      try {
        const { data } = (await API.graphql(graphqlOperation(queries.chargeByAccountId, variables))) as GraphQLResult<ChargeByAccountIdQuery>;
        if (data && data.chargeByAccountId && data.chargeByAccountId.items && data.chargeByAccountId.items.length > 0) {
          const charges = data.chargeByAccountId.items.sort((a, b) => {
              if(a && b){
                if(a.aggregationDate > b.aggregationDate) return -1;
                if(a.aggregationDate < b.aggregationDate) return 1;
                return 0;
              }
              return 0;
          });
          const charge = charges[0];
          this.lastUpdatedAt = moment(charge?.updatedAt).local().format('YYYY-MM-DD HH:mm:ss') || '';

          if(charge?.basicCharge){
              this.items.push({name: '基本料金', size: '', charge: charge.basicCharge});
          }
          if(charge?.s3Charge && charge.s3StdUsageSize && charge.s3IaUsageSize && charge.s3GlUsageSize){
              const size = parseInt(charge.s3StdUsageSize, 10) + parseInt(charge.s3IaUsageSize, 10) + parseInt(charge.s3GlUsageSize, 10);
              this.items.push({name: 'ストレージ利用料金', size: size, charge: charge.s3Charge});
          }
          if(charge?.excessTransferredCharge && charge.transferredSize){
              this.items.push({name: 'ダウンロード料金', size: parseInt(charge.transferredSize, 10), charge: charge.excessTransferredCharge});
          }
          if(charge?.exchangeRate){
              this.rate = charge.exchangeRate;
          }
          if(charge?.includingTax){
              this.totalCharge = charge.includingTax;
              if(charge.excludingTax){
                  this.tax = charge.includingTax - charge.excludingTax;
              }
          }
        }
      } catch (error) {
        console.error(error);
        this.items = [];
      } finally {
        this.tableLoading = false;
      }
  }

  private async onPrevious(){
      this.month = moment(this.month, 'YYYY-MM').subtract(1, 'month').local().format('YYYY-MM');
      await this.search();
  }

  private async onNext(){
      this.month = moment(this.month, 'YYYY-MM').add(1, 'month').local().format('YYYY-MM');
      await this.search();
  }
}

export type ChargeShow = {
  name: string;
  size: number | string;
  charge: number;
};
