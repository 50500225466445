

















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ScheduleCard from "@/components/home/ScheduleCard.vue";
import moment from "moment";
import * as subscriptions from "@/graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";
import { CoverageSerch, SearchForm, CoverageListData } from "@/services/coverage/coverageSerch";
import Loading from "@/components/Loading.vue";
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";
import Observable from 'zen-observable-ts';
import { OnCreateCoverageSubscription, OnUpdateCoverageSubscription } from "@/API";

@Component({
  components:{
    ScheduleCard,
    Loading,
  }
})
export default class Schedule extends Vue {
  @Prop({ default: moment(new Date()).local().format('YYYY-MM-DD') })
  private date;
  private user;
  private coverages: Array<CoverageListData> = [];
  private coverageSearch = new CoverageSerch();
  private searchForm: SearchForm = {
    accountId: "",
    dayStart: "",
    dayEnd: "",
    searchWord: "",
    assignedUserId: "",
  };
  private subscriptionCreate;
  private subscriptionUpdate;

  private loading = false;
  private isRead = false;

  private async created(){
    await this.setDefalut();
    await this.search();
    if(this.isRead){
      this.subscriptionCreate = (API.graphql(
        graphqlOperation(subscriptions.onCreateCoverage, {
          ownedAccountId: this.user?.attributes["custom:account_id"],
          assignedUserId: this.user?.attributes.sub,
          ownedDepartment: this.user?.attributes["custom:department"],
        })
      ) as Observable<OnCreateCoverageSubscription>).subscribe({
        next: () =>
          setTimeout(() => {
            this.search();
          }, 3000),
        error: (error) => console.warn(error),
      });
      
      this.subscriptionUpdate = (API.graphql(
        graphqlOperation(subscriptions.onUpdateCoverage, {
          ownedAccountId: this.user?.attributes["custom:account_id"],
          assignedUserId: this.user?.attributes.sub,
          createdUserId: this.user?.attributes.sub,
          ownedDepartment: this.user?.attributes["custom:department"],
        })
      ) as Observable<OnUpdateCoverageSubscription>).subscribe({
        next: () =>
          setTimeout(() => {
            this.search();
          }, 3000),
        error: (error) => console.warn(error),
      });
    }
  }

  private async setDefalut(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.isRead = isAllow("coverage", "read", role.coverage?.read) && await isActiveServie("coverage");
  }

  @Watch('date')
  private async onChangeDate(){
    await this.search();
  }

  private async search(){
    try{
      if(!this.isRead){
        return;
      }
      this.loading = true;
      this.user = this.$store.getters.user;
      this.searchForm.accountId = this.user?.attributes["custom:account_id"];
      this.searchForm.assignedUserId = this.user?.attributes?.sub,
      this.searchForm.dayStart = this.date ? moment(this.date, "YYYY-MM-DD").startOf("day").toISOString() : "";
      this.searchForm.dayEnd = this.date ?moment(this.date, 'YYYY-MM-DD').endOf("day").toISOString() : moment(this.date, 'YYYY-MM-DD').endOf("day").toISOString();
      this.coverages = await this.coverageSearch.search(this.searchForm);
    }catch(error){
      console.error(error);
      this.coverages = [];
    }finally{
      this.loading = false;
    }
  }

  private destroyed(){
    if(this.subscriptionCreate){
      this.subscriptionCreate.unsubscribe();
    }
    if(this.subscriptionUpdate){
      this.subscriptionUpdate.unsubscribe();
    }
  }
}
