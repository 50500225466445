
















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class CoverFileSelectDialog extends Vue {
  private innerDialog = false;
  private innerSelectedCoverFile = {};
  private viewFiles = new Array<object>();
  private innerFiles = new Array<object>();

  private total = 0;
  private page = 1;
  private limit = 30;
  
  private get from(): number {
    return ((this.page - 1 ) > 0 ? (this.page - 1 ) : 0) * this.limit;
  }

  private get lastPage(): number {
    return Math.ceil( this.total / this.limit );
  }

  @Prop({ default: false })
  private dialog;
  
  @Prop({ default: () => (new Array<object>()) })
  private files;

  @Prop({ default: () => ({}) })
  private selectedCoverFile;

  @Watch('dialog')
  private onChangeCoverFileDialog(newValue){
    this.innerDialog = newValue;
    this.total = this.innerFiles.length;
    this.viewFiles.splice(0);
    this.viewFiles = this.getShowFiles();
    this.innerSelectedCoverFile = this.getSelectedCoverFile();
  }

  @Watch("innerDialog")
  private onChangeInnerDialog() {
    this.$emit("cover-file-select-dialog", this.innerDialog);
  }

  @Watch('files')
  private async onChangeFiles(newValue){
    if(this.files.length <= 0){
      this.page = 1;
    }
    this.innerFiles = newValue;
  }

  @Watch('selectedCoverFile')
  private async onSelectedCoverFile(){
    this.innerSelectedCoverFile = this.getSelectedCoverFile();
  }

  @Watch('page')
  private async onChangePage(){
    this.viewFiles = this.getShowFiles();
    this.innerSelectedCoverFile = this.getSelectedCoverFile();
  }

  private getShowFiles(){
    this.viewFiles.splice(0);
    return this.innerFiles.slice((this.page - 1) * this.limit,  (this.page * this.limit));
  }

  private getSelectedCoverFile(){
    const selectedCoverFile = this.viewFiles.find(x => x === this.selectedCoverFile);
    return selectedCoverFile ? selectedCoverFile : {};
  }

  private onClickCloseDialog(){
    this.innerDialog = false;
  }

  private onClickSelectCoverFile(){
    this.$emit('cover-file-selected', this.innerSelectedCoverFile);
    this.onClickCloseDialog();
  }
}
