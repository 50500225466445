













































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { FileSearchService, SearchCondition, FileData, FileGroupData } from "@/services/files/FileSearch";
import { FileEditService } from "@/services/files/FileEdit";
import FileGroupListItem from "@/components/files/FileGroupListItem.vue";
import SearchBar from "@/components/files/SearchBar.vue";
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";

@Component({
  components: {
    FileGroupListItem,
    SearchBar,
    Loading,
    Dialog,
  }
})
export default class FileMoveDialog extends Vue {
  @Prop({ default: false })
  private fileMoveDialog;

  @Prop({ default: () => (new Array<FileData>()) })
  private moveFiles;

  @Prop({ default: () => (new Array<FileGroupData>()) })
  private moveGroups;

  private dialog = false;
  private selectedGroup: FileGroupData | undefined = {};
  private pendingGroup: FileGroupData | undefined = {};
  private groups: FileGroupData[] = [];
  private files: FileData[] = [];

  private condition: SearchCondition = {};

  private total = 0;
  private length = 0;
  private page = 1;
  private limit = 30;
  private readonly FILE_MAX_COUNT = 10000;

  private fileEditService = new FileEditService();
  private fileSearchService = new FileSearchService();
  private loading = false;
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";
  private isSearchLimit = false;

  private get from(): number {
    return ((this.page - 1 ) > 0 ? (this.page - 1 ) : 0) * this.limit;
  }

  private get to(): number {
    if(this.from + this.limit >= FileSearchService.SEARCH_LIMIT) {
      return FileSearchService.SEARCH_LIMIT - this.from;
    }
    return this.limit;
  }

  private get lastPage(): number {
    let innerTotal = this.total;
    if(innerTotal > FileSearchService.SEARCH_LIMIT){
      innerTotal = FileSearchService.SEARCH_LIMIT
    }
    return Math.ceil( innerTotal / this.limit );
  }

  private created(){
    this.condition = {};
  }

  @Watch('fileMoveDialog')
  private async onChangeFileMoveDialog(newValue){
    this.dialog = newValue;
    if(newValue){
      this.condition = {freeword: ""};
      await this.search();
    }
  }

  @Watch('page')
  private async onChangePage(){
    if(this.selectedGroup){
      this.pendingGroup = this.selectedGroup;
    }
    await this.search();
  }

  @Watch('moveFiles')
  private onChangeMoveFiles(newMoveFiles){
    this.files = newMoveFiles;
  }

  @Watch('dialog')
  private onChangeDialog(){
    this.$emit("file-move-dialog", this.dialog);
  }

  private onClickClosed(){
    this.pendingGroup = undefined;
    this.selectedGroup = undefined;
    this.resultDialog = false;
    this.files = [];
    this.groups = [];
    this.dialog = false;
    this.$emit('file-move-dialog-closed', false);
  }

  private async getFileCountInGroup(): Promise<number> {
    let count = 0;
    if(!this.selectedGroup || !this.selectedGroup.id) {
      return this.FILE_MAX_COUNT;
    }

    const condition: SearchCondition = { groupIds: [this.selectedGroup.id] };
    const result = await this.fileSearchService.searchFiles(condition, undefined, 0, 1);
    if(result && result.total){
      count = result.total || this.FILE_MAX_COUNT;
    }

    return count;
  }

  private async getFileCount(): Promise<number> {
    if(!this.moveGroups || this.moveGroups.length <= 0){
      return this.files ? this.files.length : this.FILE_MAX_COUNT;
    }

    const groupIds = new Array<string>();
    for(const group of this.moveGroups){
      if(group && group.id){
        groupIds.push(group.id);
      }
    }

    let count = 0;
    const condition: SearchCondition = { groupIds: groupIds };
    const result = await this.fileSearchService.searchFiles(condition, undefined, 0, 1);
    if(result && result.total){
      count = result.total || this.FILE_MAX_COUNT;
    }

    return count;
  }

  private async onClickSelected(){
    try{
      const sourceCount = await this.getFileCount();
      const destCount = await this.getFileCountInGroup();
      if(sourceCount + destCount > this.FILE_MAX_COUNT){
        this.resultDialog = true;
        this.resultDialogMode = "warning";
        this.resultDialogTitle = "移動中止";
        this.resultDialogMessage = `1アルバムにつき最大${this.FILE_MAX_COUNT}件までです。`;
        return;
      }

      this.loading = true;
      if(this.moveGroups && this.moveGroups.length > 0){
        await this.getFiles();
      }
      if(this.files && this.files.length > 0 && this.selectedGroup && this.selectedGroup.id){
        await this.fileEditService.moveFiles(this.selectedGroup.id, this.files);
      }
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "移動成功";
      this.resultDialogMessage = "ファイルの移動に成功しました。\n元の画面に戻ります。"
    }catch(error){
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "移動失敗";
      this.resultDialogMessage = `ファイルの移動に失敗しました。\n元の画面に戻ります。`;
    }finally{
      this.loading = false;
    }
  }

  private onChangeResultDialogClosed(){
    if(this.resultDialog && this.resultDialogMode === "warning"){
      return;
    }

    this.pendingGroup = undefined;
    this.selectedGroup = undefined;
    this.resultDialog = false;
    this.files = [];
    this.groups = [];
    this.dialog = false;
    this.$emit('file-move-dialog-closed', true);
  }

  private async onChangeSearchBar(condition){
    this.condition = condition;
    this.page = 1;
    await this.search();
  }

  private async search(){
    try{
      this.isSearchLimit = false;
      this.loading = true;
      this.groups = [];
      const result = await this.fileSearchService.searchFileGroups(this.condition, undefined, this.from, this.to);

      if(result && result.items){
        this.groups = result.items || [];
        this.total = result.total || 0;
        this.length = result.length || 0;
        this.selectedGroup = this.groups.find(group => group.id === this.pendingGroup?.id);
      }
    }catch(error){
      this.groups = [];
      this.total = 0;
      this.length = 0;
    }finally{
      this.loading = false;
      this.isSearchLimit = this.total > FileSearchService.SEARCH_LIMIT && (this.from + this.limit >= FileSearchService.SEARCH_LIMIT) ? true : false;
    }
  }

  private async getFiles(){
    if(this.moveGroups && this.moveGroups.length > 0){
      const groupIds = new Array<string>();
      for(const group of this.moveGroups){
        if(group && group.id){
          groupIds.push(group.id);
        }
      }
      const condition: SearchCondition = {
        groupIds: groupIds,
      };

      this.files = await this.fileSearchService.searchFileDataFullList(condition);
    }
  }
}
