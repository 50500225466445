









































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import ScheduleMenu from "@/components/home/ScheduleMenu.vue";
import CoverageStatus from "@/components/coverage/CoverageStatus.vue";
import CoverageView from "@/components/coverage/CoverageView.vue";
import CoverageStatusDialog from '@/components/home/CoverageStatusDialog.vue';
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";

@Component({
  components: {
    ScheduleMenu,
    CoverageStatus,
    CoverageView,
    CoverageStatusDialog,
  }
})
export default class ScheduleCard extends Vue {
  @Prop()
  private coverage;

  private id = '';
  private title = '';
  private startAt = '';
  private endAt = '';
  private location = '';
  private status = 0;

  private sheet = false;
  private statusDialog = false;
  private viewDialog = false;
  private isOperate = false;

  @Watch('coverage')
  private onChangeCoverage(newValue){
    this.setCoverage(newValue);
  }

  private created(){
    this.setDefault();
    this.setCoverage(this.coverage);
  }
  private mounted(){
    this.setDefault();
  }

  private async setDefault(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.isOperate = isAllow("coverage", "operate", role.coverage?.operate) && await isActiveServie("coverage");
  }

  private setCoverage(covearge){
    this.id = covearge.id ? covearge.id : '';
    this.title = covearge.title ? covearge.title : '';
    this.location = covearge.location ? covearge.location : '';
    this.status = covearge.status ? covearge.status : 0;
    this.startAt = covearge.startAt ? moment(covearge.startAt, 'YYYY-MM-DD HH:mm:ss.SSSZ').local().format('MM-DD HH:mm') : '';
    this.endAt = covearge.endAt ? moment(covearge.endAt, 'YYYY-MM-DD HH:mm:ss.SSSZ').local().format('MM-DD HH:mm') : '';
  }

  private onChangeScheduleMenuSheet(value){
    this.sheet = value;
  }

  private onClickShowView(){
    if(!this.id) return;
    this.$store.commit("selectCoverage", this.id);
    this.viewDialog = true;
  }

  private closeView() {
    this.viewDialog = false;
  }

  private onClickChangeStatus(){
    if(!this.isOperate) return;
    this.statusDialog = true;
  }

  private onStatusDialogClosed(){
    this.statusDialog = false;
  }
}
