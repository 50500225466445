











































































































import { Component, Vue } from "vue-property-decorator";
import * as service from "@/services/coverage/coverageDeskService";
import CoverageDeskBaseForm from "./CoverageDeskBaseForm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    Dialog,
    Loading,
  },
})
export default class CoverageDeskCreateForm extends CoverageDeskBaseForm {
  name = "coverage-desk-create-form";

  async createCoverageDesk() {
    if (!this.validate()) {
      return;
    }
    const attributes = this.$store.state.user.attributes;
    const form: service.InputForm = {
      createdUserId: attributes.sub,
      ownedAccountId: attributes["custom:account_id"],
      ownedDepartment: attributes["custom:department"],
      name: this.deskName,
      startDate: this.startDate,
      endDate: this.endDate,
      memo: this.memo,
    };
    this.loading = true;
    await this.CoverageDeskService.createCoverageDesk(form)
      .then(() => {
        this.setDefault();
        (this.$refs.form as Vue & {
          resetValidation: () => void;
        }).resetValidation();
        this.close();
      })
      .catch((e) => {
        console.log(e);
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "デスク情報登録失敗";
        this.resultDialogMessage = `デスク情報登録に失敗しました。`;
      })
      .finally(() => (this.loading = false));
  }

  private close() {
    this.$emit("createClose");
  }
}
