





































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FileListItem from "@/components/files/FileListItem.vue";
import { FileSearchService, SearchCondition, FileData } from "@/services/files/FileSearch";
import { FileEditService } from "@/services/files/FileEdit";
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";

@Component({
  components:{
    FileListItem,
    Loading,
    Dialog,
  }
})
export default class CoverFileChangeDialog extends Vue {
  @Prop({ default: false })
  private coverFileChangeDialog;

  @Prop({ default: '' })
  private groupId;

  private dialog = false;
  private selectedCoverFile: FileData | undefined = {};
  private pendingCoverFile: FileData | undefined = {};
  private files: FileData[] = [];

  private total = 0;
  private length = 0;
  private page = 1;
  private limit = 30;

  private fileEditService = new FileEditService();
  private fileSearchService = new FileSearchService();
  private loading = false;
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private get from(): number {
    return ((this.page - 1 ) > 0 ? (this.page - 1 ) : 0) * this.limit;
  }

  private get to(): number {
    if(this.from + this.limit >= FileSearchService.SEARCH_LIMIT) {
      return FileSearchService.SEARCH_LIMIT - this.from;
    }
    return this.limit;
  }

  private get lastPage(): number {
    let innerTotal = this.total;
    if(innerTotal > FileSearchService.SEARCH_LIMIT){
      innerTotal = FileSearchService.SEARCH_LIMIT
    }
    return Math.ceil( innerTotal / this.limit );
  }

  @Watch('coverFileChangeDialog')
  private async onChangeCoverFileChangeDialog(newValue){
    this.dialog = newValue;
    if(newValue){
      this.selectedCoverFile = undefined;
      this.files = [];
      await this.search();
    }
  }

  @Watch('page')
  private async onChangePage(){
    if(this.selectedCoverFile){
      this.pendingCoverFile = this.selectedCoverFile;
    }
    await this.search();
  }

  @Watch('dialog')
  private onChangeDialog(){
    this.$emit("cover-file-change-dialog", this.dialog);
  }

  private onClickClosed(){
    this.dialog = false;
    this.resultDialog = false;
    this.$emit('cover-file-change-dialog-closed', false);
  }

  private async onClickSelected(){
    try{
      if(this.groupId){
        this.loading = true;
        if(this.selectedCoverFile && this.selectedCoverFile.id){
          await this.fileEditService.changeCoverFile(this.groupId, this.selectedCoverFile.id);
        }else{
          await this.fileEditService.changeCoverFile(this.groupId);
        }
        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "info";
        this.resultDialogTitle = "変更成功";
        this.resultDialogMessage = "表紙ファイルの変更に成功しました。\n元の画面に戻ります。"
      }
    }catch(error){
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "変更失敗";
      this.resultDialogMessage = "表紙ファイルの変更に失敗しました。\n元の画面に戻ります。"
    }finally{
      this.loading = false;
    }
  }

  private onChangeResultDialogClosed(){
    this.dialog = false;
    this.resultDialog = false;
    this.$emit('cover-file-change-dialog-closed', true);
  }

  private async search(){
    const searchCondition: SearchCondition = {
      groupIds: [this.groupId],
    };

    try{
      this.loading = true;
      const result = await this.fileSearchService.searchFiles(searchCondition, undefined, this.from, this.to);
      if(result && result.items){
        this.files = result.items || [];
        this.total = result.total || 0;
        this.length = result.length || 0;
        this.selectedCoverFile = this.files.find(file => file.id === this.pendingCoverFile?.id);
      }
    }catch(error){
      this.loading = false;
      this.files = [];
      this.total = 0;
      this.length = 0;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "取得失敗";
      this.resultDialogMessage = "データの取得に失敗しました。\n元の画面に戻ります。"
    }finally{
      this.loading = false;
    }
  }
}
