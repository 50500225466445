




























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FileGroupMenu from '@/components/files/FileGroupMenu.vue';
import { Storage } from "aws-amplify";
import moment from "moment";
import { getFileType, getIcon } from '@/utils/FileUtils';

@Component({
  components:{
    FileGroupMenu,
  }
})
export default class FileGroupCard extends Vue {

  @Prop()
  private group;
  @Prop()
  private select;

  private attributes;

  private sheet = false;
  private show = false;
  private lines = 2;

  private id = '';
  private coverFileId = '';
  private src = '';
  private title = ''
  private description = '';
  private memo = '';
  private createdAt = '';
  private createdUserName ='';
  private fileType = '';

  private async created(){
    await this.setGroup();
  }

  @Watch('group')
  private async onGroupChanged(){
    await this.setGroup();
  }

  private async setGroup(){
    if(this.$store.getters.user && this.$store.getters.user.attributes){
      this.attributes = this.$store.getters.user.attributes;
    }

    this.coverFileId = this.group.coverFileId ? this.group.coverFileId : '';
    this.title = this.group.title ? this.group.title : '';
    this.description = this.group.description ? this.group.description : '';
    this.memo = this.group.memo ? this.group.memo : '';
    this.createdAt = this.group.createdAt ? moment(this.group.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSZ').local().format('YYYY-MM-DD HH:mm:ss') : '不明';
    // TODO:登録者の名前変換を行う
    this.createdUserName = this.group.createdUserName ? this.group.createdUserName : '';
    
    this.src = "";
    if(this.coverFileId && this.group.file && this.group.file.deleted === 0){
      const meta = this.group.file && this.group.file.meta ? JSON.parse(this.group.file.meta) : undefined;
      const thumbnailUri = meta && meta.thumbnailUri ? meta.thumbnailUri.split('/') : [];
      this.fileType = this.group.file.type && this.group.file.extension ? getFileType(this.group.file.extension, this.group.file.type) : '';

      if(thumbnailUri.length > 0){
        this.src = (await Storage.get(`${thumbnailUri[4]}/${thumbnailUri[5]}/${thumbnailUri[6]}/${thumbnailUri[7]}`, {download: false, expires: 300, contentType: 'image/jpeg'})).toString();
      }else{
        this.src = getIcon(this.fileType);
      }
    }
  }
  
  private onChangeGroupMenuSheet(value){
    this.sheet = value;
  }

  private onChangeGroupMenuResult(value){
    if(value){
      this.$emit('group-card-result', value);
    }
  }

  private folderImageHeight(){
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 90;
      case 'sm': return 90;
      case 'md': return 120;
      case 'lg': return 120;
      case 'xl': return 120;
    }
  }
}
