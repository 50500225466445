










































import { Component } from "vue-property-decorator";
import CoverageInfoBaseForm from "./CoverageInfoBaseForm.vue";

@Component
export default class CoverageInfoEditForm extends CoverageInfoBaseForm {
  name = "coverage-info-edit-form";

  async created() {
    this.$store.subscribe(async (mutation) => {
      if (mutation.type === "selectCoverageInfo") {
        this.selectItem = this.$store.state.selectCoverageInfoId;
        this.setCoverageInfo(this.selectItem);
      }
    });
  }

  async mounted() {
    this.selectItem = this.$store.state.selectCoverageInfoId;
    await this.setCoverageInfo(this.selectItem);
  }

  private viewClose(){
    this.$emit("viewClose");
  }
}
