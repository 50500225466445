



















import { Component, Vue } from "vue-property-decorator";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "@aws-amplify/auth";
import Home from "@/components/home/Home.vue";
import * as AuthUtils from "@/utils/AuthUtils";

@Component({
  components: {
    Home,
  },
})
export default class MyPage extends Vue {
  signedIn = false;
  userInfo = null;
  mfaPreference = "";
  async created() {
    //ログイン状態をセット
    await this.setUser();
    //ログイン状態の変更を検知させる
    onAuthUIStateChange(async () => {
      await this.setUser();
    });
  }
  beforeDestroy() {
    return onAuthUIStateChange;
  }
  //ログイン状態をセット
  async setUser() {
    try {
      this.userInfo = await AuthUtils.getUser(true);
      this.signedIn = true;
      await Auth.getPreferredMFA(this.userInfo, {
        bypassCache: true,
      }).then((data) => {
        this.mfaPreference = data;
      });
    } catch (err) {
      this.signedIn = false;
    }
  }
}
