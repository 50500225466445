










































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

@Component
export default class AudioInfo extends Vue {
  @Prop()
  private file;

  private duration = '';
  private fileFormat = '';
  private samplingRate = '';
  private channels = '';
  private audioBitRateMaximum = '';
  private audioBitDepth = '';
  private audioCodec = '';

  private created(){
    this.setFile();
  }

  @Watch('file')
  private onFileChanged(){
    this.setFile();
  }

  private setFile(){
    if(this.file){
      const meta = this.file.meta ? JSON.parse(this.file.meta) : undefined;
      this.duration = meta && meta.audioDuration ? meta.audioDuration : '';
      this.fileFormat = meta && meta.fileFormat ? meta.fileFormat : '';
      this.samplingRate = meta && meta.samplingRate ? `${parseInt(meta.samplingRate, 10)/1000}kHz` : '';
      this.channels = meta && meta.channels ? `${meta.channels}ch` : '';
      this.audioBitRateMaximum = meta && meta.audioBitRate ? `${parseInt(meta.audioBitRate, 10)/1000}kbps` : '';
      this.audioBitDepth = meta && meta.audioBitDepth ? `${meta.audioBitDepth}bit` : '';
      this.audioCodec = meta && meta.audioFormat ? meta.audioFormat : '';
    }
  }
}

