
























































































































































































































































































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FileMultipleEditForm from "@/components/files/edit/FileMultipleEditForm.vue";
import { FileData } from "@/services/files/FileSearch";
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";

@Component({
  components:{
    Dialog,
    Loading,
  }
})
export default class FileMultipleEditDialog extends FileMultipleEditForm {
  name = "file-multiple-edit-dialog";

  private dialog = false;

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = '';
  private resultDialogTitle = '';
  private resultDialogMessage = '';

  @Prop({ default: () => (new Array<FileData>()) })
  private editFiles;
  @Prop({ default: false})
  private fileMultipleEditDialog = false;

  @Watch('fileMultipleEditDialog')
  private async onChangeFileMultipleEditDialog(newValue){
    this.dialog = newValue;
    if(newValue){
      const fileIds = new Array<string>();
      for(const file of this.editFiles){
        fileIds.push(file.id);
      }
      this.setFiles(fileIds);
    }
  }

  @Watch('dialog')
  private onChangeDialog(){
    this.$emit("file-multiple-edit-dialog", this.dialog);
  }

  private created(){
    this.setDefaulMultiple();
  }

  private onClickClose(){
    this.dialog = false;
    this.resultDialog = false;
    this.setDefaulMultiple();
    this.$emit('file-multiple-edit-dialog-closed', false);
  }

  private async onClickUpdate(){
    if(!(this.$refs.fileMultipleEditForm as Vue & {validate: () => boolean}).validate()){
      return;
    }

    if(!this.customValidate()){
      return;
    }

    try{
      this.loading = true;
      await this.multipleUpdate();
      this.loading = false;

      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "編集成功";
      this.resultDialogMessage = "メタ情報の一括編集に成功しました。\n元の画面に戻ります。"
    }catch(err){
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "編集失敗";
      this.resultDialogMessage = `メタ情報の一括編集に失敗しました。\n元の画面に戻ります。`;
    }finally{
      this.loading = false;
    }
  }

  private onChangeResultDialogClosed(){
    this.dialog = false;
    this.resultDialog = false;
    this.setDefaulMultiple();
    this.$emit('file-multiple-edit-dialog-closed', true);
  }

  private onUpdateSearchInputSelectedKeywords(value){
    this.keywordRulesMethod(value);
  }
}
