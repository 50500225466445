















































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { SearchCondition, FILE_TYPE } from "@/services/files/FileSearch";
import * as AuthUtils from "@/utils/AuthUtils";
import { PGCService } from "@/API";

@Component
export default class FileSearchForm extends Vue {
  private selectedFileItem = { text: "全て", value: "all" };
  private fileItems = [
    { text: "全て", value: "all" },
    { text: "画像", value: "image" },
    { text: "動画", value: "video" },
    { text: "音声", value: "audio" },
    { text: "ドキュメント", value: "document" },
  ];
  private title = "";
  private shootingDateFrom = "";
  private shootingDateFromMenu = false;
  private shootingDateTo = "";
  private shootingDateToMenu = false;
  private isUnknownShootingDate = false;
  private city = "";
  private byline = "";
  private registrationDateFrom = "";
  private registrationDateFromMenu = false;
  private registrationDateTo = "";
  private registrationDateToMenu = false;
  private registrant = "";
  private users = new Array<object>();
  private coverageId = "";
  private fileId = "";
  private downLoadRequest = false;
  private innerCondition: SearchCondition = {};

  private publishedStatus = -1;
  private publishedDateFromMenu = false;
  private publishedDateFrom = "";
  private publishedDateToMenu = false;
  private publishedDateTo = "";
  private publishedMedia = "";
  private publishedPage = "";
  private originalEdition = { text: '全て', value: -1 };
  private storageLocation = "";

  private originalEditionItems = [
    { text: '全て', value: -1 },
    { text: 'デジタル', value: 0 },
    { text: 'カラーネガ', value: 1 },
    { text: 'モノクロネガ', value: 2 },
    { text: 'カラーポジ', value: 3 },
    { text: 'モノクロポジ', value: 4 },
    { text: '紙焼き', value: 5 },
    { text: 'その他', value: 99 },
  ];

  @Prop({ default: () => ({}) })
  private condition;

  @Watch("condition")
  private async onChangeCondition() {
    await this.getCondition();
  }

  @Watch("title")
  @Watch("selectedFileItem")
  @Watch("shootingDateFrom")
  @Watch("shootingDateTo")
  @Watch("isUnknownShootingDate")
  @Watch("city")
  @Watch("byline")
  @Watch("publishedStatus")
  @Watch("publishedDateFrom")
  @Watch("publishedDateTo")
  @Watch("publishedMedia")
  @Watch("publishedPage")
  @Watch("originalEdition")
  @Watch("storageLocation")
  @Watch("registrationDateFrom")
  @Watch("registrationDateTo")
  @Watch("registrant")
  @Watch("fileId")
  @Watch("downLoadRequest")
  private onChangeSearchWords() {
    this.setCondition();
  }

  private async created() {
    if (this.condition) {
      await this.getCondition();
      this.innerCondition = this.condition;
    } else {
      this.innerCondition = {};
    }
  }

  private clear() {
    this.selectedFileItem = { text: "全て", value: "all" };
    this.title = "";
    this.shootingDateFrom = "";
    this.shootingDateTo = "";
    this.isUnknownShootingDate = false;
    this.city = "";
    this.byline = "";
    this.registrationDateFrom = "";
    this.registrationDateTo = "";
    this.registrant = "";
    this.coverageId = "";
    this.fileId = "";
    this.downLoadRequest = false;
    this.publishedStatus = -1;
    this.publishedDateFrom = "";
    this.publishedDateTo = "";
    this.publishedMedia = "";
    this.publishedPage = "";
    this.originalEdition = { text: '全て', value: -1 };
    this.storageLocation = "";
    this.$emit("file-search-clear", true);
    this.setCondition();
  }

  private searchEnter(event) {
    if (event.keyCode !== 13) return;
    this.search();
  }

  private search() {
    this.$emit("file-search", this.innerCondition);
  }

  private async getCondition() {
    switch (this.condition.fileType) {
      case FILE_TYPE.Image: {
        this.selectedFileItem = this.fileItems[1];
        break;
      }
      case FILE_TYPE.Video: {
        this.selectedFileItem = this.fileItems[2];
        break;
      }
      case FILE_TYPE.Audio: {
        this.selectedFileItem = this.fileItems[3];
        break;
      }
      case FILE_TYPE.Document: {
        this.selectedFileItem = this.fileItems[4];
        break;
      }
      case FILE_TYPE.Adobe: {
        this.selectedFileItem = this.fileItems[5];
        break;
      }
      default: {
        this.selectedFileItem = this.fileItems[0];
      }
    }

    this.title = this.condition.title || "";
    this.shootingDateFrom = this.condition.shootingDateFrom || "";
    this.shootingDateTo = this.condition.shootingDateTo || "";    
    this.isUnknownShootingDate = this.condition.isUnknownShootingDate || false;
    this.city = this.condition.city || "";
    this.byline = this.condition.byline || "";
    this.publishedStatus = this.condition.publishedStatus !== null ||  this.condition.publishedStatus !== undefined ? this.condition.publishedStatus : -1;
    this.publishedDateFrom = this.condition.publishedDateFrom || "";
    this.publishedDateTo = this.condition.publishedDateTo || "";
    this.publishedMedia = this.condition.publishedMedia || "";
    this.publishedPage = this.condition.publishedPage || "";
    const orgEdition = this.originalEditionItems.find(x => x.value === this.condition.originalEdition)
    this.originalEdition = orgEdition || { text: '全て', value: -1 };
    this.storageLocation = this.condition.storageLocation;
    this.registrationDateFrom = this.condition.registrationDateFrom || "";
    this.registrationDateTo = this.condition.registrationDateTo || "";
    this.registrant = this.condition.registrant || "";
    this.users = await AuthUtils.listUsers(PGCService.FILING, "未選択");
    this.coverageId = "";
    this.fileId = this.condition.fileId || "";
    this.downLoadRequest = this.condition.downLoadRequest || false;
  }

  private setCondition() {
    switch (this.selectedFileItem.value) {
      case FILE_TYPE.Image: {
        this.innerCondition.fileType = FILE_TYPE.Image;
        break;
      }
      case FILE_TYPE.Video: {
        this.innerCondition.fileType = FILE_TYPE.Video;
        break;
      }
      case FILE_TYPE.Audio: {
        this.innerCondition.fileType = FILE_TYPE.Audio;
        break;
      }
      case FILE_TYPE.Document: {
        this.innerCondition.fileType = FILE_TYPE.Document;
        break;
      }
      case FILE_TYPE.Adobe: {
        this.innerCondition.fileType = FILE_TYPE.Adobe;
        break;
      }
      default: {
        this.innerCondition.fileType = FILE_TYPE.All;
      }
    }

    this.innerCondition.title = this.title;
    this.innerCondition.shootingDateFrom = this.shootingDateFrom;
    this.innerCondition.shootingDateTo = this.shootingDateTo;
    this.innerCondition.isUnknownShootingDate = this.isUnknownShootingDate;
    this.innerCondition.city = this.city;
    this.innerCondition.byline = this.byline;
    this.innerCondition.publishedStatus = this.publishedStatus;
    this.innerCondition.publishedDateFrom = this.publishedStatus !== -1 ? this.publishedDateFrom : "";
    this.innerCondition.publishedDateTo = this.publishedStatus !== -1 ? this.publishedDateTo : "";
    this.innerCondition.publishedMedia = this.publishedStatus !== -1 ? this.publishedMedia : "";
    this.innerCondition.publishedPage = this.publishedStatus !== -1 ? this.publishedPage : "";
    this.innerCondition.originalEdition = this.originalEdition.value;
    this.innerCondition.storageLocation = this.storageLocation;
    this.innerCondition.registrationDateFrom = this.registrationDateFrom;
    this.innerCondition.registrationDateTo = this.registrationDateTo;
    this.innerCondition.registrant = this.registrant;
    this.innerCondition.coverageId = this.coverageId;
    this.innerCondition.fileId = this.fileId;
    this.innerCondition.downLoadRequest = this.downLoadRequest;

    this.$emit("file-search-condition", this.innerCondition);
  }
}
