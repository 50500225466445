













































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FileGroupListItem from "@/components/files/FileGroupListItem.vue";
import FileListItem from "@/components/files/FileListItem.vue";
import SearchBar from "@/components/files/SearchBar.vue";
import { FileSearchService, SearchCondition, FileData } from "@/services/files/FileSearch";
import Loading from "@/components/Loading.vue";

@Component({
  components:{
    SearchBar,
    FileListItem,
    FileGroupListItem,
    Loading,
  }
})
export default class ShareAddDialog extends Vue {
  @Prop({ default: false })
  private shareAddDialog;

  private dialog = false;
  private loading = false;
  private selectFiles: FileData[] = [];
  private pendingFiles: FileData[] = [];
  private files: FileData[] = [];
  private selectedFiles: FileData[] = [];

  private condition: SearchCondition = {};
  private total = 0;
  private length = 0;
  private page = 1;
  private limit = 30;
  private isSearchLimit = false;

  private fileSearchService = new FileSearchService();

  get from(): number {
    return ((this.page - 1 ) > 0 ? (this.page - 1 ) : 0) * this.limit;
  }

  private get to(): number {
    if(this.from + this.limit >= FileSearchService.SEARCH_LIMIT) {
      return FileSearchService.SEARCH_LIMIT - this.from;
    }
    return this.limit;
  }

  get lastPage(): number {
    let innerTotal = this.total;
    if(innerTotal > FileSearchService.SEARCH_LIMIT){
      innerTotal = FileSearchService.SEARCH_LIMIT
    }
    return Math.ceil( innerTotal / this.limit );
  }

  private async created(){
    this.condition = {};
  }

  @Watch('shareAddDialog')
  private async onChangeShareAddDialog(newValue){
    this.dialog = newValue;
    this.selectedFiles = [];
    await this.search();
  }

  @Watch('page')
  private async onChangePage(){
    await this.search();
  }

  private onClickClosed(){
    this.pendingFiles =[];
    this.selectFiles = [];
    this.files = [];
    this.selectedFiles = [];
    this.dialog = false;
    this.$emit('share-add-dialog-selected', []);
  }

  private onClickSelected(){
    this.dialog = false;
    this.$emit('share-add-dialog-selected', this.selectedFiles);
  }

  private async onChangeSearchBar(condition){
    this.condition = condition;
    await this.search();
  }

  private async search(){
    try{
      this.isSearchLimit = false;
      this.loading = true;
      this.files = [];
      this.total = 0;
      this.length = 0;
      const result = await this.fileSearchService.searchFiles(this.condition, undefined, this.from, this.to);

      if(result && result.items){
        this.files = result.items || [];
        this.total = result.total || 0;
        this.length = result.length || 0;
      }
    }catch(error){
      this.files = [];
      this.total = 0;
      this.length = 0;
    }finally{
      this.loading = false;
      this.isSearchLimit = this.total > FileSearchService.SEARCH_LIMIT && (this.from + this.limit >= FileSearchService.SEARCH_LIMIT) ? true : false;
    }
  }
}
