





























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MetaInfo from "@/components/files/details/MetaInfo.vue";
import ImageInfo from "@/components/files/details/ImageInfo.vue";
import VideoInfo from "@/components/files/details/VideoInfo.vue";
import AudioInfo from "@/components/files/details/AudioInfo.vue";
import FileInfo from "@/components/files/details/FileInfo.vue";
import { getFileType } from "@/utils/FileUtils";

@Component({
  components:{
    MetaInfo,
    ImageInfo,
    VideoInfo,
    AudioInfo,
    FileInfo,
  }
})
export default class MetaInfoTab extends Vue {
  @Prop()
  private file;

  private tab = "meta-tab";
  private fileType = "image";

  private created(){
    this.setFile();
  }

  @Watch("file")
  private onChangeFile(){
    this.setFile();
  }

  private setFile(){
    this.tab = "meta-tab";
    this.fileType = this.file && this.file.type && this.file.extension ? getFileType(this.file.extension, this.file.type) : "";
  }
}
