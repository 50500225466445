import { API, graphqlOperation} from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import { UpdateSharingInput } from '@/API';
import { SHARE_STATUS } from '@/utils/ShareUtils'


export async function changeSharingStatus(sharingId: string, status: number) {
    const input: UpdateSharingInput = {
        id: sharingId,
    };
    switch(status){
        case SHARE_STATUS.pending:{
            input.status = SHARE_STATUS.pending;
            break;
        }
        default:{
            input.status = SHARE_STATUS.sharing;
            break;
        }
    }

    await API.graphql(graphqlOperation(mutations.updateSharing, { input: input }));
}