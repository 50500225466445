



























import { Component, Vue } from "vue-property-decorator";
import { isAllow } from "@/utils/RoleUtils";
import * as AuthUtils from "@/utils/AuthUtils";

@Component
export default class LeftMenu extends Vue {
  private mfaPreference = "";
  private menus = [
    {
      title: "ユーザー管理",
      icon: "mdi-account-multiple",
      click: this.onClickUserManagement,
      show: false,
      role: "user",
    },
    // {
    //   title: "部門管理",
    //   icon: "mdi-group",
    //   click: this.onClickDepartmentManagement,
    //   show: false,
    //   role: "department",
    // },
    {
      title: "履歴管理",
      icon: "mdi-history",
      click: this.onClickHistoryManagement,
      show: false,
      role: "history",
    },
    {
      title: "利用料金確認",
      icon: "mdi-currency-usd",
      click: this.onClickPriceManagement,
      show: false,
      role: "price",
    },
  ];

  private async created() {
    await AuthUtils.getUser();
    this.mfaPreference = this.$store.getters.user?.preferredMFA;
    this.menus.forEach((item) => {
      item.show = isAllow("management", item.role);
    });
  }

  private onClickPriceManagement() {
    this.$emit("change-admin-left-menu", "price");
  }

  private onClickUserManagement() {
    this.$emit("change-admin-left-menu", "user");
  }

  private onClickDepartmentManagement() {
    this.$emit("change-admin-left-menu", "department");
  }

  private onClickHistoryManagement() {
    this.$emit("change-admin-left-menu", "history");
  }
}
