















































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from "moment";

@Component
export default class FileInfo extends Vue {
  @Prop()
  private file;

  private fileName = '';
  private fileSize = 0;
  private fileType = '';
  private updatedAt = '';
  private createdAt = '';
  private registrant = '';
  private fileId = '';
  private cmsTitle = '素材管理システムID'
  private cmsId = '';

  private created(){
    this.setFile();
  }

  @Watch('file')
  private onFileChanged(){
    this.setFile();
  }

  private setFile(){
    if(this.file){
      this.fileName = this.file.fileName ? this.file.fileName : '';
      this.fileSize = this.file.fileSize ? parseInt(this.file.fileSize, 10) : 0;
      this.fileType = this.file.type ? this.file.type : '';
      this.updatedAt = this.file.updatedAt ? moment(this.file.updatedAt, 'YYYY-MM-DD HH:mm:ss.SSSZ').local().format('YYYY-MM-DD HH:mm:ss.SSS') : '';
      this.createdAt = this.file.createdAt ? moment(this.file.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSZ').local().format('YYYY-MM-DD HH:mm:ss.SSS') : '';
      this.registrant = this.file.createdUserName ? this.file.createdUserName : '';
      this.fileId = this.file.id ? this.file.id : '';
      const customItems = this.file.customItems ? JSON.parse(this.file.customItems) : undefined;
      this.cmsId = customItems && customItems.cmsId ? customItems.cmsId : '';
      this.cmsTitle = this.file.ownedAccountId == 'agrinews' ? 'フロントシステムID' : '素材管理システムID';
    }
  }
}

