import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router';
import { mainLayout } from '@/components/layout/MainLayout';
import FileGroupCreate from '@/views/files/FileGroupCreate.vue';
import FileGroupEdit from '@/views/files/FileGroupEdit.vue';
import FileInsert from '@/views/files/FileInsert.vue';
import FileEdit from '@/views/files/FileEdit.vue';
import FileDetail from '@/views/files/FileDetail.vue';
import FileSearchResult from '@/views/files/FileSearchResult.vue';
import FileGroupSearchResult from '@/views/files/FileGroupSearchResult.vue';
import FileGroupDetail from '@/views/files/FileGroupDetail.vue';
import ShareCreate from '@/views/share/ShareCreate.vue';
import ShareContent from '@/views/share/ShareContent.vue';
import ShareDetail from '@/views/share/ShareDetail.vue';
import ShareList from '@/views/share/ShareList.vue';
import ShareEdit from '@/views/share/ShareEdit.vue';
import FileCSVImport from '@/views/files/FileCSVImport.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    // ファイル検索結果
    path: '/:accountId/files/search_result',
    name: 'FileSearchResult',
    meta: { requiresAuth: true, requiresFiling: true, requiresFilingSearch: true },
    component: mainLayout(FileSearchResult),
    props: true,
  },
  {
    // ファイルグループ検索結果
    path: '/:accountId/groups/search_result',  
    name: 'FileGroupSearchResult',
    meta: { requiresAuth: true, requiresFiling: true, requiresFilingSearch: true },
    component: mainLayout(FileGroupSearchResult),
    props: true,
  },
  {
    // ファイルグループ詳細
    path: '/:accountId/groups/details/:id',
    name: 'FileGroupDetail',
    meta: { requiresAuth: true, requiresFiling: true, requiresFilingSearch: true },
    component: mainLayout(FileGroupDetail),
  },
  {
    // ファイル詳細
    path: '/:accountId/files/details',
    name: 'FileDetail',
    meta: { requiresAuth: true, requiresFiling: true, requiresFilingSearch: true },
    component: FileDetail,
    props: true,
  },
  {
    // ファイルグループ作成
    path: '/:accountId/files/create',
    name: 'FileGroupCreate',
    meta: { requiresAuth: true, requiresFiling: true, requiresFilingCreate: true },
    component: FileGroupCreate,
  },
  {
    // ファイルグループ情報編集
    path: '/:accountId/groups/edit/:id',
    name: 'FileGroupEdit',
    meta: { requiresAuth: true, requiresFiling: true, requiresFilingOperate: true },
    component: FileGroupEdit,
  },
  {
    // ファイル追加
    path: '/:accountId/files/insert/:id',
    name: 'FileInsert',
    meta: { requiresAuth: true, requiresFiling: true, requiresFilingCreate: true },
    component: FileInsert,
  },
  {
    // ファイル情報編集
    path: '/:accountId/files/edit/:id',
    name: 'FileEdit',
    meta: { requiresAuth: true, requiresFiling: true, requiresFilingOperate: true },
    component: FileEdit
  },

  // ファイル共有
  {
    // 共有一覧
    path: '/:accountId/files/share_list',
    name: 'ShareList',
    meta: { requiresAuth: true, requiresSharing: true, requiresSharingCreate: true },
    component: mainLayout(ShareList),

  },
  {
    // 共有ファイル一覧
    path: '/:accountId/files/share_contents/:id',
    name: 'ShareContent',
    meta: { requiresAuth: true },
    component: ShareContent,
  },
  {
    // 共有転送
    path: '/files/share_contents/:id',
    name: 'ShareContentRedirect',
    meta: { requiresAuth: true },
  },
  {
    // 共有ファイル詳細
    path: '/:accountId/files/share_details',
    name: 'ShareDetail',
    meta: { requiresAuth: true },
    component: ShareDetail,
  },
  {
    // 共有ファイル通知
    path: '/files/share_details',
    name: 'ShareRedirect',
    meta: { requiresAuth: true },
  },
  {
    // 共有新規作成
    path: '/:accountId/files/share_create',
    name: 'ShareCreate',
    meta: { requiresAuth: true, requiresSharing: true, requiresSharingCreate: true },
    component: ShareCreate,
    props: true,
  },
  {
    // 共有内容編集
    path: '/:accountId/files/share_edit/:id',
    name: 'ShareEdit',
    meta: { requiresAuth: true, requiresSharing: true, requiresSharingManage: true },
    component: ShareEdit,
  },
  {
    // CSVファイルインポート
    path: '/:accountId/files/csv/import',
    name: 'FileCSVImport',
    meta: { requiresAuth: true, requiresFiling: true, requiresFilingOperate: true },
    component: FileCSVImport,
  },
]

export default routes
