






















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { CoverageSerch, SearchForm, CoverageListData } from "@/services/coverage/coverageSerch";
import SearchBar from "@/components/files/SearchBar.vue";
import moment from "moment";
import { Coverage } from "@/API";
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";

@Component({
  components: {
    SearchBar,
    Loading,
    Dialog,
  }
})
export default class CoverageSearchDialog extends Vue {
  private innerDialog = false;
  private selectedCoverage = {};
  private coverages: Array<CoverageListData> = [];
  private coverageSearch = new CoverageSerch();
  private condition = {};

  private searchForm: SearchForm = {
    accountId: "",
    dayStart: "",
    dayEnd: "",
    searchWord: "",
    assignedUserId: null,
  };

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  @Prop({ default:false })
  private coverageSearchDialog;

  @Prop({default: () => ({})})
  private currentCoverage;

  @Prop({default: false})
  private reset;

  @Watch('reset')
  private onChangeReset(){
    this.setDefault();
    this.$emit('coverage-search-dialog-reset-completed', true);
  }

  @Watch('coverageSearchDialog')
  private async onChangeCoverageSearchDialog(newValue){
    this.innerDialog = newValue;
    if(this.currentCoverage && (this.currentCoverage as Coverage).id){
      this.selectedCoverage = this.currentCoverage as Coverage;
    }else{
      await this.search();
    }
  }

  @Watch('innerDialog')
  private onChangeInnerDialog(){
    this.$emit("coverage-search-dialog", this.innerDialog);
  }

  private created(){
    this.setDefault();
  }

  private setDefault(){
    const user = this.$store.getters.user;
    this.searchForm.accountId = user ? user.attributes["custom:account_id"] : "";
    this.searchForm.searchWord = "";
    this.searchForm.dayStart = moment(new Date()).local().startOf("day").toISOString();
    this.searchForm.dayEnd = moment(new Date()).local().endOf("day").toISOString();
    this.selectedCoverage = {};
    this.coverages = [];
    this.condition = {dateFrom: moment(new Date()).local().format('YYYY-MM-DD'), dateTo: "", freeword: ""};
  }

  private async onChangeSearchBar(value){
    this.searchForm = value;
    await this.search();
  }

  private onClickClosed(){
    this.innerDialog = false;
    this.$emit('coverage-search-selected', undefined);
  }

  private onClickSelected(){
    this.innerDialog = false;
    this.$emit('coverage-search-selected', this.selectedCoverage);
  }

  private async search(){
    try{
      this.loading = true;
      this.selectedCoverage = {};
      this.coverages = [];
      this.coverages = await this.coverageSearch.search(this.searchForm);
      this.loading = false;
    }catch(error){
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "取得失敗";
      this.resultDialogMessage = `データの取得に失敗しました。\n元の画面に戻ります。`;
    }finally{
      this.loading = false;
    }
  }
  
  private localDatetime(date){
    return date ? moment(date, 'YYYY-MM-DD HH:mm:ss.SSSZ').local().format('YYYY-MM-DD HH:mm') : '';
  }

  private onChangeResultDialogClosed(){
    this.resultDialog = false;
    this.onClickClosed();
  }

}
