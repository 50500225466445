import store from '../store'
import * as AuthUtils from '../utils/AuthUtils'

export function changeSideDrawer() {
  if(!store.state.user){
    AuthUtils.getUser();
  }
  store.commit('changeSideDrawer');
}

export function showCoverageForm() {
  store.commit('showCoverageForm');
}

export function showCoverageEditForm() {
  store.commit('showCoverageEditForm');
}
