import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@aws-amplify/ui-vue';
import Amplify from '@aws-amplify/core'
import awsconfig from './aws-exports';
import { Auth } from "aws-amplify";
// import { getSession } from './utils/AuthUtils';
// import CustomAmplifyTorken from "@/store/customAmplifyTorken";

// ローカライズ
import { I18n } from 'aws-amplify'
import { vocabularies } from './localize'
I18n.putVocabularies(vocabularies)
I18n.setLanguage('ja')
import VueI18n from 'vue-i18n';

// フィルター
import Vue2Filters from 'vue2-filters'
import lineClamp from 'vue-line-clamp'

const messages = {
  en: {
    message: {
    }
  },
  ja: {
    message: {
    }
  }
};

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'ja',
  fallbackLocale: 'ja',
  messages,
})

Vue.use(Vue2Filters)

Vue.use(lineClamp, {
  importCss: true
})

//初期化
Vue.config.productionTip = false
Amplify.configure(awsconfig)
Amplify.configure({
  API: {
    // Authorization HeaderにIdTokenを指定
    /* eslint-disable-next-line @typescript-eslint/camelcase */
    graphql_headers: async () => {
      // const session = await getSession();
      // return { Authorization: session.getIdToken().getJwtToken() };
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
  //Vuex.Storeを利用する為の設定
  // Auth:{
  //   storage:CustomAmplifyTorken,
  // }
  //sessionStorageを利用する為の設定
  Auth:{
    storage:sessionStorage,
  }
}
)
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
