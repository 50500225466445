
import { Component, Vue } from "vue-property-decorator";
import * as queries from "@/graphql/queries";
import * as service from "@/services/coverage/coverageInfoService";
import { API } from "aws-amplify";
import moment from "moment";
import { GraphQLResult } from "@aws-amplify/api";
import { GetCoverageInfoQuery } from "@/API";

@Component
export default class CoverageInfoBaseForm extends Vue {
  name = "coverage-info-base-form";
  valid = false;
  startDateMenu = false;
  endDateMenu = false;
  selectItem;
  body = "";
  startDate = new Date().toISOString().substr(0, 10);
  endDate = new Date().toISOString().substr(0, 10);
  CoverageInfoService = new service.CoverageInfoService();

  // 入力ルール
  infoBodyCount = 32;
  infoBodyRules = [(v) => !!v || '連絡事項は必須項目です', (v) => v && v.length <= this.infoBodyCount || `連絡事項は${this.infoBodyCount}文字以下です`];
  dateCount = 10;
  startDateRules = [(v) => !!v || '開始日は必須項目です', (v) => v && v.length === this.dateCount, this.dateCompare];
  endDateRules = [(v) => !!v || '終了日は必須項目です', (v) => v && v.length === this.dateCount, this.dateCompare];
  memoCount = 1000;
  memoRules = [(v) => v && v.length <= this.memoCount];

  private dateCompare(){
    if(moment(this.startDate).isSameOrBefore(moment(this.endDate))){
      return true;
    }
    return '開始日 <= 終了日です';
  }

  //ダイアログコントロール
  loading = false;
  protected resultDialog = false;
  protected resultDialogMode = "";
  protected resultDialogTitle = "";
  protected resultDialogMessage = "";

  protected confirmDialog = false;
  protected confirmMode = "";
  protected confirmTitle = "";
  protected confirmMessage = "";

  protected validate() {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  protected setDefault() {
    this.selectItem = "";
    this.body = "";
    this.startDate = new Date().toISOString().substr(0, 10);
    this.endDate = new Date().toISOString().substr(0, 10);
  }

  protected async setCoverageInfo(id: string) {
    (API.graphql({
      query: queries.getCoverageInfo,
      variables: { id: id },
    }) as Promise<GraphQLResult<GetCoverageInfoQuery>>)
      .then((result) => {
        this.body = result.data?.getCoverageInfo?.body || "";
        this.startDate = result.data?.getCoverageInfo?.startDate || new Date().toISOString().substr(0, 10);
        this.endDate = result.data?.getCoverageInfo?.endDate || new Date().toISOString().substr(0, 10);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  protected onChangeResultDialogClosed(){
    this.resultDialog = false;
  }
}
