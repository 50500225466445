












import { Component, Vue } from "vue-property-decorator";
import LeftMenu from "@/components/admin/LeftMenu.vue";
import HistoryMain from "@/components/admin/history/HistoryMain.vue";
import DepartmentMain from "@/components/admin/department/DepartmentMain.vue";
import UserMain from "@/components/admin/user/UserMain.vue";
import PriceMain from "@/components/admin/price/PriceMain.vue";

@Component({
  components: {
    LeftMenu,
    HistoryMain,
    DepartmentMain,
    UserMain,
    PriceMain,
  },
})
export default class AdminMain extends Vue {
  private isShowPrice = false;
  private isShowUser = false;
  private isShowDepartment = false;
  private isShowHistory = false;

  private onChangeAdminLeftMenu(value) {
    this.setDefault();
    switch (value) {
      case "price": {
        this.isShowPrice = true;
        break;
      }
      case "user": {
        this.isShowUser = true;
        break;
      }
      case "department": {
        this.isShowDepartment = true;
        break;
      }
      case "history": {
        this.isShowHistory = true;
        break;
      }
      default: {
        this.isShowPrice = true;
        break;
      }
    }
  }

  private setDefault() {
    this.isShowPrice = false;
    this.isShowUser = false;
    this.isShowDepartment = false;
    this.isShowHistory = false;
  }
}
