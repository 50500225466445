

































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FileAndFileGroupSearchForm from "@/components/files/searches/FileAndFileGroupSearchForm.vue";
import FileSearchForm from "@/components/files/searches/FileSearchForm.vue";
import FileGroupSearchForm from "@/components/files/searches/FileGroupSearchForm.vue";
import CoverageSearchForm from "@/components/files/searches/CoverageSearchForm.vue";
import { SearchForm } from "@/services/coverage/coverageSerch";
import { SearchCondition, SEARCH_MODE } from "@/services/files/FileSearch";

@Component({
  components: {
    FileSearchForm,
    FileAndFileGroupSearchForm,
    FileGroupSearchForm,
    CoverageSearchForm,
  }
})
export default class SearchBar extends Vue {
  private dialog = false;
  private freeword = "";
  private freewordLabel = "検索";
  private searchBtnColor = "";

  private fileSearchCondition: SearchCondition = {};
  private fileSearchMode = SEARCH_MODE.File.toString();
  
  private coverageCondition: SearchForm = {
    accountId: "",
    dayStart: "",
    dayEnd: "",
    searchWord: "",
    assignedUserId: null,
  };

  private innerCondition = {}

  @Prop({ default: "FileAndGroup"})
  private mode;

  @Prop({ default: false })
  private solo;

  @Prop({ default: () => ({}) })
  private condition;

  @Watch('condition')
  private onChangeCondition(newValue){
    this.freeword = newValue?.freeword ? newValue.freeword : "";
    this.innerCondition = newValue;
  }

  @Watch('searchCondition')
  private onChangeSearchCondition(){
    this.fileSearchCondition = this.searchCondition;
  }

  @Watch("searchMode")
  private onChangeSearchMode(newSearchMode){
    this.fileSearchMode = newSearchMode;
    this.setFreewordLabel();
    this.setSearchButtonColor();
  }

  @Watch("freeword")
  private onChangeFreeword(newFreeword){
    if(this.mode === "FileAndGroup"){
      this.freeword = newFreeword;
      this.fileSearchCondition.freeword = this.freeword;
      this.$store.commit("setSearchCondition", this.fileSearchCondition);
    }
  }

  private get searchCondition(){
    return JSON.parse(JSON.stringify(this.$store.getters.searchCondition));
  }

  private get searchMode(){
    return JSON.parse(JSON.stringify(this.$store.getters.searchMode));
  }

  private async created(){
    if(this.mode === "FileAndGroup"){
      this.setSearchMode();
      this.fileSearchCondition = this.searchCondition;
      this.freeword = this.fileSearchCondition.freeword ? this.fileSearchCondition.freeword : "";
    }
  }

  private setSearchMode(){
    if(this.searchMode){
      this.fileSearchMode = this.searchMode;
    }else{
      this.$store.commit("setSearchMode", SEARCH_MODE.File);
    }
    this.setFreewordLabel();
    this.setSearchButtonColor();
  }

  private setFreewordLabel(){
    this.freewordLabel = "検索";

    if(this.mode === "FileAndGroup"){
      if(this.fileSearchMode === SEARCH_MODE.FileGroup){
        this.freewordLabel = "アルバム検索";
      }else if(this.fileSearchMode === SEARCH_MODE.File){
        this.freewordLabel = "ファイル検索";
      }
    }
  }

  private setSearchButtonColor(){
    this.searchBtnColor = "";

    if(this.mode === "FileAndGroup"){
      if(this.fileSearchMode === SEARCH_MODE.FileGroup){
        this.searchBtnColor = "amber accent-4";
      }else if(this.fileSearchMode === SEARCH_MODE.File){
        this.searchBtnColor = "";
      }
    }
  }
  

  private showSearch() {
    this.dialog = !this.dialog;
  }

  private onChangeClear(){
    this.freeword = "";
    this.fileSearchCondition.freeword = this.freeword;
  }

  private onChangeFileAndGroupSearchCondition(value){
    this.fileSearchCondition = value;
    this.fileSearchCondition.freeword = this.freeword;
    this.$store.commit("setSearchCondition", this.fileSearchCondition);
  }

  private onChangeFileAndGroupSearch(value){
    this.fileSearchCondition = value;
    this.search();
  }
  private onChangeFileAndGroupSearchMode(value){
    this.$store.commit("setSearchMode", value);
  }

  private onChangeFileSearchCondition(value){
    this.fileSearchCondition = value;
  }

  private onChangeFileSearch(value){
    this.fileSearchCondition = value;
    this.search();
  }

  private onChangeFileGroupSearchCondition(value){
    this.fileSearchCondition = value;
  }

  private onChangeFileGroupSearch(value){
    this.fileSearchCondition = value;
    this.search();
  }

  private onChangeCoverageSearchCondition(value){
    this.coverageCondition = value;
  }

  private onChangeCoverageSearch(value){
    this.coverageCondition = value;
    this.search();
  }

  private searchEnter(event){
    if(event.keyCode !== 13) return;
    this.search();
  }

  private search(){
    this.dialog = false;
    switch(this.mode){
      case "Coverage": {
        this.coverageCondition.searchWord = this.freeword;
        this.$emit('search-bar-search', this.coverageCondition);
        break;
      }
      default: {
        this.fileSearchCondition.freeword = this.freeword;
        this.fileSearchCondition.coverageId = "";
        this.$emit('search-bar-search', this.fileSearchCondition);
        break;
      }
    }
  }
}
