































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from "moment";
import { ReuseItem, ShareItem, SaleItem } from "@/services/files/FileUpload";

@Component
export default class MetaInfo extends Vue {
  @Prop()
  private file;

  private coverageTitle = '';
  private title = '';
  private caption = '';
  private datetimeOriginatedTitle = '撮影日時'
  private datetimeOriginated = '';
  private byline = '';
  private city = '';
  private instructions = '';
  private original = '';

  private selectedKeywords: string[] = [];
  private keywordsItems: string[] = [];
  private caution = false;
  private cautionInstructions = '';
  private selectedReuseItem: ReuseItem = {text: "可", value: 1};
  private reuseItems: ReuseItem[] = [
      {text: "不可", value: 0},
      {text: "可", value: 1},
      {text: "条件付き可", value: 2}
  ];
  private reuseCondition = '';
  private selectedShareItem: ShareItem = {text: "可", value: 1};
  private shareItems: ShareItem[] = [
      {text: "不可", value: 0},
      {text: "可", value: 1},
      {text: "条件付き可", value: 2}
  ];
  private shareCondition = '';
  private selectedSaleItem: SaleItem = {text: "可", value: 1};
  private saleItems: SaleItem[] = [
      {text: "不可", value: 0},
      {text: "可", value: 1},
      {text: "条件付き可", value: 2}
  ];
  private saleCondition = '';
  private memo = '';

  private publishedStatus = 0;
  private publishedDate = '';
  private publishedMedia = '';
  private publishedPage = '';

  private originalEdition = '';
  private originalEditionItems = [
    { text: 'デジタル', value: 0 },
    { text: 'カラーネガ', value: 1 },
    { text: 'モノクロネガ', value: 2 },
    { text: 'カラーポジ', value: 3 },
    { text: 'モノクロポジ', value: 4 },
    { text: '紙焼き', value: 5 },
    { text: 'その他', value: 99 },
  ];

  private storageLocation = '';

  private created(){
    this.setFile();
  }

  @Watch('file')
  private onFileChanged(){
    this.setFile();
  }

  private setFile(){
    if(this.file){
      this.coverageTitle = this.file.coverage && this.file.coverage.title ? this.file.coverage.title : '';
      this.title = this.file.title ? this.file.title : '';
      this.caption = this.file.caption ? this.file.caption : '';
      this.instructions = this.file.instructions ? this.file.instructions : '';
      
      const meta = this.file.meta ? JSON.parse(this.file.meta) : undefined;
      this.datetimeOriginatedTitle = '撮影日時';
      // if(meta && meta.originatedStatus !== undefined && meta.originatedStatus !== null){
      //   switch(meta.originatedStatus) {
      //     case 2:
      //       this.datetimeOriginatedTitle = '撮影年月';
      //       break;
      //     case 3:
      //       this.datetimeOriginatedTitle = '撮影年頃';
      //       break;
      //     case 4:
      //       this.datetimeOriginatedTitle = '撮影年代';
      //       break;
      //     default:
      //       this.datetimeOriginatedTitle = '撮影日時';
      //       break;
      //   }
      // }

      this.datetimeOriginated = '不明';
      if(meta && meta.datetimeOriginated){
        if((meta.originatedStatus === 0 || meta.originatedStatus === 1) && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).format('YYYY年MM月DD日 HH:mm:ss.SSS');
        }else if(meta.originatedStatus === 1 && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss', true).format('YYYY年MM月DD日 HH:mm:ss');
        }else if((meta.originatedStatus === 0 || meta.originatedStatus === 1) && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DD', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DD', true).format('YYYY年MM月DD日');
        }else if(meta.originatedStatus === 2 && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM', true).format('YYYY年MM月');
        }else if(meta.originatedStatus === 2 && moment.parseZone(meta.datetimeOriginated, 'YYYY', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY', true).format('YYYY年');
        }else if((meta.originatedStatus === null || meta.originatedStatus === undefined) && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).format('YYYY年MM月DD日 HH:mm:ss.SSS');
        }
      }else if(meta && meta.originatedStatus === 3 && meta.aroundYearOriginated && moment.parseZone(meta.aroundYearOriginated, 'YYYY', true).isValid()){
        this.datetimeOriginated = moment.parseZone(meta.aroundYearOriginated, 'YYYY', true).format('YYYY年頃');
      }else if(meta && meta.originatedStatus === 4 && meta.ageOriginated && moment.parseZone(meta.ageOriginated, 'YYYY', true).isValid()){
        this.datetimeOriginated = moment.parseZone(meta.ageOriginated, 'YYYY', true).format('YYYY年代');
      }
      
      this.city = meta && meta.city ? meta.city : '';
      this.byline = meta && meta.byline ? meta.byline : '';
      
      this.original = this.file.original ? this.file.original : '';
      this.caution = this.file.caution && this.file.caution === 1 ? true : false;
      this.cautionInstructions = this.file.caution && this.file.caution === 1 && this.file.cautionInstructions ? this.file.cautionInstructions : '';
      
      if(this.file.reuse === 2){
        this.selectedReuseItem = {text: "条件付き可", value: 2};
        this.reuseCondition = this.file.reuseCondition ? this.file.reuseCondition : '';
      }else if(this.file.reuse === 0){
        this.selectedReuseItem = {text: "不可", value: 0};
      }

      const customItems = this.file.customItems ? JSON.parse(this.file.customItems) : undefined;
      if(customItems && customItems.share === 2){
        this.selectedShareItem = {text: "条件付き可", value: 2};
        this.shareCondition = customItems.shareCondition ? customItems.shareCondition : '';
      }else if(customItems && customItems.share === 0){
        this.selectedShareItem = {text: "不可", value: 0};
      }
      if(customItems && customItems.sale === 2){
        this.selectedSaleItem = {text: "条件付き可", value: 2};
        this.saleCondition = customItems.saleCondition ? customItems.saleCondition : '';
      }else if(customItems && customItems.sale === 0){
        this.selectedSaleItem = {text: "不可", value: 0};
      }
      
      this.selectedKeywords = this.file.keywords && this.file.keywords.length > 0 ? this.file.keywords : [];

      this.publishedStatus = customItems && customItems.publishedStatus ? customItems.publishedStatus : 0;
      this.publishedDate = customItems && customItems.publishedDate ? moment.parseZone(customItems.publishedDate, 'YYYY-MM-DD', true).format('YYYY年MM月DD日') : '';
      this.publishedMedia = customItems && customItems.publishedMedia ? customItems.publishedMedia : '';
      this.publishedPage = customItems && customItems.publishedPage ? customItems.publishedPage : '';

      this.originalEdition = '';
      if(customItems && customItems.originalEdition !== undefined && customItems.originalEdition !== null){
        const originalEditionItem = this.originalEditionItems.find(x => x.value === customItems.originalEdition);
        this.originalEdition = originalEditionItem ? originalEditionItem.text : '';
      }

      this.storageLocation = customItems && customItems.storageLocation ? customItems.storageLocation : '';

      this.memo = this.file.memo ? this.file.memo : '';
    }
  }
}
