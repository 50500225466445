




















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { History, SearchHistorysQueryVariables, SearchableHistoryFilterInput, SearchableHistorySortInput ,SearchableHistorySortableFields, SearchableSortDirection, SearchHistorysQuery, PGCService } from "@/API";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import moment from "moment";
import * as AuthUtils from "@/utils/AuthUtils";
import { GraphQLResult } from "@aws-amplify/api";

export interface HistroyData {
    id: string;
    operation: string;
    createdAt: string;
    userName: string;
    message: string;
}

@Component
export default class HistoryMain extends Vue {
    private headers = [
        { text: "操作", value: "operation" },
        { text: "操作日時", value: "createdAt" },
        { text: "操作ユーザー", value: "userName" },
        { text: "メッセージ", value: "message", sortable: false },
    ];
    private page = 1;
    private pageCount = 0;
    private itemsPerPage = 30;
    private searchWord = "";
    private tableLoading = false;

    private items: HistroyData[] = [];
    private user;
    private accountId = "";

    @Prop({default:false})
    private show;

    @Watch("show")
    private async onChangeShow(newValue){
        if(newValue){
            await this.search();
        }
    }

    // TODO:ESによる検索が必要かはあとで検討する
    private async created(){
        this.user = this.$store.getters.user;
        this.accountId = this.user && this.user.attributes ? this.user.attributes["custom:account_id"] : "";
    }

    private async search(){
        this.tableLoading = true;
        this.items = [];
        if(!this.accountId){
            return;
        }
        const filter: SearchableHistoryFilterInput = {
            accountId: {
                eq: this.accountId,
            },
        }
        const sort: SearchableHistorySortInput = {
            field: SearchableHistorySortableFields.createdAt,
            direction: SearchableSortDirection.desc,
        }
        const variables: SearchHistorysQueryVariables = {
            filter: filter,
            sort: sort,
            from: 0,
            limit: 10000,
        }
        try{
            const { data } = (await API.graphql(graphqlOperation(queries.searchHistorys, variables))) as GraphQLResult<SearchHistorysQuery>;
            if(data && data.searchHistorys){
                const histories = data.searchHistorys.items as History[] || [];
                const allUsers: Array<{ key: string | undefined; val: string | undefined }> = await AuthUtils.listUsers(PGCService.MANAGEMENT, "");
                
                for(const histroy of histories){
                    if(histroy){
                        const user = allUsers.find(({ val }) => val === histroy.userId);
                        const historyData: HistroyData = {
                            id: histroy.id || "",
                            operation: histroy.operation ? this.getOperation(histroy.operation) : "",
                            createdAt: histroy.createdAt ? moment(histroy.createdAt).local().format("YYYY-MM-DD HH:mm:ss") : "",
                            userName: user?.key || "",
                            message: histroy.body ? histroy.body.replaceAll(" ", "\n") : "",
                        };
                        this.items.push(historyData);
                    }
                }
            }
        }catch(error){
            console.error(error);
            this.items = [];
        }finally{
            this.tableLoading = false;
        }
    }

    private getOperation(operation: string){
        let operationName = "";
        switch(operation){
            case "share": {
                operationName = "共有";
                break;
            }
            case "download": {
                operationName = "ダウンロード";
                break;
            }
            case "download request": {
                operationName = "ダウンロード要求";
                break;
            }
            case "sale send": {
                operationName = "外販送信";
                break;
            }
        }
        return operationName;
    }

    private getDatetime(dateTime: string){
        return moment(dateTime).local().format("YYYY-MM-DD HH:mm:ss");
    }
}
