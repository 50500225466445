import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { mainLayout } from '@/components/layout/MainLayout'
import CoverageMain from '@/views/coverage/CoverageMain.vue'
import MyCoverageDetails from '../views/coverage/MyCoverageDetails.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // 取材
  {
    // 取材一覧
    path: '/:accountId/coverage/',
    name: 'CoverageMain',
    meta: { requiresAuth: true, requiresCoverage: true, requiresCoverageManage: true },
    component: mainLayout(CoverageMain),
  },
  {
    // 取材情報表示
    path: '/:accountId/coverage/details',
    name: 'MyCoverageDetails',
    meta: { requiresAuth: true, requiresCoverage: true, requiresCoverageRead: true },
    component: MyCoverageDetails,
  },
  {
    // 取材通知
    path: '/coverage/details',
    name: 'MyCoverageRedirect',
    meta: { requiresAuth: true, requiresCoverage: true, requiresCoverageRead: true },
  }
]

export default routes
