
import { Component, Vue } from "vue-property-decorator";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import { File, GetFileQuery, UpdateFileInput } from "@/API";
import moment from "moment";
import { ReuseItem, ShareItem, SaleItem, YearItem, MonthItem } from "@/services/files/FileUpload";
import { GraphQLResult } from "@aws-amplify/api";
import { createMonthItems, createYearItems } from "@/utils/FileUtils";

@Component
export default class FileEditBaseForm extends Vue {
  name = "file-edit-base-form";

  protected valid = false;
  private fileId = "";
  // eslint-disable-next-line
  protected meta: any = {};
  // eslint-disable-next-line
  protected customItems: any = {};
  protected title = "";
  protected caption = "";
  protected selectedDatetime = 1;
  protected date = "";
  protected dateMenu = false;
  protected time = "";
  protected timeMenu = false;
  protected selectedByline = 1;
  protected byline = "";
  protected selectedCity = 1;
  protected city = "";
  protected instructions = "";
  protected original = "";
  protected selectedKeywords: string[] = [];
  protected keywordsItems: string[] = [];
  protected rating = 0;
  protected protect = true;
  protected caution = false;
  protected cautionInstructions = "";
  protected selectedReuseItem: ReuseItem = {text: "可", value: 1};
  protected reuseItems: ReuseItem[] = [
    {text: "不可", value: 0},
    {text: "可", value: 1},
    {text: "条件付き可", value: 2}
  ];
  protected reuseCondition = "";
  protected selectedShareItem: ShareItem = {text: "可", value: 1};
  protected shareItems: ShareItem[] = [
    {text: "不可", value: 0},
    {text: "可", value: 1},
    {text: "条件付き可", value: 2}
  ];
  protected shareCondition = "";
  protected selectedSaleItem: SaleItem = {text: "可", value: 1};
  protected saleItems: SaleItem[] = [
    {text: "不可", value: 0},
    {text: "可", value: 1},
    {text: "条件付き可", value: 2}
  ];
  protected saleCondition = "";
  protected memo = "";

  protected yearOriginatedItems: YearItem[] = createYearItems();
  protected yearOriginated: YearItem | null = null;
  protected monthOriginatedItems: MonthItem[] = createMonthItems();
  protected monthOriginated: MonthItem | null = null;

  protected yearOriginatedRules = [this.yearOriginatedRuleMethod];
  private yearOriginatedRuleMethod(value){
    if(this.selectedDatetime === 2 && !value){
      return "撮影年は必須項目です";
    }
    return true;
  }

  protected aroundYearOriginated: YearItem | null = null;
  protected aroundYearOriginatedRules = [this.aroundYearOriginatedRuleMethod];
  private aroundYearOriginatedRuleMethod(value){
    if(this.selectedDatetime === 3 && !value){
      return "撮影年頃は必須項目です";
    }
    return true;
  }

  protected ageOriginatedItems: YearItem[] = createYearItems(10);
  protected ageOriginated: YearItem | null = null;
  protected ageOriginatedRules = [this.ageOriginatedRuleMethod];
  private ageOriginatedRuleMethod(value){
    if(this.selectedDatetime === 4 && !value){
      return "撮影年代は必須項目です";
    }
    return true;
  }

  protected publishedStatus = 0;
  protected publishedDateMenu = false;
  protected publishedDate = "";
  protected publishedDateRules = [this.publishedDateRuleMethod];

  private publishedDateRuleMethod(value){
    if(this.publishedStatus === 1 && !value){
      return "初出掲載日は必須項目です";
    }
    return true;
  }

  protected publishedMedia = "";
  protected publishedMediaRules = [
    v => !!v || "初出掲載媒体は必須項目です",
    v => (v && v.length <= 32) || "初出掲載媒体は32文字以下です",
  ];
  protected publishedPage = "";
  protected publishedPageRules = [
    v => (!v || v && String(v).match(/^[1-9][0-9]{0,4}$/) || "掲載頁は1から99999までです")
  ];

  protected originalEdition = { text: 'デジタル', value: 0 };
  protected originalEditionItems = [
    { text: 'デジタル', value: 0 },
    { text: 'カラーネガ', value: 1 },
    { text: 'モノクロネガ', value: 2 },
    { text: 'カラーポジ', value: 3 },
    { text: 'モノクロポジ', value: 4 },
    { text: '紙焼き', value: 5 },
    { text: 'その他', value: 99 },
  ];

  protected storageLocation = "";
  private storageLocationRules = [
    v => (!v || v && v.length <= 64) || '保管場所は64文字以下です',
  ];

  protected titleRules = [
    v => !!v || "タイトルは必須項目です",
    v => (v && v.length <= 128) || "タイトルは128文字以下です",
  ];
  protected captionRules = [
    v => !!v || "キャプションは必須項目です",
    v => (v && v.length <= 1024) || "キャプションは1024文字以下です",
  ];

  protected dateRules = [this.dateRuleMethod];

  private dateRuleMethod(value){
    if(this.selectedDatetime === 1 && !value){
      return "撮影日は必須項目です";
    }
    return true;
  }

  protected timeRules = [this.timeRulesMethod];

  private timeRulesMethod(value){
    // if(this.selectedDatetime === 1 && !value){
    //   return "撮影時間は必須項目です";
    // }
    const regex1 = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    if(this.selectedDatetime === 1 && value && !regex1.test(value)){
      const regex2 = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
      if(regex2.test(value)){
        return true;
      }
      return "撮影時間はHH:MM、またはHH:MM:SSで入力してください"
    }
    return true;
  }

  protected bylineRules = [this.bylineRuleMethod];
  private bylineRuleMethod(value) {
    if(this.selectedByline === 1 && !value) {
      return "撮影者は必須項目です";
    }
    if(this.selectedByline === 1 && value && value.length > 32) {
      return "撮影者は32文字以下です";
    }
    return true;
  }

  protected cityRules = [this.cityRuleMethod];
  private cityRuleMethod(value) {
    if(this.selectedCity === 1 && !value) {
      return "撮影場所は必須項目です";
    }
    if(this.selectedCity === 1 && value && value.length > 64) {
      return "撮影場所は64文字以下です";
    }
    return true
  }
  protected instructionsRules = [
    v => (!v || v && v.length <= 1024) || "連絡事項は1024文字以下です",
  ];
  protected originalRules = [
    v => (!v || v && v.length <= 64) || "著作権者は64文字以下です",
  ];

  protected isSelectedKeywordError = false;
  protected selectedKeywordErrorMessages: string | [] = [];
  protected keywordRulesMethod(value){
    if(value !== undefined && value !== null && value.length > 64){
      this.isSelectedKeywordError = true;
      this.selectedKeywordErrorMessages = 'キーワードは64文字以内です';
      return 'キーワードは64文字以内です';
    }
    this.isSelectedKeywordError = false;
    this.selectedKeywordErrorMessages = [];
    return true;
  }

  protected keywordsRulesMethod(value){
    if(value !== undefined && value !== null && value instanceof Array && value.length > 10){
      return 'キーワードは最大10単語までです';
    }
    if(value !== undefined && value !== null && value instanceof Array && value.length > 0){
      for(const i in value){
        if(value[i].length > 64){
          return 'キーワードは1単語につき64文字以内です';
        }
      }
    }
    return true;
  }

  protected cautionRules = [];
  protected cautionInstructionsRules = [
    v => (!v || v && v.length <= 256) || "取り扱い注意事項は256文字以下です",
  ];
  protected reuseConditionRules = [
    v => (!v || v && v.length <= 256) || "再使用条件は256文字以下です",
  ];
  protected shareConditionRules = [
    v => (!v || v && v.length <= 256) || "共有条件は256文字以下です",
  ];
  protected saleConditionRules = [
    v => (!v || v && v.length <= 256) || "外販条件は256文字以下です",
  ];
  protected memoRules = [
    v => (!v || v && v.length <= 2048) || "備考は2048文字以下です",
  ];

  protected setDefault(){
    this.title = "";
    this.caption = "";
    this.selectedDatetime = 0;
    this.date = moment(new Date()).local().format("YYYY-MM-DD");
    this.time = "";
    this.yearOriginated = null;
    this.monthOriginated = null;
    this.aroundYearOriginated = null;
    this.ageOriginated = null;
    this.selectedByline = 1;
    this.byline = "";
    this.selectedCity = 1;
    this.city = "";
    this.instructions = "";
    this.original = "";
    this.selectedKeywords = [];
    this.rating = 0;
    this.protect = true;
    this.caution = false;
    this.cautionInstructions = "";
    this.selectedReuseItem = {text: "可", value: 1};
    this.reuseCondition = "";
    this.selectedShareItem = {text: "可", value: 1};
    this.shareCondition = "";
    this.selectedSaleItem = {text: "可", value: 1};
    this.saleCondition = "";
    this.publishedStatus = 0;
    this.publishedDate = "";
    this.publishedMedia = "";
    this.publishedPage = "";
    this.originalEdition = { text: 'デジタル', value: 0 };
    this.storageLocation = "";
    this.memo = "";
  }

  protected async setFile(fileId: string) {
    this.fileId = fileId;
    
    if(this.fileId){
      const { data } = (await API.graphql(graphqlOperation(queries.getFile, {id: this.fileId}))) as GraphQLResult<GetFileQuery>;

      if(data && data.getFile){
        const file = (data.getFile as File);
        this.rating = file.rating ? file.rating : 0;
        this.title = file.title ? file.title : "";
        this.caption = file.caption ? file.caption : "";

        if(file.meta){
          this.meta = JSON.parse(file.meta);
          
          if((this.meta.originatedStatus === 0 || this.meta.originatedStatus === 1) && this.meta.datetimeOriginated){
            this.selectedDatetime = 1;
            if(moment.parseZone(this.meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).isValid()){
              this.date = moment.parseZone(this.meta.datetimeOriginated).format("YYYY-MM-DD");
              this.time = moment.parseZone(this.meta.datetimeOriginated).format("HH:mm:ss");
            }
            else if (moment.parseZone(this.meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
              this.date = moment.parseZone(this.meta.datetimeOriginated).format("YYYY-MM-DD");
              this.time = moment.parseZone(this.meta.datetimeOriginated).format("HH:mm:ss");
            }
            else {
              this.date = moment.parseZone(this.meta.datetimeOriginated).format("YYYY-MM-DD");
              this.time = "";
            }

          }else if(this.meta.originatedStatus === 2 && this.meta.datetimeOriginated){
            this.selectedDatetime = 2;
            if(moment.parseZone(this.meta.datetimeOriginated, 'YYYY-MM', true).isValid()){
              const year = this.yearOriginatedItems.find(x=> x.text === moment.parseZone(this.meta.datetimeOriginated).format("YYYY"))
              this.yearOriginated = year ? year : null;
              const month = this.monthOriginatedItems.find(x=> x.text === moment.parseZone(this.meta.datetimeOriginated).format("MM"))
              this.monthOriginated = month ? month : null;
            }else{
              const year = this.yearOriginatedItems.find(x=> x.text === this.meta.datetimeOriginated);
              this.yearOriginated = year ? year : null;
              this.monthOriginated = null;            
            }
          }
          else if(this.meta.originatedStatus === 3 && this.meta.aroundYearOriginated){
            this.selectedDatetime = 3;
            const year = this.yearOriginatedItems.find(x=> x.value === this.meta.aroundYearOriginated)
            this.aroundYearOriginated = year ? year : null;
          }
          else if(this.meta.originatedStatus === 4 && this.meta.ageOriginated){
            this.selectedDatetime = 4;
            const year = this.ageOriginatedItems.find(x=> x.value === this.meta.ageOriginated)
            this.ageOriginated = year ? year : null;
          }
          else{
            this.selectedDatetime = -1;
          }
          
          this.selectedCity = this.meta.city ? 1 : -1;
          this.city = this.meta.city ? this.meta.city : "";
          this.selectedByline = this.meta.byline ? 1 : -1;
          this.byline = this.meta.byline ? this.meta.byline : "";
        }

        this.instructions = file.instructions ? file.instructions : "";

        if(file.keywords && file.keywords.length > 0){
          for(const keyword of file.keywords){
            if(keyword){
              this.keywordsItems.push(keyword);
              this.selectedKeywords.push(keyword);
            }
          }
        }

        this.original = file.original ? file.original : "";
        this.protect = (file.protect && file.protect === 1) ? true : false;
        this.caution = (file.caution && file.caution === 1) ? true : false;
        this.cautionInstructions = (file.caution && file.caution === 1 && file.cautionInstructions) ? file.cautionInstructions : "";
          
        if(file.reuse !== null && file.reuse !== undefined && file.reuse === 2){
          this.selectedReuseItem = {text: "条件付き可", value: 2};
          this.reuseCondition = file.reuseCondition ? file.reuseCondition : "";
        }else if(file.reuse !== null && file.reuse !== undefined && file.reuse === 0){
          this.selectedReuseItem = {text: "不可", value: 0};
        }

        if(file.customItems){
          this.customItems = JSON.parse(file.customItems);

          if(this.customItems.sale !== null && this.customItems.sale !== undefined && this.customItems.sale === 2){
            this.selectedSaleItem = {text: "条件付き可", value: 2};
            this.saleCondition = this.customItems.saleCondition ? this.customItems.saleCondition : "";
          }else if(this.customItems.sale !== null && this.customItems.sale !== undefined && this.customItems.sale === 0){
            this.selectedSaleItem = {text: "不可", value: 0};
          }

          if(this.customItems.share !== null && this.customItems.share !== undefined && this.customItems.share === 2){
            this.selectedShareItem = {text: "条件付き可", value: 2};
            this.shareCondition = this.customItems.shareCondition ? this.customItems.shareCondition : "";
          }else if(this.customItems.share !== null && this.customItems.share !== undefined && this.customItems.share === 0){
            this.selectedShareItem = {text: "不可", value: 0};
          }

          this.publishedStatus = this.customItems.publishedStatus ? this.customItems.publishedStatus : 0;
          this.publishedDate = this.customItems.publishedDate ? moment.parseZone(this.customItems.publishedDate, 'YYYY-MM-DD', true).format('YYYY-MM-DD') : '';
          this.publishedMedia = this.customItems.publishedMedia ? this.customItems.publishedMedia : "";
          this.publishedPage = this.customItems.publishedPage ? this.customItems.publishedPage : "";

          const orgEdition = this.originalEditionItems.find(x=> x.value === this.customItems.originalEdition)
          this.originalEdition = orgEdition ? orgEdition : { text: 'デジタル', value: 0 };
          
          this.storageLocation = this.customItems.storageLocation ? this.customItems.storageLocation : "";
        }
        this.memo = file.memo ? file.memo : "";
      }
    }
  }

  protected customValidate(){
    return this.valid === true && this.isSelectedKeywordError === false ? true : false;
  }

  protected async update(){
    if(!this.customValidate()){
      return;
    }

    const inputFile: UpdateFileInput = {
      id: this.fileId,
      rating: this.rating ? this.rating : 0,
      title: this.title ? this.title : null,
      caption: this.caption ? this.caption : null,
      instructions: this.instructions ? this.instructions : null,
      memo: this.memo ? this.memo : null,
      protect: this.protect ? 1 : 0,
      original: this.original ? this.original : null,
      caution: this.caution ? 1 : 0,
      cautionInstructions: this.cautionInstructions ? this.cautionInstructions : null,
      reuse: this.selectedReuseItem.value,
      reuseCondition: this.reuseCondition ? this.reuseCondition : null,
      keywords: (this.selectedKeywords && this.selectedKeywords.length > 0) ? this.selectedKeywords : null,
    };

    this.meta.city = this.selectedCity === 1 && this.city ? this.city : null; 
    this.meta.byline = this.selectedByline === 1 && this.byline ? this.byline : null;
    // ミリ秒を残すために、あえて比較する
    if(this.selectedDatetime === 1 && this.date && this.time){
      const dateTime = `${this.date} ${this.time}`;
      if(moment.parseZone(this.meta.datetimeOriginated).format("YYYY-MM-DD HH:mm:ss") !== dateTime){
        this.meta.datetimeOriginated = moment.parseZone(dateTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.SSS");
        this.meta.aroundYearOriginated = null;
        this.meta.ageOriginated = null;
      }
    }else if(this.selectedDatetime === 1 && this.date){
      this.meta.datetimeOriginated = moment.parseZone(this.date, "YYYY-MM-DD").format("YYYY-MM-DD");
      this.meta.aroundYearOriginated = null;
      this.meta.ageOriginated = null;
    }else if(this.selectedDatetime === 2 && this.yearOriginated && this.monthOriginated){
      this.meta.datetimeOriginated = moment.parseZone(`${this.yearOriginated.text}-${this.monthOriginated.text}`, "YYYY-MM").format("YYYY-MM");
      this.meta.aroundYearOriginated = null;
      this.meta.ageOriginated = null;
    }else if(this.selectedDatetime === 2 && this.yearOriginated){
      this.meta.datetimeOriginated = moment.parseZone(`${this.yearOriginated.text}`, "YYYY").format("YYYY");
      this.meta.aroundYearOriginated = null;
      this.meta.ageOriginated = null;
    }else if(this.selectedDatetime === 3 && this.aroundYearOriginated){
      this.meta.aroundYearOriginated = parseInt(moment.parseZone(`${this.aroundYearOriginated.text}`, "YYYY").format("YYYY"), 10);
      this.meta.datetimeOriginated = null;
      this.meta.ageOriginated = null;
    }else if(this.selectedDatetime === 4 && this.ageOriginated){
      this.meta.ageOriginated = parseInt(moment.parseZone(`${this.ageOriginated.text}`, "YYYY").format("YYYY"), 10);
      this.meta.datetimeOriginated = null;
      this.meta.aroundYearOriginated = null;
    }else{
      this.meta.datetimeOriginated = null;
      this.meta.aroundYearOriginated = null;
      this.meta.ageOriginated = null;
    }

    this.meta.originatedStatus = this.selectedDatetime;
    inputFile.meta = JSON.stringify(this.meta);
  
    this.customItems.sale = this.selectedSaleItem.value;
    this.customItems.saleCondition = this.saleCondition ? this.saleCondition : null;
    this.customItems.share = this.selectedShareItem.value;
    this.customItems.shareCondition = this.shareCondition ? this.shareCondition : null;

    this.customItems.publishedStatus = this.publishedStatus,
    this.customItems.publishedDate = this.publishedStatus && this.publishedStatus === 1 && moment.parseZone(`${this.publishedDate}`, 'YYYY-MM-DD').format('YYYY-MM-DD') ? this.publishedDate : null,
    this.customItems.publishedMedia = this.publishedStatus && this.publishedStatus === 1 && this.publishedMedia ? this.publishedMedia : null,
    this.customItems.publishedPage = this.publishedStatus && this.publishedStatus === 1 && this.publishedPage ? parseInt(this.publishedPage, 10) : null,
    this.customItems.originalEdition = this.originalEdition.value,
    this.customItems.storageLocation = this.storageLocation ? this.storageLocation : null

    inputFile.customItems = JSON.stringify(this.customItems);

    await API.graphql(graphqlOperation(mutations.updateFile, {input: inputFile}));
  }
}
