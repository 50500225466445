














































import { Component } from "vue-property-decorator";
import CoverageDeskBaseForm from "./CoverageDeskBaseForm.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    Loading,
  },
})
export default class CoverageDeskEdieForm extends CoverageDeskBaseForm {
  name = "coverage-desk-edit-form";

  async created() {
    this.$store.subscribe(async (mutation) => {
      if (mutation.type === "selectCoverageDesk") {
        this.selectItem = this.$store.state.selectCoverageDeskId;
        this.setCoverageDesk(this.selectItem);
      }
    });
  }

  async mounted() {
    this.selectItem = this.$store.state.selectCoverageDeskId;
    this.loading = true;
    await this.setCoverageDesk(this.selectItem);
    this.loading = false;
  }

  private viewClose() {
    this.$emit("viewClose");
  }
}
