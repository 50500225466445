
import { Component, Vue } from "vue-property-decorator";
import * as PageAction from "@/utils/PageActionUtils";
import * as queries from "@/graphql/queries";
import { API } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { GetAccountQuery, GetCognitoUserQuery } from "@/API";

@Component
export default class UserBaseForm extends Vue {
  name = "coverage-base-form";
  valid = false;
  PageAction = PageAction;

  id = "";
  userName = "";
  email = "";
  accountName = "";
  department = "";

  // 入力ルール
  userNameRules = [(v) => !!v, (v) => v && v.length <= 64];
  emailRules = [(v) => !!v, (v) => v && v.length <= 64];

  protected async setUser() {
    //CognitoUserの最新情報を取得する
    const attributes = this.$store.state.user.attributes;
    const user = (await API.graphql({
      query: queries.getCognitoUser,
      variables: { input: { id: attributes.sub,accountId:attributes["custom:account_id"] } },
    })) as GraphQLResult<GetCognitoUserQuery>;

    const account = (await API.graphql({
      query: queries.getAccount,
      variables: { id: user.data?.getCognitoUser?.accountId },
    })) as GraphQLResult<GetAccountQuery>;
    this.userName = user.data?.getCognitoUser?.name || "";
    this.id = user.data?.getCognitoUser?.id || "";
    this.email = user.data?.getCognitoUser?.email || "";
    this.accountName = account.data?.getAccount?.company || "";
    this.department = user.data?.getCognitoUser?.department || "";
  }
}
