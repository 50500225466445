import Vue, { VueConstructor } from 'vue'
import { VApp, VMain } from 'vuetify/lib'
import Header from '../layout/Header.vue'
import SideMenu from '../layout/Menu.vue'

export function mainLayout(PageComponent: VueConstructor<Vue>) {
    return Vue.extend({
        inheritAttrs: false,
        components: {
            PageComponent,
            VApp,
            VMain,
            Header,
            SideMenu
        },
        render() {
            return (
                <v-app>
                    <Header />
                    <v-main class="mt-auto">
                        <PageComponent propsData={this.$attrs} />
                    </v-main>
                    <SideMenu />
                </v-app>
            )
        },
    })
}