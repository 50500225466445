













































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

@Component
export default class VideoInfo extends Vue {
  @Prop()
  private file;

  private duration = '';
  private fileFormat = '';
  private size = '';
  private frameRate = '';
  private colorSpace = '';
  private chromaSubsampling = '';
  private videoBitDepth = '';
  private colourPrimaries = '';
  private videoCodec = '';
  private samplingRate = '';
  private channels = '';
  private audioBitRateMaximum = '';
  private audioBitDepth = '';
  private audioCodec = '';

  private created(){
    this.setFile();
  }

  @Watch('file')
  private onFileChanged(){
    this.setFile();
  }

  private setFile(){
    if(this.file){
      const meta = this.file.meta ? JSON.parse(this.file.meta) : undefined;
      this.duration = meta && meta.videoDuration ? meta.videoDuration : '';
      this.fileFormat = meta && meta.fileFormat ? meta.fileFormat : '';
      this.size = meta && meta.width && meta.height ? `${meta.width} × ${meta.height}` : '';
      this.frameRate = meta && meta.frameRate ? `${meta.frameRate}fps` : '';
      this.colorSpace = meta && meta.colorSpace ? meta.colorSpace : '';
      this.chromaSubsampling = meta && meta.chromaSubsampling ? meta.chromaSubsampling : '';
      this.videoBitDepth = meta && meta.videoBitDepth ? `${meta.videoBitDepth}bit` : '';
      this.colourPrimaries = meta && meta.colourPrimaries ? meta.colourPrimaries : '';
      this.videoCodec = meta && meta.videoFormat ? meta.videoFormat : '';
      this.samplingRate = meta && meta.samplingRate ? `${parseInt(meta.samplingRate, 10)/1000}kHz` : '';
      this.channels = meta && meta.channels ? `${meta.channels}ch` : '';
      this.audioBitRateMaximum = meta && meta.audioBitRate ? `${parseInt(meta.audioBitRate, 10)/1000}kbps` : '';
      this.audioBitDepth = meta && meta.audioBitDepth ? `${meta.audioBitDepth}bit` : '';
      this.audioCodec = meta && meta.audioFormat ? meta.audioFormat : '';
    }
  }
}

