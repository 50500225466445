import store from "../store";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import { getAccountId } from "@/utils/AuthUtils";
import { GraphQLResult } from "@aws-amplify/api";
import { GetAccountQuery } from "@/API";

export function getDefaultSetting(): AccountSetting {
  const result: AccountSetting = {
    customCssUrl: undefined,
    filing: false,
    sharing: false,
    coverage: false,
    customBibliography: undefined,
    customCoverage: undefined,
    sale: false,
    allowTypes: []
  }
  return result;
}

export function decodeSettingJson(jsonStr: string | undefined): AccountSetting {
  return jsonStr ? JSON.parse(jsonStr) : getDefaultSetting();
}

export function encodeSettingJson(accountSetting: AccountSetting | undefined): string {
  return accountSetting ? JSON.stringify(accountSetting) : JSON.stringify(getDefaultSetting());
}

export async function getSetting(): Promise<AccountSetting> {
  if(store.getters.accountData){
    return decodeSettingJson(store.getters.accountData.config);
  }
  if(store.getters.user){
    try{
      const accountId = getAccountId(store.getters.user);
      const { data } = (await API.graphql(graphqlOperation(queries.getAccount, { id: accountId }))) as GraphQLResult<GetAccountQuery>;
      if (data && data.getAccount) {
        store.commit("setAccountData", data.getAccount);
        return decodeSettingJson(data.getAccount.config);
      }
      return getDefaultSetting();
    }catch{
      return getDefaultSetting();
    }
  }
  return getDefaultSetting();
}

export async function getCustomUrl(): Promise<string | undefined> {
  const setting = await getSetting();
  return setting.customCssUrl;
}

export async function getCustomBibliography(): Promise<customBibliographyItem[] | undefined> {
  const setting = await getSetting();
  return setting.customBibliography || [];
}

export async function getCustomCoverage(): Promise<customCoverageItem[] | undefined> {
  const setting = await getSetting();
  return setting.customCoverage || [];
}

export async function isActiveServie(service: ServiceType): Promise<boolean> {
  const setting = await getSetting();
  switch(service){
    case "filing": {
      return setting.filing || false;
    }
    case "coverage": {
      return setting.coverage || false;
    }
    case "sharing": {
      return setting.sharing || false;
    }
    case "sale": {
      return setting.sale || false;
    }
    default: {
      return false;
    }
  }
}

export type AccountSetting = {
  customCssUrl: string | undefined;
  filing: boolean | undefined;
  sharing: boolean | undefined;
  coverage: boolean | undefined;
  customBibliography: Array<customBibliographyItem> | undefined;
  customCoverage: Array<customCoverageItem> | undefined;
  sale: boolean | undefined;
  allowTypes: Array<string> | undefined;
}

export type customBibliographyItem = {
  itemCode: string;
  name: string;
  viewName: string;
  type: string;
  rule: string | undefined;
}

export type customCoverageItem = {
  itemCode: string;
  name: string;
  viewName: string;
  type: string;
  rule: string | undefined;
}

export type ItemType = "string" | "number";

export type ServiceType = "filing" | "coverage" | "sharing"| "sale";

