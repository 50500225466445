var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" 履歴管理 "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"検索","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","search":_vm.searchWord,"loading":_vm.tableLoading,"loading-text":"データを取得中です。しばらくお待ちください。","items":_vm.items,"item-key":"id"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.operation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.operation)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt)+" ")]}},{key:"item.userName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userName)+" ")]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"message"},[_vm._v(" "+_vm._s(item.message)+" ")])]}}],null,true)})],1),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }