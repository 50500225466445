













































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FileMenu from '@/components/files/FileMenu.vue';
import { Storage } from "aws-amplify";
import moment from "moment";
import { getFileType, getIcon } from "@/utils/FileUtils";

@Component({
  components:{
    FileMenu,
  }
})
export default class FileCard extends Vue {
  @Prop()
  private file;
  @Prop()
  private position;
  @Prop()
  private select;
  @Prop()
  private mode;
  @Prop()
  private sharing;

  private attributes;

  private sheet = false;
  private show = false;
  private lines = 2;
  private detailLink = {};

  private id = '';
  private src = '';
  private title = ''
  private fileName = '';
  private datetimeOriginated = '';
  private byline = '';
  private share = false;
  private sale = false;
  private caution = false;
  private protect = false;
  private rating = 0;
  private caption ='';
  private instructions = '';
  private fileType = '';
  private imgHeight = "100%"
  private imgBackColor = "grey darken";
  private imgPosition ="center center"
  private imgClass = "";
  private isCoverFile = false;

  @Watch('file')
  private async onFileChanged(){
    this.setFile();
  }

  private async created(){
    this.setFile();
  }

  private async setFile(){
    if(this.$store.getters.user && this.$store.getters.user.attributes){
      this.attributes = this.$store.getters.user.attributes;
    }

    this.src = "";
    if(this.file){
      this.fileType = this.file.type && this.file.extension ? getFileType(this.file.extension, this.file.type) : '';
      
      if(this.fileType !== "image" && this.fileType !== "video"){
        this.imgHeight = "60%";
        this.imgBackColor = "";
        this.imgClass = "img-icon"
        this.src = getIcon(this.fileType);
      }

      this.title = this.file.title ? this.file.title : '';
      this.fileName = this.file.fileName ? this.file.fileName : '';
      
      const meta = this.file.meta ? JSON.parse(this.file.meta) : undefined;
      this.datetimeOriginated = '不明';
      if(meta && meta.datetimeOriginated){
        if((meta.originatedStatus === 0 || meta.originatedStatus === 1) && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).format('YYYY年MM月DD日 HH:mm:ss.SSS');
        }else if(meta.originatedStatus === 1 && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss', true).format('YYYY年MM月DD日 HH:mm:ss');
        }else if((meta.originatedStatus === 0 || meta.originatedStatus === 1) && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DD', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DD', true).format('YYYY年MM月DD日');
        }else if(meta.originatedStatus === 2 && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM', true).format('YYYY年MM月');
        }else if(meta.originatedStatus === 2 && moment.parseZone(meta.datetimeOriginated, 'YYYY', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY', true).format('YYYY年');
        }else if((meta.originatedStatus === null || meta.originatedStatus === undefined) && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).format('YYYY年MM月DD日 HH:mm:ss.SSS');
        }
      }else if(meta && meta.originatedStatus === 3 && meta.aroundYearOriginated && moment.parseZone(meta.aroundYearOriginated, 'YYYY', true).isValid()){
        this.datetimeOriginated = moment.parseZone(meta.aroundYearOriginated, 'YYYY', true).format('YYYY年頃');
      }else if(meta && meta.originatedStatus === 4 && meta.ageOriginated && moment.parseZone(meta.ageOriginated, 'YYYY', true).isValid()){
        this.datetimeOriginated = moment.parseZone(meta.ageOriginated, 'YYYY', true).format('YYYY年代');
      }
      
      this.byline = meta.byline ? meta.byline : '';
      const thumbnailUri = meta && meta.thumbnailUri ? meta.thumbnailUri.split('/') : [];
      if((this.fileType === "image" || this.fileType === "video") && thumbnailUri.length > 0){
        this.src = (await Storage.get(`${thumbnailUri[4]}/${thumbnailUri[5]}/${thumbnailUri[6]}/${thumbnailUri[7]}`, {download: false, expires: 300, contentType: 'image/jpeg'})).toString();
      }
      
      const customItems = this.file.customItems ? JSON.parse(this.file.customItems) : undefined;
      this.share = customItems && customItems.share === 0 ? true : false;
      this.sale = customItems && customItems.sale === 0 ? true : false;
      this.caution = this.file.caution && this.file.caution === 1 ? true : false;
      this.protect = this.file.protect && this.file.protect === 1 ? true : false;
      this.rating = this.file.rating ? this.file.rating : 0;

      this.caption = this.file.caption ? this.file.caption : '';
      this.instructions = this.file.instructions ? this.file.instructions : '';

      if(this.mode === 'share' && this.sharing){
        this.detailLink = { name: 'ShareDetail', query: { q: this.sharing.id, position: this.position }};
      }else{
        if(this.file.group && this.file.group.coverFileId && this.file.group.coverFileId === this.file.id){
          this.isCoverFile = true;
        }else{
          this.isCoverFile = false;
        }
        this.detailLink = { name: 'FileDetail', params: { position: this.position } };
      }
    }
  }

  private onChangeFileMenuSheet(value){
    this.sheet = value;
  }

  private onChangeFileMenuResult(value){
    if(value){
      this.$emit('file-card-result', value);
    }
  }
}
