






































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Storage } from "aws-amplify";
import Video from "@/components/files/details/Video.vue";
import Audio from "@/components/files/details/Audio.vue";
import { getFileType, getIcon } from "@/utils/FileUtils";

@Component({
  components:{
    Video,
    Audio,
  }
})
export default class Preview extends Vue {
  @Prop()
  private file;

  private share = false;
  private sale = false;
  private caution = false;
  private protect = false;
  private rating = 0;
  private src = "";
  private poster = "";
  private type = "";
  private imgClass = "grey darken";
  private imgMaxHeight = "400";

  private async created(){
    await this.setFile();
  }

  @Watch('file')
  private async onFileChanged(){
    await this.setFile();
  }

  private async setFile(){
    this.src = '';
    this.poster = '';
    if(this.file){
      const customItems = this.file.customItems ? JSON.parse(this.file.customItems) : undefined;
      this.share = customItems && customItems.share === 0 ? true : false;
      this.sale = customItems && customItems.sale === 0 ? true : false;
      this.caution = this.file.caution && this.file.caution === 1 ? true : false;
      this.protect = this.file.protect && this.file.protect === 1 ? true : false;
      this.rating = this.file.rating ? this.file.rating : 0;
      this.type = this.file.type && this.file.extension ? getFileType(this.file.extension, this.file.type) : "";
      const meta = this.file.meta ? JSON.parse(this.file.meta) : undefined;
      // TODO:S3のURIかS3のキーかは要検討
      const thumbnailUri = meta && meta.thumbnailUri ? meta.thumbnailUri.split('/') : [];
      const previewUri = meta && meta.previewUri ? meta.previewUri.split('/') : [];      

      switch(this.type){
        case "image": {
          if(thumbnailUri.length > 0){
            this.src = (await Storage.get(`${thumbnailUri[4]}/${thumbnailUri[5]}/${thumbnailUri[6]}/${thumbnailUri[7]}`, {download: false, expires: 300, contentType: 'image/jpeg'})).toString();
          }
          break;
        }
        case "video": {
          if(thumbnailUri.length > 0 && previewUri.length > 0){
            this.src = (await Storage.get(`${previewUri[4]}/${previewUri[5]}/${previewUri[6]}/${previewUri[7]}`, {download: false, expires: 300, contentType: 'video/mp4'})).toString(); 
            this.poster = (await Storage.get(`${thumbnailUri[4]}/${thumbnailUri[5]}/${thumbnailUri[6]}/${thumbnailUri[7]}`, {download: false, expires: 300, contentType: 'image/jpeg'})).toString();
          }
          break;
        }
        case "audio": {
          if(previewUri.length > 0){
            this.src = (await Storage.get(`${previewUri[4]}/${previewUri[5]}/${previewUri[6]}/${previewUri[7]}`, {download: false, expires: 300, contentType: 'audio/mpeg'})).toString();
          }
          break;
        }
        default : {
          this.src = getIcon(this.type);
          break;
        }
      }

      if(this.type !== 'image' && this.type !== 'video'){
        this.imgMaxHeight = "200px";
        this.imgClass = "";
      }else{
        this.imgClass = "grey darken";
        this.imgMaxHeight = "400px";
      }
    }

  }
}
