
import { Component } from "vue-property-decorator";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import { UpdateFileInput } from "@/API";
import moment from "moment";
import FileEditBaseForm from '@/components/files/edit/FileEditBaseForm.vue';
import { FileSearchService } from "@/services/files/FileSearch";

@Component
export default class FileMultipleEditForm extends FileEditBaseForm {
  name = "file-multiple-edit-form";

  private fileIds: string[] = [];
  private fileSearchService = new FileSearchService();

  protected isTitle = false;
  protected isCaption = false;
  protected isDatetime = false;
  protected isByline = false;
  protected isCity = false;
  protected isInstructions = false;
  protected isOriginal = false;
  protected isKeywords = false;
  protected isRating = false;
  protected isProtect = false;
  protected isCaution = false;
  protected isReuse = false;
  protected isShare = false;
  protected isSale = false;
  protected isMemo = false;
  protected isPublishedStatus = false;
  protected isOriginalEdition = false;
  protected isStorageLocation = false;

  protected setDefaulMultiple(){
    this.setDefault();

    this.isTitle = false;
    this.isCaption = false;
    this.isDatetime = false;
    this.isByline = false;
    this.isCity = false;
    this.isInstructions = false;
    this.isOriginal = false;
    this.isKeywords = false;
    this.isRating = false;
    this.isProtect = false;
    this.isCaution = false;
    this.isReuse = false;
    this.isShare = false;
    this.isSale = false;
    this.isMemo = false;
    this.isPublishedStatus = false;
    this.isOriginalEdition = false;
    this.isStorageLocation = false;
  }
  
  protected setFiles(fileIds: string[]){
    this.fileIds = fileIds;
  }

  protected async multipleUpdate(){
    if(!this.customValidate()){
      return;
    }

    if(this.isEdit()){
      const files = await this.fileSearchService.searchFileIds(this.fileIds);

      for(const fileId of this.fileIds){
        const inputFile: UpdateFileInput = {
          id: fileId,
        };

        if(this.isRating){
          inputFile.rating = this.rating ? this.rating : 0;
        }
        if(this.isTitle){
          inputFile.title = this.title ? this.title : null;
        }
        if(this.isCaption){
          inputFile.caption = this.caption ? this.caption : null;
        }
        if(this.isInstructions){
          inputFile.instructions = this.instructions ? this.instructions : null;
        }
        if(this.isMemo){
          inputFile.memo = this.memo ? this.memo : null;
        }
        if(this.isProtect){
          inputFile.protect = this.protect ? 1 : 0;
        }
        if(this.isOriginal){
          inputFile.original = this.original ? this.original : null;
        }
        if(this.isCaution){
          inputFile.caution = this.caution ? 1 : 0;
          inputFile.cautionInstructions = this.cautionInstructions ? this.cautionInstructions : null;
        }
        if(this.isReuse){
          inputFile.reuse = this.selectedReuseItem.value;
          inputFile.reuseCondition = this.reuseCondition ? this.reuseCondition : null;
        }
        if(this.isKeywords){
          inputFile.keywords = this.selectedKeywords;
        }
        if(this.isCity || this.isByline || this.isDatetime || this.isSale || this.isShare || this.publishedStatus || this.isOriginalEdition || this.isStorageLocation){
          const file = files.find(x => x.id === fileId);
          if(file){
            const meta = file.meta ? JSON.parse(file.meta) : {};
            if(this.isCity){
              meta.city =  this.selectedCity === 1 && this.city ? this.city : null;
            }
            if(this.isByline){
              meta.byline = this.selectedByline === 1 && this.byline ? this.byline : null;
            }
            if(this.isDatetime){
              // ミリ秒を残すために、あえて比較する
              const dateTime = `${this.date} ${this.time}`;
              if(this.selectedDatetime === 1 && this.date && this.time){
                if(moment.parseZone(this.meta.datetimeOriginated).format("YYYY-MM-DD HH:mm:ss") !== dateTime){
                  meta.datetimeOriginated = moment.parseZone(dateTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.SSS");
                  meta.aroundYearOriginated = null;
                  meta.ageOriginated = null;
                }
              }else if(this.selectedDatetime === 1 && this.date ){
                meta.datetimeOriginated = moment.parseZone(this.date, "YYYY-MM-DD").format("YYYY-MM-DD");
                meta.aroundYearOriginated = null;
                meta.ageOriginated = null;
              }else if(this.selectedDatetime === 2 && this.yearOriginated && this.monthOriginated){
                meta.datetimeOriginated = moment.parseZone(`${this.yearOriginated.text}-${this.monthOriginated.text}`, 'YYYY-MM').format('YYYY-MM');
                meta.aroundYearOriginated = null;
                meta.ageOriginated = null;
              }else if(this.selectedDatetime === 2 && this.yearOriginated){
                meta.datetimeOriginated = moment.parseZone(`${this.yearOriginated.text}`, 'YYYY').format('YYYY');
                meta.aroundYearOriginated = null;
                meta.ageOriginated = null;
              }else if(this.selectedDatetime === 3 && this.aroundYearOriginated){
                meta.aroundYearOriginated = parseInt(moment.parseZone(`${this.aroundYearOriginated.text}`, 'YYYY').format('YYYY'), 10);
                meta.datetimeOriginated = null;
                meta.ageOriginated = null;
              }else if(this.selectedDatetime === 4 && this.ageOriginated){
                meta.ageOriginated = parseInt(moment.parseZone(`${this.ageOriginated.text}`, 'YYYY').format('YYYY'), 10);
                meta.datetimeOriginated = null;
                meta.aroundYearOriginated = null;
              }else{
                meta.datetimeOriginated = null;
                meta.aroundYearOriginated = null;
                meta.ageOriginated = null;
              }
              meta.originatedStatus = this.selectedDatetime;
            }
            inputFile.meta = JSON.stringify(meta);

            const customItems = file.customItems ? JSON.parse(file.customItems) : {};
            if(this.isSale){
              customItems.sale = this.selectedSaleItem.value;
              customItems.saleCondition = this.saleCondition ? this.saleCondition : null;
            }
            if(this.isShare){
              customItems.share = this.selectedShareItem.value;
              customItems.shareCondition = this.shareCondition ? this.shareCondition : null;
            }

            if(this.isPublishedStatus){
              customItems.publishedStatus = this.publishedStatus;
              customItems.publishedDate = this.publishedStatus && this.publishedStatus === 1 && moment.parseZone(`${this.publishedDate}`, 'YYYY-MM-DD').format('YYYY-MM-DD') ? this.publishedDate : null;
              customItems.publishedMedia = this.publishedStatus && this.publishedStatus === 1 && this.publishedMedia ? this.publishedMedia : null;
              customItems.publishedPage = this.publishedStatus && this.publishedStatus === 1 && this.publishedPage ? parseInt(this.publishedPage, 10) : null;
            }

            if(this.isOriginalEdition){
              customItems.originalEdition = this.originalEdition.value;
            }

            if(this.isStorageLocation){
              customItems.storageLocation = this.storageLocation ? this.storageLocation : null;
            }
            inputFile.customItems = JSON.stringify(customItems);
          }
        }
        await API.graphql(graphqlOperation(mutations.updateFile, {input: inputFile}));
      }
    }
  }

  private isEdit(): boolean {
    if(this.isTitle || this.isCaption || this.isDatetime || this.isByline || this.isCity 
      || this.isInstructions || this.isOriginal || this.isKeywords || this.isRating || this.isProtect 
      || this.isCaution || this.isReuse || this.isShare || this.isSale || this.isMemo || this.isPublishedStatus
      || this.isOriginalEdition || this.isStorageLocation){
      return true;
    }else{
      return false;
    }
  }
}
