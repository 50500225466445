
























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Confirm from '@/components/Confirm.vue';
import { SHARE_POST_PROCESS } from '@/utils/ShareUtils';
import { deleteSharing } from '@/services/share/ShareDelete';
import { changeSharingStatus } from '@/services/share/ShareEdit';
import { SHARE_STATUS } from '@/utils/ShareUtils';
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import { decodeRoleJson, isAllow } from '@/utils/RoleUtils';
import { isActiveServie } from '@/utils/AccountSettingUtils';

@Component({
    components:{
        Confirm,
        Dialog,
        Loading,
    }
})
export default class ShareMenu extends Vue {
  private innerSheet = false;
  private dialog = false;
  private confirmMode = '';
  private confirmTitle = '';
  private confirmMessage = '';
  private postProcess = '';

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private isOperate = false;

  @Prop({default:false})
  private sheet;
  @Prop()
  private sharing;
  @Prop({default:-1})
  private status;

  @Watch('sheet')
  private onChangeSheet(newValue){
    this.innerSheet = newValue;
  }

  @Watch('innerSheet')
  private onChangeInnerSheet(){
    this.$emit('share-menu-sheet', this.innerSheet);
  }

  private async created() {
    await this.setDefalut();
  }

  private async mounted() {
    await this.setDefalut();
  }

  private async setDefalut(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.isOperate = isAllow("sharing", "manage", role.sharing?.manage) && await isActiveServie("sharing");
  }

  private async onClickChangeStatus(){
    switch(this.status){
      case SHARE_STATUS.sharing: {
        this.confirmTitle = 'ステータス変更確認';
        this.confirmMessage = 'この共有を一時停止しますか？';
        this.confirmMode = 'warning';
        this.postProcess = SHARE_POST_PROCESS.ChangeStatus;
        this.dialog = true;
        break;
      }
      case SHARE_STATUS.pending: {
        this.confirmTitle = 'ステータス変更確認';
        this.confirmMessage = 'この共有を再開しますか？';
        this.confirmMode = 'info';
        this.postProcess = SHARE_POST_PROCESS.ChangeStatus;
        this.dialog = true;
        break;
      }
    }
  }

  private async onClickDelete(){
    this.confirmTitle = '削除確認';
    this.confirmMessage = 'すでに共有済です。\n削除してもよろしいでしょうか？';
    this.confirmMode = 'warning';
    this.postProcess = SHARE_POST_PROCESS.Delete;
    this.dialog = true;
  }

  private async confirmed(value){
    this.dialog = false;

    if(value){
      switch(this.postProcess){
        case SHARE_POST_PROCESS.Delete: {
          if(this.sharing){
            try{
              this.loading = true;
              await deleteSharing(this.sharing.id);
              this.loading = false;
              this.resultDialog = true;
              this.resultDialogMode = "info";
              this.resultDialogTitle = "削除成功";
              this.resultDialogMessage = "共有の削除に成功しました。"
            }catch(error){
              this.loading = false;
              this.resultDialog = true;
              this.resultDialogMode = "error";
              this.resultDialogTitle = "削除失敗";
              this.resultDialogMessage = "共有の削除に失敗しました。"
            }finally{
              this.loading = false;
            }
          }
          break;
        }
        case SHARE_POST_PROCESS.ChangeStatus: {
          if(this.sharing){
            try{
              this.loading = true;
              const newStatus = this.status === SHARE_STATUS.pending ? SHARE_STATUS.sharing : SHARE_STATUS.pending;
              await changeSharingStatus(this.sharing.id, newStatus);
              this.loading = false;
              this.resultDialog = true;
              this.resultDialogMode = "info";
              this.resultDialogTitle = "変更成功";
              this.resultDialogMessage = "共有ステータスの変更に成功しました。"
            }catch(error){
              this.loading = false;
              this.resultDialog = true;
              this.resultDialogMode = "error";
              this.resultDialogTitle = "変更失敗";
              this.resultDialogMessage = "共有ステータスの変更に失敗しました。"
            }finally{
              this.loading = false;
            }
          }
        }
      }
    }
  }

  private onChangeResultDialogClosed(){
    this.innerSheet = false;
    this.resultDialog = false;
    this.$emit('share-menu-result', true);
  }
}
