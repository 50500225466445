import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { mainLayout } from '@/components/layout/MainLayout'
import UserDetailsView from '@/views/user/UserDetailsView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // 取材
  {
    // 取材一覧
    path: '/:accountId/user/details',
    name: 'UserDetailsView',
    meta: { requiresAuth: true },
    component: mainLayout(UserDetailsView),
  },
]

export default routes
