import { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteConfig> = [
  {
    //ログイン前ホーム画面
    path: '/:accountId/',
    name: 'Home',
    component: Home
  },
  {
    //ログイン前ホーム画面
    path: '/',
    name: 'RootHome',
    component: Home
  }
]
export default routes