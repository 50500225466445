


















import { Prop, Component, Vue } from 'vue-property-decorator';

export const videoControl  = {
    bind(el, binding) {
        binding.value ? el.play() : el.pause();
    },
    update(el, binding) {
        binding.value ? el.play() : el.pause();
    },
};

@Component({
    directives: {
        videoControl,
    },
})
export default class Video extends Vue {
    private play = false;

    @Prop()
    private src;
    @Prop()
    private poster;

}
