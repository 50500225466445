






















































import { Component, Prop, Watch } from "vue-property-decorator";
import * as service from "@/services/coverage/coverageServise";
import CoverageBaseForm from "./CoverageBaseForm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import Confirm from "@/components/Confirm.vue";

@Component({
  components: {
    Dialog,
    Loading,
    Confirm,
  },
})
export default class CoverageFileEditForm extends CoverageBaseForm {
  name = "CoverageFileEditForm";
  addFiles = [];
  filePath?: string;
  innerDialog = false;
  fileEditFormValid = false;

  @Prop({ default: false })
  private dialog!: boolean;

  @Watch("dialog")
  onChangeDialog(newValue) {
    this.innerDialog = newValue;
  }

  close() {
    this.$emit("file-dialog-closed");
  }

  async created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === "selectCoverage") {
        this.id = this.$store.state.selectCoverageId;
        this.setCoverage();
      }
    });
  }

  async mounted() {
    this.id = this.$store.state.selectCoverageId;
    this.setCoverage();
  }

  protected deleteItem(filePath: string) {
    this.filePath = filePath;
    this.confirmMode = "warning";
    this.confirmTitle = "削除確認";
    this.confirmMessage = "削除してもよろしいでしょうか？";
    this.confirmDialog = true;
  }

  async deleteFile(value: boolean) {
    this.confirmDialog = false;
    if (!value) {
      return;
    }
    this.loading = true;
    if (!this.files || !this.filePath) {
      this.filePath = undefined;
      this.confirmDialog = false;
      await this.setCoverage();
      this.loading = false;
      return;
    }

    await this.CoverageServise.deleteCoverageFiles(
      this.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.files,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.filePath!
    )
      .then(() => {
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "削除失敗";
        this.resultDialogMessage = `添付ファイルの削除に失敗しました。`;
      })
      .finally(async () => {
        this.loading = false;
        this.filePath = undefined;
        this.confirmDialog = false;
      });
  }

  async addfile(fileInfo: Array<service.FileInfo>) {
    if(!this.$store.getters.user?.attributes){
      return;
    }
    const accountId = this.$store.getters.user?.attributes["custom:account_id"] || "";
    if (this.addFiles.length < 0) {
      return;
    }
    if (!(this.$refs.fileEditForm as Vue & {
          validate: () => boolean;
        }).validate()) {
      return;
    }

    this.loading = true;
    
    await this.CoverageServise.addCoverageFiles(
      this.id,
      accountId,
      fileInfo,
      this.addFiles
    )
      .then(() => {
        this.addFiles = [];
      })
      .catch((e) => {
        console.log(e);
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "追加失敗";
        this.resultDialogMessage = `添付ファイルの追加に失敗しました。`;
      })
      .finally(() => {
        this.addFiles = [];
        this.loading = false;
      });
  }
}
