






















































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import FileUploadBaseForm from "@/components/files/create/FileUploadBaseForm.vue";
import FileDragAndDrop from "@/components/files/create/FileDragAndDrop.vue";
import FileDataTable from "@/components/files/create/FileDataTable.vue";
import CoverageSearchDialog from "@/components/files/CoverageSearchDialog.vue";
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";

@Component({
  components: {
    CoverageSearchDialog,
    FileDragAndDrop,
    FileDataTable,
    Loading,
    Dialog,
  }
})
export default class FileGroupCreate extends FileUploadBaseForm {
  name = "file-group-create";

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = '';
  private resultDialogTitle = '';
  private resultDialogMessage = '';

  private isNotFile = false;
  private isMaxFileCount = false;
  private coverageSearch = false;
  private fgcCoverageId = '';
  private tableLoading = false;
  private isReset = false;
  private isIOSAlert = true;

  @Watch('files')
  private onChangeFiles(){
    if(this.isNotFile && this.files.length > 0){
      this.isNotFile = false;
    }

    if(this.isMaxFileCount && this.files.length <= this.UPLOAD_LIMIT_COUNT){
      this.isMaxFileCount = false;
    }else if(!this.isMaxFileCount && this.files.length > this.UPLOAD_LIMIT_COUNT){
      this.isMaxFileCount = true;
    }
  }

  @Watch('coverageTitle')
  private onChangeCoverage(newValue){
    if(!newValue){
      this.coverage = {};
    }
  }

  private async created(){
    if(this.$route.query.coverageId){
      this.fgcCoverageId = this.$route.query.coverageId.toString();
    }

    await this.setDefault("", this.fgcCoverageId);
    await this.setLocalStorageValue();
  }

  private async setLocalStorageValue(){
    if(window.localStorage){
      const isIOSAlertStr = localStorage.getItem('isIOSAlert');
      this.isIOSAlert = isIOSAlertStr === 'false' ? false : true;
      const isProtectStr = localStorage.getItem('isProtect');
      this.protect = isProtectStr === 'false' ? false : true;
      const isCaptionAllAssignedStr = localStorage.getItem('isCaptionAllAssgined');
      this.isCaptionAllAssgined = isCaptionAllAssignedStr === 'true' ? true : false;
      const isPublishedInfoAllAssginedStr = localStorage.getItem('isPublishedInfoAllAssgined');
      this.isPublishedInfoAllAssgined = isPublishedInfoAllAssginedStr === 'true' ? true : false;
      const isAutoInputBylineStr = localStorage.getItem('isAutoInputByline');
      this.isAutoInputByline = isAutoInputBylineStr === 'false' ? false : true;
      if(this.isAutoInputByline === true){
        this.byline = this.$store.getters.user && this.$store.getters.user.attributes ? this.$store.getters.user.attributes.name : "";
      }
      const isAutoDatetimeStr = localStorage.getItem('isAutoDatetime');
      this.isAutoDatetime = isAutoDatetimeStr === 'false' ? false : true;
    }
  }

  private onChangedFileDragAndDrop(value){
    this.files = value;
  }

  private onChangedFileDataTable(value){
    this.files = value;
  }

  private onChangedTableLoading(value){
    this.tableLoading = value;
  }

  private onResetCompleted(){
    this.isReset = false;
  }

  private back(){
    this.$router.push({name: 'MyPage'});
  }

  private onCoverageSearchDialog(value){
    this.coverageSearch = value;
  }

  private onCoverageSearchSelected(value){
    this.coverageSearch = false;
    if(value){
      this.coverage = value;
      this.coverageId = value.id;
      this.coverageTitle = value.title;
    }else{
      this.coverage = {};
      this.coverageId = "";
      this.coverageTitle = "";
    }
  }

  private async onClickUpload(){
    if(this.tableLoading){
      return;
    }

    if(this.files.length <= 0){
      this.isNotFile = true;
      return;
    }

    if(this.files.length > this.UPLOAD_LIMIT_COUNT){
      this.isMaxFileCount = true;
      return;
    }

    if(!(this.$refs.fileGroupCreateForm as Vue & {validate: () => boolean}).validate()){
      return;
    }

    if(!this.customValidate()){
      return;
    }
    
    await this.upload();

    if(this.hasError){
      this.resultDialog = true;
      this.resultDialogMode = 'error';
      this.resultDialogTitle = 'アップロード失敗';
      this.resultDialogMessage = this.errorMessage;
    }
  }

  private onChangeResultDialogClosed(){
      this.resultDialog = false;
      this.resultDialogMode = '';
      this.resultDialogTitle = '';
      this.resultDialogMessage = '';
      this.hasError = false;
      this.errorMessage = '';
  }

  private async onClickUploadCancel(){
    this.cancel = true;
    await this.abortUpload();
  }

  private async onClickUploadcompleted(){
    await this.setDefault("", this.fgcCoverageId);
    this.isReset = true;

    (this.$refs.fileGroupCreateForm as Vue & {
      resetValidation: () => void;
    }).resetValidation();

    await this.setLocalStorageValue();
  }

  private onClosedIOSAlert(){
    if(window.localStorage){
      localStorage.setItem('isIOSAlert', 'false');
    }
  }

  private onChangeProtect(value){
    if(window.localStorage){
      const isProtectStr = value === false ? 'false' : 'true';
      localStorage.setItem('isProtect', isProtectStr);
    }
  }

  private onChangeCaptionAllAssigned(value){
    if(window.localStorage){
      const isCaptionAllAssginedStr = value === false ? 'false' : 'true';
      localStorage.setItem('isCaptionAllAssgined', isCaptionAllAssginedStr);
    }
  }

  private onChangePublishedInfoAllAssgined(value){
    if(window.localStorage){
      const isPublishedInfoAllAssginedStr = value === false ? 'false' : 'true';
      localStorage.setItem('isPublishedInfoAllAssgined', isPublishedInfoAllAssginedStr);
    }
  }

  private onChangeAutoInputByline(value){
    if(window.localStorage){
      const isAutoInputBylineStr = value === false ? 'false' : 'true';
      localStorage.setItem('isAutoInputByline', isAutoInputBylineStr);
    }

    if(value === true){
      this.byline = this.$store.getters.user && this.$store.getters.user.attributes ? this.$store.getters.user.attributes.name : "";
    }
  }

  private onChangeAutoInputDatetime(value){
    if(window.localStorage){
      const isAutoDatetimeStr = value === false ? 'false' : 'true';
      localStorage.setItem('isAutoDatetime', isAutoDatetimeStr);
    }
  }

  private onUpdateSearchInputSelectedKeywords(value){
    this.keywordRulesMethod(value);
  }
}
