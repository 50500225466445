



















import { FileSearchService } from '@/services/files/FileSearch';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class FileSearchHeader extends Vue {
  private sheet = false;
  
  @Prop({ default: 1 })
  private page;
  @Prop({ default: 0 })
  private total;
  @Prop({ default: 1 })
  private limit;
  @Prop({ default: ''})
  private mode;

  private innerPage = 0;

  private created(){
    this.innerPage = this.page;
  }

  private get lastPage(): number {
    let innerTotal = this.total;
    if(innerTotal > FileSearchService.SEARCH_LIMIT){
      innerTotal = FileSearchService.SEARCH_LIMIT
    }
    return Math.ceil( innerTotal / this.limit );
  }

  @Watch('page')
  private async onPageChange(newValue){
    this.innerPage = newValue;
  }

  @Watch('innerPage')
  private async onInnerPageChange(newPage){
    this.$emit("page-number-changed", newPage);
  }
}

