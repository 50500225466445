



















































































































































































import { Component, Vue} from "vue-property-decorator";
import Confirm from "@/components/Confirm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import UserBaseForm from "@/components/admin/user/UserBaseForm.vue";
import AccesslevelSelect from "./AccesslevelSelect.vue";
import BooleanSelect from "./BooleanSelect.vue";


@Component({
  components: {
    Confirm,
    Dialog,
    Loading,
    AccesslevelSelect,
    BooleanSelect
  },
})
export default class UserCreateForm extends UserBaseForm {
  name = "user-create-form";

  private loading = false;
  private confirmDialog = false;
  private confirmMode = "";
  private confirmTitle = "";
  private confirmMessage = "";
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private async created() {
    await this.setService();
    this.user = this.$store.getters.user;
    this.accountId =
      this.user && this.user.attributes
        ? this.user.attributes["custom:account_id"]
        : "";
  }

  private async onClickCreate() {
    if (
      !(this.$refs.userCreateForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      return;
    }

    try {
      this.loading = true;
      await this.create();
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "作成成功";
      this.resultDialogMessage = "ユーザーの作成に成功しました。";
      this.onClickBack();
    } catch (error) {
      console.error(error);
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "作成失敗";
      this.resultDialogMessage = `ユーザーの作成に失敗しました。`;
    } finally {
      this.loading = false;
    }
  }

  private onChangeResultDialogClosed() {
    this.resultDialog = false;
    this.onClickBack();
  }

  private async onClickBack() {
    await this.setDefault();
    (this.$refs.userCreateForm as Vue & {
        resetValidation: () => boolean;
      }).resetValidation()

    this.$emit("user-create-form-closed", true);
  }
}
