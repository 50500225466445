import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router';
import { mainLayout } from '@/components/layout/MainLayout';
import AdminMain from "@/views/admin/AdminMain.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // アカウント管理者画面,ログイン&管理者権限 必須
  {
    // メニュー一覧
    path: '/:accountId/admin',
    name: 'Admin',
    meta: { requiresAuth: true, requiresManagementAccess: true },
    component: mainLayout(AdminMain),
  },
  {
    // サイト設定
    path: '/:accountId/admin/site_setting',
    name: 'SiteSetting',
  },
  {
    // ユーザー一覧
    path: '/:accountId/admin/user_list',
    name: 'UserList',
  },
  {
    // ユーザー登録
    path: '/:accountId/admin/user_create',
    name: 'UserCreate',
  },
  {
    // ユーザー編集
    path: '/:accountId/admin/user_edit',
    name: 'UserEdit',
  }
]

export default routes
