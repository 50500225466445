


















































































































import { Component, Vue } from "vue-property-decorator";
import * as service from "@/services/coverage/coverageDeskService";
import CoverageDeskBaseForm from "./CoverageDeskBaseForm.vue";
import Loading from "@/components/Loading.vue";
import Confirm from "@/components/Confirm.vue";

@Component({
  components: {
    Loading,
    Confirm,
  },
})
export default class CoverageDeskEditForm extends CoverageDeskBaseForm {
  name = "coverage-desk-edit-form";

  async created() {
    this.$store.subscribe(async (mutation) => {
      if (mutation.type === "selectCoverageDesk") {
        this.selectItem = this.$store.state.selectCoverageDeskId;
        this.setCoverageDesk(this.selectItem);
      }
    });
  }

  async mounted() {
    this.selectItem = this.$store.state.selectCoverageDeskId;
    this.loading = true;
    await this.setCoverageDesk(this.selectItem);
    this.loading = false;
  }

  async updateCoverageDesk() {
    if (!this.validate()) {
      return;
    }
    const attributes = this.$store.state.user.attributes;
    const form: service.EditForm = {
      id: this.selectItem,
      createdUserId: attributes.sub,
      name: this.deskName,
      startDate: this.startDate,
      endDate: this.endDate,
      memo: this.memo,
    };
    this.loading = true;
    await this.CoverageDeskService.updateCoverageDesk(form)
      .then(() => {
        this.editClose();
        this.setDefault();
        (this.$refs.form as Vue & {
          resetValidation: () => void;
        }).resetValidation();
      })
      .catch((e) => {
        console.log(e);
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "デスク情報登録失敗";
        this.resultDialogMessage = `デスク情報登録に失敗しました。`;
      })
      .finally(() => (this.loading = false));
  }

  protected deleteItem() {
    this.confirmMode = "warning";
    this.confirmTitle = "削除確認";
    this.confirmMessage = "削除してもよろしいでしょうか？";
    this.confirmDialog = true;
  }

  async deleteCoverageDesk(value: boolean) {
    this.confirmDialog = false;
    if (!value || !this.selectItem) {
      return;
    }
    await this.CoverageDeskService.deleteCoverageDesk(this.selectItem);
    this.editClose();
    this.setDefault();
    (this.$refs.form as Vue & {
      resetValidation: () => void;
    }).resetValidation();
  }

  private editClose() {
    this.confirmDialog = false;
    this.$emit("editClose");
  }
}
