

















































































































import { Component, Vue } from "vue-property-decorator";
import * as service from "@/services/coverage/coverageInfoService";
import CoverageInfoBaseForm from "./CoverageInfoBaseForm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import Confirm from "@/components/Confirm.vue";

@Component({
  components: {
    Dialog,
    Loading,
    Confirm,
  },
})
export default class CoverageInfoEditForm extends CoverageInfoBaseForm {
  name = "coverag-info-edit-form";

  async created() {
    this.$store.subscribe(async (mutation) => {
      if (mutation.type === "selectCoverageInfo") {
        this.selectItem = this.$store.state.selectCoverageInfoId;
        this.setCoverageInfo(this.selectItem);
      }
    });
  }

  async mounted() {
    this.selectItem = this.$store.state.selectCoverageInfoId;
    await this.setCoverageInfo(this.selectItem);
  }

  async updateCoverageInfo() {
    if (!this.validate()) {
      return;
    }
    const attributes = this.$store.state.user.attributes;
    const form: service.EditForm = {
      id: this.selectItem,
      createdUserId: attributes.sub,
      body: this.body,
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.loading = true;
    await this.CoverageInfoService.updateCoverageInfo(form)
      .then(() => {
        this.editClose();
        this.setDefault();
        (this.$refs.form as Vue & {
          resetValidation: () => void;
        }).resetValidation();
      })
      .catch((e) => {
        console.log(e);
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "連絡事項更新失敗";
        this.resultDialogMessage = `連絡事項の更新に失敗しました。`;
      })
      .finally(() => (this.loading = false));
  }

  deleteItem() {
    this.confirmMode = "warning";
    this.confirmTitle = "削除確認";
    this.confirmMessage = "削除してもよろしいでしょうか？";
    this.confirmDialog = true;
  }

  async deleteCoverageInfo(value: boolean) {
    this.confirmDialog = false;
    if (!value || !this.selectItem) {
      return;
    }
    this.loading = true;
    await this.CoverageInfoService.deleteCoverageInfo(this.selectItem)
      .then(() => {
        this.editClose();
        this.setDefault();
        (this.$refs.form as Vue & {
          resetValidation: () => void;
        }).resetValidation();
      })
      .catch((e) => {
        console.log(e);
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "連絡事項削除失敗";
        this.resultDialogMessage = `連絡事項の削除に失敗しました。`;
      })
      .finally(() => (this.loading = false));
  }

  private editClose() {
    this.$emit("editClose");
  }
}
