











































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Storage } from "aws-amplify";
import moment from "moment";
import { getFileType, getIcon } from "@/utils/FileUtils";

@Component
export default class FileListItem extends Vue {
  @Prop()
  private file;
  
  private title = '';
  private fileName = '';
  private datetimeOriginated = '';
  private createdAt = '';
  private byline = '';
  private src = '';
  private fileType = '';

  private created(){
    this.setFile(); 
  }
  
  @Watch('file')
  private onChangeFile(){
    this.setFile();
  }
  
  private async setFile(){
    this.src = "";
    if(this.file){
      this.title = this.file.title ? this.file.title : '';
      this.fileName = this.file.fileName ? this.file.fileName : '';
      const meta = this.file.meta ? JSON.parse(this.file.meta) : undefined;
      this.datetimeOriginated = '不明';
      if(meta && meta.datetimeOriginated){
        if((meta.originatedStatus === 0 || meta.originatedStatus === 1) && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).format('YYYY年MM月DD日 HH:mm:ss.SSS');
        }else if(meta.originatedStatus === 1 && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss', true).format('YYYY年MM月DD日 HH:mm:ss');
        }else if((meta.originatedStatus === 0 || meta.originatedStatus === 1) && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DD', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DD', true).format('YYYY年MM月DD日');
        }else if(meta.originatedStatus === 2 && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM', true).format('YYYY年MM月');
        }else if(meta.originatedStatus === 2 && moment.parseZone(meta.datetimeOriginated, 'YYYY', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY', true).format('YYYY年');
        }else if((meta.originatedStatus === null || meta.originatedStatus === undefined) && moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).isValid()){
          this.datetimeOriginated = moment.parseZone(meta.datetimeOriginated, 'YYYY-MM-DDTHH:mm:ss.SSS', true).format('YYYY年MM月DD日 HH:mm:ss.SSS');
        }
      }else if(meta && meta.originatedStatus === 3 && meta.aroundYearOriginated && moment.parseZone(meta.aroundYearOriginated, 'YYYY', true).isValid()){
        this.datetimeOriginated = moment.parseZone(meta.aroundYearOriginated, 'YYYY', true).format('YYYY年頃');
      }else if(meta && meta.originatedStatus === 4 && meta.ageOriginated && moment.parseZone(meta.ageOriginated, 'YYYY', true).isValid()){
        this.datetimeOriginated = moment.parseZone(meta.ageOriginated, 'YYYY', true).format('YYYY年代');
      }
      
      this.byline = meta && meta.byline ? meta.byline : '';
      this.createdAt = this.file.createdAt ? moment(this.file.createdAt).local().format('YYYY-MM-DD HH:mm:ss.SSSZ') : '';
      this.fileType = this.file.type && this.file.extension ? getFileType(this.file.extension, this.file.type) : '';

      const thumbnailUri = meta && meta.thumbnailUri ? meta.thumbnailUri.split('/') : [];
      if(thumbnailUri.length > 0){
        this.src = (await Storage.get(`${thumbnailUri[4]}/${thumbnailUri[5]}/${thumbnailUri[6]}/${thumbnailUri[7]}`, {download: false, expires: 300, contentType: 'image/jpeg'})).toString();
      }else{
        this.src = getIcon(this.fileType);
      }
    }
  }
}
