import { CreateCoverageInput, GetCoverageQuery, UpdateCoverageInput } from "@/API";
import * as mutations from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import { API, Storage } from "aws-amplify";
import { uuid } from "vue-uuid";
import moment from "moment";
import { GraphQLResult } from "@aws-amplify/api";
import { CreateCoverageMutation, UpdateCoverageMutation } from "@/API";

export class CoverageServise {

    /**
     * 取材予定を作成
     * @param form 
     */
    async createCoverage(form: InputForm) {
        const id = uuid.v4();
        const fileInfo = new Array<FileInfo>();
        //添付ファイルをアップロード
        for (const file of form.files) {
            const info: FileInfo = this.getFileInfo(id, form.accountId, file.name);
            await Storage.put(
                info.filePath,
                file,
                {
                    contentType: file.type,
                    contentDisposition: `attachment; filename="${encodeURI(file.name)}"`
                }
            ).then(() => fileInfo.push(info)).catch((e) => console.log(e));
        }
        const createCoverageInput: CreateCoverageInput = {
            id: id,
            title: form.title,
            location: form.location,
            startAt: form.startAt,
            endAt: form.endAt,
            description: form.description,
            memo: form.memo,
            attachmentFile: JSON.stringify(fileInfo),
            createdUserId: form.createdUserId,
            ownedAccountId: form.accountId,
            ownedDepartment: form.department,
            assignedUserId: form.assignedUserId,
            status: 1,
            deleted: 0,
        };
        try {
            (await API.graphql({ query: mutations.createCoverage, variables: { input: createCoverageInput } })) as GraphQLResult<CreateCoverageMutation>;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    /**
     * 取材予定を更新
     * @param form 
     */
    async updateCoverage(form: EditForm) {
        const updateCoverageInput: UpdateCoverageInput = {
            id: form.id,
            title: form.title,
            location: form.location,
            startAt: form.startAt,
            endAt: form.endAt,
            description: form.description,
            memo: form.memo,
            createdUserId: form.createdUserId,
            assignedUserId: form.assignedUserId,
            status: form.status,
            deleted: form.deleted,
        };
        try {
            (await API.graphql({ query: mutations.updateCoverage, variables: { input: updateCoverageInput }})) as GraphQLResult<UpdateCoverageMutation>;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    /**
     * 取材予定の捨て更新
     * @param id 
     * @param status 
     */
    async updateCoverageStatus(id: string, status: number) {
        const updateCoverageInput: UpdateCoverageInput = {
            id: id,
            status: status
        };

        try {
            await API.graphql({ query: mutations.updateCoverage, variables: { input: updateCoverageInput } }) as GraphQLResult<UpdateCoverageMutation>;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    /**
     * 取材予定に添付ファイルを追加
     * @param id 
     * @param accountId 
     * @param fileInfo 
     * @param addFiles 
     */
    async addCoverageFiles(id: string, accountId: string, fileInfo: Array<FileInfo>, addFiles: Array<File>) {
        //添付ファイルをアップロード
        for (const file of addFiles) {
            const info: FileInfo = this.getFileInfo(id, accountId, file.name);
            await Storage.put(
                info.filePath,
                file,
                {
                    contentType: file.type,
                    contentDisposition: `attachment; filename="${encodeURI(file.name)}"`
                }
            ).catch((e) => console.log(e));
            fileInfo.push(info);
        }
        const updateCoverageInput: UpdateCoverageInput = {
            id: id,
            attachmentFile: JSON.stringify(fileInfo)
        };
        try {
            (await API.graphql({ query: mutations.updateCoverage, variables: { input: updateCoverageInput } })) as GraphQLResult<UpdateCoverageMutation>
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    /**
     * 取材予定の添付ファイルを削除
     * @param id 
     * @param fileInfo 
     * @param filePath 
     */
    async deleteCoverageFiles(id: string,fileInfo: Array<FileInfo>, filePath: string) {
        const i = fileInfo.findIndex(r => r.filePath === filePath)
        fileInfo.splice(i,1)
        const updateCoverageInput: UpdateCoverageInput = {
            id: id,
            attachmentFile: JSON.stringify(fileInfo)
        };
        (API.graphql({
            query: mutations.updateCoverage,
            variables: { input: updateCoverageInput },
        }) as Promise<GraphQLResult<UpdateCoverageMutation>>).then(async () => {
            //レコードを更新してから消す
            await Storage.remove(filePath).catch();
        }).catch((e) => {
            console.log(e);
            throw e;
        });

    }

    /**
     * 取材予定を削除
     * @param id 
     */
    async deleteCoverage(id: string) {

        try {
            const { data } = (await API.graphql({
                query: queries.getCoverage,
                variables: { id: await id },
            })) as GraphQLResult<GetCoverageQuery>;

            if (data?.getCoverage && data.getCoverage.attachmentFile) {
                const files = JSON.parse(data?.getCoverage?.attachmentFile);
                for (const file of files) {
                    await Storage.remove(file.filePath, file).catch();
                }
                //ファイルを削除
                const createCoverageInput: UpdateCoverageInput = {
                    id: id,
                    deleted: 1,
                    deletedAt: moment().toISOString(),
                };
                //削除フラグを立てる
                await API.graphql({
                    query: mutations.updateCoverage,
                    variables: { input: createCoverageInput },
                });
            }
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    /**
     * 添付ファイルのアップロード情報を生成
     * @param id 
     * @param accountId 
     * @param name 
     * @returns 
     */
    private getFileInfo(id: string, accountId: string, name: string): FileInfo {
        const filePath = [
            "coverage",
            accountId,
            id,
            uuid.v4(),
        ].join("/")
        return {
            name: name,
            filePath: filePath,
        }
    }
}

export type InputForm = {
    createdUserId: string;
    accountId: string;
    department: string;
    title: string;
    startAt: string;
    endAt: string;
    description: string;
    location: string;
    memo: string;
    assignedUserId: string;
    files: Array<File>;
}

export type EditForm = {
    id: string;
    createdUserId: string;
    title: string;
    startAt: string;
    endAt: string;
    description: string;
    location: string;
    memo: string;
    assignedUserId: string;
    status: number;
    deleted: number;
}

export type FileInfo = {
    name: string;
    filePath: string;
}

export function getStatusName(i: number) {
    const list = [
        { value: 0, text: "未対応", color: "error" },
        { value: 10, text: "取材中", color: "success" },
        { value: 90, text: "完了", color: "secondary" },
    ]
    const res = list.find(({ value }) => value === i);
    return res ? res : list[0]
}