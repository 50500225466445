
import { Component } from "vue-property-decorator";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import { UpdateFileGroupInput } from "@/API";
import FileGroupEditBaseForm from '@/components/files/edit/FileGroupEditBaseForm.vue';

@Component
export default class FileGroupMultipleEditForm extends FileGroupEditBaseForm {
  name = "file-group-multiple-edit-form";

  private fileGroupIds: string[] = [];

  protected isTitle = false;
  protected isDescription = false;
  protected isMemo = false;

  protected setDefaulMultiple(){
    this.setDefault();

    this.isTitle = false;
    this.isDescription = false;
    this.isMemo = false;
  }
  
  protected setFileGroups(fileGroupIds: string[]){
    this.fileGroupIds = fileGroupIds;
  }

  protected async multipleUpdate(){

    if(this.isEdit()){
      for(const fileGroupId of this.fileGroupIds){
        const inputFileGroup: UpdateFileGroupInput = {
          id: fileGroupId,
        };

        if(this.isTitle){
          inputFileGroup.title = this.title ? this.title : null;
        }
        if(this.isDescription){
          inputFileGroup.description = this.description ? this.description : null;
        }
        if(this.isMemo){
          inputFileGroup.memo = this.memo ? this.memo : null;
        }
        
        await API.graphql(graphqlOperation(mutations.updateFileGroup, {input: inputFileGroup}));
      }
    }
  }

  private isEdit(): boolean {
    if(this.isTitle || this.isDescription || this.isMemo){
      return true;
    }else{
      return false;
    }
  }
}
