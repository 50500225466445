
















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CoverageInfoEditForm from "@/components/coverage/info/CoverageInfoEditForm.vue";
import { CoverageInfoService } from "@/services/coverage/coverageInfoService";
import Confirm from '@/components/Confirm.vue';
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";

@Component({
  components: {
    CoverageInfoEditForm,
    Confirm
  }
})
export default class InfoMenu extends Vue {
  private innerSheet = false;
  private editDialog = false;
  private confirmDialog = false;
  private confirmMode = '';
  private confirmTitle = '';
  private confirmMessage = '';
  private infoService = new CoverageInfoService();
  private isOperate = false;

  @Prop({default:false})
  private sheet;
  @Prop()
  private coverageInfo;

  @Watch('sheet')
  private onChangeSheet(newValue){
    this.innerSheet = newValue;
  }

  @Watch('innerSheet')
  private onChangeInnerSheet(){
    this.$emit('info-menu-sheet', this.innerSheet);
  }

  private created(){
    this.setDefalut();
  }

  private mounted(){
    this.setDefalut();
  }

  private async setDefalut(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.isOperate = isAllow("coverageInfo", "operate", role.coverageInfo?.operate) && await isActiveServie("coverage");
  }

  private onClickEdit() {
    this.innerSheet = false;
    this.$store.commit("selectCoverageInfo", this.coverageInfo.id);
    this.editDialog = true;
  }

  private editClose(value) {
    this.editDialog = false;
    this.$emit("info-menu-result", value);
  }

  private onClickDelete(){
    this.innerSheet = false;
    this.confirmTitle = '削除確認';
    this.confirmMessage = 'この連絡事項を削除してもよろしいでしょうか？'
    this.confirmMode = 'warning';
    this.confirmDialog = true;
  }
  private async confirmed(value){
    this.confirmDialog = false;
    if(value){
      await this.infoService.deleteCoverageInfo(this.coverageInfo.id);
      this.$emit("info-menu-result", value);
    }
  }
}
