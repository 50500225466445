





































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Confirm from "@/components/Confirm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";

export interface DepartmentData {
    id: string;
    name: string;
}

@Component({
    components:{
        Confirm,
        Dialog,
        Loading,
    }
})
export default class DepartmentMain extends Vue {
    private headers = [
        { text: "部門名", value: "name" },
        { text: "アクション", value: "action", align: "center", sortable: false },
    ];
    private tableLoading = false;
    private loading = false;
    private confirmDialog = false;
    private confirmMode = "";
    private confirmTitle = "";
    private confirmMessage = "";
    private resultDialog = false;
    private resultDialogMode = "";
    private resultDialogTitle = "";
    private resultDialogMessage = "";

    private items: DepartmentData[] = [];
    private user;
    private accountId = "";
    private selectedItem: DepartmentData | undefined = undefined;

    @Prop({default:false})
    private show;

    @Watch("show")
    private async onChangeShow(newValue){
        if(newValue){
            await this.search();
        }
    }

    // TODO:ESによる検索が必要かはあとで検討する
    private async created(){
        this.user = this.$store.getters.user;
        this.accountId = this.user && this.user.attributes ? this.user.attributes["custom:account_id"] : "";
    }

    private async search(){
        this.tableLoading = true;
        this.items = [];
        if(!this.accountId){
            return;
        }
        try{
            console.log("ダミーデータ");
            this.items = [
                {id:"001", name:"デモ１"},
                {id:"002", name:"デモ２"},
                {id:"003", name:"デモ３"},
            ];
        }catch(error){
            console.error(error);
            this.items = [];
        }finally{
            this.tableLoading = false;
        }
    }

    private editItem(item: DepartmentData){
        this.selectedItem = item;
        console.log("編集ダイアログ");
    }

    private deleteItem(item: DepartmentData){
        this.selectedItem = item;
        this.confirmMode = "warning";
        this.confirmTitle = "削除確認";
        this.confirmMessage = "この部門データを削除してもよろしいでしょうか？";
        this.confirmDialog = true;
    }

    private async confirmed(value){
        try{
            if(value){
                this.loading = true;
                console.log("削除処理");
                console.log(this.selectedItem);
                this.loading = false;
                this.resultDialog = true;
                this.resultDialogMode = "info";
                this.resultDialogTitle = "削除完了";
                this.resultDialogMessage = "部門データの削除に成功しました。"
            }
        }catch(error){
            console.error(error);
            this.resultDialog = true;
            this.resultDialogMode = "error";
            this.resultDialogTitle = "削除失敗";
            this.resultDialogMessage = `部門データの削除に失敗しました。`;
        }finally{
            this.loading = false;
            this.confirmDialog = false;
            this.selectedItem = undefined;
        }
    }

    private async onChangeResultDialogClosed(){
        await this.search();
    }
}
