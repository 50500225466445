



























import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import FileSearchForm from "@/components/files/searches/FileSearchForm.vue";
import FileGroupSearchForm from "@/components/files/searches/FileGroupSearchForm.vue";
import { SearchCondition, SEARCH_MODE } from "@/services/files/FileSearch";


@Component({
  components:{
    FileSearchForm,
    FileGroupSearchForm,
  }
})
export default class FileAndFileGroupSearchForm extends Vue {
  private tab = "File";
  private innerCondition: SearchCondition = {}

  @Prop({default:()=>({})})
  private searchCondition;

  @Prop({default:SEARCH_MODE.File})
  private searchMode;

  @Watch('searchCondition')
  private onChangeCondition(newCondition){
    this.innerCondition = newCondition;
  }

  @Watch("searchMode")
  private onChangeSearchMode(newMode){
    this.tab = newMode;
  }

  private get storeSearchCondition(){
    return JSON.parse(JSON.stringify(this.$store.getters.searchCondition));
  }

  private get storeSearchMode(){
    return JSON.parse(JSON.stringify(this.$store.getters.searchMode));
  }

  private created(){
    this.tab = this.searchMode ? this.searchMode : this.storeSearchMode;
    this.innerCondition = this.storeSearchCondition;
  }

  private onChangeClear(){
    this.$emit("file-and-group-search-clear", true);
  }

  private onChangeSearchCondition(value){
    this.$emit("file-and-group-search-condition", value);
  }

  private onChangeSearch(value){
    this.$emit("file-and-group-search", value);
  }

  @Watch('tab')
  private onChageTab(value){
    this.$emit("file-and-group-search-mode", value);
  }
}
