



































































































































































































import { Component } from "vue-property-decorator";
import * as PageAction from "@/utils/PageActionUtils";
import * as AuthUtils from "@/utils/AuthUtils";
import * as service from "@/services/coverage/coverageServise";
import CoverageBaseForm from "./CoverageBaseForm.vue";
import CoverageStatusDialog from "@/components/home/CoverageStatusDialog.vue";
import CoverageStatus from "./CoverageStatus.vue";
import CoverageFileEditForm from "./CoverageFileEditForm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import Confirm from "@/components/Confirm.vue";
import { PGCService } from "@/API";

@Component({
  components: {
    CoverageStatusDialog,
    CoverageStatus,
    CoverageFileEditForm,
    Dialog,
    Loading,
    Confirm,
  },
})
export default class CoverageUpdateForm extends CoverageBaseForm {
  name = "coverage-edit-form";
  fileEdit = false;
  statusDialog = false;
  addFiles = [];
  fileDialog = false;

  async created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === "selectCoverage") {
        this.id = this.$store.state.selectCoverageId;
        this.setCoverage();
      }
    });
  }

  async mounted() {
    this.selectUsers = await AuthUtils.listUsers(PGCService.COVERAGE);
    this.id = this.$store.state.selectCoverageId;
    this.loading = true;
    this.setCoverage();
    this.loading = false;
  }

  onFileDialogOpened() {
    this.fileDialog = true;
  }

  onFileDialogClosed() {
    this.setCoverage();
    this.fileDialog = false;
  }

  async updateCoverage() {
    if (
      !(this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      return;
    }

    if(this.isExtraInvalid){
      return;
    }

    this.loading = true;
    const attributes = this.$store.state.user.attributes;
    const form: service.EditForm = {
      id: this.id,
      createdUserId: attributes.sub,
      title: this.title,
      startAt: this.dateformat(this.startDate, this.startTime),
      endAt: this.dateformat(this.endDate, this.endTime),
      description: this.description,
      location: this.location,
      memo: this.memo,
      assignedUserId: this.assignedUserId,
      status: this.status,
      deleted: this.deleted,
    };
    await this.CoverageServise.updateCoverage(form)
      .then(() => {
        PageAction.showCoverageEditForm();
        this.addFiles = [];
      })
      .catch((e) => {
        console.log(e);
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "取材予定更新失敗";
        this.resultDialogMessage = `取材予定の更新に失敗しました。`;
      })
      .finally(() => {
        this.loading = false;
        this.formClosed(true);
      });
  }

  protected deleteItem() {
    this.confirmMode = "warning";
    this.confirmTitle = "削除確認";
    this.confirmMessage = "削除してもよろしいでしょうか？";
    this.confirmDialog = true;
  }

  async deleteCoverage(value: boolean) {
    this.confirmDialog = false;
    if (!value || !this.id) {
      return;
    }
    this.loading = true;
    await this.CoverageServise.deleteCoverage(this.id)
      .then(() => {
        PageAction.showCoverageEditForm();
        this.addFiles = [];
      })
      .catch((e) => {
        console.log(e);
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "削除失敗";
        this.resultDialogMessage = `取材予定の削除に失敗しました。`;
      })
      .finally(() => {
        this.loading = false;
        this.formClosed(true);
      });
  }

  private onStatusDialogClosed() {
    this.statusDialog = false;
    this.setCoverage();
  }

  formClosed(value){
    this.$emit('coverage-edit-form-closed', value);
  }

}
