















































































import { Component, Vue } from "vue-property-decorator";
import FileCard from "@/components/files/searches/FileCard.vue";
import FileSearchHeader from "@/components/files/searches/FileSearchHeader.vue";
import FileSearchPagination from "@/components/files/searches/FileSearchPagination.vue";
import { scrollTop } from "@/utils/FileUtils";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import { FileSearchService, FileData } from '@/services/files/FileSearch';
import moment from "moment";

Component.registerHooks([
  'beforeRouteEnter'
])

@Component({
    components:{
        FileCard,
        FileSearchHeader,
        FileSearchPagination,
        Dialog,
        Loading,
    }
})
export default class ShareContent extends Vue {
  private mode = 'share';
  private select = false;
  private selectedFiles = [];

  private id = "";
  private sharing;
  private title = "";
  private description = "";
  private limitDateTime = "";
  private files: FileData[] = [];
  private total = 0;
  private limit = 36;
  
  private page = 1;
  private length = 0;
  private backRoute = 'ShareList';

  private fileSearchService = new FileSearchService();

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private shareInfoDialog = false;
  private prevPageName = "";

  private get from(): number {
    return ((this.page - 1 ) > 0 ? (this.page - 1 ) : 0) * this.limit;
  }

  private get to(): number {
    if(this.from + this.limit >= FileSearchService.SEARCH_LIMIT) {
      return FileSearchService.SEARCH_LIMIT - this.from;
    }
    return this.limit;
  }

  private beforeRouteEnter(to, from, next){
    next(component => {
      component.prevPageName = from?.name ? from.name : "";
    });
  }

  private async mounted(){
    this.page = this.$store.getters.shareContentPage && this.prevPageName === "ShareDetail" ? this.$store.getters.shareContentPage : 1;
    await this.search();
  }

  private async search(){
    this.id = this.$route.params.id ? this.$route.params.id : '';

    if(this.id){
      scrollTop();
      
      try{
        this.loading = true;
        const result = await this.fileSearchService.searchSharingFiles(this.id, this.from, this.to);
        this.loading = false;
        if(result && result.sharing && result.items && result.items.length > 0){
          this.sharing = result.sharing || undefined;
          this.title = this.sharing && this.sharing.title ? this.sharing.title : "";
          this.description = this.sharing && this.sharing.description ? this.sharing.description : "";
          this.limitDateTime = this.sharing && this.sharing.limitDate && this.sharing.limitTime ? moment(`${this.sharing.limitDate} ${this.sharing.limitTime}`).local().format("YYYY-MM-DD HH:mm:ss") : "";
          this.files = result.items || [];
          this.total = result.total || 0;
          this.length = result.length || 0;
        }
      }catch(err){
        this.sharing = undefined;
        this.files = [];
        this.total = 0;
        this.length = 0;
        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "取得失敗";
        this.resultDialogMessage = `データの取得に失敗しました。\n再度、お試しください。`;

        if(err.errors && err.errors.length > 0){
          const error = err.errors[0];
          const errorType = error.errorType || "unknown";
          let errorMessage = error.message || "何らかのエラーが発生しました。";
          
          switch(errorType){
            case "Unauthorized":{
              errorMessage = "アクセス権がありません。"
              break;
            }
          }

          this.resultDialogMessage = errorMessage;          
        }
      }finally{
        this.loading = false;
      }
    }
  }

  private async postProcessing(){
    await this.search();
  }

  private async onChangePage(value){
    this.page = value;
    this.$store.commit("setShareContentPage", this.page);
    await this.search();
  }

  private onChangeResultDialogClosed(){
    this.resultDialog = false;
  }
}
