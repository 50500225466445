

























import { Component, Vue, Prop, Watch, Emit} from "vue-property-decorator";

@Component
export default class Confirm extends Vue {
  private toolbarColor = 'indigo';
  private btnColor = 'teal lighten-1';
  private innerDialog = false;

  @Prop({ default: false })
  private dialog!: boolean;
  @Prop({ default: 'info' })
  private mode!: string;
  @Prop({ default: '確認' })
  private title!: string;
  @Prop({ default: 'この処理を実行してもよろしいでしょうか？' })
  private message!: string;
  

  @Watch('dialog')
  private onChangeDialog(newValue){
    this.innerDialog = newValue;
  }

  @Watch('mode')
  private onChangeMode(newValue){
    switch(newValue){
      case 'error': {
        this.toolbarColor = 'error';
        this.btnColor = 'error';
        break;
      }
      case 'warning': {
        this.toolbarColor = 'warning';
        this.btnColor = 'warning';
        break;
      }
      default: {
        this.toolbarColor = 'indigo';
        this.btnColor = 'teal lighten-1';
        break;
      }
    }
  }
  
  onClickCancel(){
    this.confirm(false);
  }

  onClickOk(){  
    this.confirm(true);
  }

  @Emit('file-confirmed')
  confirm(isAccepted: boolean){
    this.innerDialog = false;
    return isAccepted;
  }
}
