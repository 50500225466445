







































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Confirm from "@/components/Confirm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import UserBaseForm from "@/components/admin/user/UserBaseForm.vue";
import { API } from "aws-amplify";
import { decodeRoleJson } from "@/utils/RoleUtils";
import * as queries from "@/graphql/queries";
import { GetCognitoUserQuery, GetCognitoUserResult, ListUserItems } from "@/API";
import AccesslevelSelect from "./AccesslevelSelect.vue";
import BooleanSelect from "./BooleanSelect.vue";
import { GraphQLResult } from "@aws-amplify/api";

@Component({
  components: {
    Confirm,
    Dialog,
    Loading,
    AccesslevelSelect,
    BooleanSelect,
  },
})
export default class UserEditForm extends UserBaseForm {
  name = "user-edit-form";

  private loading = true;
  private confirmDialog = false;
  private confirmMode = "";
  private confirmTitle = "";
  private confirmMessage = "";
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private resendPasswordConfirmDialog = false;
  private resendPasswordConfirmMode = "";
  private resendPasswordConfirmTitle = "";
  private resendPasswordConfirmMessage = "";

  private disableConfirmDialog = false;
  private disableConfirmMode = "";
  private disableConfirmTitle = "";
  private disableConfirmMessage = "";

  private enableConfirmDialog = false;
  private enableConfirmMode = "";
  private enableConfirmTitle = "";
  private enableConfirmMessage = "";

  @Prop({ default: {} })
  private editUser;

  private async created() {
    this.user = this.$store.getters.user;
    this.accountId =
      this.user && this.user.attributes
        ? this.user.attributes["custom:account_id"]
        : "";
    this.setUser();
    await this.setService();
  }

  @Watch("editUser")
  private onChangeEditUser() {
    this.setUser();
  }

  private async setUser() {
    if (this.editUser) {
      const select = this.editUser as ListUserItems;
      this.loading = true;
      ((API.graphql({
        query: queries.getCognitoUser,
        variables: { input: { id: select.id, accountId: this.user.attributes["custom:account_id"]} },
      })) as Promise<GraphQLResult<GetCognitoUserQuery>>)
        .then((result) => {
          if(result.data && result.data.getCognitoUser){
            const user: GetCognitoUserResult = result.data?.getCognitoUser;
            this.id = user.id;
            this.username = user.name;
            this.email = user.email;
            this.accountId = user.accountId;
            this.department = user.department;
            this.role = decodeRoleJson(user.role);
            this.status = user.status;
            this.enabled = user.enabled;
          }
        })
        .catch((e) => {
          console.log(e);
          this.onClickBack();
        })
        .finally(() => (this.loading = false));
    }
  }

  private async onClickEdit() {
    if (
      !(this.$refs.userEditForm as Vue & { validate: () => boolean }).validate()
    ) {
      return;
    }

    try {
      this.loading = true;
      await this.update();
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "編集成功";
      this.resultDialogMessage = "ユーザーの編集に成功しました。";
    } catch (error) {
      console.error(error);
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "編集失敗";
      this.resultDialogMessage = `ユーザーの編集に失敗しました。`;
    } finally {
      this.loading = false;
    }
  }

  private onChangeResultDialogClosed() {
    this.resultDialog = false;
    this.onClickBack();
  }

  private onClickBack() {
    this.$emit("user-edit-form-closed", true);
  }

  private async resetMfa(){
    this.confirmMode = "warning";
    this.confirmTitle = "MFAリセット確認";
    this.confirmMessage = "二要素認証デバイスリセットします、よろしいですか？";
    this.confirmDialog = true;
  }

  private async doResetMfa(value: boolean) {
    this.confirmDialog = false;
    if (!value) {
      return;
    }
    try {
      this.loading = true;
      await this.runResetMfa();
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "リセット成功";
      this.resultDialogMessage = "二要素認証デバイスリセットに成功しました。";
    } catch (error) {
      console.error(error);
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "リセット失敗";
      this.resultDialogMessage = `二要素認証デバイスリセットに失敗しました。`;
    } finally {
      this.loading = false;
    }
  }

  private async resendPasswordEmail(){
    this.resendPasswordConfirmMode = "warning";
    this.resendPasswordConfirmTitle = "仮パスワード再送確認";
    this.resendPasswordConfirmMessage = "仮パスワードを再送します、よろしいですか？";
    this.resendPasswordConfirmDialog = true;
  }

  private async doResendPasswordEmail(value: boolean) {
    this.resendPasswordConfirmDialog = false;
    if (!value) {
      return;
    }
    try {
      this.loading = true;
      await this.runResendPasswordEmail();
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "仮パスワード再送成功";
      this.resultDialogMessage = "仮パスワードの再送に成功しました。";
    } catch (error) {
      console.error(error);
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "仮パスワード再送失敗";
      this.resultDialogMessage = `仮パスワードの再送に失敗しました。`;
    } finally {
      this.loading = false;
    }
  }

  private async disable(){
    this.disableConfirmMode = "warning";
    this.disableConfirmTitle = "ユーザー無効化確認";
    this.disableConfirmMessage = "ユーザーを無効化します、よろしいですか？";
    this.disableConfirmDialog = true;
  }

  private async doDisable(value: boolean) {
    this.disableConfirmDialog = false;
    if (!value) {
      return;
    }
    try {
      this.loading = true;
      await this.runDisable();
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "ユーザー無効化成功";
      this.resultDialogMessage = "ユーザーの無効化に成功しました。";
    } catch (error) {
      console.error(error);
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "ユーザー無効化失敗";
      this.resultDialogMessage = `ユーザーの無効化に失敗しました。`;
    } finally {
      this.loading = false;
    }
  }


  private async enable(){
    this.enableConfirmMode = "warning";
    this.enableConfirmTitle = "ユーザー有効化確認";
    this.enableConfirmMessage = "ユーザーを有効化します、よろしいですか？";
    this.enableConfirmDialog = true;
  }

  private async doEnable(value: boolean) {
    this.enableConfirmDialog = false;
    if (!value) {
      return;
    }
    try {
      this.loading = true;
      await this.runRecovery();
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "ユーザー有効化成功";
      this.resultDialogMessage = "ユーザーの有効化に成功しました。";
    } catch (error) {
      console.error(error);
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "ユーザー有効化失敗";
      this.resultDialogMessage = `ユーザーの有効化に失敗しました。`;
    } finally {
      this.loading = false;
    }
  }
}
