import { API, graphqlOperation} from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import { UpdateFileInput, UpdateFileGroupInput } from "@/API";
import moment from "moment";
import { FileSearchService, SearchCondition, FileData, FileGroupData } from "@/services/files/FileSearch"


export class FileDeleteService {
    private readonly RETRY_COUNT = 3;

    async deleteFile(file: FileData, retryCount = 0) {
        try{
            // プロテクトされたファイルは削除しない
            if(file && file.id && file.protect === 0){
                const input: UpdateFileInput = {
                    id: file.id,
                    deleted: 1,
                    deletedAt: moment(new Date).utc().toISOString(),
                };
                // TODO:実ファイルも一緒に消すか、バッチで消すかは要検討
                await API.graphql(graphqlOperation(mutations.updateFile, { input: input }));
            }
        }catch(error){
            retryCount = retryCount + 1;
            if(retryCount > this.RETRY_COUNT)
            {
                throw error;
            }
            this.deleteFile(file, retryCount);
        }
    }
    
    async deleteFiles(files: Array<FileData>) {
        for (const file of files) {
            await this.deleteFile(file);
        }
    }
    
    async deleteFileGroup(fileGroup: FileGroupData) {
        if(fileGroup && fileGroup.id){
            const fileSearchService = new FileSearchService();
            const condition: SearchCondition = {
                groupIds: [fileGroup.id],
            }
        
            const files = await fileSearchService.searchFileDataFullList(condition);
            let isDelete = true;
            await this.deleteFiles(files);
            if(files.find(x => x.protect === 1)){
                isDelete = false;
            }
    
            // プロテクトされているファイルがあれば削除しない
            if(isDelete){
                const input: UpdateFileGroupInput = {
                    id: fileGroup.id,
                    deleted: 1,
                    deletedAt: moment(new Date).utc().toISOString(),
                };
                await this.updateFileGroup(input);
            }
        }
    }

    async updateFileGroup(input: UpdateFileGroupInput, retryCount = 0){
        try{
            await API.graphql(graphqlOperation(mutations.updateFileGroup, { input: input }));
        }catch(error){
            retryCount = retryCount + 1;
            if(retryCount > this.RETRY_COUNT){
                throw error;
            }
            await this.updateFileGroup(input, retryCount);
        }
    }
    
    async deleteFileGroups(fileGroups: Array<FileGroupData>) {
        for (const fileGroup of fileGroups) {
            await this.deleteFileGroup(fileGroup);
        }
    }
}
