














































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import { CoverageDesk } from "@/API";
import * as CoverageDeskService from "@/services/coverage/coverageDeskService";
import { SearchForm } from "@/services/coverage/coverageDeskService";
import CoverageDeskCreateForm from "./CoverageDeskCreateForm.vue";
import CoverageDeskEditForm from "./CoverageDeskEditForm.vue";
import CoverageDeskView from "./CoverageDeskView.vue";
import { decodeRoleJson, isAllow } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";

@Component({
  components: {
    CoverageDeskCreateForm,
    CoverageDeskEditForm,
    CoverageDeskView,
  },
})
export default class CoverageDeskList extends Vue {
  name = "coverage-desk";
  dateFrom = "";
  dateTo = "";
  benched = 0;
  items: Array<CoverageDesk> = [];
  coverageDeskService = new CoverageDeskService.CoverageDeskService();
  showForm = false;

  createDialog = false;
  viewDialog = false;
  editDialog = false;
  subscription;
  subscriptionUpdate;

  isOperate = true;

  async created() {
    try{
      const user = this.$store.getters.user;
      const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
      this.isOperate = isAllow("coverageDesk", "operate", role.coverageDesk?.operate) && await isActiveServie("coverage");
      this.setSubscription();
      await this.search();
    }catch(e){
      console.error(e);
      this.$emit("loadEnd");
    }
  }

  async search() {
    this.dateFrom = this.$store.getters.selectCoverageDate;
    this.dateTo = this.$store.getters.selectCoverageDateTo;

    const dayStart = this.getStartDate();
    const dayEnd = this.getEndDate();

    const searchForm: SearchForm = {
      dayStart: dayStart,
      dayEnd: dayEnd,
    };
    this.items = await this.coverageDeskService.search(searchForm);
  }

  private getStartDate(){
    if(!this.dateFrom || this.dateFrom.length <= 0){
      return moment("1970-01-01").startOf("day").toISOString();
    }
    return moment(this.dateFrom).startOf("day").toISOString();
  }

  private getEndDate() {
    if((!this.dateFrom || this.dateFrom.length <= 0) && (!this.dateTo || this.dateTo.length <= 0)){
      return moment("2037-12-31").endOf("day").toISOString();
    }
    if(this.dateFrom && (!this.dateTo || this.dateTo.length <= 0)){
      return moment(this.dateFrom).endOf("day").toISOString();
    }
    return moment(this.dateTo).endOf("day").toISOString();
  }

  private async createClose() {
    this.createDialog = false;
    setTimeout(() => {
      this.search();
    }, 1000);
  }

  private viewItem(item) {
    this.$store.commit("selectCoverageDesk", item.id);
    this.viewDialog = true;
  }

  private viewClose() {
    this.viewDialog = false;
  }

  private editItem(item) {
    this.$store.commit("selectCoverageDesk", item.id);
    this.editDialog = true;
  }

  private async editClose() {
    this.editDialog = false;
    setTimeout(() => {
      this.search();
    }, 1000);
  }

  private async setSubscription() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === "selectCoverageDate" || mutation.type === "selectCoverageDateTo") {
        this.search();
      }
    });
  }
}
