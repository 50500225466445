import store from '../store'


export function getDefaultRole(): UserRole {
  const result: UserRole = {
    management: {
      access: false,
      price: false,
      history: false,
      department: false,
      user: false,
      filing: false,
      coverage: false,
    },
    filing: {
      search: "DENY",
      create: false,
      operate: "DENY",
      download: false,
    },
    sharing: {
      create: false,
      read: "DENY",
      manage: "DENY",
    },
    coverage: {
      manage: false,
      operate: "DENY",
      read: "DENY"
    },
    coverageDesk: {
      operate: "DENY",
      read: "DENY"
    },
    coverageInfo: {
      operate: "DENY",
      read: "DENY"
    },
    sale: {
      operate: "DENY"
    }
  }
  return result
}

export function decodeRoleJson(jsonStr: string | undefined): UserRole {
  const defaultRole: UserRole = getDefaultRole();
  const role: UserRole = jsonStr ? JSON.parse(jsonStr) : defaultRole;
  //設定されていない場合にデフォルトを割り当てる
  for (const key in defaultRole) {
    if (!Object.prototype.hasOwnProperty.call(role, key)) {
      role[key] = defaultRole[key]
    }
  }
  return role
}

export function encodeRoleJson(role: UserRole | undefined): string {
  return role ? JSON.stringify(role) : JSON.stringify(getDefaultRole())
}

export function isAllow(roleType: RoleType, action: string, accesslevel?: Accesslevel): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const user: any = store.state.user;
  if (user === null) return false;
  const userRole: UserRole = decodeRoleJson(user.attributes["custom:role"]);
  const role = userRole[roleType];
  if (!role) return false;
  if (accesslevel) {
    if (role[action] === "DENY") {
      return false;
    }
    if (role[action] === "SELF") {
      return accesslevel === "SELF"
    }
    if (role[action] === "DEPARTMENT") {
      return accesslevel === "DEPARTMENT" || accesslevel === "SELF"
    }
    if (role[action] === "COMPANY") {
      return accesslevel === "DEPARTMENT" || accesslevel === "SELF" || accesslevel === "COMPANY"
    }
    return false
  } else {
    return role[action] ? true : false
  }
}

export type UserRole = {
  management: ManagementRole | undefined;
  filing: FilingRole | undefined;
  sharing: SharingRole | undefined;
  coverage: CoverageRole | undefined;
  coverageDesk: CoverageDeskRole | undefined;
  coverageInfo: CoverageInfoRole | undefined;
  sale: SaleRole | undefined;
}

export type ManagementRole = {
  access: boolean | undefined;
  price: boolean | undefined;
  history: boolean | undefined;
  department: boolean | undefined;
  user: boolean | undefined;
  filing: boolean | undefined;
  coverage: boolean | undefined;
}

export type FilingRole = {
  search: Accesslevel | undefined;
  create: boolean | undefined;
  operate: Accesslevel | undefined;
  download: boolean | undefined;
}

export type SharingRole = {
  create: boolean | undefined;
  read: Accesslevel | undefined;
  manage: Accesslevel | undefined;
}

export type CoverageRole = {
  manage: boolean | undefined;
  operate: Accesslevel | undefined;
  read: Accesslevel | undefined;
}

export type CoverageDeskRole = {
  operate: Accesslevel | undefined;
  read: Accesslevel | undefined;
}

export type CoverageInfoRole = {
  operate: Accesslevel | undefined;
  read: Accesslevel | undefined;
}

export type SaleRole = {
  operate: Accesslevel | undefined;
}

export type RoleType = "management" | "filing" | "coverage" | "sharing" | "coverageDesk" | "coverageInfo" | "sale";
//DENY:不可、SELF:自分で作成したもの、DEPARTMENT:所属部門、COMPANY:自社内
export type Accesslevel = "DENY" | "SELF" | "DEPARTMENT" | "COMPANY";
