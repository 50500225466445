






import { Component, Vue, Prop } from "vue-property-decorator";
import * as search from "@/services/files/FileSearch";
import FileSearchResult from "@/views/files/FileSearchResult.vue";
import * as AuthUtils from "@/utils/AuthUtils";
import router from "@/router";
import { uuid } from "vue-uuid";

@Component({
  components: {
    FileSearchResult,
  },
})
export default class CoverageFileLink extends Vue {
  name = "coverage-view";
  count = 0;
  service = new search.FileSearchService();
  condition!: search.SearchCondition;

  @Prop({ default: "" })
  private id!: string;

  created() {
    this.condition = { coverageId: this.id };
    this.service.searchFiles(this.condition, undefined, 0, 1).then((data) => {
      this.count = data.total;
    });
  }

  search(){
    this.$store.commit("setSearchCondition", this.condition);
    router.push({ path: '/' + AuthUtils.getAccountId(null) +'/files/search_result?q='+ uuid.v4()}).catch(()=>{ null })
  }
}
