/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount($id: String) {
    onCreateAccount(id: $id) {
      id
      company
      person
      domain
      logoUri
      config
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount($id: String) {
    onUpdateAccount(id: $id) {
      id
      company
      person
      domain
      logoUri
      config
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount($id: String) {
    onDeleteAccount(id: $id) {
      id
      company
      person
      domain
      logoUri
      config
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFileGroup = /* GraphQL */ `
  subscription OnCreateFileGroup(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onCreateFileGroup(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      coverFileId
      title
      description
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      file {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateFileGroup = /* GraphQL */ `
  subscription OnUpdateFileGroup(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onUpdateFileGroup(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      coverFileId
      title
      description
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      file {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteFileGroup = /* GraphQL */ `
  subscription OnDeleteFileGroup(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onDeleteFileGroup(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      coverFileId
      title
      description
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      file {
        id
        groupId
        rating
        title
        caption
        instructions
        memo
        fileName
        protect
        original
        caution
        cautionInstructions
        reuse
        reuseCondition
        keywords
        extension
        type
        meta
        tag
        customItems
        fileUri
        fileSize
        coverageId
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        group {
          id
          coverFileId
          title
          description
          memo
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          file {
            id
            groupId
            rating
            title
            caption
            instructions
            memo
            fileName
            protect
            original
            caution
            cautionInstructions
            reuse
            reuseCondition
            keywords
            extension
            type
            meta
            tag
            customItems
            fileUri
            fileSize
            coverageId
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            group {
              id
              coverFileId
              title
              description
              memo
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
            coverage {
              id
              title
              location
              startAt
              endAt
              description
              memo
              attachmentFile
              createdUserId
              ownedAccountId
              ownedDepartment
              assignedUserId
              status
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
        }
        coverage {
          id
          title
          location
          startAt
          endAt
          description
          memo
          attachmentFile
          createdUserId
          ownedAccountId
          ownedDepartment
          assignedUserId
          status
          deleted
          deletedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateFile = /* GraphQL */ `
  subscription OnCreateFile(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onCreateFile(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      groupId
      rating
      title
      caption
      instructions
      memo
      fileName
      protect
      original
      caution
      cautionInstructions
      reuse
      reuseCondition
      keywords
      extension
      type
      meta
      tag
      customItems
      fileUri
      fileSize
      coverageId
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      group {
        id
        coverFileId
        title
        description
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        file {
          id
          groupId
          rating
          title
          caption
          instructions
          memo
          fileName
          protect
          original
          caution
          cautionInstructions
          reuse
          reuseCondition
          keywords
          extension
          type
          meta
          tag
          customItems
          fileUri
          fileSize
          coverageId
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          group {
            id
            coverFileId
            title
            description
            memo
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            file {
              id
              groupId
              rating
              title
              caption
              instructions
              memo
              fileName
              protect
              original
              caution
              cautionInstructions
              reuse
              reuseCondition
              keywords
              extension
              type
              meta
              tag
              customItems
              fileUri
              fileSize
              coverageId
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
          coverage {
            id
            title
            location
            startAt
            endAt
            description
            memo
            attachmentFile
            createdUserId
            ownedAccountId
            ownedDepartment
            assignedUserId
            status
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      }
      coverage {
        id
        title
        location
        startAt
        endAt
        description
        memo
        attachmentFile
        createdUserId
        ownedAccountId
        ownedDepartment
        assignedUserId
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateFile = /* GraphQL */ `
  subscription OnUpdateFile(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onUpdateFile(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      groupId
      rating
      title
      caption
      instructions
      memo
      fileName
      protect
      original
      caution
      cautionInstructions
      reuse
      reuseCondition
      keywords
      extension
      type
      meta
      tag
      customItems
      fileUri
      fileSize
      coverageId
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      group {
        id
        coverFileId
        title
        description
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        file {
          id
          groupId
          rating
          title
          caption
          instructions
          memo
          fileName
          protect
          original
          caution
          cautionInstructions
          reuse
          reuseCondition
          keywords
          extension
          type
          meta
          tag
          customItems
          fileUri
          fileSize
          coverageId
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          group {
            id
            coverFileId
            title
            description
            memo
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            file {
              id
              groupId
              rating
              title
              caption
              instructions
              memo
              fileName
              protect
              original
              caution
              cautionInstructions
              reuse
              reuseCondition
              keywords
              extension
              type
              meta
              tag
              customItems
              fileUri
              fileSize
              coverageId
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
          coverage {
            id
            title
            location
            startAt
            endAt
            description
            memo
            attachmentFile
            createdUserId
            ownedAccountId
            ownedDepartment
            assignedUserId
            status
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      }
      coverage {
        id
        title
        location
        startAt
        endAt
        description
        memo
        attachmentFile
        createdUserId
        ownedAccountId
        ownedDepartment
        assignedUserId
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteFile = /* GraphQL */ `
  subscription OnDeleteFile(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onDeleteFile(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      groupId
      rating
      title
      caption
      instructions
      memo
      fileName
      protect
      original
      caution
      cautionInstructions
      reuse
      reuseCondition
      keywords
      extension
      type
      meta
      tag
      customItems
      fileUri
      fileSize
      coverageId
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
      group {
        id
        coverFileId
        title
        description
        memo
        createdUserId
        ownedAccountId
        ownedDepartment
        deleted
        deletedAt
        createdAt
        updatedAt
        file {
          id
          groupId
          rating
          title
          caption
          instructions
          memo
          fileName
          protect
          original
          caution
          cautionInstructions
          reuse
          reuseCondition
          keywords
          extension
          type
          meta
          tag
          customItems
          fileUri
          fileSize
          coverageId
          createdUserId
          ownedAccountId
          ownedDepartment
          deleted
          deletedAt
          createdAt
          updatedAt
          group {
            id
            coverFileId
            title
            description
            memo
            createdUserId
            ownedAccountId
            ownedDepartment
            deleted
            deletedAt
            createdAt
            updatedAt
            file {
              id
              groupId
              rating
              title
              caption
              instructions
              memo
              fileName
              protect
              original
              caution
              cautionInstructions
              reuse
              reuseCondition
              keywords
              extension
              type
              meta
              tag
              customItems
              fileUri
              fileSize
              coverageId
              createdUserId
              ownedAccountId
              ownedDepartment
              deleted
              deletedAt
              createdAt
              updatedAt
            }
          }
          coverage {
            id
            title
            location
            startAt
            endAt
            description
            memo
            attachmentFile
            createdUserId
            ownedAccountId
            ownedDepartment
            assignedUserId
            status
            deleted
            deletedAt
            createdAt
            updatedAt
          }
        }
      }
      coverage {
        id
        title
        location
        startAt
        endAt
        description
        memo
        attachmentFile
        createdUserId
        ownedAccountId
        ownedDepartment
        assignedUserId
        status
        deleted
        deletedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateSharing = /* GraphQL */ `
  subscription OnCreateSharing(
    $sharingUsers: String
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onCreateSharing(
      sharingUsers: $sharingUsers
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      title
      description
      memo
      limitDate
      limitTime
      sharingUsers
      sharingFiles
      createdUserId
      ownedAccountId
      ownedDepartment
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSharing = /* GraphQL */ `
  subscription OnUpdateSharing(
    $sharingUsers: String
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onUpdateSharing(
      sharingUsers: $sharingUsers
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      title
      description
      memo
      limitDate
      limitTime
      sharingUsers
      sharingFiles
      createdUserId
      ownedAccountId
      ownedDepartment
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSharing = /* GraphQL */ `
  subscription OnDeleteSharing(
    $sharingUsers: String
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onDeleteSharing(
      sharingUsers: $sharingUsers
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      title
      description
      memo
      limitDate
      limitTime
      sharingUsers
      sharingFiles
      createdUserId
      ownedAccountId
      ownedDepartment
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCoverage = /* GraphQL */ `
  subscription OnCreateCoverage(
    $assignedUserId: String
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onCreateCoverage(
      assignedUserId: $assignedUserId
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      title
      location
      startAt
      endAt
      description
      memo
      attachmentFile
      createdUserId
      ownedAccountId
      ownedDepartment
      assignedUserId
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoverage = /* GraphQL */ `
  subscription OnUpdateCoverage(
    $assignedUserId: String
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onUpdateCoverage(
      assignedUserId: $assignedUserId
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      title
      location
      startAt
      endAt
      description
      memo
      attachmentFile
      createdUserId
      ownedAccountId
      ownedDepartment
      assignedUserId
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoverage = /* GraphQL */ `
  subscription OnDeleteCoverage(
    $assignedUserId: String
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onDeleteCoverage(
      assignedUserId: $assignedUserId
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      title
      location
      startAt
      endAt
      description
      memo
      attachmentFile
      createdUserId
      ownedAccountId
      ownedDepartment
      assignedUserId
      status
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCoverageInfo = /* GraphQL */ `
  subscription OnCreateCoverageInfo(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onCreateCoverageInfo(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      startDate
      endDate
      body
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoverageInfo = /* GraphQL */ `
  subscription OnUpdateCoverageInfo(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onUpdateCoverageInfo(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      startDate
      endDate
      body
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoverageInfo = /* GraphQL */ `
  subscription OnDeleteCoverageInfo(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onDeleteCoverageInfo(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      startDate
      endDate
      body
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCoverageDesk = /* GraphQL */ `
  subscription OnCreateCoverageDesk(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onCreateCoverageDesk(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      startDate
      endDate
      name
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoverageDesk = /* GraphQL */ `
  subscription OnUpdateCoverageDesk(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onUpdateCoverageDesk(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      startDate
      endDate
      name
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoverageDesk = /* GraphQL */ `
  subscription OnDeleteCoverageDesk(
    $createdUserId: String
    $ownedDepartment: String
    $ownedAccountId: String
  ) {
    onDeleteCoverageDesk(
      createdUserId: $createdUserId
      ownedDepartment: $ownedDepartment
      ownedAccountId: $ownedAccountId
    ) {
      id
      startDate
      endDate
      name
      memo
      createdUserId
      ownedAccountId
      ownedDepartment
      deleted
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHistory = /* GraphQL */ `
  subscription OnCreateHistory($accountId: String) {
    onCreateHistory(accountId: $accountId) {
      id
      data
      body
      operation
      userId
      accountId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHistory = /* GraphQL */ `
  subscription OnUpdateHistory($accountId: String) {
    onUpdateHistory(accountId: $accountId) {
      id
      data
      body
      operation
      userId
      accountId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHistory = /* GraphQL */ `
  subscription OnDeleteHistory($accountId: String) {
    onDeleteHistory(accountId: $accountId) {
      id
      data
      body
      operation
      userId
      accountId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCharge = /* GraphQL */ `
  subscription OnCreateCharge($accountId: String) {
    onCreateCharge(accountId: $accountId) {
      id
      accountId
      aggregationDate
      status
      basicCharge
      s3Charge
      excessTransferredCharge
      s3StdUsageSize
      s3IaUsageSize
      s3GlIrUsageSize
      s3GlUsageSize
      transferredSize
      downloadRequestCont
      excludingTax
      includingTax
      taxRate
      exchangeRate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCharge = /* GraphQL */ `
  subscription OnUpdateCharge($accountId: String) {
    onUpdateCharge(accountId: $accountId) {
      id
      accountId
      aggregationDate
      status
      basicCharge
      s3Charge
      excessTransferredCharge
      s3StdUsageSize
      s3IaUsageSize
      s3GlIrUsageSize
      s3GlUsageSize
      transferredSize
      downloadRequestCont
      excludingTax
      includingTax
      taxRate
      exchangeRate
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCharge = /* GraphQL */ `
  subscription OnDeleteCharge($accountId: String) {
    onDeleteCharge(accountId: $accountId) {
      id
      accountId
      aggregationDate
      status
      basicCharge
      s3Charge
      excessTransferredCharge
      s3StdUsageSize
      s3IaUsageSize
      s3GlIrUsageSize
      s3GlUsageSize
      transferredSize
      downloadRequestCont
      excludingTax
      includingTax
      taxRate
      exchangeRate
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFileDownloadHistory = /* GraphQL */ `
  subscription OnCreateFileDownloadHistory {
    onCreateFileDownloadHistory {
      id
      accountId
      fileId
      fileSize
      storageClass
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFileDownloadHistory = /* GraphQL */ `
  subscription OnUpdateFileDownloadHistory {
    onUpdateFileDownloadHistory {
      id
      accountId
      fileId
      fileSize
      storageClass
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFileDownloadHistory = /* GraphQL */ `
  subscription OnDeleteFileDownloadHistory {
    onDeleteFileDownloadHistory {
      id
      accountId
      fileId
      fileSize
      storageClass
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGlacierDownloadRequest = /* GraphQL */ `
  subscription OnCreateGlacierDownloadRequest($userId: String) {
    onCreateGlacierDownloadRequest(userId: $userId) {
      id
      accountId
      fileId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGlacierDownloadRequest = /* GraphQL */ `
  subscription OnUpdateGlacierDownloadRequest($userId: String) {
    onUpdateGlacierDownloadRequest(userId: $userId) {
      id
      accountId
      fileId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGlacierDownloadRequest = /* GraphQL */ `
  subscription OnDeleteGlacierDownloadRequest($userId: String) {
    onDeleteGlacierDownloadRequest(userId: $userId) {
      id
      accountId
      fileId
      userId
      createdAt
      updatedAt
    }
  }
`;
