






















































































import { Component } from "vue-property-decorator";
import CoverageBaseForm from "./CoverageBaseForm.vue";
import * as AuthUtils from "@/utils/AuthUtils";
import CoverageStatus from "./CoverageStatus.vue";


@Component({
  components: {
    CoverageStatus,
  },
})
export default class CoverageView extends CoverageBaseForm {
  name = "coverage-view";
  assignedUserName: string | undefined = "";

  async created() {
    this.$store.subscribe(async (mutation) => {
      if (mutation.type === "selectCoverage") {
        this.id = this.$store.state.selectCoverageId;
        await this.setCoverage();
        this.assignedUserName = await AuthUtils.getUserName(
          this.assignedUserId
        );
      }
    });
  }

  async mounted() {
    this.id = this.$store.state.selectCoverageId;
    this.loading = true;
    await this.setCoverage();
    this.assignedUserName = await AuthUtils.getUserName(this.assignedUserId);
    this.loading = false;
  }
}
