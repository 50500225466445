

































































import { Component, Vue } from "vue-property-decorator";
import FileGroupEditBaseForm from "@/components/files/edit/FileGroupEditBaseForm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";

@Component({
    components:{
        Dialog,
        Loading,
    }
})
export default class FileGroupEdit extends FileGroupEditBaseForm {
  name = "file-group-edit"
  private valid = false;
  private id = "";

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private async created(){
    if(this.$route.params.id){
      this.id = this.$route.params.id.toString();
      this.setFileGroup(this.id);
    }
  }
  
  private async save(){
    if(!(this.$refs.fileGroupEditForm as Vue & {validate: () => boolean}).validate()){
      return;
    }
    try{
      this.loading = true;
      await this.update();
      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "編集成功";
      this.resultDialogMessage = "アルバム情報の編集に成功しました。\n元の画面に戻ります。"
    }catch(error){
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "編集失敗";
      this.resultDialogMessage = `アルバム情報の編集に失敗しました。\n元の画面に戻ります。`;
    }finally{
      this.loading = false;
    }
  }
  
  private onChangeResultDialogClosed(){
    this.resultDialog = false;
    this.back();
  }

  private back(){
    this.$router.go(-1);
  }
}
