import { API, graphqlOperation} from "aws-amplify";
import * as mutations from '@/graphql/mutations';
import { UpdateSharingInput } from "@/API";
import moment from "moment";


export async function deleteSharing(sharingId: string) {
    const input: UpdateSharingInput = {
        id: sharingId,
        deleted: 1,
        deletedAt: moment(new Date).utc().toISOString(),
    };

    await API.graphql(graphqlOperation(mutations.updateSharing, { input: input }));
}

export async function deleteSharings(sharingIds: Array<string>) {
    for (const sharingId of sharingIds) {
       await deleteSharing(sharingId);
    }
}