import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import { CreateHistoryInput, CreateFileDownloadHistoryInput, CreateGlacierDownloadRequestInput } from "@/API";
import store from "@/store";
import { FileData } from "@/services/files/FileSearch";
import { S3Client,HeadObjectCommand,HeadObjectCommandOutput,RestoreObjectCommand } from "@aws-sdk/client-s3";
import moment from "moment";
import { uuid } from "vue-uuid";
import awsconfig from '@/aws-exports';

export function downloadUri(uri, fileName = '') {
    if(!uri){
        return;
    }

    const a = document.createElement('a');
    a.href = uri;
    if(fileName){
        a.download = fileName;
    }

    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(uri);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

export async function downloadFile(file: FileData, storageClass: string) {
    if (!store.getters.user || !file || !file.fileName || !file.fileUri || !file.type) {
        return;
    }

    const fileId = file.id;
    const fileName = file.fileName;
    const fileSize = file.fileSize;
    const spliteUrl = file.fileUri.split('/');
    const s3Key = `${spliteUrl[4]}/${spliteUrl[5]}/${spliteUrl[6]}/${spliteUrl[7]}`;
    const contentType = file.type;
    const s3Class = storageClass ? storageClass : "STANDARD";

    let accountId = "";
    let userId = "";
    let userName = "";
    const user = store.getters.user;
    if (user) {
        accountId = user.attributes["custom:account_id"];
        userId = user.attributes.sub;
        userName = user.attributes.name;
    }

    const url = (await Storage.get(s3Key, {download: false, expires: 300, contentType: contentType})).toString();
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    
    const downloadHistry: CreateHistoryInput = {
        data: JSON.stringify({ 'tableName': 'File', 'id': fileId }),
        body: `ファイルがダウンロードされました。 ダウンロードユーザー名:[${userName}] ダウンロードファイルID:[${fileId}]`,
        operation: "download",
        userId: userId,
        accountId: accountId
    };
    await API.graphql(graphqlOperation(mutations.createHistory, { input: downloadHistry }));

    const fileDownloadHistry: CreateFileDownloadHistoryInput = {
        accountId: accountId,
        fileId: fileId ? fileId : "",
        fileSize: fileSize ? fileSize : "0",
        storageClass: s3Class,
        userId: userId,
    };
    await API.graphql(graphqlOperation(mutations.createFileDownloadHistory, { input: fileDownloadHistry }));

    a.click();
    return a;
}

export async function getStorageInfo(file: FileData) {
    if (!store.getters.user || !file || !file.fileName || !file.fileUri || !file.type) {
        return {storageClass: "", glacierStatus: "NOT_GLACIER"};
    }
    const s3url = new URL(file.fileUri);
    const s3Bucket = s3url.hostname.split(".")[0];
    const s3Key = s3url.pathname.replace(/^\//i,"");
    const credentials = await Auth.currentCredentials();
    const client = new S3Client({
        region: awsconfig.aws_project_region,
        credentials: Auth.essentialCredentials(credentials)
    });
    const command = new HeadObjectCommand({ Bucket: s3Bucket, Key: s3Key });
    const results: HeadObjectCommandOutput = await client.send(command);
    const restoreValuse = results.Restore?.split("\", ");
    const expiryDate = restoreValuse?.find((item) => item.match(/expiry-date=/i))?.split("=")[1];
    const ongoingRequest = restoreValuse?.find((item) => item.match(/ongoing-request=/i))?.split("=")[1];
    const storageClass = results.StorageClass ? results.StorageClass : "STANDARD";

    //有効期限が1時間以上後
    if (expiryDate && moment(expiryDate) >= moment()) {
        return {storageClass: storageClass, glacierStatus: "RESTORETED"};
    }
    else if (ongoingRequest?.replaceAll("\"","") === "true") {
        return {storageClass: storageClass, glacierStatus: "PROCESSING"};
    }
    else if (storageClass === "GLACIER") {
        return {storageClass: storageClass, glacierStatus: "GLACIER"};
    } else {
        return {storageClass: storageClass, glacierStatus: "NOT_GLACIER"};
    }
}

export async function downLoadRequest(file: FileData) {
    if (!store.getters.user || !file || !file.fileName || !file.fileUri || !file.type) {
        return;
    }
    const fileId = file.id;
    // eslint-disable-next-line
    const user: any = store.getters.user;
    const accountId = user.attributes["custom:account_id"];
    const userId = user.attributes.sub;
    const userName = user.attributes.name;

    const s3url = new URL(file.fileUri);
    const s3Bucket = s3url.hostname.split(".")[0];
    const s3Key = s3url.pathname.replace(/^\//i,"");
    const credentials = await Auth.currentCredentials();
    const client = new S3Client({
        region: awsconfig.aws_project_region,
        credentials: Auth.essentialCredentials(credentials)
    });
    const results = await getStorageInfo(file);
    if (results && results.glacierStatus === "GLACIER") {
        const cmd = new RestoreObjectCommand({
            Bucket: s3Bucket,
            Key: s3Key,
            RestoreRequest: {
                Days: 7,
                GlacierJobParameters: {
                    Tier: "Expedited"
                }
            }
        });
        await client.send(cmd).then(async ()=>{
                //DynamoDBに自分のリクエストであることを登録
                const requestInput: CreateGlacierDownloadRequestInput = {
                    id:uuid.v4(),
                    userId: userId,
                    accountId: accountId,
                    fileId: String(fileId).toString(),
                    createdAt: moment().toISOString(),
                    updatedAt: moment().toISOString()
                }
                await API.graphql(graphqlOperation(mutations.createGlacierDownloadRequest, { input: requestInput }));
                
                //履歴投入
                const downloadHistry: CreateHistoryInput = {
                    data: JSON.stringify({ 'tableName': 'File', 'id': fileId }),
                    body: `アーカイブファイルのダウンロード要求されました。 ダウンロードユーザー名:[${userName}] ダウンロードファイルID:[${fileId}]`,
                    operation: "download request",
                    userId: userId,
                    accountId: accountId
                };
                await API.graphql(graphqlOperation(mutations.createHistory, { input: downloadHistry }));
            });
    }
}

export type GlacierStatus = "GLACIER" | "PROCESSING" | "NOT_GLACIER" | "RESTORETED";