































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import * as subscriptions from "@/graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";
import { CoverageDesk, OnCreateCoverageDeskSubscription, OnUpdateCoverageDeskSubscription } from "@/API";
import { CoverageDeskService, SearchForm } from "@/services/coverage/coverageDeskService";
import Loading from "@/components/Loading.vue";
import { isAllow, decodeRoleJson } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";
import Observable from 'zen-observable-ts';

@Component({
  components:{
    Loading,
  }
})
export default class Desk extends Vue {
  @Prop({ default: moment(new Date()).local().format('YYYY-MM-DD') })
  private date;

  private user;
  private desks: CoverageDesk[] = [];
  private subscriptionCreate;
  private subscriptionUpdate;

  private deskService = new CoverageDeskService();
  private searchForm: SearchForm = {
    dayStart: "",
    dayEnd: "",
  }

  private loading = false;
  private isRead = false;

  @Watch('date')
  private async onChangeDate(){
    await this.search();
  }

  private async created(){
    await this.setDefalut();
    await this.search();

    if(this.isRead){
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filter: any = {};
      if(isAllow("coverageDesk", "read", "COMPANY") && await isActiveServie("coverage")){
        filter.ownedAccountId = this.user?.attributes["custom:account_id"];
      }else if(isAllow("coverageDesk", "read", "DEPARTMENT") && await isActiveServie("coverage")){
        filter.ownedDepartment = this.user?.attributes["custom:department"];
      }else if(isAllow("coverageDesk", "read", "SELF") && await isActiveServie("coverage")){
        filter.createdUserId = this.user?.attributes.sub;
      }

      this.subscriptionCreate = (API.graphql(
        graphqlOperation(subscriptions.onCreateCoverageDesk, filter)
      ) as Observable<OnCreateCoverageDeskSubscription>).subscribe({
        next: () =>
          setTimeout(() => {
            this.search();
          }, 1000),
        error: (error) => console.warn(error),
      });
      
      this.subscriptionUpdate = (API.graphql(
        graphqlOperation(subscriptions.onUpdateCoverageDesk, filter)
      ) as Observable<OnUpdateCoverageDeskSubscription>).subscribe({
        next: () =>
          setTimeout(() => {
            this.search();
          }, 1000),
        error: (error) => console.warn(error),
      });
    }
  }

  private async mounted(){
    await this.setDefalut();
  }

  private async setDefalut(){
    const user = this.$store.getters.user;
    const role = decodeRoleJson(user.attributes["custom:role"] || undefined);
    this.isRead = isAllow("coverageDesk", "read", role.coverageDesk?.read) && await isActiveServie("coverage");
  }

  private async search(){
    try{
      if(!this.isRead){
        return;
      }
      this.loading = true;
      this.user = this.$store.getters.user;
      this.searchForm.dayStart = moment(this.date).startOf("day").toISOString();
      this.searchForm.dayEnd = moment(this.date).endOf("day").toISOString();
      this.desks = await this.deskService.search(this.searchForm);
    }catch(error){
      console.error(error);
      this.desks = [];
    }finally{
      this.loading = false;
    }
  }

  private destroyed(){
    if(this.subscriptionCreate){
      this.subscriptionCreate.unsubscribe();
    }
    if(this.subscriptionUpdate){
      this.subscriptionUpdate.unsubscribe();
    }
  }
}
