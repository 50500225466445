import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import { CreateHistoryInput, CreateSelesSenderInput } from "@/API";
import { FileData, FileGroupData, FileSearchService, SearchCondition } from "@/services/files/FileSearch"
import { getSetting } from "@/utils/AccountSettingUtils";
import store from "@/store";
import { decodeRoleJson, UserRole } from "@/utils/RoleUtils";
import { getAccountId, getDepartment } from "@/utils/AuthUtils";


export async function selesSendFile(file: FileData) {
    let accountId = "";
    let userId = "";
    let userName = "";
    const user = store.getters.user;

    if (user) {
        accountId = user.attributes["custom:account_id"];
        userId = user.attributes.sub;
        userName = user.attributes.name;
    }

    const fileId = file.id ?  file.id : "";

    // データチェックはLMBDA内部で実装
    const input: CreateSelesSenderInput = {
        fileId: fileId,
        accountId: accountId
    };
    await API.graphql(graphqlOperation(mutations.createSelesSender, { input: input }));

    const saleSendHistry: CreateHistoryInput = {
        data: JSON.stringify({ 'tableName': 'File', 'id': fileId }),
        body: `外販送信されました。 外販送信ユーザー名:[${userName}] 外販送信ファイルID:[${fileId}]`,
        operation: "sale send",
        userId: userId,
        accountId: accountId
    };
    await API.graphql(graphqlOperation(mutations.createHistory, { input: saleSendHistry }));
}

export async function selesSendFiles(files: Array<FileData>) {
    for (const file of files) {
        await selesSendFile(file);
    }
}

export async function isAllowSendFile(file: FileData) {
    const item = file?.customItems || "{}"
    const fileSetting = JSON.parse(item);
    const accountSetting = await getSetting();
    const allowTypes = accountSetting?.allowTypes || [];
    if((fileSetting?.sale === 1 || fileSetting?.sale === 2) && allowTypes.findIndex(x => x === file.type)>=0){
        return true;
    }
    return false;
}

export async function isAllowSendFileOperate(file: FileData): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const user: any = store.state.user;
  if (user === null) return false;
  const userRole: UserRole = decodeRoleJson(user.attributes["custom:role"]);
  const userId = user.username;
  const department = getDepartment(user);
  const accountID = getAccountId(user);
  const role = userRole["sale"];
  if (!role) return false;
  const operation = role["operate"];

  if (operation === "SELF" && file.createdUserId === userId) {
    return true;
  }
  if (operation === "DEPARTMENT" && file.ownedDepartment === department) {
    return true;
  }
  if (operation === "COMPANY" && file.ownedAccountId === accountID) {
    return true;
  }
  return false;
}

export async function selesSendFileGroup(fileGroup: FileGroupData) {
    if(fileGroup && fileGroup.id){
        const fileSearchService = new FileSearchService();
        const condition: SearchCondition = {
            groupIds: [fileGroup.id],
        }
        const files = await fileSearchService.searchFileDataFullList(condition);
        for (const key in files) {
            if (Object.prototype.hasOwnProperty.call(files, key)) {
                isAllowSendFile(await files[key]);
            }
        }
    }
}

export async function selesSendGroups(fileGroups: Array<FileGroupData>) {
    for (const fileGroup of fileGroups) {
        await selesSendFileGroup(fileGroup);
    }
}