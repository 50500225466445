







































import { Component } from "vue-property-decorator";
import UserBaseForm from "./UserBaseForm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import Confirm from "@/components/Confirm.vue";

@Component({
  components: {
    Dialog,
    Loading,
    Confirm,
  },
})
export default class UserDetails extends UserBaseForm {
  name = "user-details";
  loading = false;

  protected resultDialog = false;
  protected resultDialogMode = "";
  protected resultDialogTitle = "";
  protected resultDialogMessage = "";

  protected confirmDialog = false;
  protected confirmMode = "";
  protected confirmTitle = "";
  protected confirmMessage = "";

  async created() {
    this.loading = true;
    await this.setUser();
    this.loading = false;
  }

  private onChangeResultDialogClosed(){
    return;
  }
}
