














































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Confirm from "@/components/Confirm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import { ListUserItems, ListUsersQuery, PGCService } from "@/API";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import { DeleteCognitoUserInput } from "@/API";
import UserCreateForm from "@/components/admin/user/UserCreateForm.vue";
import UserEditForm from "@/components/admin/user/UserEditForm.vue";
import { GraphQLResult } from "@aws-amplify/api";

@Component({
  components: {
    Confirm,
    Dialog,
    Loading,
    UserCreateForm,
    UserEditForm,
  },
})
export default class UserMain extends Vue {
  private headers = [
    { text: "ユーザー名", value: "name", align: "left" },
    // { text: "メールアドレス", value: "email" },
    // { text: "所属部門", value: "department" },
    // { text: "ステータス", value: "status" },
    // { text: "最終ログイン日時", value: "lastLoginAt" },
    { text: "ステータス", value: "enabled", align: "left" },
    { text: "アクション", value: "action", align: "center", sortable: false },
  ];

  private tableLoading = false;
  private loading = false;
  private confirmDialog = false;
  private confirmMode = "";
  private confirmTitle = "";
  private confirmMessage = "";
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private items: ListUserItems[] = [];
  private user;
  private accountId = "";
  // eslint-disable-next-line
  private selectedItem: any = {};

  private searchWord = "";

  private createDialog = false;
  private editDialog = false;

  @Prop({ default: false })
  private show;

  @Watch("show")
  private async onChangeShow(newValue) {
    if (newValue) {
      this.user = this.$store.getters.user;
      this.accountId =
        this.user && this.user.attributes
          ? this.user.attributes["custom:account_id"]
          : "";
      await this.search();
    }
  }

  created() {
    //ユーザー情報は最新を取り直す
    this.user = this.$store.getters.user;
    this.accountId =
      this.user && this.user.attributes
        ? this.user.attributes["custom:account_id"]
        : "";
  }

  private async search() {
    this.tableLoading = true;
    this.items = [];
    if (!this.accountId) {
      return;
    }
    try {
      const variables = {
        input: {
          accountId: this.accountId,
          service: PGCService.MANAGEMENT
        }
      }
      const { data } = (await API.graphql(graphqlOperation(queries.listUsers, variables))) as GraphQLResult<ListUsersQuery>;
      if (data && data.listUsers && data.listUsers.Items) {
        this.items = data.listUsers.Items as ListUserItems[];
      }
    } catch (error) {
      console.error(error);
      this.items = [];
    } finally {
      this.tableLoading = false;
    }
  }

  private createItem() {
    this.createDialog = true;
  }

  private async onCreateDialogClosed() {
    this.createDialog = false;
    await this.search();
  }

  private editItem(item: ListUserItems) {
    this.selectedItem = item;
    this.editDialog = true;
  }

  private async onEditDialogClosed() {
    this.editDialog = false;
    await this.search();
  }

  private deleteItem(item: ListUserItems) {
    if (!item) {
      return;
    }
    this.selectedItem = item;
    this.confirmMode = "warning";
    this.confirmTitle = "削除確認";
    this.confirmMessage = "このユーザーを削除してもよろしいでしょうか？";
    this.confirmDialog = true;
  }

  private async confirmed(value) {
    try {
      if (value && this.selectedItem?.id) {
        this.loading = true;
        await this.delete(this.selectedItem);
        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "info";
        this.resultDialogTitle = "削除成功";
        this.resultDialogMessage = "ユーザーの削除に成功しました。";
      }
    } catch (error) {
      console.error(error);
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "削除失敗";
      this.resultDialogMessage = `ユーザーの削除に失敗しました。`;
    } finally {
      this.loading = false;
      this.confirmDialog = false;
    }
  }

  private async onChangeResultDialogClosed() {
    this.resultDialog = false;
    await this.search();
  }

  private async delete(item: ListUserItems) {
    if (item.id) {
      const deleteCognitoUserInput: DeleteCognitoUserInput = {
        id: String(item.id).toString(),
        accountId: String(this.accountId).toString(),
      };
      await API.graphql({
        query: mutations.deleteCognitoUser,
        variables: { input: deleteCognitoUserInput },
      });
    }
  }
}
