import { ModelCoverageInfoConditionInput, CoverageInfo, CreateCoverageInfoInput, UpdateCoverageInfoInput, ModelSortDirection, CoverageInfosByOwnedAccountIdQuery, CoverageInfosByOwnedAccountIdQueryVariables } from "@/API";
import * as queries from "@/graphql/queries";
import { createCoverageInfo, updateCoverageInfo } from "@/graphql/mutations";
import { API } from "aws-amplify";
import moment from "moment";
import { uuid } from "vue-uuid";
import { GraphQLResult } from "@aws-amplify/api";
import store from "@/store";

export class CoverageInfoService {

    headers = [
        { text: "開始日時", value: "startDate" },
        { text: "終了日時", value: "endDate" },
        { text: "本文", value: "body" },
        { text: "", value: "detail", align: "center" },
    ];

    async search(searchForm: SearchForm): Promise<CoverageInfo[]> {
        let coverageInfos: Array<CoverageInfo> = [];
        const attributes = store.getters.user?.attributes;
        const filter: ModelCoverageInfoConditionInput = { deleted: { eq: 0 } };
        filter.and = [];
        filter.and?.push({
            or: [
                //対象日に開始か終了がある
                { startDate: { gt: searchForm.dayStart, lt: searchForm.dayEnd } },
                { endDate: { gt: searchForm.dayStart, lt: searchForm.dayEnd } },
                //開始と終了の間に対象日がある
                { and: [{ startDate: { lt: searchForm.dayStart } }, { endDate: { gt: searchForm.dayEnd } }] },
            ],
        });
        const variables: CoverageInfosByOwnedAccountIdQueryVariables = {
            ownedAccountId: attributes["custom:account_id"],
            startDateEndDate: {
                between: [
                    { startDate: moment(searchForm.dayStart).subtract(1, 'years').format('YYYY-MM-DD'), endDate: moment(searchForm.dayStart).format('YYYY-MM-DD') },
                    { startDate: moment(searchForm.dayEnd).format('YYYY-MM-DD'), endDate: moment(searchForm.dayEnd).add(1, 'years').format('YYYY-MM-DD') },
                ]
            },
            filter: filter,
            sortDirection: ModelSortDirection.DESC,
            limit: 1000
        };
        
        try {
            // TODO:nextTokenによる再帰処理が必要
            const { data } = (await API.graphql({
                query: queries.coverageInfosByOwnedAccountId,
                variables: variables,
            })) as GraphQLResult<CoverageInfosByOwnedAccountIdQuery>;
            coverageInfos = data?.coverageInfosByOwnedAccountId?.items as CoverageInfo[];
        } catch (e) {
            console.log(e);
            throw e;
        }
        return coverageInfos;
    }

    async createCoverageInfo(form: InputForm) {
        const id = uuid.v4();
        const createCoverageInfoInput: CreateCoverageInfoInput = {
            id: id,
            body: form.body,
            startDate: form.startDate,
            endDate: form.endDate,
            createdUserId: form.createdUserId,
            ownedAccountId: form.ownedAccountId,
            ownedDepartment: form.ownedDepartment,
            deleted: 0,
        };
        try {
            await API.graphql({
                query: createCoverageInfo,
                variables: { input: createCoverageInfoInput },
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async updateCoverageInfo(form: EditForm) {
        const updateCoverageDeskInput: UpdateCoverageInfoInput = {
            id: form.id,
            body: form.body,
            startDate: form.startDate,
            endDate: form.endDate,
            createdUserId: form.createdUserId,
        };
        try {
            await API.graphql({
                query: updateCoverageInfo,
                variables: { input: updateCoverageDeskInput },
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    async deleteCoverageInfo(id: string) {
        const updateCoverageDeskInput: UpdateCoverageInfoInput = {
            id: id,
            deleted: 1,
            deletedAt: moment().toISOString(),
        };
        //削除フラグを立てる
        try {
            await API.graphql({
                query: updateCoverageInfo,
                variables: { input: updateCoverageDeskInput },
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    }
}

export type SearchForm = {
    dayStart: string;
    dayEnd: string | null;
}

export type InputForm = {
    startDate: string;
    endDate: string;
    body: string;
    createdUserId: string;
    ownedAccountId: string;
    ownedDepartment: string;
}


export type EditForm = {
    id: string;
    startDate: string;
    endDate: string;
    body: string;
    createdUserId: string;
}