






































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from "@aws-amplify/api";
import * as mutations from '@/graphql/mutations';
import * as queries from "@/graphql/queries";
import { PGCUpdateSharingInput, GetSharingQuery, GetUserQuery, PGCService } from "@/API";
import { FileSearchService, FileData } from '@/services/files/FileSearch';
import Confirm from '@/components/Confirm.vue';
import Loading from '@/components/Loading.vue';
import Dialog from '@/components/Dialog.vue';
import ShareAddDialog from "@/components/share/ShareAddDialog.vue";
import moment from "moment";
import ShareSearchExUser from "@/components/share/ShareSearchExUser.vue";
import * as AuthUtils from "@/utils/AuthUtils";


@Component({
  components:{
      Confirm,
      Loading,
      Dialog,
      ShareAddDialog,
      ShareSearchExUser
  }
})
export default class ShareEdit extends Vue {
  private valid = false;
  private isNotFile = false;
  private tableLoading = false;

  private id = '';
  private sharingFiles = new Array<FileData>();
  private selectedSharingFiles = new Array<FileData>();
  private headers = [
    { text: 'ファイル名', value: 'fileName' },
    { text: 'タイトル', value: 'title' },
  ];
  
  private users = new Array<object>();
  private sharingUsers: string[] = [];
  private sharingUsersRules = [
    v => {
      if(v && v.length <= 0){
        return '共有するユーザーは必須項目です'
      }
      return true;
    },
    v => {
      if(v && v.length > 100){
        return '共有するユーザー数は100人以下です'
      }
      return true;
    },
  ];
  private limitDateMenu = false;
  private limitDate = '';
  private limitDateRules = [
    v => !!v || '共有期限（年月日）は必須項目です'
  ]
  private limitTimeMenu = false;
  private limitTime = '';
  private limitTimeRules = [
    v => !!v || '共有期限（時間）は必須項目です'
  ]
  private shareTitle = '';
  private shareTitleRules = [
    v => !!v || '共有タイトルは必須項目です',
    v => (v && v.length <= 64) || '共有タイトルは64文字以下です',
  ];
  private shareComment = '';
  private shareCommentRules = [
    v => {
      if(v && v.length > 1024 ){
        return '共有コメントは1024文字以下です';
      }
      return true;
    },
  ];

  private attributes;

  private confirmDialog = false;
  private confirmMode = '';
  private confirmTitle = '';
  private confirmMessage = '';

  private resultDialog = false;
  private resultDialogMode = '';
  private resultDialogTitle = '';
  private resultDialogMessage = '';
  private status = -1;

  private loading = false;

  private shareAddDialog = false;

  private fileSearchService = new FileSearchService();

  private isSharingCountLimit = false;
  private sharingLimitCount = FileSearchService.SHARE_LIMIT;

  @Watch('sharingFiles')
  private onChangeSharingFiles(){
    if(this.isNotFile && this.sharingFiles.length > 0){
      this.isNotFile = false;
    }

    if(this.isSharingCountLimit && this.sharingFiles.length <= this.sharingLimitCount){
      this.isSharingCountLimit = false;
    }
  }

  private async created(){
    try
    {
      this.tableLoading = true;

      if(this.$store.getters.user){
        this.id =this.$route.params.id;
        this.attributes = this.$store.getters.user.attributes;
      
        const { data } = (await API.graphql(graphqlOperation(queries.getSharing, {id: this.id}))) as GraphQLResult<GetSharingQuery>;
        
        if(data && data.getSharing){
          const sharing = data.getSharing;
          const files = sharing.sharingFiles as Array<string>;
          const sharedFiles = files && files.length > 0 ? await this.fileSearchService.searchShareCandidateFiles(files,[]) : new Array<FileData>();

          for(const file of sharedFiles){
              this.sharingFiles.push(file);
          }

          this.sharingUsers = sharing.sharingUsers as Array<string>;
          this.shareTitle = sharing.title || '';
          this.shareComment = sharing.description || '';
          const limitDatetime = moment(`${sharing.limitDate} ${sharing.limitTime}`, "YYYY-MM-DD HH:mm:ssZ").local();
          this.limitDate = limitDatetime.format('YYYY-MM-DD');
          this.limitTime = limitDatetime.format('HH:mm');
          this.status = sharing.status || 0;

          this.users = await AuthUtils.listUsers(PGCService.SHARING, "");

          //外部ユーザーをマージ
          for (const key in this.sharingUsers) {
            if (Object.prototype.hasOwnProperty.call(this.sharingUsers, key)) {
              const element = this.sharingUsers[key];
              const res = this.users.find((row) => row["val"] === element)
              if(!res){
                const input = { id: element };
                ((API.graphql(graphqlOperation(queries.getUser, { input: input }))) as Promise<GraphQLResult<GetUserQuery>>).then(
                  (result) => {
                    if(result.data?.getUser){
                      this.users.push({key: result.data.getUser.accountId + ":" + result.data.getUser.name, val: result.data.getUser.id});
                    }
                  }
                );
              }
            }
          }
        }
      }
    }
    catch(error)
    {
      console.error(error);
    }
    finally
    {
      this.tableLoading = false;
    }
  }

  private onClickAdd(){
    this.shareAddDialog = true;
  }

  private onChangeShareAddDialogSelected(files: FileData[]){
    const filteredFiles = files.filter(file => {
      if(file.customItems){
        const customItems = JSON.parse(file.customItems);
        if(customItems && (customItems.share === 1 || customItems.share === 2) && !this.sharingFiles.find(sharingFile => sharingFile.id === file.id)){
          return true;
        }
      }
    });
    for(const file of filteredFiles){
      if(file){
        this.sharingFiles.push(file);
      }
    }
    this.shareAddDialog = false;
  }

  private onClickDelete(){
    if(this.selectedSharingFiles.length > 0){
      this.confirmMode = 'warning';
      this.confirmTitle = '削除確認';
      this.confirmMessage = '選択したファイルを削除してもよろしいでしょうか？'
      this.confirmDialog = true;
    }
  }

  private confirmed(value){
    this.confirmDialog = false;
    if(value){
      this.sharingFiles = this.sharingFiles.filter(x => this.selectedSharingFiles.indexOf(x) == -1);
      this.selectedSharingFiles.splice(0);
    }
  }

  private async editShare(){  
    if(this.tableLoading){
      return false;
    }
    
    if(this.sharingFiles.length <= 0){
      this.isNotFile = true;
      return;
    }

    if(this.sharingFiles.length > this.sharingLimitCount){
      this.isSharingCountLimit = true;
      return;
    }

    if((this.$refs.shareEditForm as Vue & {validate: () => boolean}).validate()){
      this.loading = true;
      
      const dateTimeUtc = moment(`${this.limitDate} ${this.limitTime}`, 'YYYY-MM-DD HH:mm').utc();
      const limitDateUtc = dateTimeUtc.format('YYYY-MM-DD');
      const limitTimeUtc = dateTimeUtc.format('HH:mm:ssZ');

      const sharingFileIds = new Array<string>();
      for(const file of this.sharingFiles){
        if(file.id){
          sharingFileIds.push(file.id);
        }
      }
      
      const input: PGCUpdateSharingInput = {
        id: this.id,
        title: this.shareTitle,
        description: this.shareComment,
        limitDate: limitDateUtc,
        limitTime: limitTimeUtc,
        sharingUsers: this.sharingUsers,
        sharingFiles: sharingFileIds,
        status: this.status,
      };
      
      try{
        await API.graphql(graphqlOperation(mutations.updateSharingWithHistory, {input: input}));
        
        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "info";
        this.resultDialogTitle = "編集成功";
        this.resultDialogMessage = "共有の編集に成功しました。\n元の画面に戻ります。";
      }catch{
        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "編集失敗";
        this.resultDialogMessage = "共有の編集に失敗しました。\n元の画面に戻ります。";
      }
    }
  }

  //外部ユーザーの検索
  private addExUser(exUserStr: string, exUserId: string){
    this.users.push({key: exUserStr, val: exUserId})
    this.users = Array.from(new Set(this.users))
    this.sharingUsers.push(exUserId);
    this.sharingUsers = Array.from(new Set(this.sharingUsers))
  }
  
  private onChangeDialogClosed(value){
    if(value){
      this.resultDialog = false;
      this.back();
    }
  }

  private back(){
    this.$router.go(-1);
  }
}
