










































import { Component, Vue } from "vue-property-decorator";
import { FileSearchService, SearchCondition, FileData, SEARCH_MODE } from '@/services/files/FileSearch';
import FileCard from "@/components/files/searches/FileCard.vue";
import FileSearchHeader from "@/components/files/searches/FileSearchHeader.vue";
import FileSearchPagination from "@/components/files/searches/FileSearchPagination.vue";
import { scrollTop } from "@/utils/FileUtils";
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";
import { isAllow } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";

@Component({
  components:{
    FileCard,
    FileSearchHeader,
    FileSearchPagination,
    Loading,
    Dialog,
  }
})
export default class FileGroupDetail extends Vue {
  private mode = 'file';
  private select = false;
  private selectedFiles = [];

  private id = '';
  private total = 0;
  private files: FileData[] = [];
  private limit = 36;
  
  private page = 1;
  private length = 0;
  private routerName = 'FileGroupDetail';
  private backRoute = 'FileGroupSearchResult';

  private subscriptionCreate;
  private subscriptionUpdate;
  private fileSearchService = new FileSearchService();
  private condition: SearchCondition = {};
  private attributes;

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = '';
  private resultDialogTitle = '';
  private resultDialogMessage = '';

  private get from(): number {
    return ((this.page - 1 ) > 0 ? (this.page - 1 ) : 0) * this.limit;
  }

  private get to(): number {
    if(this.from + this.limit >= FileSearchService.SEARCH_LIMIT) {
      return FileSearchService.SEARCH_LIMIT - this.from;
    }
    return this.limit;
  }

  private async created(){
    const mode = this.$store.getters.searchMode;
    this.page = this.$store.getters.groupDetailPage ? this.$store.getters.groupDetailPage : 1;

    switch(mode){
      case SEARCH_MODE.File: {
        this.backRoute = 'FileSearchResult';
        break;
      }
      case SEARCH_MODE.FileGroup: {
        this.backRoute = 'FileGroupSearchResult';
        break;
      }
      default:{
        this.backRoute = 'Home';
        break;
      }
    }

    const user = this.$store.getters.user;
    this.attributes = user?.attributes;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter: any = {};
    if(isAllow("filing", "search", "COMPANY") && await isActiveServie("filing")){
      filter.ownedAccountId = this.attributes["custom:account_id"];
    }else if(isAllow("filing", "search", "DEPARTMENT") && await isActiveServie("filing")){
      filter.ownedDepartment = this.attributes["custom:department"];
    }else if(isAllow("filing", "search", "SELF") && await isActiveServie("filing")){
      filter.createdUserId = this.attributes.sub;
    }
  }

  private async search(){
    this.id = this.$route.params.id ? this.$route.params.id : '';
    this.condition = JSON.parse(JSON.stringify(this.$store.getters.searchCondition));
  
    if(this.id && this.condition){
      this.page = this.$store.getters.groupDetailPage ? this.$store.getters.groupDetailPage : 1;
      this.condition.groupIds = [this.id];
      this.$store.commit("setSearchCondition", this.condition);

      try{
        const result = await this.fileSearchService.searchFiles(this.condition, undefined, this.from, this.to);
        this.loading = false;
        if(result && result.items){
          this.selectedFiles = [];
          this.files = result.items || [];
          this.total = result.total || 0;
          this.length = result.length || 0;
        }
      }catch(error){
        this.files = [];
        this.total = 0;
        this.length = 0;
        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "取得失敗";
        this.resultDialogMessage = `データの取得に失敗しました。\n再度、お試しください。`;
      }finally{
        this.loading = false;
      }
    }
  }

  private sortFiles(items: FileData[]){
    const coverFileIndex = items.findIndex(x => x.group?.coverFileId === x.id);
    const coverFile = items.find(x => x.group?.coverFileId === x.id);
    if(coverFileIndex !== -1 && coverFile){
      items.splice(0, 0, coverFile);
      items.splice(coverFileIndex + 1, 1);
    }
    return items;
  }

  private async onReload(){
    this.loading = true;
    await this.search();
  }

  private async onPageNumberChanged(value){
    this.page = value;
    this.$store.commit("setGroupDetailPage", this.page);
    this.loading = true;

    if(this.$store.getters.from?.name === "FileEdit"){
      this.$store.commit("setFrom", "");
      this.$store.commit("setTo", "");
      setTimeout(() => {
        this.search();
      }, 3000);
    }else{
      scrollTop();
      await this.search();
    }
  }

  private onChangeResultDialogClosed(){
    this.resultDialog = false;
  }

  private async onChangeFileSearchHeaderResult(value){
    if(value){
      this.loading = true;
      setTimeout(() => {
          this.search();
      }, 3000);
    }
  }

  private async onChangeFileCardResult(value){
    if(value){
      this.loading = true;
      setTimeout(() => {
          this.search();
      }, 3000);
    }
  }

  private onChangeSelectMode(value){
    this.select = value;
    this.selectedFiles = [];
  }
}
