
import { Component, Vue } from "vue-property-decorator";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import { FileGroup, GetFileGroupQuery, UpdateFileGroupInput } from "@/API";
import { GraphQLResult } from "@aws-amplify/api";

@Component
export default class FileEditBaseForm extends Vue {
  name = "file-group-edit-base-form";

  private fileGroupId = "";

  protected title = "";
  protected description = "";
  protected memo = "";

  protected titleRules = [
    v => !!v || "タイトルは必須項目です",
    v => (v && v.length <= 128) || "タイトルは128文字以下です",
  ];
  protected descriptionRules = [
    v => !!v || "キャプションは必須項目です",
    v => (v && v.length <= 1024) || "キャプションは1024文字以下です",
  ];
  protected memoRules = [
    v => (!v || v && v.length <= 2048) || "備考は2048文字以下です",
  ];

  protected setDefault(){
    this.title = "";
    this.description = "";
    this.memo = "";
  }

  protected async setFileGroup(fileGroupId: string){
    this.fileGroupId = fileGroupId;

    if(this.fileGroupId){
      const { data } = (await API.graphql(graphqlOperation(queries.getFileGroup, {id: this.fileGroupId}))) as GraphQLResult<GetFileGroupQuery>;
      if(data && data.getFileGroup){
        const fileGroup = (data.getFileGroup as FileGroup);
        this.title = fileGroup.title ? fileGroup.title : "";
        this.description = fileGroup.description ? fileGroup.description : "";
        this.memo = fileGroup.memo ? fileGroup.memo : "";
      }
    }
  }

  protected async update(){
    const inputFileGroup: UpdateFileGroupInput = {
      id: this.fileGroupId,
      title: this.title ? this.title : null,
      description: this.description ? this.description : null,
      memo: this.memo ? this.memo : null,
    };

    await API.graphql(graphqlOperation(mutations.updateFileGroup, {input: inputFileGroup}));
  }
}
