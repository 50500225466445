


































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { SearchForm } from "@/services/coverage/coverageSerch";
import moment from "moment";

@Component
export default class CoverageSearchForm extends Vue {
  private dateFrom = moment(new Date()).local().format('YYYY-MM-DD');
  private dateFromMenu = false;
  private dateTo = "";
  private dateToMenu = false;
  private innerCondition: SearchForm = {
    accountId: "",
    dayStart: "",
    dayEnd: "",
    searchWord: "",
    assignedUserId: null,
  };

  @Prop({ default: () => ({}) })
  private condition;

  @Watch('condition')
  private onChangeCondition(newValue){
    this.dateFrom = newValue?.dateFrom ? moment(newValue.dateFrom).local().format('YYYY-MM-DD') : moment(new Date()).local().format('YYYY-MM-DD');
    this.dateTo = newValue?.dateTo ? moment(newValue.dateTo).local().format('YYYY-MM-DD') : "";
  }

  @Watch("dateFrom")
  @Watch("dateTo")
  private onChangeInnerCondition(){
    this.setCondition();
  }

  private created(){
    this.setCondition();
  }

  private mounted(){
    this.setCondition();
  }

  private clear(){
    this.dateFrom = moment(new Date()).local().format('YYYY-MM-DD');
    this.dateTo = "";
    this.$emit("coverage-search-clear", true);
  }

  private search(){
    this.$emit("coverage-search", this.innerCondition);
  }

  private setCondition(){
    const user = this.$store.getters.user;
    this.innerCondition.accountId = user ? user.attributes["custom:account_id"] : "";
    this.innerCondition.dayStart = this.dateFrom ? moment(this.dateFrom, "YYYY-MM-DD").startOf("day").toISOString() : "";
    this.innerCondition.dayEnd = this.dateTo ? moment(this.dateTo, 'YYYY-MM-DD').endOf("day").toISOString() : moment(this.dateFrom, 'YYYY-MM-DD').endOf("day").toISOString();
    this.$emit("coverage-search-condition", this.innerCondition);
  }
}
