
























import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class Dialog extends Vue {
  private innerDialog = false;
  private toolbarColor = 'indigo';
  private btnColor = 'teal lighten-1';

  @Prop({ default: false })
  private dialog!: boolean;
  @Prop({ default: 'info' })
  private mode!: string;
  @Prop({ default: '成功' })
  private title!: string;
  @Prop({ default: '処理に成功しました' })
  private message!: string;

  @Watch('dialog')
  private onChangeDialog(newValue){
    this.innerDialog = newValue;
  }
  
  @Watch('mode')
  private onChangeMode(newValue){
    switch(newValue){
      case 'error': {
        this.toolbarColor = 'error';
        this.btnColor = 'error';
        break;
      }
      case 'warning': {
        this.toolbarColor = 'warning';
        this.btnColor = 'warning';
        break;
      }
      default: {
        this.toolbarColor = 'indigo';
        this.btnColor = 'teal lighten-1';
        break;
      }
    }
  }
  
  private onClickClose(){
    this.innerDialog = false;
    this.$emit('dialog-closed', true);
  }
}
