

















import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class Audio extends Vue {
    private audioIcon = "/img/audio.png";

    @Prop()
    private src;
}
