
import { Component, Vue } from "vue-property-decorator";
import { API } from "aws-amplify";
import * as mutations from "@/graphql/mutations";
import {
  CreateCognitoUserInput,
  UpdateCognitoUserInput,
  ResetMFAInput,
  ResendPasswordEmailInput,
  DisableCognitoUserInput,
  RecoveryCognitoUserInput
} from "@/API";
import { UserRole, encodeRoleJson, getDefaultRole } from "@/utils/RoleUtils";
import { isActiveServie } from "@/utils/AccountSettingUtils";

@Component
export default class UserBaseForm extends Vue {
  name = "user-base-form";

  protected valid = false;
  protected user;
  protected departments = [];
  protected roles = [];

  protected isFiling = false;
  protected isCoverage = false;
  protected isSharing = false;
  protected isSale = false;

  id: string | undefined = "";
  username: string | undefined = "";
  email: string | undefined = "";
  accountId: string | undefined = "";
  department: string | undefined = "";
  role: UserRole | undefined = getDefaultRole();
  status: string | undefined = "";
  enabled: boolean | undefined = true;

  protected usernameCounter = 128;
  protected emailCounter = 256;
  protected departmentCounter = 64;

  protected rules = {
    required: (value) => !!value || "必須項目です",
    email: (value) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "メールアドレスを入力してください";
    },
    usernameCounter: (value) =>
      (value && value.length <= this.usernameCounter) || "最大128文字です",
    emailCounter: (value) =>
      (value && value.length <= this.emailCounter) || "最大256文字です",
    departmentCounter: (value) =>
      (value && value.length <= this.departmentCounter) || "最大64文字です",
  };

  protected async setDefault() {
    this.username = "";
    this.email = "";
    this.department = "";
    this.role = getDefaultRole();
  }

  protected async setService() {
    this.isFiling = await isActiveServie("filing");
    this.isCoverage = await isActiveServie("coverage");
    this.isSharing = await isActiveServie("sharing");
    this.isSale = await isActiveServie("sale");
  }

  protected async create() {
    const attributes = this.$store.state.user.attributes;
    this.updateRole();
    const createCognitoUserInput: CreateCognitoUserInput = {
      name: String(this.username).toString(),
      email: String(this.email).toString(),
      accountId: attributes["custom:account_id"],
      department: String(this.department).toString(),
      role: encodeRoleJson(this.role),
    };

    await API.graphql({
      query: mutations.createCognitoUser,
      variables: { input: createCognitoUserInput },
    });
  }

  protected async update() {
    this.updateRole();
    const updateCognitoUserInput: UpdateCognitoUserInput = {
      id: String(this.id).toString(),
      name: String(this.username).toString(),
      email: String(this.email).toString(),
      accountId: String(this.accountId).toString(),
      department: String(this.department).toString(),
      role: encodeRoleJson(this.role),
    };

    await API.graphql({
      query: mutations.updateCognitoUser,
      variables: { input: updateCognitoUserInput },
    });
  }

  protected async runResetMfa() {
    const resetMFAInput: ResetMFAInput = {
      id: String(this.id).toString(),
      accountId: String(this.accountId).toString()
    };
    await API.graphql({
      query: mutations.resetMFA,
      variables: { input: resetMFAInput },
    });
  }

  protected async runResendPasswordEmail() {
    const resendPasswordEmailInput: ResendPasswordEmailInput = {
      id: String(this.id).toString(),
      email: String(this.email).toString(),
      accountId: String(this.accountId).toString(),
    };
    await API.graphql({
      query: mutations.resendPasswordEmail,
      variables: { input: resendPasswordEmailInput },
    });
  }

  protected async runDisable() {
    const disableCognitoUserInput: DisableCognitoUserInput = {
      id: String(this.id).toString(),
      accountId: String(this.accountId).toString(),
    };
    await API.graphql({
      query: mutations.disableCognitoUser,
      variables: { input: disableCognitoUserInput },
    });
  }

  protected async runRecovery() {
    const recoveryCognitoUserInput: RecoveryCognitoUserInput = {
      id: String(this.id).toString(),
      email: String(this.email).toString(),
      accountId: String(this.accountId).toString(),
    };
    await API.graphql({
      query: mutations.recoveryCognitoUser,
      variables: { input: recoveryCognitoUserInput },
    });
  }

  private updateRole(){
    if(this.role && this.role.coverage?.operate && this.role.coverage?.read){
      Object.assign(this.role, 
        {
          coverageDesk: {
            operate: this.role.coverage?.manage ? this.role.coverage.operate : "DENY",
            read: this.role.coverage.read
        },
        coverageInfo: {
          operate: this.role.coverage?.manage ? this.role.coverage.operate : "DENY",
          read: this.role.coverage.read
        }
      });
    }
  }
}
