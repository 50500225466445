















































































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class FileDetailHeader extends Vue {
  private sheet = false;

  @Prop()
  private file;
  @Prop()
  private position;
  @Prop()
  private total;
  @Prop()
  private session;
  @Prop()
  private backRoute;

  back() {
    switch (this.backRoute) {
      case "FileSearchResult": {
        const searchPage = window.sessionStorage.getItem("searchPage");
        this.$router.push({
          name: this.backRoute,
          query: { q: this.session, page: searchPage ? searchPage : "1" },
        });
        break;
      }
      case "FileGroupDetail": {
        const groupPage = window.sessionStorage.getItem("groupPage");
        this.$router.push({
          name: this.backRoute,
          query: { q: this.session, page: groupPage ? groupPage : "1" },
        });
        break;
      }
      default: {
        this.$router.go(-1);
        break;
      }
    }
  }
}
