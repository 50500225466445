



























































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import FileEditBaseForm from '@/components/files/edit/FileEditBaseForm.vue';
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";

@Component({
  components:{
    Dialog,
    Loading,
  }
})
export default class FileEdit extends FileEditBaseForm {
  name = "file-edit"

  private id = "";

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = '';
  private resultDialogTitle = '';
  private resultDialogMessage = '';

  private async created(){
    this.setDefault();
    
    if(this.$route.params.id){
      this.id = this.$route.params.id.toString();
      await this.setFile(this.id);
    }
  }

  private async onClickUpdate(){
    if(!(this.$refs.fileEditForm as Vue & {validate: () => boolean}).validate()){
      return;
    }

    if(!this.customValidate()){
      return;
    }

    try{
      this.loading = true;
      await this.update();
      this.loading = false;

      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "編集成功";
      this.resultDialogMessage = "メタ情報の編集に成功しました。\n元の画面に戻ります。"

    }catch(err){
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "編集失敗";
      this.resultDialogMessage = `メタ情報の編集に失敗しました。\n元の画面に戻ります。`;

    }finally{
      this.loading = false;
    }
  }

  private back(){
    this.resultDialog = false;
    this.$router.go(-1);
  }

  private onChangeResultDialogClosed(){
    this.back();
  }

  private onUpdateSearchInputSelectedKeywords(value){
    this.keywordRulesMethod(value);
  }
}
