






import { Component, Prop, Vue } from "vue-property-decorator";
import { getStatusName } from "@/services/coverage/coverageServise";

@Component
export default class CoverageStatus extends Vue {
  name = "coverage-status";
  @Prop()
  status;

  private getStatusName() {
    return getStatusName(this.status);
  }
}
