











































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Coverage } from "../../API";
import MetaInfoTab from '@/components/files/details/MetaInfoTab.vue';
import FileDetailHeader from '@/components/files/details/FileDetailHeader.vue';
import FileMenu from '@/components/files/FileMenu.vue';
import Preview from '@/components/files/details/Preview.vue';
import { FileSearchService, FileData } from '@/services/files/FileSearch';
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components:{
    FileDetailHeader,
    FileMenu,
    MetaInfoTab,
    Preview,
    Dialog,
    Loading,
  }
})
export default class ShareDetail extends Vue {
  private mode = 'share';
  private sheet = false;
  
  private file: FileData = {};
  // eslint-disable-next-line
  private coverage: any = {};

  private sharingId = '';
  private position = 0;
  private limit = 1;
  private total = 0;
  private backRoute = '';
  private first = 0;

  private fileSearchService = new FileSearchService();

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  private get from(){
    const from = 0;
    if(this.total <= 0){
      return from;
    }
    if(this.total <= this.position){
      return this.total;
    }
    return this.position + 1;
  }

  private get to(): number {
    if(this.total > FileSearchService.SEARCH_LIMIT){
      return FileSearchService.SEARCH_LIMIT - 1;
    }
    return this.total - 1;
  }

  private async created(){
    await this.search()
  }

  @Watch('$route')
  private async onRouteChange() {
    // URLが変わったら、検索する
    await this.search();
  }

  private async search(){
    this.sharingId = this.$route.query.q ? this.$route.query.q.toString() : '';
    this.position = this.$route.query.position ? parseInt(this.$route.query.position.toString() ,10) : 0;

    if(this.position <= this.first){
      this.onClickFirst();
    }

    if(this.position >= FileSearchService.SEARCH_LIMIT){
      this.position = FileSearchService.SEARCH_LIMIT - 1;
      this.$router.push({ name: 'ShareDetail', query: { q: this.sharingId, position: this.position.toString() }});
    }

    this.total = 0;
    this.file = {};
    this.coverage = {};

    if(this.sharingId){
      try{
        this.loading = true;
        const result = await this.fileSearchService.searchSharingFiles(this.sharingId, this.position, this.limit);
        this.loading = false;

        if(result && result.items && result.items.length > 0){
          this.file = result.items[0];
          this.total = result.total || 0;
          if(this.file.coverageId && this.file.coverage){
            this.coverage = (this.file.coverage as Coverage);
          }
        }else{
          this.loading = false;
          this.resultDialog = true;
          this.resultDialogMode = "warning";
          this.resultDialogTitle = "取得失敗";
          this.resultDialogMessage = `このファイルは削除されているか、アクセス権がありません。`;
        }
      }catch(err){
        this.loading = false;
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "取得失敗";
        this.resultDialogMessage = `データの取得に失敗しました。\n再度、お試しください。`;
        
        if(err.errors && err.errors.length > 0){
          const error = err.errors[0];
          const errorType = error.errorType || "unknown";
          let errorMessage = error.message || "何らかのエラーが発生しました。";
          
          switch(errorType){
            case "Unauthorized":{
              errorMessage = "アクセス権がありません。"
              break;
            }
          }

          this.resultDialogMessage = errorMessage;          
        }
      }finally{
        this.loading = false;
      }
    }
  }

  private onChangeResultDialogClosed(){
    this.resultDialog = false;
  }

  private onChangeFileMenuSheet(value){
    this.sheet = value;
  }

  private back(){
    this.$router.push({name: 'ShareContent', params: { id: this.sharingId }});
  }

  private onClickFirst(){
    this.$router.push({ name: 'ShareDetail', query: { q: this.sharingId, position: this.first.toString() }});
  }

  private onClickPrevious(){
    this.$router.push({ name: 'ShareDetail', query: { q: this.sharingId, position: (this.position - 1).toString() }});
  }

  private onClickNext(){
    this.$router.push({ name: 'ShareDetail', query: { q: this.sharingId, position: (this.position + 1).toString() }});
  }

  private onClickLast(){
    this.$router.push({ name: 'ShareDetail', query: { q: this.sharingId, position: this.to.toString() }});
  }
}
