










































































import { Component, Vue, Watch } from "vue-property-decorator";
import Desk from "@/components/home/Desk.vue";
import Info from "@/components/home/Info.vue";
import Schedule from "@/components/home/Schedule.vue";
import moment from "moment";

@Component({
  components:{
    Desk,
    Info,
    Schedule,
  }
})
export default class Home extends Vue {
  private navigation = "schedule";
  private searchDateMenu = false;
  private searchDate = moment(new Date()).local().format('YYYY-MM-DD');
  private displayDate = this.searchDate;
  private date = moment(this.displayDate).date();
  private day = moment(this.displayDate).day();
  private month = moment(this.displayDate).month();
  private year = moment(this.displayDate).year();

  @Watch('displayDate')
  onChangeDisplayDate(){
    this.date = moment(this.displayDate).date();
    this.day = moment(this.displayDate).day();
    this.month = moment(this.displayDate).month();
    this.year = moment(this.displayDate).year();
  }
}
