












import { Component, Vue } from "vue-property-decorator";

@Component
export default class BooleanSelect extends Vue {
  name = "BooleanSelect";
}
