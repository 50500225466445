






import { Component, Vue } from "vue-property-decorator";
import CoverageView from "@/components/coverage/CoverageView.vue";
import * as AuthUtils from "@/utils/AuthUtils";

@Component({
  name: "my_page",
  components: {
    CoverageView,
  },
})
export default class MyCoverageDetails extends Vue {

  async created() {
    this.$store.commit("selectCoverage", this.$route.query?.id);
  }

  closeView() {
    this.$router.push({ name: 'MyPage', params: AuthUtils.getAccountId(null) })
  }
}
