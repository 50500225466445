






































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FileGroupMultipleEditForm from "@/components/files/edit/FileGroupMultipleEditForm.vue";
import { FileGroupData } from "@/services/files/FileSearch";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components:{
    Dialog,
    Loading,
  }
})
export default class FileGroupMultipleEditDialog extends FileGroupMultipleEditForm {
  name = "file-group-multiple-edit-dialog";
  private valid = false;
  private dialog = false;
  private loading = false;
  private resultDialog = false;
  private resultDialogMode = "";
  private resultDialogTitle = "";
  private resultDialogMessage = "";

  @Prop({ default: () => (new Array<FileGroupData>()) })
  private editFileGroups;
  @Prop({ default: false})
  private fileGroupMultipleEditDialog = false;

  @Watch('fileGroupMultipleEditDialog')
  private async onChangeFileGroupMultipleEditDialog(newValue){
    this.dialog = newValue;
    if(newValue){
      const fileGroupIds = new Array<string>();
      for(const fileGroup of this.editFileGroups){
        fileGroupIds.push(fileGroup.id);
      }
      this.setFileGroups(fileGroupIds);
    }
  }

  @Watch('dialog')
  private onChangeDialog(){
    this.$emit("file-group-multiple-edit-dialog", this.dialog);
  }

  private created(){
    this.setDefaulMultiple();
  }

  private onClickClose(){
    this.dialog = false;
    this.resultDialog = false;
    this.setDefaulMultiple();
    this.$emit('file-group-multiple-edit-dialog-closed', false);
  }

  private async onClickUpdate(){    
    if(!(this.$refs.fileGroupMultipleEditForm as Vue & {validate: () => boolean}).validate()){
      return;
    }
    try{
      this.loading = true;
      await this.multipleUpdate();
      this.resultDialog = true;
      this.resultDialogMode = "info";
      this.resultDialogTitle = "編集成功";
      this.resultDialogMessage = "アルバム情報の一括編集に成功しました。\n元の画面に戻ります。"
    }catch(error){
      this.resultDialogMode = "error";
      this.resultDialogTitle = "編集失敗";
      this.resultDialogMessage = `アルバム情報の一括編集に失敗しました。\n元の画面に戻ります。`;
    }finally{
      this.loading = false;
    }
  }

  private onChangeResultDialogClosed(){
    this.dialog = false;
    this.resultDialog = false;
    this.setDefaulMultiple();
    this.$emit('file-group-multiple-edit-dialog-closed', true);
  }
}
