/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type PGCCreateSharingInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  memo?: string | null,
  limitDate: string,
  limitTime: string,
  sharingUsers: Array< string | null >,
  sharingFiles: Array< string | null >,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  status: number,
  deleted: number,
};

export type Sharing = {
  __typename: "Sharing",
  id: string,
  title?: string | null,
  description?: string | null,
  memo?: string | null,
  limitDate: string,
  limitTime: string,
  sharingUsers: Array< string | null >,
  sharingFiles: Array< string | null >,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  status: number,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type PGCUpdateSharingInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  memo?: string | null,
  limitDate?: string | null,
  limitTime?: string | null,
  sharingUsers?: Array< string | null > | null,
  sharingFiles?: Array< string | null > | null,
  status?: number | null,
  deleted?: number | null,
  deletedAt?: string | null,
};

export type CreateCognitoUserInput = {
  name: string,
  email: string,
  accountId: string,
  department: string,
  role: string,
};

export type CreateCognitoUserResult = {
  __typename: "CreateCognitoUserResult",
  id: string,
};

export type DeleteCognitoUserInput = {
  id: string,
  accountId: string,
};

export type DeleteCognitoUserResult = {
  __typename: "DeleteCognitoUserResult",
  id: string,
};

export type UpdateCognitoUserInput = {
  id: string,
  name: string,
  email: string,
  accountId: string,
  department: string,
  role: string,
};

export type UpdateCognitoUserResult = {
  __typename: "UpdateCognitoUserResult",
  id: string,
};

export type RecoveryCognitoUserInput = {
  id: string,
  email: string,
  accountId: string,
};

export type RecoveryCognitoUserResult = {
  __typename: "RecoveryCognitoUserResult",
  id: string,
};

export type DisableCognitoUserInput = {
  id: string,
  accountId: string,
};

export type DisableCognitoUserResult = {
  __typename: "DisableCognitoUserResult",
  id: string,
};

export type ResetMFAInput = {
  id: string,
  accountId: string,
};

export type ResetMFAResult = {
  __typename: "ResetMFAResult",
  id: string,
};

export type ResendPasswordEmailInput = {
  id: string,
  email: string,
  accountId: string,
};

export type ResendPasswordEmailResult = {
  __typename: "ResendPasswordEmailResult",
  id: string,
};

export type CreateSelesSenderInput = {
  fileId: string,
  accountId: string,
};

export type CreateSelesSenderResult = {
  __typename: "CreateSelesSenderResult",
  id: string,
};

export type CreateAccountInput = {
  id?: string | null,
  company: string,
  person: string,
  domain: string,
  logoUri: string,
  config: string,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelAccountConditionInput = {
  company?: ModelStringInput | null,
  person?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  logoUri?: ModelStringInput | null,
  config?: ModelStringInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  company: string,
  person: string,
  domain: string,
  logoUri: string,
  config: string,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateAccountInput = {
  id: string,
  company?: string | null,
  person?: string | null,
  domain?: string | null,
  logoUri?: string | null,
  config?: string | null,
  deleted?: number | null,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateFileGroupInput = {
  id?: string | null,
  coverFileId?: string | null,
  title?: string | null,
  description?: string | null,
  memo?: string | null,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelFileGroupConditionInput = {
  coverFileId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFileGroupConditionInput | null > | null,
  or?: Array< ModelFileGroupConditionInput | null > | null,
  not?: ModelFileGroupConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type FileGroup = {
  __typename: "FileGroup",
  id: string,
  coverFileId?: string | null,
  title?: string | null,
  description?: string | null,
  memo?: string | null,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  file?: File | null,
};

export type File = {
  __typename: "File",
  id: string,
  groupId: string,
  rating?: number | null,
  title?: string | null,
  caption?: string | null,
  instructions?: string | null,
  memo?: string | null,
  fileName: string,
  protect: number,
  original?: string | null,
  caution: number,
  cautionInstructions?: string | null,
  reuse: number,
  reuseCondition?: string | null,
  keywords?: Array< string | null > | null,
  extension?: string | null,
  type?: string | null,
  meta?: string | null,
  tag?: Array< string | null > | null,
  customItems?: string | null,
  fileUri?: string | null,
  fileSize?: string | null,
  coverageId?: string | null,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  group?: FileGroup | null,
  coverage?: Coverage | null,
};

export type Coverage = {
  __typename: "Coverage",
  id: string,
  title?: string | null,
  location: string,
  startAt: string,
  endAt: string,
  description?: string | null,
  memo?: string | null,
  attachmentFile?: string | null,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  assignedUserId: string,
  status: number,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateFileGroupInput = {
  id: string,
  coverFileId?: string | null,
  title?: string | null,
  description?: string | null,
  memo?: string | null,
  createdUserId?: string | null,
  ownedAccountId?: string | null,
  ownedDepartment?: string | null,
  deleted?: number | null,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteFileGroupInput = {
  id: string,
};

export type CreateFileInput = {
  id?: string | null,
  groupId: string,
  rating?: number | null,
  title?: string | null,
  caption?: string | null,
  instructions?: string | null,
  memo?: string | null,
  fileName: string,
  protect: number,
  original?: string | null,
  caution: number,
  cautionInstructions?: string | null,
  reuse: number,
  reuseCondition?: string | null,
  keywords?: Array< string | null > | null,
  extension?: string | null,
  type?: string | null,
  meta?: string | null,
  tag?: Array< string | null > | null,
  customItems?: string | null,
  fileUri?: string | null,
  fileSize?: string | null,
  coverageId?: string | null,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelFileConditionInput = {
  groupId?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  title?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  protect?: ModelIntInput | null,
  original?: ModelStringInput | null,
  caution?: ModelIntInput | null,
  cautionInstructions?: ModelStringInput | null,
  reuse?: ModelIntInput | null,
  reuseCondition?: ModelStringInput | null,
  keywords?: ModelStringInput | null,
  extension?: ModelStringInput | null,
  type?: ModelStringInput | null,
  meta?: ModelStringInput | null,
  tag?: ModelStringInput | null,
  customItems?: ModelStringInput | null,
  fileUri?: ModelStringInput | null,
  fileSize?: ModelStringInput | null,
  coverageId?: ModelIDInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFileConditionInput | null > | null,
  or?: Array< ModelFileConditionInput | null > | null,
  not?: ModelFileConditionInput | null,
};

export type UpdateFileInput = {
  id: string,
  groupId?: string | null,
  rating?: number | null,
  title?: string | null,
  caption?: string | null,
  instructions?: string | null,
  memo?: string | null,
  fileName?: string | null,
  protect?: number | null,
  original?: string | null,
  caution?: number | null,
  cautionInstructions?: string | null,
  reuse?: number | null,
  reuseCondition?: string | null,
  keywords?: Array< string | null > | null,
  extension?: string | null,
  type?: string | null,
  meta?: string | null,
  tag?: Array< string | null > | null,
  customItems?: string | null,
  fileUri?: string | null,
  fileSize?: string | null,
  coverageId?: string | null,
  createdUserId?: string | null,
  ownedAccountId?: string | null,
  ownedDepartment?: string | null,
  deleted?: number | null,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteFileInput = {
  id: string,
};

export type CreateSharingInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  memo?: string | null,
  limitDate: string,
  limitTime: string,
  sharingUsers: Array< string | null >,
  sharingFiles: Array< string | null >,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  status: number,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelSharingConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  limitDate?: ModelStringInput | null,
  limitTime?: ModelStringInput | null,
  sharingFiles?: ModelStringInput | null,
  status?: ModelIntInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSharingConditionInput | null > | null,
  or?: Array< ModelSharingConditionInput | null > | null,
  not?: ModelSharingConditionInput | null,
};

export type UpdateSharingInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  memo?: string | null,
  limitDate?: string | null,
  limitTime?: string | null,
  sharingUsers?: Array< string | null > | null,
  sharingFiles?: Array< string | null > | null,
  createdUserId?: string | null,
  ownedAccountId?: string | null,
  ownedDepartment?: string | null,
  status?: number | null,
  deleted?: number | null,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteSharingInput = {
  id: string,
};

export type CreateCoverageInput = {
  id?: string | null,
  title?: string | null,
  location: string,
  startAt: string,
  endAt: string,
  description?: string | null,
  memo?: string | null,
  attachmentFile?: string | null,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  assignedUserId: string,
  status: number,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCoverageConditionInput = {
  title?: ModelStringInput | null,
  location?: ModelStringInput | null,
  startAt?: ModelStringInput | null,
  endAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  attachmentFile?: ModelStringInput | null,
  status?: ModelIntInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCoverageConditionInput | null > | null,
  or?: Array< ModelCoverageConditionInput | null > | null,
  not?: ModelCoverageConditionInput | null,
};

export type UpdateCoverageInput = {
  id: string,
  title?: string | null,
  location?: string | null,
  startAt?: string | null,
  endAt?: string | null,
  description?: string | null,
  memo?: string | null,
  attachmentFile?: string | null,
  createdUserId?: string | null,
  ownedAccountId?: string | null,
  ownedDepartment?: string | null,
  assignedUserId?: string | null,
  status?: number | null,
  deleted?: number | null,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCoverageInput = {
  id: string,
};

export type CreateCoverageInfoInput = {
  id?: string | null,
  startDate: string,
  endDate: string,
  body: string,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCoverageInfoConditionInput = {
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  body?: ModelStringInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCoverageInfoConditionInput | null > | null,
  or?: Array< ModelCoverageInfoConditionInput | null > | null,
  not?: ModelCoverageInfoConditionInput | null,
};

export type CoverageInfo = {
  __typename: "CoverageInfo",
  id: string,
  startDate: string,
  endDate: string,
  body: string,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateCoverageInfoInput = {
  id: string,
  startDate?: string | null,
  endDate?: string | null,
  body?: string | null,
  createdUserId?: string | null,
  ownedAccountId?: string | null,
  ownedDepartment?: string | null,
  deleted?: number | null,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCoverageInfoInput = {
  id: string,
};

export type CreateCoverageDeskInput = {
  id?: string | null,
  startDate: string,
  endDate: string,
  name: string,
  memo?: string | null,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCoverageDeskConditionInput = {
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  name?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCoverageDeskConditionInput | null > | null,
  or?: Array< ModelCoverageDeskConditionInput | null > | null,
  not?: ModelCoverageDeskConditionInput | null,
};

export type CoverageDesk = {
  __typename: "CoverageDesk",
  id: string,
  startDate: string,
  endDate: string,
  name: string,
  memo?: string | null,
  createdUserId: string,
  ownedAccountId: string,
  ownedDepartment: string,
  deleted: number,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateCoverageDeskInput = {
  id: string,
  startDate?: string | null,
  endDate?: string | null,
  name?: string | null,
  memo?: string | null,
  createdUserId?: string | null,
  ownedAccountId?: string | null,
  ownedDepartment?: string | null,
  deleted?: number | null,
  deletedAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCoverageDeskInput = {
  id: string,
};

export type CreateHistoryInput = {
  id?: string | null,
  data: string,
  body: string,
  operation: string,
  userId: string,
  accountId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelHistoryConditionInput = {
  data?: ModelStringInput | null,
  body?: ModelStringInput | null,
  operation?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHistoryConditionInput | null > | null,
  or?: Array< ModelHistoryConditionInput | null > | null,
  not?: ModelHistoryConditionInput | null,
};

export type History = {
  __typename: "History",
  id: string,
  data: string,
  body: string,
  operation: string,
  userId: string,
  accountId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateHistoryInput = {
  id: string,
  data?: string | null,
  body?: string | null,
  operation?: string | null,
  userId?: string | null,
  accountId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteHistoryInput = {
  id: string,
};

export type CreateChargeInput = {
  id?: string | null,
  accountId: string,
  aggregationDate: string,
  status: number,
  basicCharge: number,
  s3Charge: number,
  excessTransferredCharge: number,
  s3StdUsageSize: string,
  s3IaUsageSize: string,
  s3GlIrUsageSize?: string | null,
  s3GlUsageSize: string,
  transferredSize: string,
  downloadRequestCont: number,
  excludingTax: number,
  includingTax: number,
  taxRate: number,
  exchangeRate: number,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelChargeConditionInput = {
  aggregationDate?: ModelStringInput | null,
  status?: ModelIntInput | null,
  basicCharge?: ModelIntInput | null,
  s3Charge?: ModelIntInput | null,
  excessTransferredCharge?: ModelIntInput | null,
  s3StdUsageSize?: ModelStringInput | null,
  s3IaUsageSize?: ModelStringInput | null,
  s3GlIrUsageSize?: ModelStringInput | null,
  s3GlUsageSize?: ModelStringInput | null,
  transferredSize?: ModelStringInput | null,
  downloadRequestCont?: ModelIntInput | null,
  excludingTax?: ModelIntInput | null,
  includingTax?: ModelIntInput | null,
  taxRate?: ModelFloatInput | null,
  exchangeRate?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChargeConditionInput | null > | null,
  or?: Array< ModelChargeConditionInput | null > | null,
  not?: ModelChargeConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Charge = {
  __typename: "Charge",
  id: string,
  accountId: string,
  aggregationDate: string,
  status: number,
  basicCharge: number,
  s3Charge: number,
  excessTransferredCharge: number,
  s3StdUsageSize: string,
  s3IaUsageSize: string,
  s3GlIrUsageSize?: string | null,
  s3GlUsageSize: string,
  transferredSize: string,
  downloadRequestCont: number,
  excludingTax: number,
  includingTax: number,
  taxRate: number,
  exchangeRate: number,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateChargeInput = {
  id: string,
  accountId: string,
  aggregationDate?: string | null,
  status?: number | null,
  basicCharge?: number | null,
  s3Charge?: number | null,
  excessTransferredCharge?: number | null,
  s3StdUsageSize?: string | null,
  s3IaUsageSize?: string | null,
  s3GlIrUsageSize?: string | null,
  s3GlUsageSize?: string | null,
  transferredSize?: string | null,
  downloadRequestCont?: number | null,
  excludingTax?: number | null,
  includingTax?: number | null,
  taxRate?: number | null,
  exchangeRate?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteChargeInput = {
  id: string,
  accountId: string,
};

export type CreateFileDownloadHistoryInput = {
  id?: string | null,
  accountId: string,
  fileId: string,
  fileSize: string,
  storageClass?: string | null,
  userId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelFileDownloadHistoryConditionInput = {
  fileId?: ModelIDInput | null,
  fileSize?: ModelStringInput | null,
  storageClass?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFileDownloadHistoryConditionInput | null > | null,
  or?: Array< ModelFileDownloadHistoryConditionInput | null > | null,
  not?: ModelFileDownloadHistoryConditionInput | null,
};

export type FileDownloadHistory = {
  __typename: "FileDownloadHistory",
  id: string,
  accountId: string,
  fileId: string,
  fileSize: string,
  storageClass?: string | null,
  userId: string,
  createdAt: string,
  updatedAt?: string | null,
};

export type UpdateFileDownloadHistoryInput = {
  id: string,
  accountId: string,
  fileId?: string | null,
  fileSize?: string | null,
  storageClass?: string | null,
  userId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteFileDownloadHistoryInput = {
  id: string,
  accountId: string,
};

export type CreateGlacierDownloadRequestInput = {
  id?: string | null,
  accountId: string,
  fileId: string,
  userId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelGlacierDownloadRequestConditionInput = {
  fileId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGlacierDownloadRequestConditionInput | null > | null,
  or?: Array< ModelGlacierDownloadRequestConditionInput | null > | null,
  not?: ModelGlacierDownloadRequestConditionInput | null,
};

export type GlacierDownloadRequest = {
  __typename: "GlacierDownloadRequest",
  id: string,
  accountId: string,
  fileId: string,
  userId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateGlacierDownloadRequestInput = {
  id: string,
  accountId: string,
  fileId?: string | null,
  userId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteGlacierDownloadRequestInput = {
  id: string,
  accountId: string,
};

export type PGCSearchableFileSortInput = {
  field?: PGCSearchableFileSortableFields | null,
  direction?: PGCSearchableSortDirection | null,
};

export enum PGCSearchableFileSortableFields {
  id = "id",
  groupId = "groupId",
  rating = "rating",
  title = "title",
  caption = "caption",
  instructions = "instructions",
  memo = "memo",
  fileName = "fileName",
  protect = "protect",
  original = "original",
  caution = "caution",
  cautionInstructions = "cautionInstructions",
  reuse = "reuse",
  reuseCondition = "reuseCondition",
  keywords = "keywords",
  extension = "extension",
  type = "type",
  meta = "meta",
  tag = "tag",
  customItems = "customItems",
  fileUri = "fileUri",
  fileSize = "fileSize",
  coverageId = "coverageId",
  createdUserId = "createdUserId",
  ownedAccountId = "ownedAccountId",
  ownedDepartment = "ownedDepartment",
  deleted = "deleted",
  deletedAt = "deletedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum PGCSearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type PGCSharingWithFileConnection = {
  __typename: "PGCSharingWithFileConnection",
  sharing?: Sharing | null,
  files?:  Array<File | null > | null,
  nextToken?: Array< string | null > | null,
  total?: number | null,
};

export type PGCSearchableFileConnection = {
  __typename: "PGCSearchableFileConnection",
  items?:  Array<File | null > | null,
  nextToken?: Array< string | null > | null,
  total?: number | null,
};

export type SearchableFileFilterInput = {
  id?: SearchableIDFilterInput | null,
  groupId?: SearchableIDFilterInput | null,
  rating?: SearchableIntFilterInput | null,
  title?: SearchableStringFilterInput | null,
  caption?: SearchableStringFilterInput | null,
  instructions?: SearchableStringFilterInput | null,
  memo?: SearchableStringFilterInput | null,
  fileName?: SearchableStringFilterInput | null,
  protect?: SearchableIntFilterInput | null,
  original?: SearchableStringFilterInput | null,
  caution?: SearchableIntFilterInput | null,
  cautionInstructions?: SearchableStringFilterInput | null,
  reuse?: SearchableIntFilterInput | null,
  reuseCondition?: SearchableStringFilterInput | null,
  keywords?: SearchableStringFilterInput | null,
  extension?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  meta?: SearchableStringFilterInput | null,
  tag?: SearchableStringFilterInput | null,
  customItems?: SearchableStringFilterInput | null,
  fileUri?: SearchableStringFilterInput | null,
  fileSize?: SearchableStringFilterInput | null,
  coverageId?: SearchableIDFilterInput | null,
  createdUserId?: SearchableIDFilterInput | null,
  ownedAccountId?: SearchableIDFilterInput | null,
  ownedDepartment?: SearchableStringFilterInput | null,
  deleted?: SearchableIntFilterInput | null,
  deletedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableFileFilterInput | null > | null,
  or?: Array< SearchableFileFilterInput | null > | null,
  not?: SearchableFileFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableFileSortInput = {
  field?: SearchableFileSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFileSortableFields {
  id = "id",
  groupId = "groupId",
  rating = "rating",
  title = "title",
  caption = "caption",
  instructions = "instructions",
  memo = "memo",
  fileName = "fileName",
  protect = "protect",
  original = "original",
  caution = "caution",
  cautionInstructions = "cautionInstructions",
  reuse = "reuse",
  reuseCondition = "reuseCondition",
  keywords = "keywords",
  extension = "extension",
  type = "type",
  meta = "meta",
  tag = "tag",
  customItems = "customItems",
  fileUri = "fileUri",
  fileSize = "fileSize",
  coverageId = "coverageId",
  createdUserId = "createdUserId",
  ownedAccountId = "ownedAccountId",
  ownedDepartment = "ownedDepartment",
  deleted = "deleted",
  deletedAt = "deletedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableFileConnection = {
  __typename: "SearchableFileConnection",
  items?:  Array<File | null > | null,
  nextToken?: Array< string | null > | null,
  total?: number | null,
};

export type SearchableFileGroupFilterInput = {
  id?: SearchableIDFilterInput | null,
  coverFileId?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  memo?: SearchableStringFilterInput | null,
  createdUserId?: SearchableIDFilterInput | null,
  ownedAccountId?: SearchableIDFilterInput | null,
  ownedDepartment?: SearchableStringFilterInput | null,
  deleted?: SearchableIntFilterInput | null,
  deletedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableFileGroupFilterInput | null > | null,
  or?: Array< SearchableFileGroupFilterInput | null > | null,
  not?: SearchableFileGroupFilterInput | null,
};

export type SearchableFileGroupSortInput = {
  field?: SearchableFileGroupSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableFileGroupSortableFields {
  id = "id",
  coverFileId = "coverFileId",
  title = "title",
  description = "description",
  memo = "memo",
  createdUserId = "createdUserId",
  ownedAccountId = "ownedAccountId",
  ownedDepartment = "ownedDepartment",
  deleted = "deleted",
  deletedAt = "deletedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableFileGroupConnection = {
  __typename: "SearchableFileGroupConnection",
  items?:  Array<FileGroup | null > | null,
  nextToken?: Array< string | null > | null,
  total?: number | null,
};

export type SearchableCoverageFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  location?: SearchableStringFilterInput | null,
  startAt?: SearchableStringFilterInput | null,
  endAt?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  memo?: SearchableStringFilterInput | null,
  attachmentFile?: SearchableStringFilterInput | null,
  createdUserId?: SearchableIDFilterInput | null,
  ownedAccountId?: SearchableIDFilterInput | null,
  ownedDepartment?: SearchableStringFilterInput | null,
  assignedUserId?: SearchableIDFilterInput | null,
  status?: SearchableIntFilterInput | null,
  deleted?: SearchableIntFilterInput | null,
  deletedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableCoverageFilterInput | null > | null,
  or?: Array< SearchableCoverageFilterInput | null > | null,
  not?: SearchableCoverageFilterInput | null,
};

export type SearchableCoverageSortInput = {
  field?: SearchableCoverageSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCoverageSortableFields {
  id = "id",
  title = "title",
  location = "location",
  startAt = "startAt",
  endAt = "endAt",
  description = "description",
  memo = "memo",
  attachmentFile = "attachmentFile",
  createdUserId = "createdUserId",
  ownedAccountId = "ownedAccountId",
  ownedDepartment = "ownedDepartment",
  assignedUserId = "assignedUserId",
  status = "status",
  deleted = "deleted",
  deletedAt = "deletedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableCoverageConnection = {
  __typename: "SearchableCoverageConnection",
  items?:  Array<Coverage | null > | null,
  nextToken?: Array< string | null > | null,
  total?: number | null,
};

export type SearchableSharingFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  memo?: SearchableStringFilterInput | null,
  limitDate?: SearchableStringFilterInput | null,
  limitTime?: SearchableStringFilterInput | null,
  sharingUsers?: SearchableStringFilterInput | null,
  sharingFiles?: SearchableStringFilterInput | null,
  createdUserId?: SearchableIDFilterInput | null,
  ownedAccountId?: SearchableIDFilterInput | null,
  ownedDepartment?: SearchableStringFilterInput | null,
  status?: SearchableIntFilterInput | null,
  deleted?: SearchableIntFilterInput | null,
  deletedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableSharingFilterInput | null > | null,
  or?: Array< SearchableSharingFilterInput | null > | null,
  not?: SearchableSharingFilterInput | null,
};

export type SearchableSharingSortInput = {
  field?: SearchableSharingSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableSharingSortableFields {
  id = "id",
  title = "title",
  description = "description",
  memo = "memo",
  limitDate = "limitDate",
  limitTime = "limitTime",
  sharingUsers = "sharingUsers",
  sharingFiles = "sharingFiles",
  createdUserId = "createdUserId",
  ownedAccountId = "ownedAccountId",
  ownedDepartment = "ownedDepartment",
  status = "status",
  deleted = "deleted",
  deletedAt = "deletedAt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableSharingConnection = {
  __typename: "SearchableSharingConnection",
  items?:  Array<Sharing | null > | null,
  nextToken?: Array< string | null > | null,
  total?: number | null,
};

export type SearchableHistoryFilterInput = {
  id?: SearchableIDFilterInput | null,
  data?: SearchableStringFilterInput | null,
  body?: SearchableStringFilterInput | null,
  operation?: SearchableStringFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  accountId?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableHistoryFilterInput | null > | null,
  or?: Array< SearchableHistoryFilterInput | null > | null,
  not?: SearchableHistoryFilterInput | null,
};

export type SearchableHistorySortInput = {
  field?: SearchableHistorySortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableHistorySortableFields {
  id = "id",
  data = "data",
  body = "body",
  operation = "operation",
  userId = "userId",
  accountId = "accountId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableHistoryConnection = {
  __typename: "SearchableHistoryConnection",
  items?:  Array<History | null > | null,
  nextToken?: Array< string | null > | null,
  total?: number | null,
};

export type ListUserInput = {
  accountId: string,
  service: PGCService,
};

export enum PGCService {
  FILING = "FILING",
  COVERAGE = "COVERAGE",
  SHARING = "SHARING",
  MANAGEMENT = "MANAGEMENT",
  SALE = "SALE",
}


export type ListUserResult = {
  __typename: "ListUserResult",
  Items?:  Array<ListUserItems | null > | null,
};

export type ListUserItems = {
  __typename: "ListUserItems",
  id: string,
  name: string,
  enabled: boolean,
};

export type GetUserInput = {
  id?: string | null,
  email?: string | null,
};

export type GetUserResult = {
  __typename: "GetUserResult",
  id: string,
  name: string,
  email: string,
  accountId: string,
  department: string,
};

export type GetCognitoUserInput = {
  id: string,
  accountId: string,
};

export type GetCognitoUserResult = {
  __typename: "GetCognitoUserResult",
  id: string,
  name: string,
  email: string,
  accountId: string,
  department: string,
  role: string,
  deleted: boolean,
  status: string,
  enabled: boolean,
};

export type ExportFilesCSVInput = {
  accountId: string,
  fileIds?: Array< string | null > | null,
  fileGroupIds?: Array< string | null > | null,
};

export type ExportFilesCSVResult = {
  __typename: "ExportFilesCSVResult",
  signedUrl: string,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  company?: ModelStringInput | null,
  person?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  logoUri?: ModelStringInput | null,
  config?: ModelStringInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelFileGroupFilterInput = {
  id?: ModelIDInput | null,
  coverFileId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  createdUserId?: ModelIDInput | null,
  ownedAccountId?: ModelIDInput | null,
  ownedDepartment?: ModelStringInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFileGroupFilterInput | null > | null,
  or?: Array< ModelFileGroupFilterInput | null > | null,
  not?: ModelFileGroupFilterInput | null,
};

export type ModelFileGroupConnection = {
  __typename: "ModelFileGroupConnection",
  items:  Array<FileGroup | null >,
  nextToken?: string | null,
};

export type ModelFileFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  title?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  protect?: ModelIntInput | null,
  original?: ModelStringInput | null,
  caution?: ModelIntInput | null,
  cautionInstructions?: ModelStringInput | null,
  reuse?: ModelIntInput | null,
  reuseCondition?: ModelStringInput | null,
  keywords?: ModelStringInput | null,
  extension?: ModelStringInput | null,
  type?: ModelStringInput | null,
  meta?: ModelStringInput | null,
  tag?: ModelStringInput | null,
  customItems?: ModelStringInput | null,
  fileUri?: ModelStringInput | null,
  fileSize?: ModelStringInput | null,
  coverageId?: ModelIDInput | null,
  createdUserId?: ModelIDInput | null,
  ownedAccountId?: ModelIDInput | null,
  ownedDepartment?: ModelStringInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFileFilterInput | null > | null,
  or?: Array< ModelFileFilterInput | null > | null,
  not?: ModelFileFilterInput | null,
};

export type ModelFileConnection = {
  __typename: "ModelFileConnection",
  items:  Array<File | null >,
  nextToken?: string | null,
};

export type ModelSharingFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  limitDate?: ModelStringInput | null,
  limitTime?: ModelStringInput | null,
  sharingUsers?: ModelStringInput | null,
  sharingFiles?: ModelStringInput | null,
  createdUserId?: ModelIDInput | null,
  ownedAccountId?: ModelIDInput | null,
  ownedDepartment?: ModelStringInput | null,
  status?: ModelIntInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSharingFilterInput | null > | null,
  or?: Array< ModelSharingFilterInput | null > | null,
  not?: ModelSharingFilterInput | null,
};

export type ModelSharingConnection = {
  __typename: "ModelSharingConnection",
  items:  Array<Sharing | null >,
  nextToken?: string | null,
};

export type ModelCoverageFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  location?: ModelStringInput | null,
  startAt?: ModelStringInput | null,
  endAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  attachmentFile?: ModelStringInput | null,
  createdUserId?: ModelIDInput | null,
  ownedAccountId?: ModelIDInput | null,
  ownedDepartment?: ModelStringInput | null,
  assignedUserId?: ModelIDInput | null,
  status?: ModelIntInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCoverageFilterInput | null > | null,
  or?: Array< ModelCoverageFilterInput | null > | null,
  not?: ModelCoverageFilterInput | null,
};

export type ModelCoverageConnection = {
  __typename: "ModelCoverageConnection",
  items:  Array<Coverage | null >,
  nextToken?: string | null,
};

export type ModelCoverageInfoFilterInput = {
  id?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  body?: ModelStringInput | null,
  createdUserId?: ModelIDInput | null,
  ownedAccountId?: ModelIDInput | null,
  ownedDepartment?: ModelStringInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCoverageInfoFilterInput | null > | null,
  or?: Array< ModelCoverageInfoFilterInput | null > | null,
  not?: ModelCoverageInfoFilterInput | null,
};

export type ModelCoverageInfoConnection = {
  __typename: "ModelCoverageInfoConnection",
  items:  Array<CoverageInfo | null >,
  nextToken?: string | null,
};

export type ModelCoverageInfoByOwnedAccountIdCompositeKeyConditionInput = {
  eq?: ModelCoverageInfoByOwnedAccountIdCompositeKeyInput | null,
  le?: ModelCoverageInfoByOwnedAccountIdCompositeKeyInput | null,
  lt?: ModelCoverageInfoByOwnedAccountIdCompositeKeyInput | null,
  ge?: ModelCoverageInfoByOwnedAccountIdCompositeKeyInput | null,
  gt?: ModelCoverageInfoByOwnedAccountIdCompositeKeyInput | null,
  between?: Array< ModelCoverageInfoByOwnedAccountIdCompositeKeyInput | null > | null,
  beginsWith?: ModelCoverageInfoByOwnedAccountIdCompositeKeyInput | null,
};

export type ModelCoverageInfoByOwnedAccountIdCompositeKeyInput = {
  startDate?: string | null,
  endDate?: string | null,
};

export type ModelCoverageDeskFilterInput = {
  id?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  name?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  createdUserId?: ModelIDInput | null,
  ownedAccountId?: ModelIDInput | null,
  ownedDepartment?: ModelStringInput | null,
  deleted?: ModelIntInput | null,
  deletedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCoverageDeskFilterInput | null > | null,
  or?: Array< ModelCoverageDeskFilterInput | null > | null,
  not?: ModelCoverageDeskFilterInput | null,
};

export type ModelCoverageDeskConnection = {
  __typename: "ModelCoverageDeskConnection",
  items:  Array<CoverageDesk | null >,
  nextToken?: string | null,
};

export type ModelCoverageDeskByOwnedAccountIdCompositeKeyConditionInput = {
  eq?: ModelCoverageDeskByOwnedAccountIdCompositeKeyInput | null,
  le?: ModelCoverageDeskByOwnedAccountIdCompositeKeyInput | null,
  lt?: ModelCoverageDeskByOwnedAccountIdCompositeKeyInput | null,
  ge?: ModelCoverageDeskByOwnedAccountIdCompositeKeyInput | null,
  gt?: ModelCoverageDeskByOwnedAccountIdCompositeKeyInput | null,
  between?: Array< ModelCoverageDeskByOwnedAccountIdCompositeKeyInput | null > | null,
  beginsWith?: ModelCoverageDeskByOwnedAccountIdCompositeKeyInput | null,
};

export type ModelCoverageDeskByOwnedAccountIdCompositeKeyInput = {
  startDate?: string | null,
  endDate?: string | null,
};

export type ModelHistoryFilterInput = {
  id?: ModelIDInput | null,
  data?: ModelStringInput | null,
  body?: ModelStringInput | null,
  operation?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHistoryFilterInput | null > | null,
  or?: Array< ModelHistoryFilterInput | null > | null,
  not?: ModelHistoryFilterInput | null,
};

export type ModelHistoryConnection = {
  __typename: "ModelHistoryConnection",
  items:  Array<History | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelChargeFilterInput = {
  id?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  aggregationDate?: ModelStringInput | null,
  status?: ModelIntInput | null,
  basicCharge?: ModelIntInput | null,
  s3Charge?: ModelIntInput | null,
  excessTransferredCharge?: ModelIntInput | null,
  s3StdUsageSize?: ModelStringInput | null,
  s3IaUsageSize?: ModelStringInput | null,
  s3GlIrUsageSize?: ModelStringInput | null,
  s3GlUsageSize?: ModelStringInput | null,
  transferredSize?: ModelStringInput | null,
  downloadRequestCont?: ModelIntInput | null,
  excludingTax?: ModelIntInput | null,
  includingTax?: ModelIntInput | null,
  taxRate?: ModelFloatInput | null,
  exchangeRate?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChargeFilterInput | null > | null,
  or?: Array< ModelChargeFilterInput | null > | null,
  not?: ModelChargeFilterInput | null,
};

export type ModelChargeConnection = {
  __typename: "ModelChargeConnection",
  items:  Array<Charge | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelChargeByAccountIdCompositeKeyConditionInput = {
  eq?: ModelChargeByAccountIdCompositeKeyInput | null,
  le?: ModelChargeByAccountIdCompositeKeyInput | null,
  lt?: ModelChargeByAccountIdCompositeKeyInput | null,
  ge?: ModelChargeByAccountIdCompositeKeyInput | null,
  gt?: ModelChargeByAccountIdCompositeKeyInput | null,
  between?: Array< ModelChargeByAccountIdCompositeKeyInput | null > | null,
  beginsWith?: ModelChargeByAccountIdCompositeKeyInput | null,
};

export type ModelChargeByAccountIdCompositeKeyInput = {
  aggregationDate?: string | null,
  status?: number | null,
};

export type ModelFileDownloadHistoryFilterInput = {
  id?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  fileId?: ModelIDInput | null,
  fileSize?: ModelStringInput | null,
  storageClass?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFileDownloadHistoryFilterInput | null > | null,
  or?: Array< ModelFileDownloadHistoryFilterInput | null > | null,
  not?: ModelFileDownloadHistoryFilterInput | null,
};

export type ModelFileDownloadHistoryConnection = {
  __typename: "ModelFileDownloadHistoryConnection",
  items:  Array<FileDownloadHistory | null >,
  nextToken?: string | null,
};

export type ModelGlacierDownloadRequestFilterInput = {
  id?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  fileId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGlacierDownloadRequestFilterInput | null > | null,
  or?: Array< ModelGlacierDownloadRequestFilterInput | null > | null,
  not?: ModelGlacierDownloadRequestFilterInput | null,
};

export type ModelGlacierDownloadRequestConnection = {
  __typename: "ModelGlacierDownloadRequestConnection",
  items:  Array<GlacierDownloadRequest | null >,
  nextToken?: string | null,
};

export type CreateSharingWithHistoryMutationVariables = {
  input: PGCCreateSharingInput,
};

export type CreateSharingWithHistoryMutation = {
  createSharingWithHistory?:  {
    __typename: "Sharing",
    id: string,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    limitDate: string,
    limitTime: string,
    sharingUsers: Array< string | null >,
    sharingFiles: Array< string | null >,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateSharingWithHistoryMutationVariables = {
  input: PGCUpdateSharingInput,
};

export type UpdateSharingWithHistoryMutation = {
  updateSharingWithHistory?:  {
    __typename: "Sharing",
    id: string,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    limitDate: string,
    limitTime: string,
    sharingUsers: Array< string | null >,
    sharingFiles: Array< string | null >,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateCognitoUserMutationVariables = {
  input?: CreateCognitoUserInput | null,
};

export type CreateCognitoUserMutation = {
  createCognitoUser?:  {
    __typename: "CreateCognitoUserResult",
    id: string,
  } | null,
};

export type DeleteCognitoUserMutationVariables = {
  input?: DeleteCognitoUserInput | null,
};

export type DeleteCognitoUserMutation = {
  deleteCognitoUser?:  {
    __typename: "DeleteCognitoUserResult",
    id: string,
  } | null,
};

export type UpdateCognitoUserMutationVariables = {
  input?: UpdateCognitoUserInput | null,
};

export type UpdateCognitoUserMutation = {
  updateCognitoUser?:  {
    __typename: "UpdateCognitoUserResult",
    id: string,
  } | null,
};

export type RecoveryCognitoUserMutationVariables = {
  input?: RecoveryCognitoUserInput | null,
};

export type RecoveryCognitoUserMutation = {
  recoveryCognitoUser?:  {
    __typename: "RecoveryCognitoUserResult",
    id: string,
  } | null,
};

export type DisableCognitoUserMutationVariables = {
  input?: DisableCognitoUserInput | null,
};

export type DisableCognitoUserMutation = {
  disableCognitoUser?:  {
    __typename: "DisableCognitoUserResult",
    id: string,
  } | null,
};

export type ResetMFAMutationVariables = {
  input?: ResetMFAInput | null,
};

export type ResetMFAMutation = {
  resetMFA?:  {
    __typename: "ResetMFAResult",
    id: string,
  } | null,
};

export type ResendPasswordEmailMutationVariables = {
  input?: ResendPasswordEmailInput | null,
};

export type ResendPasswordEmailMutation = {
  resendPasswordEmail?:  {
    __typename: "ResendPasswordEmailResult",
    id: string,
  } | null,
};

export type CreateSelesSenderMutationVariables = {
  input?: CreateSelesSenderInput | null,
};

export type CreateSelesSenderMutation = {
  createSelesSender?:  {
    __typename: "CreateSelesSenderResult",
    id: string,
  } | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    company: string,
    person: string,
    domain: string,
    logoUri: string,
    config: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    company: string,
    person: string,
    domain: string,
    logoUri: string,
    config: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    company: string,
    person: string,
    domain: string,
    logoUri: string,
    config: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateFileGroupMutationVariables = {
  input: CreateFileGroupInput,
  condition?: ModelFileGroupConditionInput | null,
};

export type CreateFileGroupMutation = {
  createFileGroup?:  {
    __typename: "FileGroup",
    id: string,
    coverFileId?: string | null,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateFileGroupMutationVariables = {
  input: UpdateFileGroupInput,
  condition?: ModelFileGroupConditionInput | null,
};

export type UpdateFileGroupMutation = {
  updateFileGroup?:  {
    __typename: "FileGroup",
    id: string,
    coverFileId?: string | null,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteFileGroupMutationVariables = {
  input: DeleteFileGroupInput,
  condition?: ModelFileGroupConditionInput | null,
};

export type DeleteFileGroupMutation = {
  deleteFileGroup?:  {
    __typename: "FileGroup",
    id: string,
    coverFileId?: string | null,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateFileMutationVariables = {
  input: CreateFileInput,
  condition?: ModelFileConditionInput | null,
};

export type CreateFileMutation = {
  createFile?:  {
    __typename: "File",
    id: string,
    groupId: string,
    rating?: number | null,
    title?: string | null,
    caption?: string | null,
    instructions?: string | null,
    memo?: string | null,
    fileName: string,
    protect: number,
    original?: string | null,
    caution: number,
    cautionInstructions?: string | null,
    reuse: number,
    reuseCondition?: string | null,
    keywords?: Array< string | null > | null,
    extension?: string | null,
    type?: string | null,
    meta?: string | null,
    tag?: Array< string | null > | null,
    customItems?: string | null,
    fileUri?: string | null,
    fileSize?: string | null,
    coverageId?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    group?:  {
      __typename: "FileGroup",
      id: string,
      coverFileId?: string | null,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        groupId: string,
        rating?: number | null,
        title?: string | null,
        caption?: string | null,
        instructions?: string | null,
        memo?: string | null,
        fileName: string,
        protect: number,
        original?: string | null,
        caution: number,
        cautionInstructions?: string | null,
        reuse: number,
        reuseCondition?: string | null,
        keywords?: Array< string | null > | null,
        extension?: string | null,
        type?: string | null,
        meta?: string | null,
        tag?: Array< string | null > | null,
        customItems?: string | null,
        fileUri?: string | null,
        fileSize?: string | null,
        coverageId?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        group?:  {
          __typename: "FileGroup",
          id: string,
          coverFileId?: string | null,
          title?: string | null,
          description?: string | null,
          memo?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          file?:  {
            __typename: "File",
            id: string,
            groupId: string,
            rating?: number | null,
            title?: string | null,
            caption?: string | null,
            instructions?: string | null,
            memo?: string | null,
            fileName: string,
            protect: number,
            original?: string | null,
            caution: number,
            cautionInstructions?: string | null,
            reuse: number,
            reuseCondition?: string | null,
            keywords?: Array< string | null > | null,
            extension?: string | null,
            type?: string | null,
            meta?: string | null,
            tag?: Array< string | null > | null,
            customItems?: string | null,
            fileUri?: string | null,
            fileSize?: string | null,
            coverageId?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
        coverage?:  {
          __typename: "Coverage",
          id: string,
          title?: string | null,
          location: string,
          startAt: string,
          endAt: string,
          description?: string | null,
          memo?: string | null,
          attachmentFile?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          assignedUserId: string,
          status: number,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
    } | null,
    coverage?:  {
      __typename: "Coverage",
      id: string,
      title?: string | null,
      location: string,
      startAt: string,
      endAt: string,
      description?: string | null,
      memo?: string | null,
      attachmentFile?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      assignedUserId: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
  } | null,
};

export type UpdateFileMutationVariables = {
  input: UpdateFileInput,
  condition?: ModelFileConditionInput | null,
};

export type UpdateFileMutation = {
  updateFile?:  {
    __typename: "File",
    id: string,
    groupId: string,
    rating?: number | null,
    title?: string | null,
    caption?: string | null,
    instructions?: string | null,
    memo?: string | null,
    fileName: string,
    protect: number,
    original?: string | null,
    caution: number,
    cautionInstructions?: string | null,
    reuse: number,
    reuseCondition?: string | null,
    keywords?: Array< string | null > | null,
    extension?: string | null,
    type?: string | null,
    meta?: string | null,
    tag?: Array< string | null > | null,
    customItems?: string | null,
    fileUri?: string | null,
    fileSize?: string | null,
    coverageId?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    group?:  {
      __typename: "FileGroup",
      id: string,
      coverFileId?: string | null,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        groupId: string,
        rating?: number | null,
        title?: string | null,
        caption?: string | null,
        instructions?: string | null,
        memo?: string | null,
        fileName: string,
        protect: number,
        original?: string | null,
        caution: number,
        cautionInstructions?: string | null,
        reuse: number,
        reuseCondition?: string | null,
        keywords?: Array< string | null > | null,
        extension?: string | null,
        type?: string | null,
        meta?: string | null,
        tag?: Array< string | null > | null,
        customItems?: string | null,
        fileUri?: string | null,
        fileSize?: string | null,
        coverageId?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        group?:  {
          __typename: "FileGroup",
          id: string,
          coverFileId?: string | null,
          title?: string | null,
          description?: string | null,
          memo?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          file?:  {
            __typename: "File",
            id: string,
            groupId: string,
            rating?: number | null,
            title?: string | null,
            caption?: string | null,
            instructions?: string | null,
            memo?: string | null,
            fileName: string,
            protect: number,
            original?: string | null,
            caution: number,
            cautionInstructions?: string | null,
            reuse: number,
            reuseCondition?: string | null,
            keywords?: Array< string | null > | null,
            extension?: string | null,
            type?: string | null,
            meta?: string | null,
            tag?: Array< string | null > | null,
            customItems?: string | null,
            fileUri?: string | null,
            fileSize?: string | null,
            coverageId?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
        coverage?:  {
          __typename: "Coverage",
          id: string,
          title?: string | null,
          location: string,
          startAt: string,
          endAt: string,
          description?: string | null,
          memo?: string | null,
          attachmentFile?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          assignedUserId: string,
          status: number,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
    } | null,
    coverage?:  {
      __typename: "Coverage",
      id: string,
      title?: string | null,
      location: string,
      startAt: string,
      endAt: string,
      description?: string | null,
      memo?: string | null,
      attachmentFile?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      assignedUserId: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
  } | null,
};

export type DeleteFileMutationVariables = {
  input: DeleteFileInput,
  condition?: ModelFileConditionInput | null,
};

export type DeleteFileMutation = {
  deleteFile?:  {
    __typename: "File",
    id: string,
    groupId: string,
    rating?: number | null,
    title?: string | null,
    caption?: string | null,
    instructions?: string | null,
    memo?: string | null,
    fileName: string,
    protect: number,
    original?: string | null,
    caution: number,
    cautionInstructions?: string | null,
    reuse: number,
    reuseCondition?: string | null,
    keywords?: Array< string | null > | null,
    extension?: string | null,
    type?: string | null,
    meta?: string | null,
    tag?: Array< string | null > | null,
    customItems?: string | null,
    fileUri?: string | null,
    fileSize?: string | null,
    coverageId?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    group?:  {
      __typename: "FileGroup",
      id: string,
      coverFileId?: string | null,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        groupId: string,
        rating?: number | null,
        title?: string | null,
        caption?: string | null,
        instructions?: string | null,
        memo?: string | null,
        fileName: string,
        protect: number,
        original?: string | null,
        caution: number,
        cautionInstructions?: string | null,
        reuse: number,
        reuseCondition?: string | null,
        keywords?: Array< string | null > | null,
        extension?: string | null,
        type?: string | null,
        meta?: string | null,
        tag?: Array< string | null > | null,
        customItems?: string | null,
        fileUri?: string | null,
        fileSize?: string | null,
        coverageId?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        group?:  {
          __typename: "FileGroup",
          id: string,
          coverFileId?: string | null,
          title?: string | null,
          description?: string | null,
          memo?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          file?:  {
            __typename: "File",
            id: string,
            groupId: string,
            rating?: number | null,
            title?: string | null,
            caption?: string | null,
            instructions?: string | null,
            memo?: string | null,
            fileName: string,
            protect: number,
            original?: string | null,
            caution: number,
            cautionInstructions?: string | null,
            reuse: number,
            reuseCondition?: string | null,
            keywords?: Array< string | null > | null,
            extension?: string | null,
            type?: string | null,
            meta?: string | null,
            tag?: Array< string | null > | null,
            customItems?: string | null,
            fileUri?: string | null,
            fileSize?: string | null,
            coverageId?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
        coverage?:  {
          __typename: "Coverage",
          id: string,
          title?: string | null,
          location: string,
          startAt: string,
          endAt: string,
          description?: string | null,
          memo?: string | null,
          attachmentFile?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          assignedUserId: string,
          status: number,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
    } | null,
    coverage?:  {
      __typename: "Coverage",
      id: string,
      title?: string | null,
      location: string,
      startAt: string,
      endAt: string,
      description?: string | null,
      memo?: string | null,
      attachmentFile?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      assignedUserId: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
  } | null,
};

export type CreateSharingMutationVariables = {
  input: CreateSharingInput,
  condition?: ModelSharingConditionInput | null,
};

export type CreateSharingMutation = {
  createSharing?:  {
    __typename: "Sharing",
    id: string,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    limitDate: string,
    limitTime: string,
    sharingUsers: Array< string | null >,
    sharingFiles: Array< string | null >,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateSharingMutationVariables = {
  input: UpdateSharingInput,
  condition?: ModelSharingConditionInput | null,
};

export type UpdateSharingMutation = {
  updateSharing?:  {
    __typename: "Sharing",
    id: string,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    limitDate: string,
    limitTime: string,
    sharingUsers: Array< string | null >,
    sharingFiles: Array< string | null >,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteSharingMutationVariables = {
  input: DeleteSharingInput,
  condition?: ModelSharingConditionInput | null,
};

export type DeleteSharingMutation = {
  deleteSharing?:  {
    __typename: "Sharing",
    id: string,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    limitDate: string,
    limitTime: string,
    sharingUsers: Array< string | null >,
    sharingFiles: Array< string | null >,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateCoverageMutationVariables = {
  input: CreateCoverageInput,
  condition?: ModelCoverageConditionInput | null,
};

export type CreateCoverageMutation = {
  createCoverage?:  {
    __typename: "Coverage",
    id: string,
    title?: string | null,
    location: string,
    startAt: string,
    endAt: string,
    description?: string | null,
    memo?: string | null,
    attachmentFile?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    assignedUserId: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateCoverageMutationVariables = {
  input: UpdateCoverageInput,
  condition?: ModelCoverageConditionInput | null,
};

export type UpdateCoverageMutation = {
  updateCoverage?:  {
    __typename: "Coverage",
    id: string,
    title?: string | null,
    location: string,
    startAt: string,
    endAt: string,
    description?: string | null,
    memo?: string | null,
    attachmentFile?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    assignedUserId: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteCoverageMutationVariables = {
  input: DeleteCoverageInput,
  condition?: ModelCoverageConditionInput | null,
};

export type DeleteCoverageMutation = {
  deleteCoverage?:  {
    __typename: "Coverage",
    id: string,
    title?: string | null,
    location: string,
    startAt: string,
    endAt: string,
    description?: string | null,
    memo?: string | null,
    attachmentFile?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    assignedUserId: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateCoverageInfoMutationVariables = {
  input: CreateCoverageInfoInput,
  condition?: ModelCoverageInfoConditionInput | null,
};

export type CreateCoverageInfoMutation = {
  createCoverageInfo?:  {
    __typename: "CoverageInfo",
    id: string,
    startDate: string,
    endDate: string,
    body: string,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateCoverageInfoMutationVariables = {
  input: UpdateCoverageInfoInput,
  condition?: ModelCoverageInfoConditionInput | null,
};

export type UpdateCoverageInfoMutation = {
  updateCoverageInfo?:  {
    __typename: "CoverageInfo",
    id: string,
    startDate: string,
    endDate: string,
    body: string,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteCoverageInfoMutationVariables = {
  input: DeleteCoverageInfoInput,
  condition?: ModelCoverageInfoConditionInput | null,
};

export type DeleteCoverageInfoMutation = {
  deleteCoverageInfo?:  {
    __typename: "CoverageInfo",
    id: string,
    startDate: string,
    endDate: string,
    body: string,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateCoverageDeskMutationVariables = {
  input: CreateCoverageDeskInput,
  condition?: ModelCoverageDeskConditionInput | null,
};

export type CreateCoverageDeskMutation = {
  createCoverageDesk?:  {
    __typename: "CoverageDesk",
    id: string,
    startDate: string,
    endDate: string,
    name: string,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateCoverageDeskMutationVariables = {
  input: UpdateCoverageDeskInput,
  condition?: ModelCoverageDeskConditionInput | null,
};

export type UpdateCoverageDeskMutation = {
  updateCoverageDesk?:  {
    __typename: "CoverageDesk",
    id: string,
    startDate: string,
    endDate: string,
    name: string,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteCoverageDeskMutationVariables = {
  input: DeleteCoverageDeskInput,
  condition?: ModelCoverageDeskConditionInput | null,
};

export type DeleteCoverageDeskMutation = {
  deleteCoverageDesk?:  {
    __typename: "CoverageDesk",
    id: string,
    startDate: string,
    endDate: string,
    name: string,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateHistoryMutationVariables = {
  input: CreateHistoryInput,
  condition?: ModelHistoryConditionInput | null,
};

export type CreateHistoryMutation = {
  createHistory?:  {
    __typename: "History",
    id: string,
    data: string,
    body: string,
    operation: string,
    userId: string,
    accountId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateHistoryMutationVariables = {
  input: UpdateHistoryInput,
  condition?: ModelHistoryConditionInput | null,
};

export type UpdateHistoryMutation = {
  updateHistory?:  {
    __typename: "History",
    id: string,
    data: string,
    body: string,
    operation: string,
    userId: string,
    accountId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteHistoryMutationVariables = {
  input: DeleteHistoryInput,
  condition?: ModelHistoryConditionInput | null,
};

export type DeleteHistoryMutation = {
  deleteHistory?:  {
    __typename: "History",
    id: string,
    data: string,
    body: string,
    operation: string,
    userId: string,
    accountId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateChargeMutationVariables = {
  input: CreateChargeInput,
  condition?: ModelChargeConditionInput | null,
};

export type CreateChargeMutation = {
  createCharge?:  {
    __typename: "Charge",
    id: string,
    accountId: string,
    aggregationDate: string,
    status: number,
    basicCharge: number,
    s3Charge: number,
    excessTransferredCharge: number,
    s3StdUsageSize: string,
    s3IaUsageSize: string,
    s3GlIrUsageSize?: string | null,
    s3GlUsageSize: string,
    transferredSize: string,
    downloadRequestCont: number,
    excludingTax: number,
    includingTax: number,
    taxRate: number,
    exchangeRate: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateChargeMutationVariables = {
  input: UpdateChargeInput,
  condition?: ModelChargeConditionInput | null,
};

export type UpdateChargeMutation = {
  updateCharge?:  {
    __typename: "Charge",
    id: string,
    accountId: string,
    aggregationDate: string,
    status: number,
    basicCharge: number,
    s3Charge: number,
    excessTransferredCharge: number,
    s3StdUsageSize: string,
    s3IaUsageSize: string,
    s3GlIrUsageSize?: string | null,
    s3GlUsageSize: string,
    transferredSize: string,
    downloadRequestCont: number,
    excludingTax: number,
    includingTax: number,
    taxRate: number,
    exchangeRate: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteChargeMutationVariables = {
  input: DeleteChargeInput,
  condition?: ModelChargeConditionInput | null,
};

export type DeleteChargeMutation = {
  deleteCharge?:  {
    __typename: "Charge",
    id: string,
    accountId: string,
    aggregationDate: string,
    status: number,
    basicCharge: number,
    s3Charge: number,
    excessTransferredCharge: number,
    s3StdUsageSize: string,
    s3IaUsageSize: string,
    s3GlIrUsageSize?: string | null,
    s3GlUsageSize: string,
    transferredSize: string,
    downloadRequestCont: number,
    excludingTax: number,
    includingTax: number,
    taxRate: number,
    exchangeRate: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateFileDownloadHistoryMutationVariables = {
  input: CreateFileDownloadHistoryInput,
  condition?: ModelFileDownloadHistoryConditionInput | null,
};

export type CreateFileDownloadHistoryMutation = {
  createFileDownloadHistory?:  {
    __typename: "FileDownloadHistory",
    id: string,
    accountId: string,
    fileId: string,
    fileSize: string,
    storageClass?: string | null,
    userId: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type UpdateFileDownloadHistoryMutationVariables = {
  input: UpdateFileDownloadHistoryInput,
  condition?: ModelFileDownloadHistoryConditionInput | null,
};

export type UpdateFileDownloadHistoryMutation = {
  updateFileDownloadHistory?:  {
    __typename: "FileDownloadHistory",
    id: string,
    accountId: string,
    fileId: string,
    fileSize: string,
    storageClass?: string | null,
    userId: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type DeleteFileDownloadHistoryMutationVariables = {
  input: DeleteFileDownloadHistoryInput,
  condition?: ModelFileDownloadHistoryConditionInput | null,
};

export type DeleteFileDownloadHistoryMutation = {
  deleteFileDownloadHistory?:  {
    __typename: "FileDownloadHistory",
    id: string,
    accountId: string,
    fileId: string,
    fileSize: string,
    storageClass?: string | null,
    userId: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type CreateGlacierDownloadRequestMutationVariables = {
  input: CreateGlacierDownloadRequestInput,
  condition?: ModelGlacierDownloadRequestConditionInput | null,
};

export type CreateGlacierDownloadRequestMutation = {
  createGlacierDownloadRequest?:  {
    __typename: "GlacierDownloadRequest",
    id: string,
    accountId: string,
    fileId: string,
    userId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateGlacierDownloadRequestMutationVariables = {
  input: UpdateGlacierDownloadRequestInput,
  condition?: ModelGlacierDownloadRequestConditionInput | null,
};

export type UpdateGlacierDownloadRequestMutation = {
  updateGlacierDownloadRequest?:  {
    __typename: "GlacierDownloadRequest",
    id: string,
    accountId: string,
    fileId: string,
    userId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteGlacierDownloadRequestMutationVariables = {
  input: DeleteGlacierDownloadRequestInput,
  condition?: ModelGlacierDownloadRequestConditionInput | null,
};

export type DeleteGlacierDownloadRequestMutation = {
  deleteGlacierDownloadRequest?:  {
    __typename: "GlacierDownloadRequest",
    id: string,
    accountId: string,
    fileId: string,
    userId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListSharingWithFilesQueryVariables = {
  sharingId: string,
  sort?: PGCSearchableFileSortInput | null,
  limit?: number | null,
  nextToken?: Array< string | null > | null,
  from?: number | null,
};

export type ListSharingWithFilesQuery = {
  listSharingWithFiles?:  {
    __typename: "PGCSharingWithFileConnection",
    sharing?:  {
      __typename: "Sharing",
      id: string,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      limitDate: string,
      limitTime: string,
      sharingUsers: Array< string | null >,
      sharingFiles: Array< string | null >,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    files?:  Array< {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null > | null,
    nextToken?: Array< string | null > | null,
    total?: number | null,
  } | null,
};

export type ListSharingFilesQueryVariables = {
  sharingId: string,
  fileId: string,
};

export type ListSharingFilesQuery = {
  listSharingFiles?:  {
    __typename: "File",
    id: string,
    groupId: string,
    rating?: number | null,
    title?: string | null,
    caption?: string | null,
    instructions?: string | null,
    memo?: string | null,
    fileName: string,
    protect: number,
    original?: string | null,
    caution: number,
    cautionInstructions?: string | null,
    reuse: number,
    reuseCondition?: string | null,
    keywords?: Array< string | null > | null,
    extension?: string | null,
    type?: string | null,
    meta?: string | null,
    tag?: Array< string | null > | null,
    customItems?: string | null,
    fileUri?: string | null,
    fileSize?: string | null,
    coverageId?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    group?:  {
      __typename: "FileGroup",
      id: string,
      coverFileId?: string | null,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        groupId: string,
        rating?: number | null,
        title?: string | null,
        caption?: string | null,
        instructions?: string | null,
        memo?: string | null,
        fileName: string,
        protect: number,
        original?: string | null,
        caution: number,
        cautionInstructions?: string | null,
        reuse: number,
        reuseCondition?: string | null,
        keywords?: Array< string | null > | null,
        extension?: string | null,
        type?: string | null,
        meta?: string | null,
        tag?: Array< string | null > | null,
        customItems?: string | null,
        fileUri?: string | null,
        fileSize?: string | null,
        coverageId?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        group?:  {
          __typename: "FileGroup",
          id: string,
          coverFileId?: string | null,
          title?: string | null,
          description?: string | null,
          memo?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          file?:  {
            __typename: "File",
            id: string,
            groupId: string,
            rating?: number | null,
            title?: string | null,
            caption?: string | null,
            instructions?: string | null,
            memo?: string | null,
            fileName: string,
            protect: number,
            original?: string | null,
            caution: number,
            cautionInstructions?: string | null,
            reuse: number,
            reuseCondition?: string | null,
            keywords?: Array< string | null > | null,
            extension?: string | null,
            type?: string | null,
            meta?: string | null,
            tag?: Array< string | null > | null,
            customItems?: string | null,
            fileUri?: string | null,
            fileSize?: string | null,
            coverageId?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
        coverage?:  {
          __typename: "Coverage",
          id: string,
          title?: string | null,
          location: string,
          startAt: string,
          endAt: string,
          description?: string | null,
          memo?: string | null,
          attachmentFile?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          assignedUserId: string,
          status: number,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
    } | null,
    coverage?:  {
      __typename: "Coverage",
      id: string,
      title?: string | null,
      location: string,
      startAt: string,
      endAt: string,
      description?: string | null,
      memo?: string | null,
      attachmentFile?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      assignedUserId: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
  } | null,
};

export type PgcSearchFilesQueryVariables = {
  filter?: string | null,
  sort?: PGCSearchableFileSortInput | null,
  limit?: number | null,
  nextToken?: Array< string | null > | null,
  from?: number | null,
};

export type PgcSearchFilesQuery = {
  pgcSearchFiles?:  {
    __typename: "PGCSearchableFileConnection",
    items?:  Array< {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null > | null,
    nextToken?: Array< string | null > | null,
    total?: number | null,
  } | null,
};

export type SearchFilesQueryVariables = {
  filter?: SearchableFileFilterInput | null,
  sort?: SearchableFileSortInput | null,
  limit?: number | null,
  nextToken?: Array< string | null > | null,
  from?: number | null,
};

export type SearchFilesQuery = {
  searchFiles?:  {
    __typename: "SearchableFileConnection",
    items?:  Array< {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null > | null,
    nextToken?: Array< string | null > | null,
    total?: number | null,
  } | null,
};

export type SearchFileGroupsQueryVariables = {
  filter?: SearchableFileGroupFilterInput | null,
  sort?: SearchableFileGroupSortInput | null,
  limit?: number | null,
  nextToken?: Array< string | null > | null,
  from?: number | null,
};

export type SearchFileGroupsQuery = {
  searchFileGroups?:  {
    __typename: "SearchableFileGroupConnection",
    items?:  Array< {
      __typename: "FileGroup",
      id: string,
      coverFileId?: string | null,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        groupId: string,
        rating?: number | null,
        title?: string | null,
        caption?: string | null,
        instructions?: string | null,
        memo?: string | null,
        fileName: string,
        protect: number,
        original?: string | null,
        caution: number,
        cautionInstructions?: string | null,
        reuse: number,
        reuseCondition?: string | null,
        keywords?: Array< string | null > | null,
        extension?: string | null,
        type?: string | null,
        meta?: string | null,
        tag?: Array< string | null > | null,
        customItems?: string | null,
        fileUri?: string | null,
        fileSize?: string | null,
        coverageId?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        group?:  {
          __typename: "FileGroup",
          id: string,
          coverFileId?: string | null,
          title?: string | null,
          description?: string | null,
          memo?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          file?:  {
            __typename: "File",
            id: string,
            groupId: string,
            rating?: number | null,
            title?: string | null,
            caption?: string | null,
            instructions?: string | null,
            memo?: string | null,
            fileName: string,
            protect: number,
            original?: string | null,
            caution: number,
            cautionInstructions?: string | null,
            reuse: number,
            reuseCondition?: string | null,
            keywords?: Array< string | null > | null,
            extension?: string | null,
            type?: string | null,
            meta?: string | null,
            tag?: Array< string | null > | null,
            customItems?: string | null,
            fileUri?: string | null,
            fileSize?: string | null,
            coverageId?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
        coverage?:  {
          __typename: "Coverage",
          id: string,
          title?: string | null,
          location: string,
          startAt: string,
          endAt: string,
          description?: string | null,
          memo?: string | null,
          attachmentFile?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          assignedUserId: string,
          status: number,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: Array< string | null > | null,
    total?: number | null,
  } | null,
};

export type SearchCoveragesQueryVariables = {
  filter?: SearchableCoverageFilterInput | null,
  sort?: SearchableCoverageSortInput | null,
  limit?: number | null,
  nextToken?: Array< string | null > | null,
  from?: number | null,
};

export type SearchCoveragesQuery = {
  searchCoverages?:  {
    __typename: "SearchableCoverageConnection",
    items?:  Array< {
      __typename: "Coverage",
      id: string,
      title?: string | null,
      location: string,
      startAt: string,
      endAt: string,
      description?: string | null,
      memo?: string | null,
      attachmentFile?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      assignedUserId: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
    nextToken?: Array< string | null > | null,
    total?: number | null,
  } | null,
};

export type SearchSharingsQueryVariables = {
  filter?: SearchableSharingFilterInput | null,
  sort?: SearchableSharingSortInput | null,
  limit?: number | null,
  nextToken?: Array< string | null > | null,
  from?: number | null,
};

export type SearchSharingsQuery = {
  searchSharings?:  {
    __typename: "SearchableSharingConnection",
    items?:  Array< {
      __typename: "Sharing",
      id: string,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      limitDate: string,
      limitTime: string,
      sharingUsers: Array< string | null >,
      sharingFiles: Array< string | null >,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
    nextToken?: Array< string | null > | null,
    total?: number | null,
  } | null,
};

export type SearchHistorysQueryVariables = {
  filter?: SearchableHistoryFilterInput | null,
  sort?: SearchableHistorySortInput | null,
  limit?: number | null,
  nextToken?: Array< string | null > | null,
  from?: number | null,
};

export type SearchHistorysQuery = {
  searchHistorys?:  {
    __typename: "SearchableHistoryConnection",
    items?:  Array< {
      __typename: "History",
      id: string,
      data: string,
      body: string,
      operation: string,
      userId: string,
      accountId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null > | null,
    nextToken?: Array< string | null > | null,
    total?: number | null,
  } | null,
};

export type ListUsersQueryVariables = {
  input?: ListUserInput | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ListUserResult",
    Items?:  Array< {
      __typename: "ListUserItems",
      id: string,
      name: string,
      enabled: boolean,
    } | null > | null,
  } | null,
};

export type GetUserQueryVariables = {
  input?: GetUserInput | null,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "GetUserResult",
    id: string,
    name: string,
    email: string,
    accountId: string,
    department: string,
  } | null,
};

export type GetCognitoUserQueryVariables = {
  input?: GetCognitoUserInput | null,
};

export type GetCognitoUserQuery = {
  getCognitoUser?:  {
    __typename: "GetCognitoUserResult",
    id: string,
    name: string,
    email: string,
    accountId: string,
    department: string,
    role: string,
    deleted: boolean,
    status: string,
    enabled: boolean,
  } | null,
};

export type ExportFilesCSVQueryVariables = {
  input?: ExportFilesCSVInput | null,
};

export type ExportFilesCSVQuery = {
  exportFilesCSV?:  {
    __typename: "ExportFilesCSVResult",
    signedUrl: string,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    company: string,
    person: string,
    domain: string,
    logoUri: string,
    config: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  id?: string | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      company: string,
      person: string,
      domain: string,
      logoUri: string,
      config: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFileGroupQueryVariables = {
  id: string,
};

export type GetFileGroupQuery = {
  getFileGroup?:  {
    __typename: "FileGroup",
    id: string,
    coverFileId?: string | null,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListFileGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelFileGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFileGroupsQuery = {
  listFileGroups?:  {
    __typename: "ModelFileGroupConnection",
    items:  Array< {
      __typename: "FileGroup",
      id: string,
      coverFileId?: string | null,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        groupId: string,
        rating?: number | null,
        title?: string | null,
        caption?: string | null,
        instructions?: string | null,
        memo?: string | null,
        fileName: string,
        protect: number,
        original?: string | null,
        caution: number,
        cautionInstructions?: string | null,
        reuse: number,
        reuseCondition?: string | null,
        keywords?: Array< string | null > | null,
        extension?: string | null,
        type?: string | null,
        meta?: string | null,
        tag?: Array< string | null > | null,
        customItems?: string | null,
        fileUri?: string | null,
        fileSize?: string | null,
        coverageId?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        group?:  {
          __typename: "FileGroup",
          id: string,
          coverFileId?: string | null,
          title?: string | null,
          description?: string | null,
          memo?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          file?:  {
            __typename: "File",
            id: string,
            groupId: string,
            rating?: number | null,
            title?: string | null,
            caption?: string | null,
            instructions?: string | null,
            memo?: string | null,
            fileName: string,
            protect: number,
            original?: string | null,
            caution: number,
            cautionInstructions?: string | null,
            reuse: number,
            reuseCondition?: string | null,
            keywords?: Array< string | null > | null,
            extension?: string | null,
            type?: string | null,
            meta?: string | null,
            tag?: Array< string | null > | null,
            customItems?: string | null,
            fileUri?: string | null,
            fileSize?: string | null,
            coverageId?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
        coverage?:  {
          __typename: "Coverage",
          id: string,
          title?: string | null,
          location: string,
          startAt: string,
          endAt: string,
          description?: string | null,
          memo?: string | null,
          attachmentFile?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          assignedUserId: string,
          status: number,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFileQueryVariables = {
  id: string,
};

export type GetFileQuery = {
  getFile?:  {
    __typename: "File",
    id: string,
    groupId: string,
    rating?: number | null,
    title?: string | null,
    caption?: string | null,
    instructions?: string | null,
    memo?: string | null,
    fileName: string,
    protect: number,
    original?: string | null,
    caution: number,
    cautionInstructions?: string | null,
    reuse: number,
    reuseCondition?: string | null,
    keywords?: Array< string | null > | null,
    extension?: string | null,
    type?: string | null,
    meta?: string | null,
    tag?: Array< string | null > | null,
    customItems?: string | null,
    fileUri?: string | null,
    fileSize?: string | null,
    coverageId?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    group?:  {
      __typename: "FileGroup",
      id: string,
      coverFileId?: string | null,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        groupId: string,
        rating?: number | null,
        title?: string | null,
        caption?: string | null,
        instructions?: string | null,
        memo?: string | null,
        fileName: string,
        protect: number,
        original?: string | null,
        caution: number,
        cautionInstructions?: string | null,
        reuse: number,
        reuseCondition?: string | null,
        keywords?: Array< string | null > | null,
        extension?: string | null,
        type?: string | null,
        meta?: string | null,
        tag?: Array< string | null > | null,
        customItems?: string | null,
        fileUri?: string | null,
        fileSize?: string | null,
        coverageId?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        group?:  {
          __typename: "FileGroup",
          id: string,
          coverFileId?: string | null,
          title?: string | null,
          description?: string | null,
          memo?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          file?:  {
            __typename: "File",
            id: string,
            groupId: string,
            rating?: number | null,
            title?: string | null,
            caption?: string | null,
            instructions?: string | null,
            memo?: string | null,
            fileName: string,
            protect: number,
            original?: string | null,
            caution: number,
            cautionInstructions?: string | null,
            reuse: number,
            reuseCondition?: string | null,
            keywords?: Array< string | null > | null,
            extension?: string | null,
            type?: string | null,
            meta?: string | null,
            tag?: Array< string | null > | null,
            customItems?: string | null,
            fileUri?: string | null,
            fileSize?: string | null,
            coverageId?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
        coverage?:  {
          __typename: "Coverage",
          id: string,
          title?: string | null,
          location: string,
          startAt: string,
          endAt: string,
          description?: string | null,
          memo?: string | null,
          attachmentFile?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          assignedUserId: string,
          status: number,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
    } | null,
    coverage?:  {
      __typename: "Coverage",
      id: string,
      title?: string | null,
      location: string,
      startAt: string,
      endAt: string,
      description?: string | null,
      memo?: string | null,
      attachmentFile?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      assignedUserId: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
  } | null,
};

export type ListFilesQueryVariables = {
  id?: string | null,
  filter?: ModelFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFilesQuery = {
  listFiles?:  {
    __typename: "ModelFileConnection",
    items:  Array< {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSharingQueryVariables = {
  id: string,
};

export type GetSharingQuery = {
  getSharing?:  {
    __typename: "Sharing",
    id: string,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    limitDate: string,
    limitTime: string,
    sharingUsers: Array< string | null >,
    sharingFiles: Array< string | null >,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListSharingsQueryVariables = {
  id?: string | null,
  filter?: ModelSharingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSharingsQuery = {
  listSharings?:  {
    __typename: "ModelSharingConnection",
    items:  Array< {
      __typename: "Sharing",
      id: string,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      limitDate: string,
      limitTime: string,
      sharingUsers: Array< string | null >,
      sharingFiles: Array< string | null >,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCoverageQueryVariables = {
  id: string,
};

export type GetCoverageQuery = {
  getCoverage?:  {
    __typename: "Coverage",
    id: string,
    title?: string | null,
    location: string,
    startAt: string,
    endAt: string,
    description?: string | null,
    memo?: string | null,
    attachmentFile?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    assignedUserId: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListCoveragesQueryVariables = {
  id?: string | null,
  filter?: ModelCoverageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCoveragesQuery = {
  listCoverages?:  {
    __typename: "ModelCoverageConnection",
    items:  Array< {
      __typename: "Coverage",
      id: string,
      title?: string | null,
      location: string,
      startAt: string,
      endAt: string,
      description?: string | null,
      memo?: string | null,
      attachmentFile?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      assignedUserId: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCoverageInfoQueryVariables = {
  id: string,
};

export type GetCoverageInfoQuery = {
  getCoverageInfo?:  {
    __typename: "CoverageInfo",
    id: string,
    startDate: string,
    endDate: string,
    body: string,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListCoverageInfosQueryVariables = {
  id?: string | null,
  filter?: ModelCoverageInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCoverageInfosQuery = {
  listCoverageInfos?:  {
    __typename: "ModelCoverageInfoConnection",
    items:  Array< {
      __typename: "CoverageInfo",
      id: string,
      startDate: string,
      endDate: string,
      body: string,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CoverageInfosByOwnedAccountIdQueryVariables = {
  ownedAccountId?: string | null,
  startDateEndDate?: ModelCoverageInfoByOwnedAccountIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCoverageInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CoverageInfosByOwnedAccountIdQuery = {
  coverageInfosByOwnedAccountId?:  {
    __typename: "ModelCoverageInfoConnection",
    items:  Array< {
      __typename: "CoverageInfo",
      id: string,
      startDate: string,
      endDate: string,
      body: string,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCoverageDeskQueryVariables = {
  id: string,
};

export type GetCoverageDeskQuery = {
  getCoverageDesk?:  {
    __typename: "CoverageDesk",
    id: string,
    startDate: string,
    endDate: string,
    name: string,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListCoverageDesksQueryVariables = {
  id?: string | null,
  filter?: ModelCoverageDeskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCoverageDesksQuery = {
  listCoverageDesks?:  {
    __typename: "ModelCoverageDeskConnection",
    items:  Array< {
      __typename: "CoverageDesk",
      id: string,
      startDate: string,
      endDate: string,
      name: string,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CoverageDesksByOwnedAccountIdQueryVariables = {
  ownedAccountId?: string | null,
  startDateEndDate?: ModelCoverageDeskByOwnedAccountIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCoverageDeskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CoverageDesksByOwnedAccountIdQuery = {
  coverageDesksByOwnedAccountId?:  {
    __typename: "ModelCoverageDeskConnection",
    items:  Array< {
      __typename: "CoverageDesk",
      id: string,
      startDate: string,
      endDate: string,
      name: string,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHistoryQueryVariables = {
  id: string,
};

export type GetHistoryQuery = {
  getHistory?:  {
    __typename: "History",
    id: string,
    data: string,
    body: string,
    operation: string,
    userId: string,
    accountId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListHistorysQueryVariables = {
  id?: string | null,
  filter?: ModelHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHistorysQuery = {
  listHistorys?:  {
    __typename: "ModelHistoryConnection",
    items:  Array< {
      __typename: "History",
      id: string,
      data: string,
      body: string,
      operation: string,
      userId: string,
      accountId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChargeQueryVariables = {
  id: string,
  accountId: string,
};

export type GetChargeQuery = {
  getCharge?:  {
    __typename: "Charge",
    id: string,
    accountId: string,
    aggregationDate: string,
    status: number,
    basicCharge: number,
    s3Charge: number,
    excessTransferredCharge: number,
    s3StdUsageSize: string,
    s3IaUsageSize: string,
    s3GlIrUsageSize?: string | null,
    s3GlUsageSize: string,
    transferredSize: string,
    downloadRequestCont: number,
    excludingTax: number,
    includingTax: number,
    taxRate: number,
    exchangeRate: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListChargesQueryVariables = {
  id?: string | null,
  accountId?: ModelIDKeyConditionInput | null,
  filter?: ModelChargeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListChargesQuery = {
  listCharges?:  {
    __typename: "ModelChargeConnection",
    items:  Array< {
      __typename: "Charge",
      id: string,
      accountId: string,
      aggregationDate: string,
      status: number,
      basicCharge: number,
      s3Charge: number,
      excessTransferredCharge: number,
      s3StdUsageSize: string,
      s3IaUsageSize: string,
      s3GlIrUsageSize?: string | null,
      s3GlUsageSize: string,
      transferredSize: string,
      downloadRequestCont: number,
      excludingTax: number,
      includingTax: number,
      taxRate: number,
      exchangeRate: number,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChargeByAggregationDateQueryVariables = {
  aggregationDate?: string | null,
  status?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChargeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChargeByAggregationDateQuery = {
  chargeByAggregationDate?:  {
    __typename: "ModelChargeConnection",
    items:  Array< {
      __typename: "Charge",
      id: string,
      accountId: string,
      aggregationDate: string,
      status: number,
      basicCharge: number,
      s3Charge: number,
      excessTransferredCharge: number,
      s3StdUsageSize: string,
      s3IaUsageSize: string,
      s3GlIrUsageSize?: string | null,
      s3GlUsageSize: string,
      transferredSize: string,
      downloadRequestCont: number,
      excludingTax: number,
      includingTax: number,
      taxRate: number,
      exchangeRate: number,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChargeByAccountIdQueryVariables = {
  accountId?: string | null,
  aggregationDateStatus?: ModelChargeByAccountIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChargeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChargeByAccountIdQuery = {
  chargeByAccountId?:  {
    __typename: "ModelChargeConnection",
    items:  Array< {
      __typename: "Charge",
      id: string,
      accountId: string,
      aggregationDate: string,
      status: number,
      basicCharge: number,
      s3Charge: number,
      excessTransferredCharge: number,
      s3StdUsageSize: string,
      s3IaUsageSize: string,
      s3GlIrUsageSize?: string | null,
      s3GlUsageSize: string,
      transferredSize: string,
      downloadRequestCont: number,
      excludingTax: number,
      includingTax: number,
      taxRate: number,
      exchangeRate: number,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFileDownloadHistoryQueryVariables = {
  id: string,
  accountId: string,
};

export type GetFileDownloadHistoryQuery = {
  getFileDownloadHistory?:  {
    __typename: "FileDownloadHistory",
    id: string,
    accountId: string,
    fileId: string,
    fileSize: string,
    storageClass?: string | null,
    userId: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type ListFileDownloadHistorysQueryVariables = {
  id?: string | null,
  accountId?: ModelIDKeyConditionInput | null,
  filter?: ModelFileDownloadHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFileDownloadHistorysQuery = {
  listFileDownloadHistorys?:  {
    __typename: "ModelFileDownloadHistoryConnection",
    items:  Array< {
      __typename: "FileDownloadHistory",
      id: string,
      accountId: string,
      fileId: string,
      fileSize: string,
      storageClass?: string | null,
      userId: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FileDownloadHistoryByCreatedAtQueryVariables = {
  createdAt?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFileDownloadHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FileDownloadHistoryByCreatedAtQuery = {
  fileDownloadHistoryByCreatedAt?:  {
    __typename: "ModelFileDownloadHistoryConnection",
    items:  Array< {
      __typename: "FileDownloadHistory",
      id: string,
      accountId: string,
      fileId: string,
      fileSize: string,
      storageClass?: string | null,
      userId: string,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGlacierDownloadRequestQueryVariables = {
  id: string,
  accountId: string,
};

export type GetGlacierDownloadRequestQuery = {
  getGlacierDownloadRequest?:  {
    __typename: "GlacierDownloadRequest",
    id: string,
    accountId: string,
    fileId: string,
    userId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListGlacierDownloadRequestsQueryVariables = {
  id?: string | null,
  accountId?: ModelIDKeyConditionInput | null,
  filter?: ModelGlacierDownloadRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGlacierDownloadRequestsQuery = {
  listGlacierDownloadRequests?:  {
    __typename: "ModelGlacierDownloadRequestConnection",
    items:  Array< {
      __typename: "GlacierDownloadRequest",
      id: string,
      accountId: string,
      fileId: string,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GlacierDownloadRequestByCreatedAtQueryVariables = {
  createdAt?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGlacierDownloadRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GlacierDownloadRequestByCreatedAtQuery = {
  glacierDownloadRequestByCreatedAt?:  {
    __typename: "ModelGlacierDownloadRequestConnection",
    items:  Array< {
      __typename: "GlacierDownloadRequest",
      id: string,
      accountId: string,
      fileId: string,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    company: string,
    person: string,
    domain: string,
    logoUri: string,
    config: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    company: string,
    person: string,
    domain: string,
    logoUri: string,
    config: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    company: string,
    person: string,
    domain: string,
    logoUri: string,
    config: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateFileGroupSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnCreateFileGroupSubscription = {
  onCreateFileGroup?:  {
    __typename: "FileGroup",
    id: string,
    coverFileId?: string | null,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateFileGroupSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnUpdateFileGroupSubscription = {
  onUpdateFileGroup?:  {
    __typename: "FileGroup",
    id: string,
    coverFileId?: string | null,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteFileGroupSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnDeleteFileGroupSubscription = {
  onDeleteFileGroup?:  {
    __typename: "FileGroup",
    id: string,
    coverFileId?: string | null,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      groupId: string,
      rating?: number | null,
      title?: string | null,
      caption?: string | null,
      instructions?: string | null,
      memo?: string | null,
      fileName: string,
      protect: number,
      original?: string | null,
      caution: number,
      cautionInstructions?: string | null,
      reuse: number,
      reuseCondition?: string | null,
      keywords?: Array< string | null > | null,
      extension?: string | null,
      type?: string | null,
      meta?: string | null,
      tag?: Array< string | null > | null,
      customItems?: string | null,
      fileUri?: string | null,
      fileSize?: string | null,
      coverageId?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      group?:  {
        __typename: "FileGroup",
        id: string,
        coverFileId?: string | null,
        title?: string | null,
        description?: string | null,
        memo?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        file?:  {
          __typename: "File",
          id: string,
          groupId: string,
          rating?: number | null,
          title?: string | null,
          caption?: string | null,
          instructions?: string | null,
          memo?: string | null,
          fileName: string,
          protect: number,
          original?: string | null,
          caution: number,
          cautionInstructions?: string | null,
          reuse: number,
          reuseCondition?: string | null,
          keywords?: Array< string | null > | null,
          extension?: string | null,
          type?: string | null,
          meta?: string | null,
          tag?: Array< string | null > | null,
          customItems?: string | null,
          fileUri?: string | null,
          fileSize?: string | null,
          coverageId?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          group?:  {
            __typename: "FileGroup",
            id: string,
            coverFileId?: string | null,
            title?: string | null,
            description?: string | null,
            memo?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
          coverage?:  {
            __typename: "Coverage",
            id: string,
            title?: string | null,
            location: string,
            startAt: string,
            endAt: string,
            description?: string | null,
            memo?: string | null,
            attachmentFile?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            assignedUserId: string,
            status: number,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
      } | null,
      coverage?:  {
        __typename: "Coverage",
        id: string,
        title?: string | null,
        location: string,
        startAt: string,
        endAt: string,
        description?: string | null,
        memo?: string | null,
        attachmentFile?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        assignedUserId: string,
        status: number,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateFileSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnCreateFileSubscription = {
  onCreateFile?:  {
    __typename: "File",
    id: string,
    groupId: string,
    rating?: number | null,
    title?: string | null,
    caption?: string | null,
    instructions?: string | null,
    memo?: string | null,
    fileName: string,
    protect: number,
    original?: string | null,
    caution: number,
    cautionInstructions?: string | null,
    reuse: number,
    reuseCondition?: string | null,
    keywords?: Array< string | null > | null,
    extension?: string | null,
    type?: string | null,
    meta?: string | null,
    tag?: Array< string | null > | null,
    customItems?: string | null,
    fileUri?: string | null,
    fileSize?: string | null,
    coverageId?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    group?:  {
      __typename: "FileGroup",
      id: string,
      coverFileId?: string | null,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        groupId: string,
        rating?: number | null,
        title?: string | null,
        caption?: string | null,
        instructions?: string | null,
        memo?: string | null,
        fileName: string,
        protect: number,
        original?: string | null,
        caution: number,
        cautionInstructions?: string | null,
        reuse: number,
        reuseCondition?: string | null,
        keywords?: Array< string | null > | null,
        extension?: string | null,
        type?: string | null,
        meta?: string | null,
        tag?: Array< string | null > | null,
        customItems?: string | null,
        fileUri?: string | null,
        fileSize?: string | null,
        coverageId?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        group?:  {
          __typename: "FileGroup",
          id: string,
          coverFileId?: string | null,
          title?: string | null,
          description?: string | null,
          memo?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          file?:  {
            __typename: "File",
            id: string,
            groupId: string,
            rating?: number | null,
            title?: string | null,
            caption?: string | null,
            instructions?: string | null,
            memo?: string | null,
            fileName: string,
            protect: number,
            original?: string | null,
            caution: number,
            cautionInstructions?: string | null,
            reuse: number,
            reuseCondition?: string | null,
            keywords?: Array< string | null > | null,
            extension?: string | null,
            type?: string | null,
            meta?: string | null,
            tag?: Array< string | null > | null,
            customItems?: string | null,
            fileUri?: string | null,
            fileSize?: string | null,
            coverageId?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
        coverage?:  {
          __typename: "Coverage",
          id: string,
          title?: string | null,
          location: string,
          startAt: string,
          endAt: string,
          description?: string | null,
          memo?: string | null,
          attachmentFile?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          assignedUserId: string,
          status: number,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
    } | null,
    coverage?:  {
      __typename: "Coverage",
      id: string,
      title?: string | null,
      location: string,
      startAt: string,
      endAt: string,
      description?: string | null,
      memo?: string | null,
      attachmentFile?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      assignedUserId: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
  } | null,
};

export type OnUpdateFileSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnUpdateFileSubscription = {
  onUpdateFile?:  {
    __typename: "File",
    id: string,
    groupId: string,
    rating?: number | null,
    title?: string | null,
    caption?: string | null,
    instructions?: string | null,
    memo?: string | null,
    fileName: string,
    protect: number,
    original?: string | null,
    caution: number,
    cautionInstructions?: string | null,
    reuse: number,
    reuseCondition?: string | null,
    keywords?: Array< string | null > | null,
    extension?: string | null,
    type?: string | null,
    meta?: string | null,
    tag?: Array< string | null > | null,
    customItems?: string | null,
    fileUri?: string | null,
    fileSize?: string | null,
    coverageId?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    group?:  {
      __typename: "FileGroup",
      id: string,
      coverFileId?: string | null,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        groupId: string,
        rating?: number | null,
        title?: string | null,
        caption?: string | null,
        instructions?: string | null,
        memo?: string | null,
        fileName: string,
        protect: number,
        original?: string | null,
        caution: number,
        cautionInstructions?: string | null,
        reuse: number,
        reuseCondition?: string | null,
        keywords?: Array< string | null > | null,
        extension?: string | null,
        type?: string | null,
        meta?: string | null,
        tag?: Array< string | null > | null,
        customItems?: string | null,
        fileUri?: string | null,
        fileSize?: string | null,
        coverageId?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        group?:  {
          __typename: "FileGroup",
          id: string,
          coverFileId?: string | null,
          title?: string | null,
          description?: string | null,
          memo?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          file?:  {
            __typename: "File",
            id: string,
            groupId: string,
            rating?: number | null,
            title?: string | null,
            caption?: string | null,
            instructions?: string | null,
            memo?: string | null,
            fileName: string,
            protect: number,
            original?: string | null,
            caution: number,
            cautionInstructions?: string | null,
            reuse: number,
            reuseCondition?: string | null,
            keywords?: Array< string | null > | null,
            extension?: string | null,
            type?: string | null,
            meta?: string | null,
            tag?: Array< string | null > | null,
            customItems?: string | null,
            fileUri?: string | null,
            fileSize?: string | null,
            coverageId?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
        coverage?:  {
          __typename: "Coverage",
          id: string,
          title?: string | null,
          location: string,
          startAt: string,
          endAt: string,
          description?: string | null,
          memo?: string | null,
          attachmentFile?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          assignedUserId: string,
          status: number,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
    } | null,
    coverage?:  {
      __typename: "Coverage",
      id: string,
      title?: string | null,
      location: string,
      startAt: string,
      endAt: string,
      description?: string | null,
      memo?: string | null,
      attachmentFile?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      assignedUserId: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
  } | null,
};

export type OnDeleteFileSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnDeleteFileSubscription = {
  onDeleteFile?:  {
    __typename: "File",
    id: string,
    groupId: string,
    rating?: number | null,
    title?: string | null,
    caption?: string | null,
    instructions?: string | null,
    memo?: string | null,
    fileName: string,
    protect: number,
    original?: string | null,
    caution: number,
    cautionInstructions?: string | null,
    reuse: number,
    reuseCondition?: string | null,
    keywords?: Array< string | null > | null,
    extension?: string | null,
    type?: string | null,
    meta?: string | null,
    tag?: Array< string | null > | null,
    customItems?: string | null,
    fileUri?: string | null,
    fileSize?: string | null,
    coverageId?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    group?:  {
      __typename: "FileGroup",
      id: string,
      coverFileId?: string | null,
      title?: string | null,
      description?: string | null,
      memo?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        groupId: string,
        rating?: number | null,
        title?: string | null,
        caption?: string | null,
        instructions?: string | null,
        memo?: string | null,
        fileName: string,
        protect: number,
        original?: string | null,
        caution: number,
        cautionInstructions?: string | null,
        reuse: number,
        reuseCondition?: string | null,
        keywords?: Array< string | null > | null,
        extension?: string | null,
        type?: string | null,
        meta?: string | null,
        tag?: Array< string | null > | null,
        customItems?: string | null,
        fileUri?: string | null,
        fileSize?: string | null,
        coverageId?: string | null,
        createdUserId: string,
        ownedAccountId: string,
        ownedDepartment: string,
        deleted: number,
        deletedAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        group?:  {
          __typename: "FileGroup",
          id: string,
          coverFileId?: string | null,
          title?: string | null,
          description?: string | null,
          memo?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          file?:  {
            __typename: "File",
            id: string,
            groupId: string,
            rating?: number | null,
            title?: string | null,
            caption?: string | null,
            instructions?: string | null,
            memo?: string | null,
            fileName: string,
            protect: number,
            original?: string | null,
            caution: number,
            cautionInstructions?: string | null,
            reuse: number,
            reuseCondition?: string | null,
            keywords?: Array< string | null > | null,
            extension?: string | null,
            type?: string | null,
            meta?: string | null,
            tag?: Array< string | null > | null,
            customItems?: string | null,
            fileUri?: string | null,
            fileSize?: string | null,
            coverageId?: string | null,
            createdUserId: string,
            ownedAccountId: string,
            ownedDepartment: string,
            deleted: number,
            deletedAt?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | null,
        } | null,
        coverage?:  {
          __typename: "Coverage",
          id: string,
          title?: string | null,
          location: string,
          startAt: string,
          endAt: string,
          description?: string | null,
          memo?: string | null,
          attachmentFile?: string | null,
          createdUserId: string,
          ownedAccountId: string,
          ownedDepartment: string,
          assignedUserId: string,
          status: number,
          deleted: number,
          deletedAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
      } | null,
    } | null,
    coverage?:  {
      __typename: "Coverage",
      id: string,
      title?: string | null,
      location: string,
      startAt: string,
      endAt: string,
      description?: string | null,
      memo?: string | null,
      attachmentFile?: string | null,
      createdUserId: string,
      ownedAccountId: string,
      ownedDepartment: string,
      assignedUserId: string,
      status: number,
      deleted: number,
      deletedAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
  } | null,
};

export type OnCreateSharingSubscriptionVariables = {
  sharingUsers?: string | null,
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnCreateSharingSubscription = {
  onCreateSharing?:  {
    __typename: "Sharing",
    id: string,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    limitDate: string,
    limitTime: string,
    sharingUsers: Array< string | null >,
    sharingFiles: Array< string | null >,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateSharingSubscriptionVariables = {
  sharingUsers?: string | null,
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnUpdateSharingSubscription = {
  onUpdateSharing?:  {
    __typename: "Sharing",
    id: string,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    limitDate: string,
    limitTime: string,
    sharingUsers: Array< string | null >,
    sharingFiles: Array< string | null >,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteSharingSubscriptionVariables = {
  sharingUsers?: string | null,
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnDeleteSharingSubscription = {
  onDeleteSharing?:  {
    __typename: "Sharing",
    id: string,
    title?: string | null,
    description?: string | null,
    memo?: string | null,
    limitDate: string,
    limitTime: string,
    sharingUsers: Array< string | null >,
    sharingFiles: Array< string | null >,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateCoverageSubscriptionVariables = {
  assignedUserId?: string | null,
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnCreateCoverageSubscription = {
  onCreateCoverage?:  {
    __typename: "Coverage",
    id: string,
    title?: string | null,
    location: string,
    startAt: string,
    endAt: string,
    description?: string | null,
    memo?: string | null,
    attachmentFile?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    assignedUserId: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateCoverageSubscriptionVariables = {
  assignedUserId?: string | null,
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnUpdateCoverageSubscription = {
  onUpdateCoverage?:  {
    __typename: "Coverage",
    id: string,
    title?: string | null,
    location: string,
    startAt: string,
    endAt: string,
    description?: string | null,
    memo?: string | null,
    attachmentFile?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    assignedUserId: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteCoverageSubscriptionVariables = {
  assignedUserId?: string | null,
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnDeleteCoverageSubscription = {
  onDeleteCoverage?:  {
    __typename: "Coverage",
    id: string,
    title?: string | null,
    location: string,
    startAt: string,
    endAt: string,
    description?: string | null,
    memo?: string | null,
    attachmentFile?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    assignedUserId: string,
    status: number,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateCoverageInfoSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnCreateCoverageInfoSubscription = {
  onCreateCoverageInfo?:  {
    __typename: "CoverageInfo",
    id: string,
    startDate: string,
    endDate: string,
    body: string,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateCoverageInfoSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnUpdateCoverageInfoSubscription = {
  onUpdateCoverageInfo?:  {
    __typename: "CoverageInfo",
    id: string,
    startDate: string,
    endDate: string,
    body: string,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteCoverageInfoSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnDeleteCoverageInfoSubscription = {
  onDeleteCoverageInfo?:  {
    __typename: "CoverageInfo",
    id: string,
    startDate: string,
    endDate: string,
    body: string,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateCoverageDeskSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnCreateCoverageDeskSubscription = {
  onCreateCoverageDesk?:  {
    __typename: "CoverageDesk",
    id: string,
    startDate: string,
    endDate: string,
    name: string,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateCoverageDeskSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnUpdateCoverageDeskSubscription = {
  onUpdateCoverageDesk?:  {
    __typename: "CoverageDesk",
    id: string,
    startDate: string,
    endDate: string,
    name: string,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteCoverageDeskSubscriptionVariables = {
  createdUserId?: string | null,
  ownedDepartment?: string | null,
  ownedAccountId?: string | null,
};

export type OnDeleteCoverageDeskSubscription = {
  onDeleteCoverageDesk?:  {
    __typename: "CoverageDesk",
    id: string,
    startDate: string,
    endDate: string,
    name: string,
    memo?: string | null,
    createdUserId: string,
    ownedAccountId: string,
    ownedDepartment: string,
    deleted: number,
    deletedAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateHistorySubscriptionVariables = {
  accountId?: string | null,
};

export type OnCreateHistorySubscription = {
  onCreateHistory?:  {
    __typename: "History",
    id: string,
    data: string,
    body: string,
    operation: string,
    userId: string,
    accountId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateHistorySubscriptionVariables = {
  accountId?: string | null,
};

export type OnUpdateHistorySubscription = {
  onUpdateHistory?:  {
    __typename: "History",
    id: string,
    data: string,
    body: string,
    operation: string,
    userId: string,
    accountId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteHistorySubscriptionVariables = {
  accountId?: string | null,
};

export type OnDeleteHistorySubscription = {
  onDeleteHistory?:  {
    __typename: "History",
    id: string,
    data: string,
    body: string,
    operation: string,
    userId: string,
    accountId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateChargeSubscriptionVariables = {
  accountId?: string | null,
};

export type OnCreateChargeSubscription = {
  onCreateCharge?:  {
    __typename: "Charge",
    id: string,
    accountId: string,
    aggregationDate: string,
    status: number,
    basicCharge: number,
    s3Charge: number,
    excessTransferredCharge: number,
    s3StdUsageSize: string,
    s3IaUsageSize: string,
    s3GlIrUsageSize?: string | null,
    s3GlUsageSize: string,
    transferredSize: string,
    downloadRequestCont: number,
    excludingTax: number,
    includingTax: number,
    taxRate: number,
    exchangeRate: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateChargeSubscriptionVariables = {
  accountId?: string | null,
};

export type OnUpdateChargeSubscription = {
  onUpdateCharge?:  {
    __typename: "Charge",
    id: string,
    accountId: string,
    aggregationDate: string,
    status: number,
    basicCharge: number,
    s3Charge: number,
    excessTransferredCharge: number,
    s3StdUsageSize: string,
    s3IaUsageSize: string,
    s3GlIrUsageSize?: string | null,
    s3GlUsageSize: string,
    transferredSize: string,
    downloadRequestCont: number,
    excludingTax: number,
    includingTax: number,
    taxRate: number,
    exchangeRate: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteChargeSubscriptionVariables = {
  accountId?: string | null,
};

export type OnDeleteChargeSubscription = {
  onDeleteCharge?:  {
    __typename: "Charge",
    id: string,
    accountId: string,
    aggregationDate: string,
    status: number,
    basicCharge: number,
    s3Charge: number,
    excessTransferredCharge: number,
    s3StdUsageSize: string,
    s3IaUsageSize: string,
    s3GlIrUsageSize?: string | null,
    s3GlUsageSize: string,
    transferredSize: string,
    downloadRequestCont: number,
    excludingTax: number,
    includingTax: number,
    taxRate: number,
    exchangeRate: number,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateFileDownloadHistorySubscription = {
  onCreateFileDownloadHistory?:  {
    __typename: "FileDownloadHistory",
    id: string,
    accountId: string,
    fileId: string,
    fileSize: string,
    storageClass?: string | null,
    userId: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateFileDownloadHistorySubscription = {
  onUpdateFileDownloadHistory?:  {
    __typename: "FileDownloadHistory",
    id: string,
    accountId: string,
    fileId: string,
    fileSize: string,
    storageClass?: string | null,
    userId: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteFileDownloadHistorySubscription = {
  onDeleteFileDownloadHistory?:  {
    __typename: "FileDownloadHistory",
    id: string,
    accountId: string,
    fileId: string,
    fileSize: string,
    storageClass?: string | null,
    userId: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateGlacierDownloadRequestSubscriptionVariables = {
  userId?: string | null,
};

export type OnCreateGlacierDownloadRequestSubscription = {
  onCreateGlacierDownloadRequest?:  {
    __typename: "GlacierDownloadRequest",
    id: string,
    accountId: string,
    fileId: string,
    userId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateGlacierDownloadRequestSubscriptionVariables = {
  userId?: string | null,
};

export type OnUpdateGlacierDownloadRequestSubscription = {
  onUpdateGlacierDownloadRequest?:  {
    __typename: "GlacierDownloadRequest",
    id: string,
    accountId: string,
    fileId: string,
    userId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteGlacierDownloadRequestSubscriptionVariables = {
  userId?: string | null,
};

export type OnDeleteGlacierDownloadRequestSubscription = {
  onDeleteGlacierDownloadRequest?:  {
    __typename: "GlacierDownloadRequest",
    id: string,
    accountId: string,
    fileId: string,
    userId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};
