
















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { SearchCondition, FileSearchService, FileGroupData } from '@/services/files/FileSearch'
import FileGroupCard from "@/components/files/searches/FileGroupCard.vue";
import FileSearchHeader from "@/components/files/searches/FileSearchHeader.vue";
import FileSearchPagination from "@/components/files/searches/FileSearchPagination.vue";
import { scrollTop } from "@/utils/FileUtils";
import Loading from "@/components/Loading.vue";
import Dialog from "@/components/Dialog.vue";
import { isAllow } from '@/utils/RoleUtils';
import { isActiveServie } from '@/utils/AccountSettingUtils';

@Component({
  components: {
    FileSearchHeader,
    FileGroupCard,
    FileSearchPagination,
    Loading,
    Dialog,
  }
})
export default class FileGroupSearchResult extends Vue {
  private mode = 'group';
  private select = false;
  private selectedGroups = [];

  private total = 0;
  private groups: FileGroupData[] = [];
  private limit = 36;
  private attributes;
  
  private page = 1;
  private length = 0;

  // private subscriptionCreate;
  // private subscriptionUpdate;
  private fileSearchService = new FileSearchService();
  private condition: SearchCondition = {};

  private loading = false;
  private resultDialog = false;
  private resultDialogMode = '';
  private resultDialogTitle = '';
  private resultDialogMessage = '';
  private isSearchLimit = false;

  @Watch('$route')
  private async onChangeRoute(){
    this.loading = true;
    await this.search();
  }

  private get from(): number {
    return ((this.page - 1 ) > 0 ? (this.page - 1 ) : 0) * this.limit;
  }

  private get to(): number {
    if(this.from + this.limit >= FileSearchService.SEARCH_LIMIT) {
      return FileSearchService.SEARCH_LIMIT - this.from;
    }
    return this.limit;
  }

  private async created(){
    this.$store.commit("setGroupDetailPage", 1);
    this.page = this.$store.getters.searchResultPage ? this.$store.getters.searchResultPage : 1;
    const user = this.$store.getters.user;
    this.attributes = user?.attributes;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter: any = {};
    if(isAllow("filing", "search", "COMPANY") && await isActiveServie("filing")){
      filter.ownedAccountId = this.attributes["custom:account_id"];
    }else if(isAllow("filing", "search", "DEPARTMENT") && await isActiveServie("filing")){
      filter.ownedDepartment = this.attributes["custom:department"];
    }else if(isAllow("filing", "search", "SELF") && await isActiveServie("filing")){
      filter.createdUserId = this.attributes.sub;
    }
  }

  private async search(){
    this.isSearchLimit = false;
    this.page = this.$store.getters.searchResultPage ? this.$store.getters.searchResultPage : 1;
    this.condition = this.$store.getters.searchCondition ? JSON.parse(JSON.stringify(this.$store.getters.searchCondition)) : {};
    this.condition.groupIds = undefined;
    this.$store.commit("setSearchCondition", this.condition);

    try{
      const result = await this.fileSearchService.searchFileGroups(this.condition, undefined, this.from, this.to);
      this.loading = false;
      if(result && result.items){
        this.groups = result.items || [];
        this.total = result.total || 0;
        this.length = result.length || 0;
      }
    }catch(error){
      this.groups = [];
      this.total = 0;
      this.length = 0;
      this.loading = false;
      this.resultDialog = true;
      this.resultDialogMode = "error";
      this.resultDialogTitle = "検索失敗";
      this.resultDialogMessage = `検索に失敗しました。\n再度、検索をお試しください。`;
    }finally{
      this.loading = false;
      this.isSearchLimit = this.total > FileSearchService.SEARCH_LIMIT && (this.from + this.limit >= FileSearchService.SEARCH_LIMIT) ? true : false;
    }
  }
  
  private async onReload(){
    this.loading = true;
    await this.search();
  }
  
  private onChangeSelectMode(value){
    this.select = value;
    this.selectedGroups = [];
  }

  private async onPageNumberChanged(value){
    this.page = value;
    this.$store.commit("setSearchResultPage", this.page);
    this.loading = true;

    if(this.$store.getters.from?.name === "FileGroupEdit"){
      setTimeout(() => {
        this.$store.commit("setFrom", "");
        this.$store.commit("setTo", "");
        this.search();
      }, 3000);
    }else{
      scrollTop();
      await this.search();
    }
  }

  private async onChangeFileSearchHeaderResult(value){
    if(value){
      this.loading = true;
      setTimeout(() => {
          this.search();
      }, 3000);
    }
  }

  private onChangeResultDialogClosed(){
    this.resultDialog = false;
  }

  private async onChangeFileGroupCardResult(value){
    if(value){
      this.loading = true;
      setTimeout(() => {
        this.search();
      }, 3000);
    }
  }
}
