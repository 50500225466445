






























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import * as PageAction from "@/utils/PageActionUtils";
import * as AuthUtils from "@/utils/AuthUtils";
import * as service from "@/services/coverage/coverageServise";
import CoverageBaseForm from "./CoverageBaseForm.vue";
import Dialog from "@/components/Dialog.vue";
import Loading from "@/components/Loading.vue";
import { PGCService } from "@/API";

@Component({
  components: {
    Dialog,
    Loading,
  },
})
export default class CoverageCreateForm extends CoverageBaseForm {
  name = "coverage-create-form";

  async created() {
    this.selectUsers = await AuthUtils.listUsers(PGCService.COVERAGE);
    await this.setDefault();
  }

  @Watch('$store.getters.selectCoverageDate')
  @Watch('$store.getters.selectCoverageDateTo')
  private onChangeCoverageDate(){
    if(this.$store.getters.selectCoverageDateTo || !this.$store.getters.selectCoverageDate){
      this.startDate = new Date().toISOString().substr(0, 10);
      this.endDate = new Date().toISOString().substr(0, 10);
    }else{
      this.startDate = this.$store.getters.selectCoverageDate ? this.$store.getters.selectCoverageDate : new Date().toISOString().substr(0, 10);
      this.endDate = this.$store.getters.selectCoverageDate ? this.$store.getters.selectCoverageDate : new Date().toISOString().substr(0, 10);
    }
  }

  async createCoverage() {
    if (
      !(this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      return;
    }

    if(this.isExtraInvalid){
      return;
    }
    
    this.loading = true;
    const attributes = this.$store.getters.user?.attributes;
    const form: service.InputForm = {
      createdUserId: attributes.sub,
      accountId: attributes["custom:account_id"],
      department: attributes["custom:department"],
      title: this.title,
      startAt: this.dateformat(this.startDate, this.startTime),
      endAt: this.dateformat(this.endDate, this.endTime),
      description: this.description,
      location: this.location,
      memo: this.memo,
      assignedUserId: this.assignedUserId,
      files: this.files,
    };
    await this.CoverageServise.createCoverage(form)
      .then(() => {
        PageAction.showCoverageForm();
        this.setDefault();
        (this.$refs.form as Vue & {
          resetValidation: () => void;
        }).resetValidation();
      })
      .catch((e) => {
        console.log(e);
        this.resultDialog = true;
        this.resultDialogMode = "error";
        this.resultDialogTitle = "取材予定登録失敗";
        this.resultDialogMessage = `取材予定の登録に失敗しました。`;
      })
      .finally(() => {
        this.loading = false;
        this.formClosed(true);
      });
  }

  formClosed(value){
    console.log("createformclosed", value)
    this.$emit('coverage-create-form-closed', value);
  }
}
